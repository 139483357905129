import { CloseHandler, OpenHandler } from '@minoru/react-dnd-treeview';
import { atom, selector } from 'recoil';

import type { ITaxonomyNode } from '../types/taxonomy.types';
import { selectedTaxonomyState } from './global.state';
import { graphState, searchResultsState, selectedNodeState } from './tree.state';

export const searchValueState = atom<string | null>({
	key: 'searchValue',
	default: null,
});

export const treeMenuOpenState = atom<boolean>({
	key: 'treeMenuOpen',
	default: false,
});

export const kebabOpenedState = atom<boolean>({
	key: 'kebabOpened',
	default: false,
});

export const kebabPositionState = atom<number>({
	key: 'kebabPosition',
	default: 0,
});

export const closeTreeMenuNodesState = atom<CloseHandler | null>({
	key: 'closeTreeMenuNodes',
	default: null,
});

export const openTreeMenuNodesState = atom<OpenHandler | null>({
	key: 'openTreeMenuNodes',
	default: null,
});

export const closeTreeMenuState = atom<(() => void) | null>({
	key: 'closeTreeMenu',
	default: null,
});

export const pinnedSearchResultsState = atom<ITaxonomyNode[]>({
	key: 'pinnedSearchResults',
	default: [],
});

interface TreeMenuNode {
	droppable: boolean;
	id: string | number;
	parent: string | number;
	text: string;
}
export const treeMenuNodesState = selector<TreeMenuNode[]>({
	key: 'treeMenuNodes',
	get: ({ get }) => {
		const graph = get(graphState);
		const rootNodes = new Set(graph.entryNodes());
		const { edges, nodes } = get(selectedTaxonomyState).tree;

		const mappedNodes = new Map(nodes.map(node => [node.id, node]));

		const saturatedNodes = edges.map(({ from, to }) => {
			const node = mappedNodes.get(to as string);

			return {
				droppable: true,
				id: to as string,
				parent: rootNodes.has(String(from)) ? 0 : (from as string),
				text: node?.text as string,
			};
		});

		return saturatedNodes;
	},
});

export const selectedTreeMenuNodeState = selector({
	key: 'selectedTreeMenuNode',
	get: ({ get }) => {
		const selectedNode = get(selectedNodeState);
		const treeMenuNodes = get(treeMenuNodesState);

		return treeMenuNodes?.find(node => node.id === selectedNode?.id);
	},
});

export const resultsOpenedState = selector({
	key: 'resultsOpened',
	get: ({ get }) => {
		const searchResults = get(searchResultsState);

		return searchResults.results.length > 0;
	},
});
