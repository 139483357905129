import { useRecoilValue, useResetRecoilState } from 'recoil';

import { useOutsideClick } from '../../../../hooks';
import {
	useSetAddNodeModalAction,
	useSetDeleteNodeModalAction,
	useSetRenameNodeModalAction,
} from '../../../../services/modal.services';
import { useHideNodeNeighbours } from '../../../../services/node.services';
import { selectedNodeState } from '../../../../state/tree.state';
import { kebabOpenedState, kebabPositionState } from '../../../../state/treeMenuBar.state';
import { TREE_MENU_TESTS_IDS } from '../../treeMenuBar.constants';
import * as Style from './kebabMenu.styles';

export const KebabMenu = () => {
	const resetKebabOpened = useResetRecoilState(kebabOpenedState);
	const kebabPosition = useRecoilValue(kebabPositionState);
	const selectedNode = useRecoilValue(selectedNodeState);

	const hideNodeNeighbours = useHideNodeNeighbours();
	const setAddNodeModalAction = useSetAddNodeModalAction();
	const setDeleteNodeModalAction = useSetDeleteNodeModalAction();
	const setRenameNodeModalAction = useSetRenameNodeModalAction();

	const kebabRef = useOutsideClick(() => resetKebabOpened());

	return (
		<Style.KebabMenu positionY={kebabPosition} ref={kebabRef} data-testid={TREE_MENU_TESTS_IDS.KEBAB_MENU}>
			<Style.Square />
			<Style.KebabText
				onClick={() => {
					resetKebabOpened();
					setAddNodeModalAction(selectedNode?.text);
				}}
				data-testid={TREE_MENU_TESTS_IDS.ADD_BUTTON}
			>
				Add a Child Node
			</Style.KebabText>
			<Style.KebabText onClick={() => hideNodeNeighbours(selectedNode?.id as string)}>Hide Neighbours</Style.KebabText>
			<Style.Hr />
			<Style.KebabText
				onClick={() => {
					resetKebabOpened();
					setRenameNodeModalAction(selectedNode?.text);
				}}
				data-testid={TREE_MENU_TESTS_IDS.RENAME_BUTTON}
			>
				Rename Node
			</Style.KebabText>
			<Style.KebabDeleteText
				onClick={() => {
					resetKebabOpened();
					setDeleteNodeModalAction();
				}}
				data-testid={TREE_MENU_TESTS_IDS.DELETE_BUTTON}
			>
				Delete Node
			</Style.KebabDeleteText>
		</Style.KebabMenu>
	);
};
