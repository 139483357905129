import { memo, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { infoBarModeState, infoBarOpenState } from '../../state/infoBar.state';
import { selectedNodeState } from '../../state/tree.state';
import { Description, Header, NodeContentList } from './atomComponents';
import { INFO_BAR, INFO_BAR_TESTS_IDS } from './infoBar.constants';
import * as Style from './infoBar.styles';

export const InfoBarBody = () => {
	const infoBarOpen = useRecoilValue(infoBarOpenState);
	const selectedNode = useRecoilValue(selectedNodeState);
	const setInfoBarMode = useSetRecoilState(infoBarModeState);

	useEffect(() => {
		setInfoBarMode(undefined);
	}, [selectedNode, setInfoBarMode]);

	return (
		<Style.InfoBar data-testid={INFO_BAR_TESTS_IDS.INFO_BAR} open={infoBarOpen}>
			<Header />
			<Style.DataContainer>
				<Description data-testid={INFO_BAR_TESTS_IDS.INFO_BAR_DESCRIPTION} />
				<NodeContentList data-testid={INFO_BAR_TESTS_IDS.INFO_BAR_EXAMPLES} formType={INFO_BAR.EXAMPLES} />
				<NodeContentList data-testid={INFO_BAR_TESTS_IDS.INFO_BAR_SYNONYMS} formType={INFO_BAR.SYNONYMS} />
			</Style.DataContainer>
		</Style.InfoBar>
	);
};

export const InfoBar = memo(InfoBarBody);
