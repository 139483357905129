import intersection from 'lodash/intersection';
import uniqBy from 'lodash/unionBy';
import { ParseResult } from 'papaparse';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import { TREE_SETTINGS } from '../components/tree/tree.constants';
import { CSV } from '../constants/csv.constants';
import { TAXONOMY_ERRORS } from '../constants/errors.constants';
import {
	selectedTaxonomyState,
	taxonomiesNamesState,
	taxonomyErrorState,
	taxonomyToImportState,
	tempFileNameState,
} from '../state/global.state';
import { headerDropdownOpenState } from '../state/header.state';
import type { ISynonym, ITaxonomyEdge, ITaxonomyNode, TaxonomyTreeWithChildrenNode } from '../types/taxonomy.types';
import { useDirectDependenciesOf } from './graph.services';
import { useSetRenameImportedTaxonomyModalAction } from './modal.services';

interface ILevels {
	level0: IParseResult;
	level1: IParseResult;
	level2: IParseResult;
	level3: IParseResult;
	level4: IParseResult;
	level5: IParseResult;
	topic: IParseResult;
	primary: boolean;
}

interface IParseResult {
	text: string;
	id: string;
	data: { level: keyof ILevels; path?: string };
	path?: string;
}

interface SynonymsData {
	[key: string]: ISynonym | IParseResult;
}

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const getNodes = (rowNodes: IParseResult[], rootNode: IParseResult, synonymsMap: Map<string, ISynonym[]>): ITaxonomyNode[] =>
	rowNodes.map(node =>
		node.text === rootNode?.text
			? { ...node, id: TREE_SETTINGS.ROOT_NODE }
			: synonymsMap.has(node.id)
			? { ...node, data: { ...node.data, synonyms: synonymsMap.get(node.id) } }
			: node,
	);

const generateKey = (text: string, path?: string) => (path ? `${path}::${text}` : `${text}`);

export const mapNodes = (nodes: Array<ITaxonomyNode>) => {
	return new Map<string, string>(
		nodes.map(item => {
			return [generateKey(item.text, item.data?.path), item.id];
		}),
	);
};

const mapSynonyms = (results: Array<ILevels>) => {
	const resultsForSynonyms = results as unknown as SynonymsData[];

	return new Map(
		resultsForSynonyms.reduce<[string, ISynonym[]][]>((acc, row) => {
			const lastElement =
				row['topic'] ?? row['level5'] ?? row['level4'] ?? row['level3'] ?? row['level2'] ?? (row['level1'] as IParseResult);

			const synonyms: ISynonym[] = [];

			Object.keys(row).forEach(key => {
				if (key.startsWith('synonym') && row[key]) synonyms.push(row[key] as ISynonym);
			});

			if (synonyms.length > 0) return [...acc, [lastElement.id, synonyms]];

			return acc;
		}, []),
	);
};

const getEdges = (results: string[][], nodes: Array<ITaxonomyNode>) => {
	const edges: ITaxonomyEdge[] = [];
	const mappedNodes = mapNodes(nodes);

	const findNode = (text: string, path: string, topic?: boolean) => {
		const levelKey = topic ? generateKey(text, 'granularTopic') : generateKey(text, path);
		return mappedNodes.get(levelKey);
	};

	results.forEach(entry => {
		const pathToCheck: string[] = [];
		for (let i = 1; i < entry.length - 1; i++) {
			if (entry[i]) {
				const from = findNode(entry[i], pathToCheck && pathToCheck.join('::'));
				let to;
				pathToCheck.push(entry[i]);
				for (let j = i + 1; j < entry.length; j++) {
					to = findNode(entry[j], pathToCheck.join('::'), j === entry.length - 1);
					if (to) break;
				}
				if (from && to) edges.push({ from, to, id: uuidv4(), primary: entry[0] === 'primary' });
			}
		}
	});

	const uniqEdges = uniqBy(edges, ({ from, to }) => [from, to].join());

	return uniqEdges;
};

export const useLoadingStart = () => {
	const resetHeaderDropdownOpen = useResetRecoilState(headerDropdownOpenState);
	const resetSelectedTaxonomy = useResetRecoilState(selectedTaxonomyState);
	const resetTaxonomyError = useResetRecoilState(taxonomyErrorState);
	const resetTaxonomyToImport = useResetRecoilState(taxonomyToImportState);
	const resetTempFileName = useResetRecoilState(tempFileNameState);

	return () => {
		resetTaxonomyToImport();
		resetHeaderDropdownOpen();
		resetTaxonomyError();
		resetTempFileName();
		resetSelectedTaxonomy();
	};
};

export const useUploadAccepted = () => {
	const setTaxonomyError = useSetRecoilState(taxonomyErrorState);
	const setTaxonomyToImport = useSetRecoilState(taxonomyToImportState);
	const setTempFileName = useSetRecoilState(tempFileNameState);
	const resetTempFileName = useResetRecoilState(tempFileNameState);
	const taxonomiesNames = useRecoilValue(taxonomiesNamesState);

	const setRenameImportedTaxonomyModalAction = useSetRenameImportedTaxonomyModalAction();

	const taxonomyKeys: Array<keyof ILevels> = ['level0', 'level1', 'level2', 'level3', 'level4', 'level5', 'topic'];

	return async (results: ParseResult<ILevels>, file: File) => {
		const fileName = file.name.replace('.csv', '');
		setTempFileName(fileName);

		const defaultRootNode: IParseResult = {
			text: fileName,
			id: TREE_SETTINGS.ROOT_NODE,
			path: '',
			data: { level: taxonomyKeys[0] },
		};

		// In case there is no root node in the csv file, we are manually adding a node with the name of the file
		const updatedResults = results.data.map(entry =>
			Object.keys(entry).includes('level0') ? entry : { ...entry, level0: defaultRootNode },
		);
		await delay(2000);

		const currentLevels = intersection(taxonomyKeys).sort((a, b) => a.localeCompare(b)) as Array<keyof Omit<ILevels, 'primary'>>;

		if (currentLevels.length === 0) return setTaxonomyError(TAXONOMY_ERRORS.BAD_CSV);

		const pathsSet = new Set<string[]>();
		const paths = updatedResults.map(entry => {
			const path = currentLevels.reduce<string[]>((acc, item) => {
				if (entry[item]) {
					acc.push(entry[item].text);
				}

				return acc;
			}, []);
			path.unshift(entry['primary'] ? 'primary' : '');
			pathsSet.add(path);
			return path;
		});

		const findNodeInSet = (nodes: Set<IParseResult>, text: string, path: string, level?: string): boolean => {
			for (const node of nodes) {
				if (
					(node.data?.level === level && level === 'topic' && node.text === text) ||
					(node.text === text && node.data?.path === path)
				) {
					return true;
				}
			}
			return false;
		};

		const rawNodesSet = new Set<IParseResult>();

		paths.forEach(path => {
			path.forEach((item, index) => {
				if (index > 0) {
					const parentPath: string[] = path.slice(1, index);
					const parent = parentPath.length > 0 ? parentPath.join('::') : '';

					const node = {
						text: item,
						id: uuidv4(),
						data: {
							level: index < path.length - 1 ? currentLevels[index] : 'topic',
							path: index < path.length - 1 ? parent : 'granularTopic',
						},
					};
					!findNodeInSet(rawNodesSet, node.text, node.data?.path, node.data?.level) && rawNodesSet.add(node);
				}
			});
		});

		const rawNodes = Array.from(rawNodesSet);

		const rootNode = rawNodes?.find(node => node.data.level === taxonomyKeys[0]) || defaultRootNode;
		if (!rootNode) return setTaxonomyError(TAXONOMY_ERRORS.ROOT_NODE);

		const synonymsMap = mapSynonyms(updatedResults);
		const nodes: ITaxonomyNode[] = getNodes(rawNodes, rootNode, synonymsMap);
		const edges = getEdges(Array.from(pathsSet), nodes);

		const taxonomy = {
			taxonomyId: '',
			taxonomyName: fileName,
			tree: { nodes, edges, logs: [] },
		};

		if (taxonomiesNames.includes(taxonomy.taxonomyName)) {
			resetTempFileName();
			setRenameImportedTaxonomyModalAction(taxonomy.taxonomyName);
		}

		setTaxonomyToImport(taxonomy);
	};
};

export const useTreeToPaths = () => {
	const findPathsFromRoot = (
		tree: Map<string, TaxonomyTreeWithChildrenNode>,
		startNodeId: string,
		currentPath: string[] = [],
	): string[][] => {
		const currentPathCopy = [startNodeId, ...currentPath];

		if (tree.get(startNodeId)?.childrenIds.length === 0) {
			return [currentPathCopy.reverse()];
		}

		const paths: string[][] = [];
		const childrenIds = tree.get(startNodeId)?.childrenIds;

		if (childrenIds && childrenIds.length) {
			for (const childId of childrenIds) {
				const childPaths = findPathsFromRoot(tree, childId, currentPathCopy);

				paths.push(...childPaths);
			}
		}

		return paths;
	};

	return (tree: Map<string, TaxonomyTreeWithChildrenNode>) => {
		return findPathsFromRoot(tree, TREE_SETTINGS.ROOT_NODE);
	};
};

export const useManipulateTree = () => {
	const directDependenciesOf = useDirectDependenciesOf();

	return (nodes: ITaxonomyNode[]) => {
		const manipulatedTreeMap = new Map<string, TaxonomyTreeWithChildrenNode>();

		nodes.forEach(node => {
			manipulatedTreeMap.set(node.id, {
				id: node.id,
				text: node.text,
				childrenIds: directDependenciesOf(node.id),
			});
		});

		return manipulatedTreeMap;
	};
};

export const usePrepareCsvToExport = () => {
	const { tree } = useRecoilValue(selectedTaxonomyState);
	const treeToPath = useTreeToPaths();
	const manipulateTree = useManipulateTree();

	return ({ nodesMap }: { nodesMap: Map<string, ITaxonomyNode> }) => {
		const manipulatedTree = manipulateTree(tree.nodes);
		const manipulatedTreeNodes = treeToPath(manipulatedTree);

		const getNamesByIds = ({ nodesMap }: { nodesMap: Map<string, ITaxonomyNode> }) => {
			const namesArray: ITaxonomyNode[][] = [];
			if (manipulatedTreeNodes) {
				manipulatedTreeNodes.map(path => {
					const names = path.map(id => nodesMap.get(id)) as ITaxonomyNode[];
					names && namesArray.push(names);
				});
			}

			const row = namesArray.map(item => {
				const granularTopic = item?.at(-1);
				if (granularTopic) {
					return generateCsvRow(granularTopic, item);
				}
			});

			return row || [];
		};

		const createSynonymsArray = (granularTopic: ITaxonomyNode): string[] => {
			const synonymsArray = new Array(CSV.SYNONYMS_COUNT);
			if (granularTopic?.data?.synonyms) {
				granularTopic.data.synonyms.forEach((synonym: ISynonym, idx: number) => {
					synonymsArray[idx] = synonym.content;
				});
			}
			return synonymsArray;
		};

		const createLevelsArray = (dependenciesArray: ITaxonomyNode[]): string[] => {
			const levelsArray = new Array(CSV.LEVELS_COUNT);
			const granularItem = dependenciesArray.at(-1);
			levelsArray[0] = granularItem?.text;
			for (let i = 1; i < dependenciesArray.length - 1; i++) {
				levelsArray[levelsArray.length - i] = dependenciesArray[i].text;
			}
			return levelsArray;
		};

		const detectPrimary = (dependenciesArray: ITaxonomyNode[]): string => {
			const primaryEdges = tree.edges.filter(edge => edge.primary);
			if (dependenciesArray.length <= 1) return '';
			return primaryEdges.some(edge => edge.to === dependenciesArray.at(-1)?.id && edge.from === dependenciesArray.at(-2)?.id)
				? ''
				: 'secondary';
		};

		const generateCsvRow = (granularTopic: ITaxonomyNode, dependenciesArray: ITaxonomyNode[]) => {
			const csvRow = [
				detectPrimary(dependenciesArray),
				...createLevelsArray(dependenciesArray),
				...createSynonymsArray(granularTopic),
			];
			return csvRow;
		};

		const result = getNamesByIds({ nodesMap });
		return result;
	};
};
