const WARNING_SUFFIX = '{warning}';

export const ERROR_TYPE = {
	duplicate: 'duplicate',
	generalError: 'general-{error}',
	generalWarning: `general-${WARNING_SUFFIX}`,
	warningSuffix: WARNING_SUFFIX,
};

export const GENERAL_ERRORS = {
	DUPLICATE_VALUE: 'Item has duplicate value',
	INPUT_RANGE: 'Name must be between 2-60 characters',
	MULTITAGS_GENERAL_ERROR: 'There are errors in some of the highlighted nodes. Click on each node to view its error.',
	MULTITAGS_GENERAL_WARNING: 'There are warnings in some of the highlighted nodes. Click on each node to view its warning.',
	MULTITAGS_EXISTS_IN_LIST: 'Exists in the list',
};

export const TAXONOMY_ERRORS = {
	TAXONOMY: 'Taxonomy could not be loaded',
	REQUIRED: 'Taxonomy name is required',
	ROOT_NODE: 'Taxonomy has no root node',
	BAD_CSV: 'CSV file is not valid',
	NAME_ALREADY_TAKEN: 'Try another name that is not one of your taxonomies',
	GRANULAR_TOPIC_REPETITION: 'There is already granular topic with this name, please change',
	BAD_JSON: 'JSON file is not valid',
	NOT_JSON: 'You can only upload JSON files',
	EXPORT_CSV: 'CSV file could not be exported',
};

export const NODE_ERRORS = {
	REQUIRED: 'Node name is required',
	NODE_EXIST_IN_THE_SAME_LEVEL: 'Node name already exists in this level',
	NODE_NAME_EXIST: 'Node name exists in:{{matched-nodes}}',
	GRANULAR_TOPIC_REPETITION: 'There is already granular topic with this name, please change',
};

export const AUTH_ERRORS = {
	TOKEN_EXPIRED: 'Token expired',
};

export const CONNECTION_ERRORS = {
	NETWORK: 'Network error occurred, please try again.',
};
