import cn from 'classnames';
import { KeyboardEvent, memo, MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import { NodeData, NodeProps, Port, Remove } from 'reaflow';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { useMultipleParents } from '../../../../services/graph.services';
import { useSetDeleteNodeModalAction } from '../../../../services/modal.services';
import { useToggleCollapseNode } from '../../../../services/node.services';
import { headerDropdownOpenState, userMenuOpenState } from '../../../../state/header.state';
import { connectionModeState, granularTopicsState, selectedNodeState } from '../../../../state/tree.state';
import type { ITaxonomyNode } from '../../../../types/taxonomy.types';
import { DRAG_TYPE, TREE_SETTINGS, TREE_TESTS_IDS } from '../../tree.constants';
import style from './taxonomyNode.module.css';
import * as Style from './taxonomyNode.styles';

type TaxonomyNodeProps = {
	onKeyDown: ((event: KeyboardEvent<SVGGElement>, data: NodeData) => void) | null | undefined;
	toggleSelection: (value: string) => void;
	selections: string[];
} & NodeProps;

const toggleChild = (arr: string[], item: string) => (arr.includes(item) ? arr.filter(i => i !== item) : [...arr, item]);

const NodeIcon = ({ children, width, height }: { children: ReactNode; width?: number; height?: number }) => (
	<Style.NodeIcon width={width} height={height}>
		<Style.IconWrapper>{children}</Style.IconWrapper>
	</Style.NodeIcon>
);

export const TaxonomyNode = memo(function TaxonomyNode({
	onKeyDown,
	toggleSelection,
	selections,
	...nodeProps
}: TaxonomyNodeProps) {
	const [connectionMode, setConnectionMode] = useRecoilState(connectionModeState);
	const resetHeaderDropdownOpen = useResetRecoilState(headerDropdownOpenState);
	const granularTopics = useRecoilValue(granularTopicsState);
	const [selectedNode, setSelectedNode] = useRecoilState(selectedNodeState);
	const resetUserMenuOpen = useResetRecoilState(userMenuOpenState);
	const [expandable, setExpandable] = useState(false);
	const [isSelected, setIsSelected] = useState(false);
	const [isBlocked, setIsBlocked] = useState(false);
	const [multiple, setMultiple] = useState(false);

	const setDeleteNodeModalAction = useSetDeleteNodeModalAction();
	const multipleParents = useMultipleParents();

	const detectSelection = useCallback(() => {
		if (selectedNode?.id === nodeProps.properties.id) return true;
		if (selections.includes(nodeProps.properties.id)) return true;
		if (connectionMode.parent === nodeProps.properties.id) return true;
		return false;
	}, [connectionMode.parent, nodeProps.properties.id, selectedNode?.id, selections]);

	useEffect(() => {
		setIsSelected(detectSelection());
	}, [detectSelection]);

	useEffect(() => {
		setExpandable(!granularTopics?.some(node => node.id === nodeProps.properties.id));
	}, [granularTopics, nodeProps.properties.id]);

	useEffect(() => {
		if (connectionMode.activeParent) setIsBlocked(selections.includes(nodeProps.properties.id));
		if (!connectionMode.active) setIsBlocked(false);
	}, [connectionMode, nodeProps.properties.id, selections]);

	useEffect(() => {
		setMultiple(multipleParents(nodeProps.properties.id));
	}, [multipleParents, nodeProps.properties.id]);

	let timer: ReturnType<typeof setTimeout>;

	const toggleCollapseNode = useToggleCollapseNode();

	const handleOneClick = (data: ITaxonomyNode) => {
		if (connectionMode.activeParent) return setConnectionMode(prevState => ({ ...prevState, parent: data.id }));

		if (connectionMode.active) {
			toggleSelection(data.id);
			setConnectionMode(prevState => ({ ...prevState, children: toggleChild(prevState.children, data.id) }));
			return;
		}

		setSelectedNode(data);
	};

	const onClickHandler = (event: MouseEvent<SVGGElement>, data: ITaxonomyNode) => {
		resetHeaderDropdownOpen();
		resetUserMenuOpen();
		clearTimeout(timer);
		if (event.detail === 1) {
			timer = setTimeout(() => handleOneClick(data), 250);
		} else if (event.detail === 2) {
			toggleCollapseNode(data.id);
		}
	};

	return (
		<Style.TaxonomyNode
			{...nodeProps}
			onClick={onClickHandler}
			onKeyDown={onKeyDown}
			onRemove={() => setDeleteNodeModalAction()}
			dragCursor={TREE_SETTINGS.DRAG_CURSOR}
			dragType={DRAG_TYPE}
			isSelected={isSelected}
			label={<Style.NodeLabel isSelected={isSelected} className={style.label} />}
			remove={<Remove hidden />}
			port={<Port />}
			className={cn(style.node, { [style.nodeActive]: isSelected })}
			isBlocked={isBlocked}
			disabled={isBlocked}
		>
			{expandable && (
				<NodeIcon width={nodeProps.width} height={nodeProps.height}>
					<Style.ExpandableIcon
						className={style.expandable}
						$isSelected={isSelected}
						data-testid={TREE_TESTS_IDS.EXPANDABLE_ICON}
					/>
				</NodeIcon>
			)}
			{multiple && (
				<NodeIcon width={nodeProps.width} height={nodeProps.height}>
					<Style.MultipleIcon className={style.multiple} data-testid={TREE_TESTS_IDS.MULTIPLE_ICON} />
				</NodeIcon>
			)}
		</Style.TaxonomyNode>
	);
});
