import * as yup from 'yup';

import { GENERAL_ERRORS, TAXONOMY_ERRORS } from '../constants/errors.constants';
import { useIsTaxonomyNameIncludeInTaxonomiesList } from '../services/validation.services';

declare module 'yup' {
	interface StringSchema {
		isTaxonomyNameTaken(format: string): StringSchema;
	}
}

export const TaxonomySchema = () => {
	const isTaxonomyNameIncludeInTaxonomiesList = useIsTaxonomyNameIncludeInTaxonomiesList();

	yup.addMethod(yup.string, 'isTaxonomyNameTaken', function (errorMessage) {
		return this.test(`isTaxonomyNameTaken`, errorMessage, function (value) {
			const { path, createError } = this;
			return (value && !isTaxonomyNameIncludeInTaxonomiesList(value)) || createError({ path, message: errorMessage });
		});
	});

	return yup.object().shape({
		name: yup
			.string()
			.label('Taxonomy name')
			.required(TAXONOMY_ERRORS.REQUIRED)
			.min(2, GENERAL_ERRORS.INPUT_RANGE)
			.max(60, GENERAL_ERRORS.INPUT_RANGE)
			.isTaxonomyNameTaken(TAXONOMY_ERRORS.NAME_ALREADY_TAKEN),
	});
};
