import { useMutation } from '@tanstack/react-query';
import { useResetRecoilState } from 'recoil';

import { MODAL_TESTS_IDS } from '../../../../constants/modals.constants';
import { TaxonomySchema } from '../../../../schemas/TaxonomySchema';
import { useCreateTaxonomyRequest } from '../../../../services/api.services';
import { useAddTaxonomy } from '../../../../services/tree.services';
import { selectedTaxonomyState } from '../../../../state/global.state';
import { BaseForm } from '../../atomsComponents/BaseForm/BaseForm';
import { BaseModal, BaseModalProps } from '../../BaseModal';

type CreateTaxonomyModalProps = BaseModalProps & { onClose: () => void };
export const CreateTaxonomyModal = ({ onClose, showModal }: CreateTaxonomyModalProps) => {
	const resetSelectedTaxonomy = useResetRecoilState(selectedTaxonomyState);

	const addTaxonomy = useAddTaxonomy();
	const createTaxonomyRequest = useCreateTaxonomyRequest();

	const { isLoading, mutateAsync } = useMutation(['createTaxonomy'], createTaxonomyRequest);

	const onSubmit = async (taxonomy: Record<string, string>) => {
		resetSelectedTaxonomy();
		const newTaxonomy = await mutateAsync({ taxonomyName: taxonomy.name });
		addTaxonomy(newTaxonomy);

		onClose();
	};

	return (
		<BaseModal title="Add new Taxonomy" showModal={showModal} testid={MODAL_TESTS_IDS.CREATE_TAXONOMY_MODAL}>
			<BaseForm
				description="Type a new name for the taxonomy"
				fields={[{ fieldName: 'name', fieldType: 'input' }]}
				isLoading={isLoading}
				onSubmit={onSubmit}
				onDiscard={onClose}
				submitBtnText="SAVE"
				scheme={TaxonomySchema()}
			/>
		</BaseModal>
	);
};
