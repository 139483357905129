export const HEADER_TESTS_IDS = {
	HEADER_CONTAINER: 'HEADER_CONTAINER',
	USER_INFO: 'USER_INFO',
	LOGOUT_BUTTON: 'LOGOUT_BUTTON',
	USER_MENU: 'USER_MENU',
	HISTORY: 'HISTORY',
	SUGGESTED: 'SUGGESTED',
	ALL_CHANGES: 'ALL_CHANGES',
	NOTIFICATIONS_MENU: 'NOTIFICATIONS_MENU',
	NOTIFICATIONS: 'NOTIFICATIONS',
};

export const NOTIFICATIONS_TESTS_IDS = {
	NOTIFICATIONS_CONTAINER: 'NOTIFICATIONS_CONTAINER',
	NOTIFICATIONS_LIST: 'NOTIFICATIONS_LIST',
	NOTIFICATIONS_ITEM: 'NOTIFICATIONS_ITEM',
	NOTIFICATIONS_ITEM_TITLE: 'NOTIFICATIONS_ITEM_TITLE',
	NOTIFICATIONS_BADGE: 'NOTIFICATIONS_BADGE',
};

export const USER_MENU_TESTS_IDS = {
	USER_MENU_CONTAINER: 'USER_MENU_CONTAINER',
	USER_MENU_LIST: 'USER_MENU_LIST',
	USER_MENU_ITEM: 'USER_MENU_ITEM',
	USER_MENU_ITEM_TITLE: 'USER_MENU_ITEM_TITLE',
};
