import { Fragment, useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { Checked } from '../../../../assets/icons/treeMenuBar';
import { useNodeLevels, useNodePathToRoot } from '../../../../services/node.services';
import { useExpandSelectedTreeNode } from '../../../../services/treeMenuBar.services';
import { connectionModeState, selectedNodeState } from '../../../../state/tree.state';
import { pinnedSearchResultsState, searchValueState } from '../../../../state/treeMenuBar.state';
import type { ISynonym, ITaxonomyNode } from '../../../../types/taxonomy.types';
import { TREE_MENU_TESTS_IDS } from '../../treeMenuBar.constants';
import * as Style from './searchResult.styles';

const SearchResults = ({ text }: { text: string }) => {
	const searchValue = useRecoilValue(searchValueState);

	const cleanValue = searchValue?.replace(/[!@#$%^&*()+=\-[\]\\';,./{}|":<>?~_]/g, '\\$&');
	const parts = text.split(new RegExp(`(${cleanValue})`, 'gi'));

	return (
		<Style.ResultText>
			{parts.map((item, i) => (
				<Fragment key={i}>
					{searchValue && item.toLowerCase().includes(searchValue.toLowerCase()) ? <Style.Keyword>{item}</Style.Keyword> : item}
				</Fragment>
			))}
		</Style.ResultText>
	);
};

const SearchResultText = ({ node }: { node: ITaxonomyNode }) => {
	const nodeLevels = useNodeLevels();
	const levels = nodeLevels(node.id);

	return (
		<>
			<SearchResults text={node.text} />
			{levels && <Style.LevelText>{`${levels.length === 1 ? 'Level' : 'Levels'} ${levels.join(', ')}`}</Style.LevelText>}
		</>
	);
};

const Synonyms = ({ synonyms }: { synonyms?: ISynonym[] }) => (
	<>
		{synonyms && synonyms.length > 0 && (
			<Style.Synonyms>
				{synonyms.map(synonym => (
					<Style.Synonym key={synonym.id}>
						<SearchResults text={synonym.content} />
					</Style.Synonym>
				))}
			</Style.Synonyms>
		)}
	</>
);

interface SearchResultProps {
	node: ITaxonomyNode;
	showSynonyms: boolean;
}
export const SearchResult = ({ node, showSynonyms }: SearchResultProps) => {
	const connectionMode = useRecoilValue(connectionModeState);
	const [pinnedSearchResults, setPinnedSearchResults] = useRecoilState(pinnedSearchResultsState);
	const resetPinnedSearchResults = useResetRecoilState(pinnedSearchResultsState);
	const resetSearchValue = useResetRecoilState(searchValueState);
	const setSelectedNode = useSetRecoilState(selectedNodeState);
	const [checked, setChecked] = useState(false);

	const expandSelectedTreeNode = useExpandSelectedTreeNode();
	const nodePathToRoot = useNodePathToRoot();

	useEffect(() => setChecked(pinnedSearchResults.some(({ id }) => id === node.id)), [node, pinnedSearchResults]);

	const handleClick = useCallback(() => {
		if (!connectionMode.active) setSelectedNode(node);
		nodePathToRoot(node.id);
		resetSearchValue();
		resetPinnedSearchResults();
	}, [connectionMode.active, node, nodePathToRoot, resetPinnedSearchResults, resetSearchValue, setSelectedNode]);

	const handleChange = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(checked);
		setPinnedSearchResults(prevState => (checked ? [...prevState, node] : prevState?.filter(({ id }) => id !== node.id)));

		if (checked) {
			if (!connectionMode.active) setSelectedNode(node);
			expandSelectedTreeNode(node.id);
		}
	};

	return (
		<Style.Result>
			<Style.Label>
				<Style.Input type="checkbox" checked={checked} onChange={handleChange} />
				<Style.Checkbox data-testid={TREE_MENU_TESTS_IDS.CHECKBOX} checked={checked}>
					{checked && <Checked />}
				</Style.Checkbox>
			</Style.Label>
			<Style.ResultWrapper data-testid={TREE_MENU_TESTS_IDS.SEARCH_RESULT} onClick={handleClick}>
				<SearchResultText node={node} />
				{showSynonyms && <Synonyms synonyms={node.data?.synonyms} />}
			</Style.ResultWrapper>
		</Style.Result>
	);
};
