import styled from 'styled-components';

import { Colors } from '../../styles/colors.styles';
import { FONT_SIZES } from '../../styles/fonts.styles';

export const ImportJsonFileInput = styled.input`
	display: none;
`;

export const UploadContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
`;

export const UploadJsonText = styled.span`
	font-size: ${FONT_SIZES.H3};
	color: ${Colors.secondary300};
	line-height: 23px;
	margin: 24px 0;
`;

export const BrowseButton = styled.button`
	max-width: 110px;
	background-color: ${Colors.primary500};
	align-self: center;
	font-size: ${FONT_SIZES.BODY};
	line-height: 14px;
	font-weight: 500;
	text-transform: uppercase;
	color: ${Colors.white};
	padding: 12px 28px;
	border-radius: 4px;
`;

export const Button = styled.button`
	color: ${Colors.secondary50};
	font-size: ${FONT_SIZES.XS};
	text-align: left;
`;
