import ProgressBar from '@ramonak/react-progress-bar';
import { useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { taxonomyErrorState, tempFileNameState } from '../../../../state/global.state';
import { PROGRESS_BAR, PROGRESS_BAR_TESTS_IDS } from './progressBar.constants';
import styles from './progressBar.module.css';
import * as Style from './progressBar.styles';

const FileName = () => {
	const tempFileName = useRecoilValue(tempFileNameState);

	return <Style.FileName data-testid={PROGRESS_BAR_TESTS_IDS.FILE_NAME}>{tempFileName}</Style.FileName>;
};

const RetryButton = () => {
	const resetTaxonomyError = useResetRecoilState(taxonomyErrorState);
	const resetTempFileName = useResetRecoilState(tempFileNameState);

	const handleRetryClick = () => {
		resetTaxonomyError();
		resetTempFileName();
	};

	return (
		<Style.RetryButton data-testid={PROGRESS_BAR_TESTS_IDS.RETRY_BUTTON} onClick={handleRetryClick}>
			{PROGRESS_BAR.BUTTON_TEXT}
		</Style.RetryButton>
	);
};

const ErrorRetryButton = ({ error }: { error: string }) => (
	<>
		<Style.Error data-testid={PROGRESS_BAR_TESTS_IDS.ERROR}>{error}</Style.Error>
		<RetryButton />
	</>
);

export const Progress = () => {
	const tempFileName = useRecoilValue(tempFileNameState);
	const taxonomyError = useRecoilValue(taxonomyErrorState);
	const [progress, setProgress] = useState(0);
	const [progressBar, setProgressBar] = useState({
		bgColor: PROGRESS_BAR.BG_COLOR,
		customLabel: PROGRESS_BAR.CUSTOM_LABEL,
	});

	useEffect(() => {
		if (tempFileName) {
			const interval = setInterval(() => {
				setProgress(oldValue => {
					const newValue = oldValue + 5;

					if (newValue === 100) {
						clearInterval(interval);
					}
					return newValue;
				});
			}, 100);
		}

		return () => {
			setProgress(0);
		};
	}, [tempFileName]);

	useEffect(() => {
		if (taxonomyError) {
			setProgressBar({
				bgColor: PROGRESS_BAR.ERROR_COLOR,
				customLabel: ' ',
			});
		}
		return () => {
			setProgressBar({
				bgColor: PROGRESS_BAR.BG_COLOR,
				customLabel: PROGRESS_BAR.CUSTOM_LABEL,
			});
		};
	}, [taxonomyError]);

	return (
		<Style.ProgressBar data-testid={PROGRESS_BAR_TESTS_IDS.PROGRESS_BAR}>
			<FileName />
			<ProgressBar
				completed={progress}
				bgColor={progressBar.bgColor}
				baseBgColor={PROGRESS_BAR.BASE_BG_COLOR}
				height={PROGRESS_BAR.HEIGHT}
				borderRadius={PROGRESS_BAR.BORDER_RADIUS}
				customLabel={progressBar.customLabel}
				className={styles.wrapper}
				labelClassName={styles.label}
			/>
			{taxonomyError && <ErrorRetryButton error={taxonomyError} />}
		</Style.ProgressBar>
	);
};
