import { useMutation } from '@tanstack/react-query';
import { memo, useEffect } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { useCreateTaxonomyRequest } from '../../../../services/api.services';
import { useAddTaxonomy } from '../../../../services/tree.services';
import { selectedTaxonomyState, taxonomyToImportState, tempFileNameState } from '../../../../state/global.state';
import { isTaxonomyErrorState } from '../../../../state/header.state';
import { UploadJsonFile } from '../../../uploadJsonFile';
import { ProgressBar } from '..';

const ImportTaxonomyBody = () => {
	const setIsTaxonomyError = useSetRecoilState(isTaxonomyErrorState);
	const tempFileName = useRecoilValue(tempFileNameState);
	const taxonomyToImport = useRecoilValue(taxonomyToImportState);
	const { tree } = useRecoilValue(selectedTaxonomyState);
	const resetSelectedTaxonomy = useResetRecoilState(selectedTaxonomyState);

	const addTaxonomy = useAddTaxonomy();

	const isTaxonomySelected = tree.nodes.length > 0;

	const createTaxonomyRequest = useCreateTaxonomyRequest();

	const { isLoading, mutateAsync } = useMutation(['importTaxonomy'], createTaxonomyRequest, {
		onError: () => setIsTaxonomyError(true),
	});

	useEffect(() => {
		const init = async () => {
			resetSelectedTaxonomy();
			const newTaxonomy = await mutateAsync({ taxonomy: taxonomyToImport });
			addTaxonomy(newTaxonomy);
		};
		taxonomyToImport && tempFileName && init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taxonomyToImport, tempFileName]);

	if (isTaxonomySelected) return null;

	return tempFileName || isLoading ? <ProgressBar /> : <UploadJsonFile isTreeComponent />;
};

export const ImportTaxonomy = memo(ImportTaxonomyBody);
