import type { MODE_CONFIG } from '../../../../types/taxonomy.types';
import { NodeContent } from '..';
import * as Style from './nodeContentList.styles';

interface DefaultDataProps {
	currentConfig: MODE_CONFIG;
}

export const RenderNoEditModeDefaultData = ({ currentConfig }: DefaultDataProps) => {
	return (
		<Style.NodeContentList data-testid={currentConfig.dataTestIdItemsContainer}>
			<NodeContent index={0} content={currentConfig.placeHolder} placeholder title={currentConfig.labelText} />
		</Style.NodeContentList>
	);
};
