import styled from 'styled-components';

import { Colors } from '../../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../../styles/fonts.styles';

export const FormDescription = styled.span`
	font-size: ${FONT_SIZES.H4};
	line-height: 1.5;
	padding-bottom: 4px;
`;

export const FormInfo = styled.span`
	font-size: ${FONT_SIZES.H5};
	line-height: 1.5;
	padding-bottom: 4px;
`;

export const FormAlert = styled.span<{ color: Colors }>`
	font-size: ${FONT_SIZES.H6};
	font-weight: 700;
	line-height: 16px;
	color: ${({ color }) => color};
	padding-top: 4px;
	padding-bottom: 4px;
`;
