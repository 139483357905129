import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import { useOutsideClick } from '../../../../hooks';
import { useHandleTaxonomyClick } from '../../../../services/header.services';
import { taxonomiesSelector, taxonomiesWithChangesSelector } from '../../../../state/global.state';
import { notificationsMenuOpenState } from '../../../../state/header.state';
import { historyMenuState } from '../../../../state/history.state';
import { isAdminState } from '../../../../state/user.state';
import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { IPendingChange } from '../../../../types/taxonomy.types';
import { HEADER_TESTS_IDS } from '../../header.constants';

const Root = styled.div`
	width: 250px;
	position: absolute;
	top: 38px;
	left: 0;
	display: flex;
	flex-direction: column;
	background: ${Colors.secondary800};
	border: 1px solid ${Colors.secondary500};
	transform: translateX(calc(50% - 10px));
	padding-top: 8px;
	padding-bottom: 8px;
	border-radius: 6px;
	z-index: 40;
`;

const Square = styled.span`
	position: absolute;
	width: 20px;
	height: 20px;
	left: 16px;
	top: -8px;
	background: ${Colors.secondary800};
	transform: rotate(45deg);
	z-index: -10;
`;

const Span = styled.span`
	color: ${Colors.selected500};
`;

const ListItem = styled.span<{ disabled?: boolean }>`
	color: ${Colors.secondary50};
	font-size: ${FONT_SIZES.XS};
	line-height: 1.15;
	padding: 8px 16px;
	transition: 0.3s;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
	&:hover {
		background-color: ${Colors.secondary900};
	}
`;

export const NotificationsDropdown = () => {
	const isAdmin = useRecoilValue(isAdminState);
	const taxonomies = useRecoilValue(taxonomiesSelector);
	const setHistoryMenu = useSetRecoilState(historyMenuState);
	const pendingChanges = useRecoilValue(taxonomiesWithChangesSelector);
	const resetNotificationsMenuOpen = useResetRecoilState(notificationsMenuOpenState);

	const dropDownRef = useOutsideClick(() => resetNotificationsMenuOpen());

	const handleTaxonomyClick = useHandleTaxonomyClick();

	const handleClick = (id: string) => {
		const taxonomy = taxonomies.find(taxonomy => taxonomy.taxonomyId === id);
		resetNotificationsMenuOpen();
		if (taxonomy) {
			handleTaxonomyClick(taxonomy);
			setHistoryMenu({ type: 'all', open: true });
		}
	};

	if (!isAdmin || !pendingChanges) return null;

	return (
		<Root ref={dropDownRef}>
			<Square />
			{pendingChanges ? (
				pendingChanges.map((change: { taxonomyName: string; taxonomyId: string; changes: IPendingChange[] }) => (
					<ListItem
						onClick={() => handleClick(change.taxonomyId)}
						data-testid={HEADER_TESTS_IDS.NOTIFICATIONS}
						key={change.taxonomyId}
					>
						{change.taxonomyName} | <Span>{change.changes.length} new </Span> set{change.changes.length > 1 && 's'} of changes
					</ListItem>
				))
			) : (
				<ListItem data-testid={HEADER_TESTS_IDS.NOTIFICATIONS}>No pending changes</ListItem>
			)}
		</Root>
	);
};
