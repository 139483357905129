import { APP_TESTS } from '../../app.constants';
import * as Style from './preloader.styles';

export const Preloader = () => {
	return (
		<Style.Preloader data-testid={APP_TESTS.PRELOADER}>
			<Style.Circle />
		</Style.Preloader>
	);
};
