import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { icon, IIcon, SpinnerCircle } from '../../../../styles/global.styles';

export const Search = styled.div`
	background: ${Colors.secondary800};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
`;

export const SearchForm = styled.form`
	width: 100%;
`;

export const Label = styled.label`
	width: 100%;
	position: relative;
`;

export const Input = styled.input`
	width: 100%;
	padding: 12px 32px 11px 32px;
	font-size: ${FONT_SIZES.BODY};
	color: ${Colors.white};
	background-color: ${Colors.secondary800};
	border: none;
	border-bottom: 1px solid ${Colors.secondary500};
	transition-property: border-color, color, background-color;
	transition-duration: 0.3s;
	caret-color: ${Colors.primary500};

	&::placeholder {
		color: ${Colors.secondary500};
		transition: color 0.3s;
	}

	&:hover {
		border-color: ${Colors.white};

		&::placeholder {
			color: ${Colors.secondary300};
		}
	}

	&:focus {
		border-color: ${Colors.secondary500};
		background-color: ${Colors.secondary900};

		&::placeholder {
			color: ${Colors.secondary300};
		}
	}
`;

export const SearchIcon = styled.svg<{ isActive: boolean } & IIcon>`
	${icon};

	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);

	${Input}:hover + & {
		path {
			fill: ${Colors.white};
		}
	}

	${Input}:focus + & {
		path {
			fill: ${Colors.white};
		}
	}
`;

export const CancelButton = styled.button`
	position: absolute;
	top: 50%;
	right: 8px;
	transform: translateY(-50%);
	max-height: 24px;
`;

export const CancelIcon = styled.svg`
	width: 24px;
	height: 24px;
	transition: fill 1.3ms;
	path {
		transition: fill 1.3ms;
	}

	${CancelButton}:active & {
		path:first-child {
			fill: ${Colors.secondary900};
		}
	}
`;

export const Spinner = styled.div`
	position: absolute;
	top: 50%;
	right: 8px;
	transform: translateY(-50%);
	max-height: 24px;
`;

export const Circle = styled(SpinnerCircle)`
	width: 20px;
	height: 20px;
	border: 3px solid ${Colors.secondary100};
	border-bottom-color: ${Colors.primary300};
`;
