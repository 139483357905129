import { useQuery } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Delete } from '../../../../assets/icons/infoBar';
import { getSynonymsRequest } from '../../../../services/api.services';
import {
	isSynonymExistsInSynonymsList,
	useErrorMessage,
	useIsEqualToGranularTopic,
} from '../../../../services/validation.services';
import { EDITED_SECTION } from '../../../../state/infoBar.state';
import type { IExamplesSynonyms, ISynonym, ITaxonomyNode, MODE_CONFIG } from '../../../../types/taxonomy.types';
import { INFO_BAR, INFO_BAR_TESTS_IDS } from '../../infoBar.constants';
import { Preloader } from '../preloader';
import * as Style from './editNodeContent.styles';

interface EditNodeContentProps {
	handleOnSaveNodeContent: (updatedSynonyms: IExamplesSynonyms) => void;
	discardEdit: () => void;
	nodesContentList: IExamplesSynonyms;
	selectedNode: ITaxonomyNode | undefined;
	config: MODE_CONFIG;
}

export const EditNodeContent = ({
	nodesContentList = [],
	handleOnSaveNodeContent,
	discardEdit,
	selectedNode,
	config,
}: EditNodeContentProps) => {
	const [isValid, setIsValid] = useState(false);
	const [newNodeContent, setNewNodeContent] = useState('');
	const [nodeContentList, setNodeContentList] = useState<IExamplesSynonyms>(nodesContentList || []);
	const [suggestedSynonyms, setSuggestedSynonyms] = useState<string[]>([]);

	const errorMessage = useErrorMessage();
	const isEqualToGranularTopic = useIsEqualToGranularTopic();

	const query = useMemo(
		() =>
			selectedNode?.text
				.match(/\b(\w+)\b/g)
				?.filter((value: string[]) => value.length > 2)
				.join(','),
		[selectedNode?.text],
	);

	const { isLoading, data } = useQuery<{ [key: string]: string[] }>({
		cacheTime: 0,
		enabled: !!query,
		queryFn: () => getSynonymsRequest(query),
		queryKey: ['getSynonyms', query],
		retry: 1,
	});

	useEffect(() => {
		if (data) {
			let synonyms: string[] = [];
			if (Object.keys(data).length >= 3) {
				synonyms = Object.values(data || {}).reduce((acc, curr) => [...acc, ...curr.slice(0, 2)], []);
			}
			if (Object.keys(data).length === 2) {
				synonyms = Object.values(data || {}).reduce((acc, curr) => [...acc, ...curr.slice(0, 4)], []);
			}
			if (Object.keys(data).length === 1) {
				synonyms = Object.values(data).flat();
			}

			const currentSynonymsSet = new Set(selectedNode?.data?.synonyms?.map((synonym: ISynonym) => synonym.content));
			setSuggestedSynonyms(synonyms.filter(synonym => !currentSynonymsSet.has(synonym)));
		}
	}, [data, isLoading, selectedNode?.data?.synonyms]);

	const listValidation = useMemo(
		() => ({
			[INFO_BAR.SYNONYMS_TITLE]: !isEqual(nodeContentList, selectedNode?.data?.synonyms),
			[INFO_BAR.EXAMPLES_TITLE]: !isEqual(nodeContentList, selectedNode?.data?.examples),
		}),
		[nodeContentList, selectedNode?.data?.examples, selectedNode?.data?.synonyms],
	);

	const nodeContentValidation = useCallback(() => {
		return !nodeContentList.some(item => item.errMsg && item.errMsg.length > 0);
	}, [nodeContentList]);

	useEffect(() => {
		const titleValidation = listValidation[config.title];
		if (config.title === INFO_BAR.SYNONYMS_TITLE) {
			const isListValid = nodeContentValidation();
			setIsValid(isListValid && titleValidation);
		} else {
			setIsValid(titleValidation);
		}
	}, [config.title, listValidation, nodeContentValidation]);

	const handleChangeNodeContent = (value: string, id: string | undefined) => {
		if (!id) {
			const id = uuidv4();
			const tempNewNodeContent = { id, content: value, errMsg: synonymValidationMessage(id, value) };

			setNodeContentList(prevState => [...prevState, tempNewNodeContent]);
		} else {
			const index = nodeContentList.findIndex(item => item.id === id);
			const temp = [...nodeContentList];

			temp[index] = {
				...temp[index],
				content: value,
				errMsg: synonymValidationMessage(id, value),
			};

			setNodeContentList(temp.filter(item => item.content.length > 0));
		}
	};

	const synonymValidationMessage = (id: string, newValue: string) => {
		if (config.title == INFO_BAR.SYNONYMS_TITLE) {
			return (
				errorMessage(newValue) || isSynonymExistsInSynonymsList(id, newValue, nodeContentList) || isEqualToGranularTopic(newValue)
			);
		}
	};

	const handleFirstChangeNodeContent = (value: string, id: string | undefined) => {
		handleChangeNodeContent(value, id);
		setNewNodeContent('');
	};

	const handleAddSynonym = (synonym: string) => {
		handleFirstChangeNodeContent(synonym, '');
		setSuggestedSynonyms(prevState => prevState.filter(item => item !== synonym));
	};

	const handleDeleteSuggestion = (e: MouseEvent, synonym: string) => {
		e.stopPropagation();
		setSuggestedSynonyms(prevState => prevState.filter(item => item !== synonym));
	};

	return (
		<>
			{config.editMode === EDITED_SECTION.SYNONYMS &&
				(isLoading ? (
					<Preloader />
				) : (
					suggestedSynonyms.length > 0 && (
						<Style.Suggestions data-testid={INFO_BAR_TESTS_IDS.SUGGESTIONS}>
							<Style.Tip>You can use our suggestions or type synonyms on your own</Style.Tip>
							<Style.Tags>
								{suggestedSynonyms.map(synonym => (
									<Style.Tag key={synonym} onClick={() => handleAddSynonym(synonym)} data-testid={INFO_BAR_TESTS_IDS.SUGGESTION}>
										<Style.TagText>{synonym}</Style.TagText>
										<Style.DeleteIcon onClick={e => handleDeleteSuggestion(e, synonym)}>
											<Delete />
										</Style.DeleteIcon>
									</Style.Tag>
								))}
							</Style.Tags>
						</Style.Suggestions>
					)
				))}
			<Style.NodeContentList data-testid={config.dataTestIdItemsContainer}>
				{nodeContentList?.map((item, index) => (
					<Style.NodeContent data-testid={config.dataTestIdContainer} key={item.id}>
						<Style.Label data-testid={config.dataTestIdLabel}>{`${config.labelText} ${index + 1}`}</Style.Label>
						<Style.TextArea
							data-testid={config.dataTestIdTextArea}
							onChange={e => handleChangeNodeContent(e.target.value, item.id)}
							value={item?.content}
							placeholder={config.placeHolder}
							onFocus={({ target }) => {
								target.value.length === 1 && target.setSelectionRange(1, 1);
							}}
							autoFocus={index === nodeContentList.length - 1}
						/>
						{item.errMsg && config.title == INFO_BAR.SYNONYMS_TITLE && (
							<Style.Error data-testid={INFO_BAR_TESTS_IDS.ERROR_MESSAGE}>{`${item.errMsg}`}</Style.Error>
						)}
					</Style.NodeContent>
				))}
				<Style.NodeContent>
					<Style.TextArea
						data-testid={INFO_BAR_TESTS_IDS.ADD_NEW_ITEM}
						onChange={e => handleFirstChangeNodeContent(e.target.value, '')}
						value={newNodeContent}
						placeholder={config.placeHolder}
					/>
				</Style.NodeContent>
			</Style.NodeContentList>

			<Style.ButtonsContainer>
				<Style.SaveButton
					onClick={() => handleOnSaveNodeContent(nodeContentList)}
					data-testid={config.dataTestIdSaveBtn}
					disabled={!isValid}
				>
					{INFO_BAR.SAVE}
				</Style.SaveButton>
				<Style.DiscardButton onClick={discardEdit} data-testid={config.dataTestIdDiscardBtn}>
					{INFO_BAR.DISCARD_CHANGES}
				</Style.DiscardButton>
			</Style.ButtonsContainer>
		</>
	);
};
