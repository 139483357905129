import { atom } from 'recoil';

export enum EDITED_SECTION {
	SYNONYMS,
	EXAMPLES,
	DESCRIPTION,
}

export const infoBarModeState = atom<EDITED_SECTION | undefined>({
	key: 'infoBarMode',
	default: undefined,
});

export const infoBarOpenState = atom<boolean>({
	key: 'infoBarOpen',
	default: false,
});
