import { ErrorMessage } from '@hookform/error-message';
import { ComponentProps } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import styled from 'styled-components';

import { MODAL_TESTS_IDS } from '../../../../../constants/modals.constants';
import { Colors } from '../../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../../styles/fonts.styles';

const Root = styled.div`
	min-height: 21px;
	font-size: ${FONT_SIZES.XXS};
`;

const Message = styled.p`
	margin: 0 0 0 12px;
	color: ${Colors.warningRed};
`;

export const ErrorFieldMessage = ({ name }: ComponentProps<typeof ErrorMessage>) => {
	const { control } = useFormContext();
	const { errors } = useFormState({ control });

	return (
		<Root>
			<ErrorMessage
				errors={errors}
				name={name}
				render={({ message }) => <Message data-testid={MODAL_TESTS_IDS.ERROR_MESSAGE}>{message}</Message>}
			/>
		</Root>
	);
};
