import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { icon } from '../../../../styles/global.styles';

export const MenuButton = styled.button<{ treeMenuOpen: boolean }>`
	display: flex;
	padding: ${({ treeMenuOpen }) => (treeMenuOpen ? '0' : '16px')};
	background-color: ${({ treeMenuOpen }) => (treeMenuOpen ? 'transparent' : Colors.secondary800)};
`;

export const MenuIcon = styled.svg`
	${icon};

	${MenuButton}:disabled & {
		path {
			fill: ${Colors.secondary500};
		}
	}
	${MenuButton}:active & {
		path {
			fill: ${Colors.secondary500};
		}
	}
`;
