import { DepGraph } from 'dependency-graph';
import { atom, selector } from 'recoil';

import { TREE_SETTINGS } from '../components/tree/tree.constants';
import { UNDO_CONTROL } from '../constants/controls.constants';
import type { IConnectionMode, ISynonym, ITaxonomyEdge, ITaxonomyNode, ITreeNodesState } from '../types/taxonomy.types';
import { selectedTaxonomyState } from './global.state';
import { searchValueState } from './treeMenuBar.state';

export const isRootNode = ({ id }: ITaxonomyNode) => id === TREE_SETTINGS.ROOT_NODE;

export const treeNodesState = atom<ITreeNodesState[]>({
	key: 'treeNodes',
	default: selector({
		key: 'treeNodes/Default',
		get: ({ get }) => {
			const { nodes } = get(selectedTaxonomyState).tree;

			return nodes.map(({ id }) => ({
				expanded: false,
				id,
				show: false,
				chunk: 1,
			}));
		},
	}),
});

export const selectedNodeState = atom<ITaxonomyNode | undefined>({
	key: 'selectedNode',
	default: undefined,
});

export const selectedEdgeState = atom<ITaxonomyEdge | undefined>({
	key: 'selectedEdge',
	default: undefined,
});

export const clearHistoryState = atom<((nodes: ITaxonomyNode[], edges: ITaxonomyEdge[]) => void) | null>({
	key: 'clearHistory',
	default: null,
});

export const canUndoState = atom<boolean>({
	key: 'canUndo',
	default: false,
});

interface IUndoControlButtons {
	nodeId: string | null;
	nodesForUndo: ITreeNodesState[];
	type: UNDO_CONTROL | null;
}

export const undoControlButtonsState = atom<IUndoControlButtons>({
	key: 'undoControlButtons',
	default: { nodeId: null, nodesForUndo: [], type: null },
});

export const connectionModeState = atom<IConnectionMode>({
	key: 'connectionMode',
	default: {
		active: false,
		activeChildren: false,
		activeParent: false,
		children: [],
		parent: undefined,
		setIsPrimary: false,
	},
});

export const clearSelectionsState = atom<((value?: string[]) => void) | undefined>({
	key: 'clearSelections',
	default: undefined,
});

export const onCanvasClickState = atom<(() => void) | undefined>({
	key: 'onCanvasClick',
	default: undefined,
});

export const nodeActionsOpenState = atom({
	key: 'nodeActionsOpen',
	default: false,
});

export const pathActionsOpenState = atom({
	key: 'pathActionsOpen',
	default: false,
});

export const rootNodeState = selector({
	key: 'rootNode',
	get: ({ get }) => {
		const { nodes } = get(selectedTaxonomyState).tree;

		return nodes?.find(isRootNode);
	},
});

export const visibleTreeState = selector({
	key: 'visibleTree',
	get: ({ get }) => {
		const { nodes, edges } = get(selectedTaxonomyState).tree;
		const treeNodes = get(treeNodesState);

		if (nodes && edges) {
			const nodesIdsToShowSet = new Set(treeNodes.filter(({ show }) => show).map(({ id }) => id));
			const visibleNodes = nodes?.filter(node => nodesIdsToShowSet.has(node.id));

			const nodesSet = new Set(visibleNodes.map(node => node.id));
			const visibleEdges = edges?.filter(edge => nodesSet.has(edge.from as string) && nodesSet.has(edge.to as string));

			return { nodes: visibleNodes, edges: visibleEdges };
		}
		return { nodes: [], edges: [] };
	},
});

export const graphState = selector({
	key: 'graph',
	get: ({ get }) => {
		const { edges, nodes } = get(selectedTaxonomyState).tree;
		const graph = new DepGraph();

		nodes?.forEach(node => graph.addNode(node.id));
		edges?.forEach(edge => graph.addDependency(edge.from as string, edge.to as string));

		return graph;
	},
});

export const searchResultsState = selector({
	key: 'searchResults',
	get: ({ get }) => {
		const { nodes } = get(selectedTaxonomyState).tree;
		const rootNode = get(rootNodeState);
		const searchValue = get(searchValueState);

		const results = [];

		for (const node of nodes) {
			const { id, text, data } = node;
			if (
				(id !== rootNode?.id && text.toLowerCase().includes(searchValue?.toLowerCase())) ||
				data?.synonyms?.find((synonym: ISynonym) => synonym.content?.toLowerCase().includes(searchValue?.toLowerCase() as string))
			) {
				results.push(node);
			}
		}

		const sortedResults = results.sort((a, b) => a.text.localeCompare(b.text));

		if (results.length > 50) return { results: results.slice(0, 49), moreResults: results.length - 50 };

		return { results: sortedResults, moreResults: 0 };
	},
});

export const granularTopicsState = selector<ITaxonomyNode[] | undefined>({
	key: 'granularTopics',
	get: ({ get }) => {
		const { nodes, edges } = get(selectedTaxonomyState).tree;
		const edgesSet = new Set(edges.map(edge => edge.from));

		return nodes?.filter(node => !edgesSet.has(node.id));
	},
});

export const selectedNodeIsGranularState = selector({
	key: 'selectedNodeIsGranular',
	get: ({ get }) => {
		const granularTopics = get(granularTopicsState);
		const selectedNode = get(selectedNodeState);

		return granularTopics?.some(node => node.id === selectedNode?.id);
	},
});
