import { useEffect, useRef } from 'react';

export const useOutsideClick = (handleClose: () => void) => {
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (e: MouseEvent) => {
			if (containerRef.current && !containerRef.current.contains(e.target as HTMLDivElement)) {
				handleClose();
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => document.removeEventListener('click', handleClickOutside);
	}, [containerRef, handleClose]);

	return containerRef;
};
