import { ReactNode } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { dialogConfigState, isConfirmationModalOpenState } from '../../../state/modal.state';
import { SaveTaxonomyModal } from '../taxonomyModals/SaveTaxonomy';

type ConfirmationDialogProviderProps = {
	children: ReactNode;
};

export const ConfirmationDialogProvider = ({ children }: ConfirmationDialogProviderProps) => {
	const [isModalOpen, setIsModalOpen] = useRecoilState(isConfirmationModalOpenState);
	const dialogConfig = useRecoilValue(dialogConfigState);

	const onConfirm = () => {
		setIsModalOpen(false);
		dialogConfig?.actionCallback(true);
	};

	const onDismiss = () => {
		setIsModalOpen(false);
		dialogConfig?.actionCallback(false);
	};

	return (
		<>
			{isModalOpen && <SaveTaxonomyModal showModal={isModalOpen} onConfirm={onConfirm} onClose={onDismiss} />}
			{children}
		</>
	);
};
