import { PropsWithChildren } from 'react';
import ReactModal from 'react-modal';

import { Colors } from '../../../styles/colors.styles';
import styles from '../ReactModal.module.css';
import * as Styles from './BaseModal.styles';

export interface BaseModalProps {
	showModal: boolean;
	testid?: string;
	title?: string;
	color?: Colors;
}
export const BaseModal = ({ showModal, title, color, children, testid }: PropsWithChildren<BaseModalProps>) => {
	const modalRoot = document.getElementById('modal-root');

	return (
		<ReactModal
			isOpen={showModal}
			ariaHideApp={false}
			parentSelector={() => modalRoot as HTMLElement}
			shouldCloseOnEsc={false}
			shouldCloseOnOverlayClick={false}
			className={styles.content}
			overlayClassName={styles.overlay}
		>
			<Styles.ModalContainer data-testid={testid}>
				<Styles.TitleWrapper>
					<Styles.Title color={color}>{title}</Styles.Title>
				</Styles.TitleWrapper>
				<Styles.Hr />
				{children}
			</Styles.ModalContainer>
		</ReactModal>
	);
};
