import { ColDef, ICellRendererParams } from 'ag-grid-community';

const CellRenderer = (props: ICellRendererParams) => {
	const parts = (props.value as string)?.split('~');

	return parts?.map((item, i) => <span key={i}>{i % 2 ? <strong>{item}</strong> : item}</span>);
};

export const dateColumn: ColDef = {
	headerName: 'Date',
	field: 'date',
	sort: 'desc',
	filter: 'agDateColumnFilter',
	valueFormatter: data => new Date(data?.value).toLocaleString('en-US', { timeZone: 'UTC', hour12: false }),
	filterParams: {
		buttons: ['reset'],
		inRangeInclusive: true,
		filterOptions: [
			'inRange',
			'greaterThan',
			{
				displayKey: 'last24hours',
				displayName: 'Last 24 hrs',
				predicate: (_: Date, cellValue: Date) => {
					const timeStamp = Math.round(new Date().getTime() / 1000);
					const timeStampYesterday = timeStamp - 24 * 3600;
					return cellValue.getTime() >= new Date(timeStampYesterday * 1000).getTime();
				},
				numberOfInputs: 0,
			},
			{
				displayKey: 'last7days',
				displayName: 'Last 7 days',
				predicate: (_: Date, cellValue: Date) => {
					const today = new Date();
					const oneWeekAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
					return cellValue >= oneWeekAgo;
				},
				numberOfInputs: 0,
			},
			{
				displayKey: 'last30days',
				displayName: 'Last 30 days',
				predicate: (_: Date, cellValue: Date) => {
					const today = new Date();
					const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
					return cellValue >= oneMonthAgo;
				},
				numberOfInputs: 0,
			},
		],
		suppressAndOrCondition: true,
		comparator: function (filterLocalDateAtMidnight: Date, cellValue: Date) {
			if (filterLocalDateAtMidnight.toDateString() === cellValue.toDateString()) {
				return 0;
			}

			if (cellValue < filterLocalDateAtMidnight) {
				return -1;
			}

			if (cellValue > filterLocalDateAtMidnight) {
				return 1;
			}
		},
	},
	unSortIcon: true,
	width: 132,
};

export const typeColumn: ColDef = {
	headerName: 'Type',
	field: 'type',
	filter: true,
	filterParams: {
		buttons: ['reset'],
		filterOptions: ['contains', 'equals'],
	},
	unSortIcon: true,
	width: 100,
};

export const descriptionColumn: ColDef = {
	headerName: 'Description',
	field: 'description',
	filter: true,
	filterParams: {
		buttons: ['reset'],
		filterOptions: ['contains', 'equals'],
	},
	flex: 1,
	cellRenderer: CellRenderer,
};

export const editedByColumn: ColDef = {
	headerName: 'Edited by',
	field: 'editedBy',
	width: 90,
};
