import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { SpinnerCircle } from '../../../../styles/global.styles';

const Root = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Circle = styled(SpinnerCircle)`
	width: 20px;
	height: 20px;
	border: 2px solid ${Colors.secondary300};
	border-bottom-color: ${Colors.secondary800};
`;

const Text = styled.p`
	color: ${Colors.secondary300};
	font-size: ${FONT_SIZES.XS};
	line-height: 14px;
	margin-bottom: 0;
	margin-top: 12px;
`;

export const Preloader = () => {
	return (
		<Root>
			<Circle />
			<Text>Loading suggestions...</Text>
		</Root>
	);
};
