import { Auth0Provider } from '@auth0/auth0-react';

import { Auth } from './auth.constants';

interface IAuthProps {
	children: JSX.Element;
}

export const AuthProvider = ({ children }: IAuthProps) => (
	<Auth0Provider
		domain={Auth.DOMAIN}
		clientId={Auth.CLIENT_ID}
		cacheLocation="localstorage"
		authorizationParams={{
			redirect_uri: window.location.origin,
		}}
	>
		{children}
	</Auth0Provider>
);
