import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { TREE_SETTINGS } from '../components/tree/tree.constants';
import {
	selectedTaxonomyCopyState,
	selectedTaxonomyState,
	taxonomyErrorState,
	taxonomyToFetchState,
	taxonomyToImportState,
	tempFileNameState,
} from '../state/global.state';
import {
	exportMenuOpenState,
	headerDropdownOpenState,
	isTaxonomyErrorState,
	notificationsMenuOpenState,
	userMenuOpenState,
} from '../state/header.state';
import { fetchPendingChangesState, pendingChangesCopyState, pendingChangesState, tempLogsState } from '../state/history.state';
import {
	clearHistoryState,
	clearSelectionsState,
	connectionModeState,
	selectedEdgeState,
	selectedNodeState,
	treeNodesState,
} from '../state/tree.state';
import { closeTreeMenuState, kebabOpenedState, pinnedSearchResultsState, searchValueState } from '../state/treeMenuBar.state';
import type { ITaxonomy } from '../types/taxonomy.types';
import { useGetTaxonomyRequest } from './api.services';
import { myTaxonomies } from './myTaxonomies.services';

export const useHandleTaxonomyClick = () => {
	const resetHeaderDropdownOpen = useResetRecoilState(headerDropdownOpenState);
	const setFetchPendingChanges = useSetRecoilState(fetchPendingChangesState);
	const { taxonomyId } = useRecoilValue(selectedTaxonomyState);
	const resetSelectedTaxonomy = useResetRecoilState(selectedTaxonomyState);
	const setTaxonomyToFetch = useSetRecoilState(taxonomyToFetchState);
	const setTempFileName = useSetRecoilState(tempFileNameState);

	const getTaxonomyRequest = useGetTaxonomyRequest();
	const queryClient = useQueryClient();
	const selectTaxonomy = useSelectTaxonomy();

	return async (taxonomy: ITaxonomy) => {
		if (taxonomyId === taxonomy.taxonomyId) return;

		if (taxonomy.isDraft) {
			const selectedTaxonomy = await myTaxonomies.getSavedTaxonomy(taxonomy.taxonomyId);
			resetSelectedTaxonomy();

			if (selectedTaxonomy) {
				selectTaxonomy(selectedTaxonomy);
				selectedTaxonomy.originId &&
					taxonomy.versionId &&
					setFetchPendingChanges({ taxonomyId: selectedTaxonomy.originId, versionId: taxonomy.versionId });
			}
			resetHeaderDropdownOpen();
			return;
		}

		taxonomy.versionId && setFetchPendingChanges({ taxonomyId: taxonomy.taxonomyId, versionId: taxonomy.versionId });

		queryClient.prefetchQuery(['getTaxonomy', taxonomy.taxonomyId], () => getTaxonomyRequest(taxonomy.taxonomyId));

		resetSelectedTaxonomy();
		setTaxonomyToFetch(taxonomy);
		setTempFileName(taxonomy.taxonomyName);

		resetHeaderDropdownOpen();
	};
};

export const useSelectTaxonomy = () => {
	const clearHistory = useRecoilValue(clearHistoryState);
	const clearSelections = useRecoilValue(clearSelectionsState);
	const closeTreeMenu = useRecoilValue(closeTreeMenuState);
	const resetConnectionMode = useResetRecoilState(connectionModeState);
	const { taxonomyId } = useRecoilValue(fetchPendingChangesState);
	const resetFetchPendingChanges = useResetRecoilState(fetchPendingChangesState);
	const resetIsTaxonomyError = useResetRecoilState(isTaxonomyErrorState);
	const resetKebabOpened = useResetRecoilState(kebabOpenedState);
	const resetPinnedSearchResults = useResetRecoilState(pinnedSearchResultsState);
	const resetSearchValue = useResetRecoilState(searchValueState);
	const resetSelectedEdge = useResetRecoilState(selectedEdgeState);
	const resetSelectedNode = useResetRecoilState(selectedNodeState);
	const setSelectedTaxonomy = useSetRecoilState(selectedTaxonomyState);
	const resetPendingChanges = useResetRecoilState(pendingChangesState);
	const resetTaxonomyError = useResetRecoilState(taxonomyErrorState);
	const resetTaxonomyToImport = useResetRecoilState(taxonomyToImportState);
	const resetTaxonomyToFetch = useResetRecoilState(taxonomyToFetchState);
	const resetTempFileName = useResetRecoilState(tempFileNameState);
	const resetTempLogs = useResetRecoilState(tempLogsState);
	const resetTreeNodes = useResetRecoilState(treeNodesState);
	const setTaxonomyCopy = useSetRecoilState(selectedTaxonomyCopyState);
	const pendingChanges = useRecoilValue(pendingChangesState);
	const setPendingChangesCopy = useSetRecoilState(pendingChangesCopyState);

	return ({ tree, ...taxonomy }: ITaxonomy) => {
		const { edges, logs } = tree;
		let { nodes } = tree;

		if (!nodes || nodes.length === 0) {
			nodes = [{ text: taxonomy.taxonomyName, id: TREE_SETTINGS.ROOT_NODE }];
		}

		setSelectedTaxonomy({ ...taxonomy, tree: { nodes, edges, logs } });
		setTaxonomyCopy({ ...taxonomy, tree: { nodes, edges, logs } });
		pendingChanges && setPendingChangesCopy([...pendingChanges]);
		resetTreeNodes();

		// reset states
		resetSelectedNode();
		resetSelectedEdge();

		closeTreeMenu?.();
		resetKebabOpened();
		resetSearchValue();
		resetPinnedSearchResults();

		clearHistory?.(nodes, edges);
		resetTempLogs();
		resetConnectionMode();
		clearSelections?.();

		resetTaxonomyToFetch();
		resetTaxonomyError();
		resetTempFileName();
		resetIsTaxonomyError();
		resetTaxonomyToImport();

		!taxonomyId && resetPendingChanges();
		resetFetchPendingChanges();
	};
};

export const useCloseHeaderDropdowns = () => {
	const resetHeaderDropdownOpen = useResetRecoilState(headerDropdownOpenState);
	const resetUserMenuOpen = useResetRecoilState(userMenuOpenState);
	const resetExportMenuOpen = useResetRecoilState(exportMenuOpenState);
	const resetNotificationsMenuOpen = useResetRecoilState(notificationsMenuOpenState);

	return () => {
		resetHeaderDropdownOpen();
		resetUserMenuOpen();
		resetExportMenuOpen();
		resetNotificationsMenuOpen();
	};
};
