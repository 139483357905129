import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import * as Icon from '../../../../assets/icons/header/actionBar';
import { useOutsideClick } from '../../../../hooks';
import { useCloseHeaderDropdowns } from '../../../../services/header.services';
import { useResetActionsOpen } from '../../../../services/tree.services';
import { selectedTaxonomyState } from '../../../../state/global.state';
import { exportMenuOpenState } from '../../../../state/header.state';
import { Colors } from '../../../../styles/colors.styles';
import { ACTION_BAR_TESTS_IDS } from '../../actionBar.constants';
import { ExportDropdown } from '../exportDropdown';
import * as Style from './exportTaxonomy.styles';

export const ExportTaxonomy = () => {
	const [exportMenuOpen, setExportMenuOpen] = useRecoilState(exportMenuOpenState);
	const selectedTaxonomy = useRecoilValue(selectedTaxonomyState);
	const resetExportMenuOpen = useResetRecoilState(exportMenuOpenState);

	const dropDownRef = useOutsideClick(() => resetExportMenuOpen());

	const resetActionsOpen = useResetActionsOpen();
	const closeDropdowns = useCloseHeaderDropdowns();

	const handleExportMenuClick = async (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();

		resetActionsOpen();
		closeDropdowns();
		setExportMenuOpen(!exportMenuOpen);
	};

	return (
		<Style.ExportMenuWrapper
			onClick={e => handleExportMenuClick(e)}
			ref={dropDownRef}
			data-testid={ACTION_BAR_TESTS_IDS.EXPORT_MENU}
		>
			<Style.ExportIcon
				disabled={!selectedTaxonomy}
				width="24px"
				height="24px"
				fill={Colors.primary100}
				fillHover={Colors.primary500}
			>
				<Icon.Export />
			</Style.ExportIcon>
			{exportMenuOpen && <ExportDropdown />}
		</Style.ExportMenuWrapper>
	);
};
