import { ComponentProps } from 'react';
import { useRecoilValue } from 'recoil';

import { MODAL_TESTS_IDS } from '../../../../constants/modals.constants';
import { dialogConfigSelector, isConfirmationModalOpenState } from '../../../../state/modal.state';
import { BaseModalContent } from '../../atomsComponents/BaseForm/BaseModalContent';
import { FormDescription } from '../../atomsComponents/modalAtoms';
import { BaseModal } from '../../BaseModal';

type SaveTaxonomyModalProps = Omit<ComponentProps<typeof BaseModal>, 'title' | 'testid'> & {
	onClose: () => void;
	onConfirm: () => void;
};
export const SaveTaxonomyModal = ({ onClose, onConfirm }: SaveTaxonomyModalProps) => {
	const isConfirmationModalOpen = useRecoilValue(isConfirmationModalOpenState);
	const dialogConfig = useRecoilValue(dialogConfigSelector);

	const onSubmit = () => {
		onConfirm();
	};

	const onDismiss = () => {
		onClose();
	};

	return (
		<BaseModal title="Confirmation before save" showModal={isConfirmationModalOpen} testid={MODAL_TESTS_IDS.SAVE_TAXONOMY_MODAL}>
			<BaseModalContent discardBtnText="CANCEL" submitBtnText="APPROVE" onSubmit={onSubmit} onDiscard={onDismiss} isValid>
				{dialogConfig?.message && <FormDescription>{dialogConfig?.message}</FormDescription>}
				<FormDescription>Click approve to save the taxonomy or cancel to go back to the taxonomy editor.</FormDescription>
			</BaseModalContent>
		</BaseModal>
	);
};
