import { MouseEvent } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import styled from 'styled-components';

import { ArrowDown, ArrowUp, Bell } from '../../../../assets/icons/header/userMenu';
import { taxonomiesWithChangesSelector } from '../../../../state/global.state';
import { headerDropdownOpenState, notificationsMenuOpenState, userMenuOpenState } from '../../../../state/header.state';
import { isPendingChangesLoadingState } from '../../../../state/history.state';
import { isAdminState, userState } from '../../../../state/user.state';
import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { icon, SpinnerCircle } from '../../../../styles/global.styles';
import { HEADER_TESTS_IDS } from '../../header.constants';
import { NotificationsDropdown } from '../notificationsDropdown';
import { UserDropdown } from '../userDropdown';

const Root = styled.div`
	margin-right: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
`;

const UserWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding-right: 8px;
	border-right: 1px solid ${Colors.secondary500};
`;

const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 12px;
`;

const Name = styled.p`
	font-size: ${FONT_SIZES.BODY};
	line-height: 1.15;
	color: ${Colors.secondary50};
	margin-top: 0;
	margin-bottom: 0;
`;

const Role = styled.p`
	font-size: ${FONT_SIZES.XS};
	line-height: 1.15;
	color: ${Colors.secondary100};
	margin-top: 0;
	margin-bottom: 0;
`;

const Arrow = styled.svg`
	${icon};
`;

const NotificationsWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
`;

const Notification = styled.button<{ disabled?: boolean }>`
	display: flex;
	padding: 5px 8px;
`;

const Badge = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 4px;
	left: 18px;
	min-width: 16px;
	height: 16px;
	border-radius: 8px;
	background-color: ${Colors.alert500};
	color: ${Colors.white};
	font-size: ${FONT_SIZES.XS};
	font-weight: 600;
	line-height: 1.15;
	padding: 4px 6px 2px;
`;

const BellIcon = styled.svg`
	${icon};
`;

const Spinner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: 40px;
`;

const Circle = styled(SpinnerCircle)`
	width: 16px;
	height: 16px;
	border: 2px solid ${Colors.secondary300};
	border-bottom-color: ${Colors.secondary800};
`;

const Preloader = () => (
	<Spinner>
		<Circle />
	</Spinner>
);

const ArrowButton = () => {
	const userMenuOpen = useRecoilValue(userMenuOpenState);
	return (
		<Arrow width="16px" height="16px" fill={Colors.secondary100} fillHover={Colors.white}>
			{userMenuOpen ? <ArrowUp /> : <ArrowDown />}
		</Arrow>
	);
};

const NotificationButton = () => {
	const setIsPendingChangesLoading = useRecoilValue(isPendingChangesLoadingState);
	const isNotifications = useRecoilValue(taxonomiesWithChangesSelector);
	const activeBell = isNotifications?.length > 0;

	return (
		<Notification>
			{setIsPendingChangesLoading ? (
				<Preloader />
			) : (
				<BellIcon
					width="24px"
					height="24px"
					fill={activeBell ? Colors.selected300 : Colors.secondary300}
					fillHover={Colors.selected300}
				>
					<Bell />
				</BellIcon>
			)}
		</Notification>
	);
};

export const UserMenu = () => {
	const isAdmin = useRecoilValue(isAdminState);
	const resetHeaderDropdownOpen = useResetRecoilState(headerDropdownOpenState);
	const user = useRecoilValue(userState);
	const [userMenuOpen, setUserMenuOpen] = useRecoilState(userMenuOpenState);
	const [notificationsMenuOpen, setNotificationsMenuOpen] = useRecoilState(notificationsMenuOpenState);
	const taxonomiesWithChanges = useRecoilValue(taxonomiesWithChangesSelector);

	const handleUserMenuClick = (e: MouseEvent) => {
		e.stopPropagation();
		resetHeaderDropdownOpen();
		setUserMenuOpen(!userMenuOpen);
	};

	const handleNotificationsMenuClick = (e: MouseEvent) => {
		e.stopPropagation();
		resetHeaderDropdownOpen();
		setNotificationsMenuOpen(!notificationsMenuOpen);
	};

	return (
		<Root data-testid={HEADER_TESTS_IDS.USER_INFO}>
			<UserWrapper onClick={e => handleUserMenuClick(e)} data-testid={HEADER_TESTS_IDS.USER_MENU}>
				<UserInfo>
					<Name>{user?.nickname}</Name>
					<Role>{isAdmin ? 'Admin' : 'Member'}</Role>
				</UserInfo>
				<ArrowButton />
			</UserWrapper>
			{userMenuOpen && <UserDropdown />}
			<NotificationsWrapper onClick={e => handleNotificationsMenuClick(e)} data-testid={HEADER_TESTS_IDS.NOTIFICATIONS_MENU}>
				{taxonomiesWithChanges?.length > 0 && <Badge>{taxonomiesWithChanges.length}</Badge>}
				<NotificationButton />
			</NotificationsWrapper>
			{notificationsMenuOpen && <NotificationsDropdown />}
		</Root>
	);
};
