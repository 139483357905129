import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import styled from 'styled-components';

import { useOutsideClick } from '../../../../hooks';
import { useCloseHeaderDropdowns } from '../../../../services/header.services';
import { isDefaultTaxonomyState } from '../../../../state/global.state';
import { userMenuOpenState } from '../../../../state/header.state';
import { historyMenuState, pendingChangesState } from '../../../../state/history.state';
import { isAdminState } from '../../../../state/user.state';
import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { HEADER_TESTS_IDS } from '../../header.constants';

const Root = styled.div`
	width: 250px;
	position: absolute;
	top: 36px;
	right: 0;
	display: flex;
	flex-direction: column;
	background: ${Colors.secondary800};
	border: 1px solid ${Colors.secondary500};
	transform: translateX(calc(100% - 83px));
	padding-top: 8px;
	padding-bottom: 8px;
	border-radius: 6px;
	z-index: 40;

	&::before {
		content: '';
		position: absolute;
		width: 20px;
		height: 20px;
		left: 16px;
		top: -11px;
		background: ${Colors.secondary800};
		transform: rotate(45deg);
		z-index: -10;
		border-top: 1px solid ${Colors.secondary500};
		border-left: 1px solid ${Colors.secondary500};
	}
`;

const Hr = styled.hr`
	width: 100%;
	border: 0.5px solid ${Colors.secondary900};
`;

const ListItem = styled.span<{ disabled?: boolean }>`
	color: ${Colors.secondary50};
	font-size: ${FONT_SIZES.XS};
	line-height: 1.15;
	padding: 8px 16px;
	transition: 0.3s;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

	&:hover {
		background-color: ${Colors.secondary900};
	}
`;

const LogoutItem = styled(ListItem)`
	color: ${Colors.alert500};
`;

export const UserDropdown = () => {
	const [historyMenu, setHistoryMenu] = useRecoilState(historyMenuState);
	const isAdmin = useRecoilValue(isAdminState);
	const isDefaultTaxonomy = useRecoilValue(isDefaultTaxonomyState);
	const pendingChanges = useRecoilValue(pendingChangesState);
	const resetUserMenuOpen = useResetRecoilState(userMenuOpenState);

	const dropDownRef = useOutsideClick(() => resetUserMenuOpen());
	const closeDropdowns = useCloseHeaderDropdowns();

	const { logout } = useAuth0();

	const handleLogout = () => logout();

	const handleClick = (type: 'history' | 'suggested' | 'all') => {
		closeDropdowns();
		if (historyMenu.type === type) return setHistoryMenu({ type: null, open: false });
		setHistoryMenu({ type, open: true });
	};

	return (
		<Root ref={dropDownRef}>
			{!isDefaultTaxonomy && (
				<>
					<ListItem onClick={() => handleClick('history')} data-testid={HEADER_TESTS_IDS.HISTORY} disabled={isDefaultTaxonomy}>
						Changes history log
					</ListItem>
					{isAdmin ? (
						<ListItem
							onClick={() => handleClick('all')}
							data-testid={HEADER_TESTS_IDS.ALL_CHANGES}
							disabled={isDefaultTaxonomy || !pendingChanges}
						>
							View all changes review
						</ListItem>
					) : (
						<ListItem
							onClick={() => handleClick('suggested')}
							data-testid={HEADER_TESTS_IDS.SUGGESTED}
							disabled={isDefaultTaxonomy}
						>
							My suggested changes
						</ListItem>
					)}
					<Hr />
				</>
			)}
			<LogoutItem onClick={handleLogout} data-testid={HEADER_TESTS_IDS.LOGOUT_BUTTON}>
				Log out
			</LogoutItem>
		</Root>
	);
};
