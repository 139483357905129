import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import { useCallback } from 'react';
import { createEdgeFromNodes, removeAndUpsertNodes } from 'reaflow';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import { UNDO_CONTROL } from '../constants/controls.constants';
import { selectedTaxonomyState } from '../state/global.state';
import {
	connectionModeState,
	rootNodeState,
	selectedNodeState,
	treeNodesState,
	undoControlButtonsState,
	visibleTreeState,
} from '../state/tree.state';
import { closeTreeMenuNodesState, closeTreeMenuState, openTreeMenuNodesState } from '../state/treeMenuBar.state';
import type { ITaxonomyEdge, ITaxonomyNode, ITreeLog } from '../types/taxonomy.types';
import {
	useDependantsOf,
	useDependenciesOf,
	useDirectDependantsOf,
	useDirectDependenciesOf,
	useEntryNodes,
} from './graph.services';
import { useLevelsForLogs } from './history.services';
import { useCreateLog, useResetSelections, useSaveTaxonomy } from './tree.services';

export const useCollapseAllNodes = () => {
	const closeTreeMenu = useRecoilValue(closeTreeMenuState);
	const { nodes } = useRecoilValue(selectedTaxonomyState).tree;
	const setTreeNodes = useSetRecoilState(treeNodesState);

	const entryNodes = useEntryNodes();
	const directDependenciesOf = useDirectDependenciesOf();
	const resetSelections = useResetSelections();

	return () => {
		const rootNodes = entryNodes();
		const rootDirectDependencies = rootNodes.map(id => directDependenciesOf(id));
		const nodesToShow = new Set([...rootNodes, ...rootDirectDependencies].flat());

		const updatedTreeNodes = nodes.map(({ id }) => ({
			id,
			chunk: 1,
			expanded: rootNodes.includes(id),
			show: nodesToShow.has(id),
		}));

		setTreeNodes(updatedTreeNodes);

		closeTreeMenu?.();
		resetSelections();
	};
};

export const useNodeSiblings = () => {
	const directDependantsOf = useDirectDependantsOf();
	const directDependenciesOf = useDirectDependenciesOf();

	return useCallback(
		(nodeId: string) => {
			const parentNodes = directDependantsOf(nodeId);
			const parentNodesChildren = parentNodes.map(parentNode => directDependenciesOf(parentNode));
			return parentNodesChildren.flat().filter(parentNodeChild => parentNodeChild !== nodeId);
		},
		[directDependantsOf, directDependenciesOf],
	);
};

export const useNodeSiblingsPerParent = () => {
	const directDependantsOf = useDirectDependantsOf();
	const directDependenciesOf = useDirectDependenciesOf();

	return useCallback(
		(nodeId: string) => {
			const parentNodes = directDependantsOf(nodeId);
			return parentNodes.map(parentNode => ({
				id: parentNode,
				siblings: directDependenciesOf(parentNode).filter(parentNodeChild => parentNodeChild !== nodeId),
			}));
		},
		[directDependantsOf, directDependenciesOf],
	);
};

const useCollapseNode = () => {
	const connectionMode = useRecoilValue(connectionModeState);
	const closeTreeMenu = useRecoilValue(closeTreeMenuState);
	const closeTreeMenuNodes = useRecoilValue(closeTreeMenuNodesState);
	const rootNode = useRecoilValue(rootNodeState);
	const [treeNodes, setTreeNodes] = useRecoilState(treeNodesState);
	const setSelectedNode = useSetRecoilState(selectedNodeState);

	const dependenciesOf = useDependenciesOf();
	const directDependenciesOf = useDirectDependenciesOf();
	const nodeSiblings = useNodeSiblings();

	return useCallback(
		(nodeId: string) => {
			const nodesToHide = new Set([...new Set([...directDependenciesOf(nodeId), ...dependenciesOf(nodeId)])]);
			const nodesToCollapse = new Set([nodeId, ...nodesToHide]);

			const siblings = nodeSiblings(nodeId);

			treeNodes
				.filter(({ expanded, id }) => expanded && siblings.includes(id))
				.forEach(({ id }) => {
					const siblingsDependencies = new Set([...dependenciesOf(id), ...directDependenciesOf(id)]);
					siblingsDependencies.forEach(siblingsDependency => {
						nodesToHide.delete(siblingsDependency);
						nodesToCollapse.delete(siblingsDependency);
					});
				});

			treeNodes
				.filter(({ expanded, id }) => !expanded && siblings.includes(id))
				.forEach(({ id }) => {
					nodesToHide.delete(id);
					nodesToCollapse.delete(id);
				});

			const updatedTreeNodes = treeNodes.map(({ expanded, id, show, chunk }) => ({
				expanded: nodesToCollapse.has(id) ? false : expanded,
				id,
				show: nodesToHide.has(id) ? false : show,
				chunk,
			}));

			setTreeNodes(updatedTreeNodes);

			rootNode?.id === nodeId ? closeTreeMenu?.() : closeTreeMenuNodes?.([nodeId]);
			if (!connectionMode.active)
				setSelectedNode(prevState => (prevState && nodesToHide.has(prevState?.id) ? undefined : prevState));
		},
		[
			closeTreeMenu,
			closeTreeMenuNodes,
			connectionMode.active,
			dependenciesOf,
			directDependenciesOf,
			nodeSiblings,
			rootNode?.id,
			setSelectedNode,
			setTreeNodes,
			treeNodes,
		],
	);
};

export const useExpandNode = () => {
	const connectionMode = useRecoilValue(connectionModeState);
	const openTreeMenuNodes = useRecoilValue(openTreeMenuNodesState);
	const setSelectedNode = useSetRecoilState(selectedNodeState);
	const [treeNodes, setTreeNodes] = useRecoilState(treeNodesState);
	const { nodes } = useRecoilValue(selectedTaxonomyState).tree;

	const directDependenciesOf = useDirectDependenciesOf();

	return useCallback(
		(nodeId: string) => {
			const targetNode = treeNodes.find(({ id }) => id === nodeId);
			const directDeps = directDependenciesOf(nodeId);
			const node = nodes.find(node => node.id === nodeId);

			if (!connectionMode.active) setSelectedNode(node);

			if (directDeps.length <= 25) {
				const nodeIdsToShow = new Set(directDependenciesOf(nodeId));

				const updatedTreeNodes = treeNodes.map(node => ({
					...node,
					expanded: node.id === nodeId ? true : node.expanded,
					show: nodeIdsToShow.has(node.id) ? true : node.show,
				}));

				openTreeMenuNodes?.([nodeId]);
				return setTreeNodes(updatedTreeNodes);
			}

			if (targetNode && directDeps.length > 25) {
				const nodeIdsToShow = new Set(directDeps.slice((targetNode?.chunk - 1) * 25, targetNode?.chunk * 25));

				const updatedTreeNodes = treeNodes.map(node => ({
					...node,
					expanded: node.id === nodeId ? true : node.expanded,
					show: nodeIdsToShow.has(node.id) ? true : node.show,
				}));
				setTreeNodes(updatedTreeNodes);
			}
		},
		[treeNodes, directDependenciesOf, nodes, connectionMode.active, setSelectedNode, openTreeMenuNodes, setTreeNodes],
	);
};

export const useToggleCollapseNode = () => {
	const collapseNode = useCollapseNode();
	const expandNode = useExpandNode();
	const treeNodes = useRecoilValue(treeNodesState);
	const visibleTree = useRecoilValue(visibleTreeState);

	return (nodeId?: string) => {
		const treeNode = treeNodes.find(({ id }) => id == nodeId);

		if (nodeId && treeNode && visibleTree.nodes.some(({ id }) => id === treeNode.id)) {
			treeNode.expanded ? collapseNode(nodeId) : expandNode(nodeId);
		}
	};
};

export const useNodeLevels = () => {
	const directDependantsOf = useDirectDependantsOf();
	const entryNodes = useEntryNodes();
	const rootNodes = new Set(entryNodes());

	const calculateLevels = (nodeId: string, depth: number): number[] => {
		const nodeDependants = directDependantsOf(nodeId);

		return [
			...new Set(
				nodeDependants.flatMap(node => {
					if (rootNodes.has(node)) return depth;
					return calculateLevels(node, depth + 1);
				}),
			),
		].sort((a, b) => a - b);
	};

	return (nodeId?: string) => {
		if (!nodeId) return undefined;

		const levels = calculateLevels(nodeId, 1);

		if (levels?.length > 0) return levels;
	};
};

export const useHideNodeNeighbours = () => {
	const closeTreeMenu = useRecoilValue(closeTreeMenuState);
	const closeTreeMenuNodes = useRecoilValue(closeTreeMenuNodesState);
	const [treeNodes, setTreeNodes] = useRecoilState(treeNodesState);
	const rootNode = useRecoilValue(rootNodeState);
	const [undoControlButtons, setUndoControlButtons] = useRecoilState(undoControlButtonsState);
	const resetUndoControlButtons = useResetRecoilState(undoControlButtonsState);
	const visibleTree = useRecoilValue(visibleTreeState);

	const dependenciesOf = useDependenciesOf();
	const nodeSiblings = useNodeSiblings();

	return useCallback(
		(nodeId: string) => {
			if (undoControlButtons.nodeId === nodeId && undoControlButtons.type === UNDO_CONTROL.HIDE) {
				setTreeNodes(undoControlButtons.nodesForUndo);
				resetUndoControlButtons();
				return;
			}

			setUndoControlButtons({
				nodeId,
				nodesForUndo: treeNodes,
				type: UNDO_CONTROL.HIDE,
			});

			const siblings = nodeSiblings(nodeId);

			let newNodesState = treeNodes.map(node => ({
				...node,
				expanded: siblings.includes(node.id) ? false : node.expanded,
				show: siblings.includes(node.id) ? false : node.show,
			}));

			const openedSiblings = uniq(
				intersection(
					visibleTree.edges.map(edge => edge.from),
					siblings,
				),
			);

			openedSiblings &&
				openedSiblings.forEach(nodeId => {
					const children = dependenciesOf(nodeId as string);
					newNodesState = newNodesState.map(node => ({ ...node, show: children.includes(node.id) ? false : node.show }));
					rootNode?.id === nodeId ? closeTreeMenu?.() : closeTreeMenuNodes?.([nodeId as string]);
				});

			setTreeNodes(newNodesState);
			closeTreeMenuNodes?.([...newNodesState.filter(({ show }) => !show).map(({ id }) => id)]);
		},
		[
			closeTreeMenu,
			closeTreeMenuNodes,
			dependenciesOf,
			nodeSiblings,
			resetUndoControlButtons,
			rootNode?.id,
			setTreeNodes,
			setUndoControlButtons,
			treeNodes,
			undoControlButtons,
			visibleTree.edges,
		],
	);
};

export const useNodePathToRoot = () => {
	const closeTreeMenuNodes = useRecoilValue(closeTreeMenuNodesState);
	const [treeNodes, setTreeNodes] = useRecoilState(treeNodesState);
	const [undoControlButtons, setUndoControlButtons] = useRecoilState(undoControlButtonsState);
	const resetUndoControlButtons = useResetRecoilState(undoControlButtonsState);

	const dependantsOf = useDependantsOf();

	return useCallback(
		(nodeId: string) => {
			if (undoControlButtons.nodeId === nodeId && undoControlButtons.type === UNDO_CONTROL.PATH) {
				setTreeNodes(undoControlButtons.nodesForUndo);
				resetUndoControlButtons();
				return;
			}

			setUndoControlButtons({
				nodeId,
				nodesForUndo: treeNodes,
				type: UNDO_CONTROL.PATH,
			});

			const nodesToExpand = dependantsOf(nodeId);
			const nodesToShow = [nodeId, ...nodesToExpand];

			const newNodesState = treeNodes.map(node => ({
				...node,
				expanded: nodesToExpand.includes(node.id),
				show: nodesToShow.includes(node.id),
			}));

			setTreeNodes(newNodesState);
			closeTreeMenuNodes?.([...newNodesState.filter(({ show }) => !show).map(({ id }) => id)]);
		},
		[
			undoControlButtons,
			setUndoControlButtons,
			treeNodes,
			dependantsOf,
			setTreeNodes,
			closeTreeMenuNodes,
			resetUndoControlButtons,
		],
	);
};

export const useMoveNode = () => {
	const rootNode = useRecoilValue(rootNodeState);
	const setSelectedNode = useSetRecoilState(selectedNodeState);
	const { tree } = useRecoilValue(selectedTaxonomyState);
	const treeNodes = useRecoilValue(treeNodesState);

	const createLog = useCreateLog();
	const dependantsOf = useDependantsOf();
	const directDependenciesOf = useDirectDependenciesOf();
	const levelsForLogs = useLevelsForLogs();
	const saveTaxonomy = useSaveTaxonomy();
	const directDependantsOf = useDirectDependantsOf();

	const duplicateName = (fromNode: ITaxonomyNode, toNode: ITaxonomyNode): boolean => {
		const targetChildren = new Set(directDependenciesOf(toNode.id));

		return tree.nodes.some(({ id, text }) => targetChildren.has(id) && text.toLowerCase() === fromNode.text.toLowerCase());
	};

	return async (from: string, to = rootNode?.id) => {
		const dragFrom = tree.nodes.filter(node => node.id === from)[0];
		const dropTo = tree.nodes.filter(node => node.id === to)[0] || rootNode;
		if (duplicateName(dragFrom, dropTo)) return;
		const { fromLevels, toLevels } = levelsForLogs(dragFrom.id, dropTo.id);

		const oldParents = tree.nodes.filter(node => directDependantsOf(from).includes(node.id) && { id: node.id, text: node.text });
		const newEdges = tree.edges.filter(edge => edge.to !== from);
		const edgesToSave = [...newEdges, createEdgeFromNodes(dropTo, dragFrom)];

		const log = createLog('nodeMove', { from: dragFrom, to: dropTo, oldParents, fromLevels, toLevels });

		const nodesToExpand = new Set([dropTo.id, ...directDependantsOf(dropTo.id)]);
		const nodesToShow = new Set([dragFrom.id, ...dependantsOf(dropTo.id), ...nodesToExpand]);

		const updatedTreeNodes = treeNodes.map(node => ({
			...node,
			expanded: node.expanded || nodesToExpand.has(node.id),
			show: node.show || nodesToShow.has(node.id),
		}));

		await saveTaxonomy({ ...tree, edges: edgesToSave }, log, updatedTreeNodes);
		setSelectedNode(dragFrom);
	};
};

const addNodeAndEdge = (nodes: ITaxonomyNode[], edges: ITaxonomyEdge[], newNode: ITaxonomyNode, parentNode: ITaxonomyNode) => {
	const newEdge = {
		id: `${parentNode.id}-${newNode.id}`,
		from: parentNode.id,
		to: newNode.id,
	};
	return {
		nodes: [...nodes, newNode],
		edges: [...edges, newEdge],
	};
};

export const useCreateNode = () => {
	const setSelectedNode = useSetRecoilState(selectedNodeState);
	const { tree } = useRecoilValue(selectedTaxonomyState);
	const treeNodes = useRecoilValue(treeNodesState);

	const createLog = useCreateLog();
	const nodeLevels = useNodeLevels();
	const saveTaxonomy = useSaveTaxonomy();
	const dependantsOf = useDependantsOf();
	const directDependantsOf = useDirectDependantsOf();

	return async (parent: ITaxonomyNode, nodes?: Record<string, unknown>, child?: ITaxonomyNode) => {
		const nodesToCreate: Array<ITaxonomyNode> = [];
		let updatedNodesAndEdges: ReturnType<typeof addNodeAndEdge> = { edges: tree.edges, nodes: tree.nodes };

		if (nodes) {
			(nodes.names as string[]).map(name => {
				const nodeToCreate = { id: uuidv4(), text: name };
				nodesToCreate.push(nodeToCreate);
				updatedNodesAndEdges = addNodeAndEdge(updatedNodesAndEdges.nodes, updatedNodesAndEdges.edges, nodeToCreate, parent);
			});
		}

		if (child) {
			nodesToCreate.push(child);
			updatedNodesAndEdges = addNodeAndEdge(updatedNodesAndEdges.nodes, updatedNodesAndEdges.edges, child, parent);
		}

		const updatedTree = { ...tree, nodes: updatedNodesAndEdges.nodes, edges: updatedNodesAndEdges.edges };
		const updatedTreeNodes = [...treeNodes, ...nodesToCreate.map(({ id }) => ({ expanded: false, id, show: true, chunk: 1 }))];

		const parentLevels = nodeLevels(parent?.id) || '0';
		const childLevels = parentLevels === '0' ? '1' : parentLevels.map(level => level + 1);

		const logs: ITreeLog[] = [];
		nodesToCreate.forEach(node => logs.push(createLog('nodeAdd', { parent, children: node, childLevels, parentLevels })));

		const nodeToExpand = new Set([parent.id, ...directDependantsOf(parent.id)]);
		const nodesToShow = new Set([...dependantsOf(parent.id), ...nodeToExpand]);

		const updatedTreeNodesWithShow = updatedTreeNodes.map(node => ({
			...node,
			expanded: node.expanded || nodeToExpand.has(node.id),
			show: node.show || nodesToShow.has(node.id),
		}));

		await saveTaxonomy(updatedTree, logs, updatedTreeNodesWithShow);
		setSelectedNode(nodesToCreate[0]);
	};
};

export const useRenameNode = () => {
	const [selectedNode, setSelectedNode] = useRecoilState(selectedNodeState);
	const { tree } = useRecoilValue(selectedTaxonomyState);
	const treeNodes = useRecoilValue(treeNodesState);

	const createLog = useCreateLog();
	const nodeLevels = useNodeLevels();
	const saveTaxonomy = useSaveTaxonomy();

	return async (name: string, nodeId: string) => {
		const oldName = tree.nodes?.find(({ id }) => id === nodeId)?.text;
		const newNodes = tree.nodes?.map(node => (node.id === nodeId ? { ...node, text: name } : node));

		const levels = nodeLevels(nodeId);
		const log = createLog('nodeRename', { name: name, oldName, id: nodeId, levels });

		await saveTaxonomy({ ...tree, nodes: newNodes }, log, treeNodes);
		selectedNode && setSelectedNode({ ...selectedNode, text: name });
	};
};

export const useDeleteNode = () => {
	const { tree } = useRecoilValue(selectedTaxonomyState);
	const treeNodes = useRecoilValue(treeNodesState);
	const setSelectedNode = useSetRecoilState(selectedNodeState);

	const createLog = useCreateLog();
	const dependantsOf = useDependantsOf();
	const directDependantsOf = useDirectDependantsOf();
	const nodeLevels = useNodeLevels();
	const saveTaxonomy = useSaveTaxonomy();

	return async (nodeToDelete: ITaxonomyNode) => {
		const updatedTreeNodes = treeNodes.filter(({ id }) => id !== nodeToDelete.id);

		const parents = tree.nodes.filter(
			node => directDependantsOf(nodeToDelete.id).includes(node.id) && { id: node.id, text: node.text },
		);
		const result = removeAndUpsertNodes(tree.nodes, tree.edges, nodeToDelete);
		const updatedTree = { ...tree, nodes: result.nodes, edges: result.edges };

		const levels = nodeLevels(nodeToDelete.id);
		const log = createLog('nodeDelete', { node: nodeToDelete, parents, levels });

		const nodesToExpand = new Set(directDependantsOf(nodeToDelete.id));
		const nodesToShow = new Set([...dependantsOf(nodeToDelete.id), ...nodesToExpand]);

		const updatedTreeNodesWithShow = updatedTreeNodes.map(node => ({
			...node,
			expanded: node.expanded || nodesToExpand.has(node.id),
			show: node.show || nodesToShow.has(node.id),
		}));

		await saveTaxonomy(updatedTree, log, updatedTreeNodesWithShow);

		setSelectedNode(updatedTreeNodes.find(node => node.id === directDependantsOf(nodeToDelete.id)[0]));
	};
};
