import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { ArrowDown, ArrowUp, Delete, Star, UnStar } from '../../../../assets/icons/tree';
import { useMultipleParents } from '../../../../services/graph.services';
import { useSetDeletePathModalAction, useSetPrimaryModalAction } from '../../../../services/modal.services';
import { useResetActionsOpen } from '../../../../services/tree.services';
import {
	nodeActionsOpenState,
	pathActionsOpenState,
	selectedEdgeState,
	selectedNodeIsGranularState,
} from '../../../../state/tree.state';
import { TREE_TESTS_IDS } from '../../tree.constants';
import * as Style from './actions.styles';

export const PathActions = () => {
	const resetNodeActionsOpen = useResetRecoilState(nodeActionsOpenState);
	const [pathActionsOpen, setPathActionsOpen] = useRecoilState(pathActionsOpenState);
	const [disableDeletePath, setDisableDeletePath] = useState(false);

	const selectedEdge = useRecoilValue(selectedEdgeState);
	const selectedNodeIsGranular = useRecoilValue(selectedNodeIsGranularState);
	const isEdgePrimary = selectedEdge && selectedEdge.primary ? selectedEdge.primary : false;
	const edgeTargetType = isEdgePrimary ? 'Secondary' : 'Primary';

	const setPrimaryModalAction = useSetPrimaryModalAction();
	const setDeletePathModalAction = useSetDeletePathModalAction();

	const resetActionsOpen = useResetActionsOpen();
	const multipleParents = useMultipleParents();

	useEffect(() => {
		if (!selectedEdge) setPathActionsOpen(false);
	}, [selectedEdge, setPathActionsOpen]);

	useEffect(() => {
		selectedEdge?.to && !multipleParents(selectedEdge.to) ? setDisableDeletePath(true) : setDisableDeletePath(false);
	}, [multipleParents, selectedEdge, setDisableDeletePath]);

	return (
		<Style.Actions>
			<Style.MainButton
				disabled={!selectedEdge}
				isOpen={pathActionsOpen}
				onClick={() => {
					resetNodeActionsOpen();
					setPathActionsOpen(prevState => !prevState);
				}}
				data-testid={TREE_TESTS_IDS.PATH_ACTIONS}
			>
				{pathActionsOpen ? <ArrowUp /> : <ArrowDown />}
				<Style.Heading>Path Actions</Style.Heading>
			</Style.MainButton>
			{pathActionsOpen && (
				<Style.ActionsList>
					<Style.ActionItem>
						<Style.Action
							onClick={() => {
								resetActionsOpen();
								setPrimaryModalAction(isEdgePrimary);
							}}
							disabled={!selectedNodeIsGranular}
							data-testid={TREE_TESTS_IDS.PATH_TOGGLE}
						>
							{isEdgePrimary ? <UnStar /> : <Star />}
							<Style.Text>Set As {edgeTargetType}</Style.Text>
						</Style.Action>
					</Style.ActionItem>

					<Style.ActionItem>
						<Style.DeleteAction
							onClick={() => {
								resetActionsOpen();
								setDeletePathModalAction();
							}}
							disabled={!selectedEdge || disableDeletePath}
							data-testid={TREE_TESTS_IDS.DELETE_PATH}
						>
							<Delete />
							<Style.DeleteText>Delete Path</Style.DeleteText>
						</Style.DeleteAction>
					</Style.ActionItem>
				</Style.ActionsList>
			)}
		</Style.Actions>
	);
};
