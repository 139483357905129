import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';

export const DropDownContainer = styled.div`
	position: absolute;
	width: 250px;
	min-height: 150px;
	max-height: 80vh;
	margin: 0;
	padding: 0;
	padding-right: 4px;
	background: ${Colors.secondary800};
	top: 50px;
	right: 0;
	transform: translateX(calc(50% - 13.5px));
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	border: 1px solid ${Colors.secondary500};
	box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.45);

	&::before {
		content: '';
		position: absolute;
		width: 16px;
		height: 16px;
		left: calc(50% - 10px);
		top: -8px;
		background: ${Colors.secondary800};
		border-top: 1px solid ${Colors.secondary500};
		border-left: 1px solid ${Colors.secondary500};
		transform: rotate(45deg);
		z-index: -10;
	}
`;

export const Square = styled.span`
	position: absolute;
	width: 16px;
	height: 16px;
	left: calc(50% - 10px);
	top: -5px;
	background: ${Colors.secondary800};
	transform: rotate(45deg);
	z-index: -10;
`;
