import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';

export const LongSeparationLine = styled.span`
	height: 32px;
	width: 1px;
	line-height: 16px;
	background-color: ${Colors.secondary500};
`;
