import { useCSVReader } from 'react-papaparse';
import { v4 as uuidv4 } from 'uuid';

import { TAXONOMY_ERRORS } from '../../../../constants/errors.constants';
import { useLoadingStart, useUploadAccepted } from '../../../../services/csv.services';
import * as Style from './csv.styles';

const transformHeader = (header: string) => {
	header = header.toLowerCase().replace(/\W/g, '');

	if (header.startsWith('granulartopic')) return 'topic';
	if (header.startsWith('matchtype')) return 'primary';

	return header;
};

const transformValue = (value: string, level: string) => {
	if (level.startsWith('primary')) return !value;

	if (!value) return null;

	if (level.startsWith('synonym')) return { id: uuidv4(), content: value };

	return { text: value, id: uuidv4(), data: { level } };
};

const handleUploadRejected = () => {
	throw new Error(TAXONOMY_ERRORS.TAXONOMY);
};

export const CSV = () => {
	const { CSVReader } = useCSVReader();
	const handleLoadingStart = useLoadingStart();
	const handleUploadAccepted = useUploadAccepted();

	return (
		<CSVReader
			onUploadAccepted={handleUploadAccepted}
			onUploadRejected={handleUploadRejected}
			config={{
				header: true,
				transformHeader: transformHeader,
				skipEmptyLines: true,
				transform: transformValue,
				beforeFirstChunk: handleLoadingStart,
			}}
		>
			{({ getRootProps }: { getRootProps: () => void }) => (
				<Style.Button {...(typeof getRootProps === 'function' ? getRootProps() : {})}>Import taxonomy (CSV)</Style.Button>
			)}
		</CSVReader>
	);
};
