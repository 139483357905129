import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { MODAL_TESTS_IDS } from '../../../../constants/modals.constants';
import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { BaseModal, BaseModalProps } from '../../BaseModal';

const spin = keyframes`
 100% { transform: rotate(360deg) }
`;

const SpinnerCircle = styled.div`
	display: block;
	border-radius: 50%;
	animation: ${spin} 1.4s infinite linear;
`;

const Spinner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: 40px;
	margin-bottom: 24px;
	margin-top: 12px;
`;

const Circle = styled(SpinnerCircle)`
	width: 36px;
	height: 36px;
	border: 2px solid ${Colors.primary100};
	border-bottom-color: ${Colors.primary500};
`;

const ModalDescription = styled.div`
	padding: 24px;
	font-size: ${FONT_SIZES.H4};
	line-height: 21px;
	padding-bottom: 4px;
`;

const Preloader = () => (
	<Spinner>
		<Circle />
	</Spinner>
);

const downloadDescription = 'The download will start automatically when the file is ready.';

type ExportModalProps = BaseModalProps & { onClose: () => void };

export const ExportModal = ({ showModal }: ExportModalProps) => {
	const [description, setDescription] = useState(downloadDescription);

	useEffect(() => {
		setTimeout(() => {
			setDescription('Just a few more seconds...');
		}, 10000);

		return () => {
			setDescription(downloadDescription);
		};
	}, []);

	return (
		<BaseModal title="Preparing Your Taxonomy for Download" showModal={showModal} testid={MODAL_TESTS_IDS.EXPORT_CSV_MODAL}>
			<ModalDescription>{description}</ModalDescription>
			<Preloader />
		</BaseModal>
	);
};
