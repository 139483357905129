import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { MODAL_TESTS_IDS } from '../../../../../constants/modals.constants';
import { Colors } from '../../../../../styles/colors.styles';
import { ErrorFieldMessage } from '../ErrorFieldMessage';
import { InputField } from '../InputField';
import { Label } from '../Label';
import { MultiTagsInput } from '../MultiTagsInput';
import { MultiTagsInputErrorFieldMessage } from '../MultiTagsInputErrorFieldMessage';

interface IInput {
	Component: typeof InputField;
	ErrorMessage: typeof ErrorFieldMessage;
}

interface IMultiTagsInput {
	Component: typeof MultiTagsInput;
	ErrorMessage: typeof MultiTagsInputErrorFieldMessage;
}

type IInputs = (IInput | IMultiTagsInput) & { dataTestId: string };

const Inputs: Record<string, IInputs> = {
	input: { Component: InputField, dataTestId: MODAL_TESTS_IDS.MODAL_INPUT, ErrorMessage: ErrorFieldMessage },
	multiTagsInput: {
		Component: MultiTagsInput,
		dataTestId: MODAL_TESTS_IDS.MODAL_MULTI_TAGS_INPUT,
		ErrorMessage: MultiTagsInputErrorFieldMessage,
	},
};

const Root = styled.div<{ invalid: boolean }>`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 16px;
	border: 1px solid ${Colors.secondary500};
	border-radius: 4px;

	${({ invalid }) =>
		invalid &&
		css`
			border: 1px solid ${Colors.alert500};
		`}
`;

interface FormFieldProps extends Omit<ControllerRenderProps<FieldValues, string>, 'ref' | 'value'> {
	autoFocus?: boolean;
	defaultValue?: string | string[] | number;
	invalid: boolean;
	isLoading?: boolean;
	label?: string;
	placeholder?: string;
	type: keyof typeof Inputs;
}

export const FormField = ({
	autoFocus,
	defaultValue,
	invalid,
	isLoading,
	label,
	name,
	onBlur,
	onChange,
	placeholder,
	type,
}: FormFieldProps) => {
	const { Component, dataTestId, ErrorMessage } = Inputs[type];

	return (
		<>
			<Root invalid={invalid}>
				{label && (
					<Label htmlFor={name} invalid={invalid}>
						{label}
					</Label>
				)}
				<Component
					autoFocus={autoFocus}
					data-testid={dataTestId}
					defaultValue={defaultValue}
					id={name}
					name={name}
					onBlur={onBlur}
					onChange={onChange}
					placeholder={placeholder || label}
					disabled={isLoading}
				/>
			</Root>
			<ErrorMessage name={name} />
		</>
	);
};
