import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { icon, IIcon } from '../../../../styles/global.styles';

interface IListItemProps {
	depth: number;
	droppable: boolean;
	selected: boolean;
	isDragging: boolean;
	isDropTarget: boolean;
}

type IArrowIcon = {
	selected: boolean;
	isDragging: boolean;
	isDropTarget: boolean;
	depth: number;
} & IIcon;

export const ListItem = styled.div<IListItemProps>`
	display: flex;
	align-items: center;
	margin-left: ${({ depth }) => `${depth * 12}px`};
	padding-left: ${({ droppable }) => (droppable ? '2px' : '28px')};
	background-color: ${Colors.secondary800};
	color: ${({ depth }) => (depth !== 0 ? Colors.secondary300 : Colors.white)};
	color: ${({ depth, selected }) => selected && depth !== 0 && Colors.white};
	color: ${({ depth, selected }) => selected && depth === 0 && Colors.selected300};
	border: ${({ isDropTarget }) => (isDropTarget ? `2px solid ${Colors.secondary300}` : `2px solid ${Colors.secondary800}`)};
	border-radius: 2px;
	position: relative;
	transition: 0.3s;
	cursor: pointer;

	&:hover {
		background-color: ${Colors.secondary500};
		color: ${({ depth }) => depth !== 0 && Colors.white};
	}

	&:active {
		background-color: ${Colors.black};
		border-color: ${Colors.selected300};
		transition-delay: 0.1s;
		color: ${({ depth, selected }) => selected && depth !== 0 && Colors.selected300};
		color: ${({ isDragging }) => isDragging && Colors.selected100};
		border-style: ${({ isDragging }) => isDragging && 'dashed'};
		border-color: ${({ isDragging }) => isDragging && Colors.selected300};
	}
`;

export const NodeText = styled.div<{ depth: number }>`
	font-size: ${FONT_SIZES.BODY};
	line-height: 16px;
	margin-bottom: 0;
	margin-top: 0;
	padding: ${({ depth }) => (depth === 0 ? '6px 8px' : '4px')};
	white-space: nowrap;
	overflow: hidden;
	display: flex;
	align-items: center;
`;

export const NodeName = styled.span`
	max-width: 18ch;
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const Count = styled.span`
	margin-left: 2px;
`;

export const IconContainer = styled.div`
	width: 32px;
	height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ArrowIcon = styled.svg<IArrowIcon>`
	${icon};

	position: static;
	visibility: visible;
	opacity: 1;

	path {
		fill: ${({ depth }) => depth !== 0 && Colors.secondary300};
		fill: ${({ selected }) => selected && Colors.selected300};
		fill: ${({ selected, depth }) => selected && depth !== 0 && Colors.white};
		fill: ${({ isDropTarget }) => isDropTarget && Colors.secondary500};
	}

	${ListItem}:hover && {
		path {
			fill: ${({ depth }) => depth !== 0 && Colors.white};
		}
	}

	${ListItem}:active && {
		position: ${({ depth }) => depth === 0 && 'absolute'};
		visibility: ${({ depth }) => depth === 0 && 'hidden'};
		opacity: ${({ depth }) => depth === 0 && 0};
		transition-delay: 0.1s;

		path {
			fill: ${({ selected, depth, isDragging }) => depth !== 0 && (selected || isDragging) && Colors.selected300};
		}
	}
`;

export const DragIcon = styled.svg<{ depth: number } & IIcon>`
	${icon};

	position: absolute;
	opacity: 0;
	visibility: hidden;

	${ListItem}:hover && {
		position: ${({ depth }) => depth !== 0 && 'static'};
		visibility: ${({ depth }) => depth !== 0 && 'visible'};
		opacity: ${({ depth }) => depth !== 0 && 1};
	}

	${ListItem}:active && {
		position: static;
		visibility: visible;
		opacity: 1;
		transition-delay: 0.1s;

		path {
			fill: ${Colors.selected300};
		}
	}
`;

export const KebabButton = styled.button`
	margin-left: auto;
	display: flex;
	opacity: 0;
	transition: opacity 0.3s;

	${ListItem}:hover && {
		opacity: 1;
	}

	${ListItem}:active && {
		opacity: 0;
	}
`;

export const KebabIcon = styled.svg`
	${icon};
`;

export const HoverInfo = styled.div`
	display: flex;
	position: absolute;
	top: 0;
	left: 24px;
	transform: translateY(-75%);
	background-color: ${Colors.white};
	border-radius: 4px;
	opacity: 0;
	visibility: hidden;
	transition-property: visibility, opacity;
	transition-duration: 0.3s;

	${ListItem}:hover && {
		visibility: visible;
		opacity: 1;
		transition-delay: 0.7s;
	}

	${ListItem}:active && {
		visibility: hidden;
		opacity: 0;
		transition-delay: 0s;
	}
`;

export const HoverText = styled.span`
	color: ${Colors.secondary800};
	font-size: ${FONT_SIZES.XXS};
	font-weight: 700;
	line-height: 1.2;
	padding: 4px 8px;
`;

export const Square = styled.span`
	position: absolute;
	width: 8px;
	height: 8px;
	left: 8px;
	bottom: 0;
	background: ${Colors.white};
	transform: translateY(50%) rotate(45deg);
`;
