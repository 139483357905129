import { ReactNode } from 'react';
import { FieldError, useFormContext, useFormState } from 'react-hook-form';
import styled from 'styled-components';

import { Error, Warning } from '../../../../../assets/icons/modal';
import { ERROR_TYPE, GENERAL_ERRORS } from '../../../../../constants/errors.constants';
import { MODAL_TESTS_IDS } from '../../../../../constants/modals.constants';
import { isError, isWarning } from '../../../../../services/validation.services';
import { Colors } from '../../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../../styles/fonts.styles';

const Root = styled.div`
	font-size: ${FONT_SIZES.XXS};
	line-height: 14px;
	min-height: 32px;
	margin-left: 10px;
	margin-top: 4px;
`;

const Message = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: 2px;
`;

const Text = styled.p<{ isError?: boolean }>`
	margin-top: 0;
	margin-bottom: 0;
	color: ${({ isError }) => (isError ? Colors.warningRed : Colors.selected500)};

	ul {
		padding-left: 18px;
		margin-top: 0;
		margin-bottom: 0;
	}
`;

const Icon = styled.svg`
	width: 14px;
	height: 14px;
	margin-right: 2px;
`;

interface MessageTemplateProps {
	dataTestId: string;
	icon: ReactNode;
	isError?: boolean;
	message?: string;
}
const MessageTemplate = ({ dataTestId, icon, isError, message }: MessageTemplateProps) =>
	message ? (
		<Message>
			<Icon>{icon}</Icon>
			<Text data-testid={dataTestId} isError={isError} dangerouslySetInnerHTML={{ __html: message }} />
		</Message>
	) : null;

export const MultiTagsInputErrorFieldMessage = ({ name }: { name: string }) => {
	const { control } = useFormContext();
	const formState = useFormState({ control });

	const formErrors = formState.errors[name];

	let error: FieldError | undefined;
	let warning: FieldError | undefined;

	if (formErrors) {
		if (formErrors.message && formErrors.type) {
			if (isWarning(formErrors.type)) warning = { ...formErrors };
			else error = { ...formErrors };
		}

		try {
			const deepErrors = Object.values(formErrors) as FieldError[];
			if (!error && deepErrors.some(({ type }) => type && isError(type))) {
				error = { type: ERROR_TYPE.generalError, message: GENERAL_ERRORS.MULTITAGS_GENERAL_ERROR };
			}

			if (!warning && deepErrors.some(({ type }) => type && isWarning(type))) {
				warning = { type: ERROR_TYPE.generalWarning, message: GENERAL_ERRORS.MULTITAGS_GENERAL_WARNING };
			}
			// eslint-disable-next-line no-empty
		} catch {}
	}

	return (
		<Root>
			{error && (
				<MessageTemplate
					key={`${error.type}-${error.message}`}
					dataTestId={MODAL_TESTS_IDS.ERROR_MESSAGE}
					icon={<Error />}
					isError
					message={error.message}
				/>
			)}
			{warning && (
				<MessageTemplate
					key={`${warning.type}-${warning.message}`}
					dataTestId={MODAL_TESTS_IDS.WARNING_MESSAGE}
					icon={<Warning />}
					message={warning.message}
				/>
			)}
		</Root>
	);
};
