import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_FAMILIES, FONT_SIZES } from '../../../../styles/fonts.styles';

export const GridBox = styled.div.attrs(() => ({
	className: 'ag-theme-alpine',
}))`
	width: 100%;
	flex: 1;
	font-size: ${FONT_SIZES.XS};
	line-height: 14px;
	border: 1px solid ${Colors.secondary800};

	--ag-line-height: 14px;
	--ag-background-color: ${Colors.white};
	--ag-foreground-color: ${Colors.secondary500};
	--ag-header-foreground-color: ${Colors.white};
	--ag-checkbox-unchecked-color: ${Colors.primary100};

	.ag-root-wrapper {
		border: 0;
	}

	.ag-row {
		font-size: ${FONT_SIZES.XS};
		font-family: ${FONT_FAMILIES.REGULAR};
	}

	.ag-sort-indicator-icon {
		color: ${Colors.primary500};
	}

	.ag-header-container {
		background-color: ${Colors.secondary800};
	}

	.ag-cell-label-container {
		justify-content: flex-end;
	}

	.ag-header-cell-label {
		flex: 0 0 auto;
	}

	.ag-header-cell-menu-button {
		margin-left: 4px;
	}

	.ag-header-cell {
		padding-left: 8px;
		padding-right: 8px;
		font-weight: 400;
		font-family: ${FONT_FAMILIES.REGULAR};
	}

	.ag-cell {
		padding-left: 8px;
		padding-right: 8px;
	}

	.approve,
	.reject {
		display: flex;
		align-items: center;
		justify-self: 'flex-end';
	}

	.approve-header {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid ${Colors.primary500};
	}
`;
