import { useRecoilValue } from 'recoil';

import * as Icon from '../../../../assets/icons/infoBar';
import { useSetRenameNodeModalAction } from '../../../../services/modal.services';
import { useNodeLevels } from '../../../../services/node.services';
import { selectedNodeState } from '../../../../state/tree.state';
import { Colors } from '../../../../styles/colors.styles';
import { INFO_BAR_TESTS_IDS } from '../../infoBar.constants';
import * as Style from './header.styles';

export const Header = () => {
	const selectedNode = useRecoilValue(selectedNodeState);
	const setRenameNodeModalAction = useSetRenameNodeModalAction();
	const nodeLevels = useNodeLevels();

	const nodeName = selectedNode?.text;
	const levels = nodeLevels(selectedNode?.id);

	const handleModalOpenAndEditMode = () => setRenameNodeModalAction(nodeName);

	return (
		<Style.Header data-testid={INFO_BAR_TESTS_IDS.HEADER_COMPONENT}>
			<Style.TitleWrapper>
				<Style.Title data-testid={INFO_BAR_TESTS_IDS.HEADER_TITLE}>{nodeName}</Style.Title>
				<Style.EditIcon
					data-testid={INFO_BAR_TESTS_IDS.EDIT_BUTTON}
					onClick={handleModalOpenAndEditMode}
					width="15px"
					height="15px"
					fill={Colors.primary500}
					fillHover={Colors.primary300}
				>
					<Icon.Edit />
				</Style.EditIcon>
			</Style.TitleWrapper>
			{levels ? <Style.Depth>{`${levels.length === 1 ? 'Level' : 'Levels'} ${levels.join(', ')}`}</Style.Depth> : null}
		</Style.Header>
	);
};
