import { Label, Node } from 'reaflow';
import styled from 'styled-components';

import * as Icons from '../../../../assets/icons/tree';
import { Colors } from '../../../../styles/colors.styles';

export const TaxonomyNode = styled(Node)<{ isSelected: boolean; isBlocked: boolean }>`
	stroke: ${({ isSelected }) => (isSelected ? Colors.black : Colors.secondary800)} !important;
	fill: ${({ isSelected }) => (isSelected ? Colors.black : Colors.secondary800)};
	stroke-width: 0;
	stroke-dasharray: 4;
	transition-property: fill, stroke, stroke-width;
	transition-duration: 0.3s;
	opacity: ${({ isBlocked }) => (isBlocked ? '0.5' : '1')};
	pointer-events: ${({ isBlocked }) => (isBlocked ? 'none' : 'auto')};

	&:hover {
		stroke: ${({ isSelected }) => (isSelected ? Colors.secondary800 : Colors.secondary500)} !important;
		fill: ${({ isSelected }) => (isSelected ? Colors.secondary800 : Colors.secondary500)};
	}

	&:active {
		stroke: ${({ isSelected }) => (isSelected ? Colors.black : Colors.secondary900)} !important;
		fill: ${({ isSelected }) => (isSelected ? Colors.black : Colors.secondary900)};
		stroke-width: 4;
		transition-delay: 0.1s;
	}
`;

export const NodeLabel = styled(Label)<{ isSelected: boolean }>`
	fill: ${({ isSelected }) => (isSelected ? Colors.selected300 : Colors.secondary100)};
	transition: fill 0.3s;
`;

export const NodeIcon = styled.foreignObject<{ width?: number; height?: number }>`
	width: ${({ width }) => `${width || 0}px`};
	height: ${({ height }) => `${height || 0}px`};
	pointer-events: none;
`;

export const IconWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const ExpandableIcon = styled(Icons.Expandable)<{ $isSelected: boolean }>`
	fill: ${({ $isSelected }) => ($isSelected ? Colors.selected300 : Colors.selected500)};
	position: absolute;
	bottom: 0;
	right: 0;
`;

export const MultipleIcon = styled(Icons.Multiple)`
	fill: ${Colors.primary300};
`;
