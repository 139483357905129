import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { Add, ArrowDown, ArrowUp, Connect, Delete, Edit, Hide, ShowPath } from '../../../../assets/icons/tree';
import { UNDO_CONTROL } from '../../../../constants/controls.constants';
import { useMultipleParents } from '../../../../services/graph.services';
import {
	useSetAddNodeModalAction,
	useSetDeleteNodeModalAction,
	useSetRenameNodeModalAction,
} from '../../../../services/modal.services';
import { useHideNodeNeighbours, useNodePathToRoot } from '../../../../services/node.services';
import { useResetActionsOpen, useResetSelections } from '../../../../services/tree.services';
import {
	clearSelectionsState,
	connectionModeState,
	nodeActionsOpenState,
	pathActionsOpenState,
	rootNodeState,
	selectedNodeState,
	undoControlButtonsState,
} from '../../../../state/tree.state';
import { TREE_TESTS_IDS } from '../../tree.constants';
import * as Style from './actions.styles';

export const CancelConnection = () => {
	const clearSelections = useRecoilValue(clearSelectionsState);
	const connectionMode = useRecoilValue(connectionModeState);
	const resetConnectionMode = useResetRecoilState(connectionModeState);

	const handleClick = () => {
		if (connectionMode.active) {
			clearSelections?.();
			return resetConnectionMode();
		}
	};

	return (
		<Style.CancelButton onClick={handleClick} data-testid={TREE_TESTS_IDS.CONNECT_CANCEL_BUTTON}>
			<Connect />
			<Style.CancelText>Cancel Connection</Style.CancelText>
		</Style.CancelButton>
	);
};

export const NodeActions = () => {
	const clearSelections = useRecoilValue(clearSelectionsState);
	const [connectionMode, setConnectionMode] = useRecoilState(connectionModeState);
	const resetConnectionMode = useResetRecoilState(connectionModeState);
	const [nodeActionsOpen, setNodeActionsOpen] = useRecoilState(nodeActionsOpenState);
	const resetPathActionsOpen = useResetRecoilState(pathActionsOpenState);
	const rootNode = useRecoilValue(rootNodeState);
	const selectedNode = useRecoilValue(selectedNodeState);
	const undoControlButtons = useRecoilValue(undoControlButtonsState);

	const setAddNodeModalAction = useSetAddNodeModalAction();
	const setRenameNodeModalAction = useSetRenameNodeModalAction();
	const setDeleteNodeModalAction = useSetDeleteNodeModalAction();

	const hideNodeNeighbours = useHideNodeNeighbours();
	const multipleParents = useMultipleParents();
	const nodePathToRoot = useNodePathToRoot();
	const resetActionsOpen = useResetActionsOpen();
	const resetSelections = useResetSelections();

	const handleConnection = () => {
		if (connectionMode.active) {
			clearSelections?.();
			return resetConnectionMode();
		}

		resetSelections();
		setConnectionMode(prevState => ({ ...prevState, active: true, activeChildren: true }));
	};

	return (
		<Style.Actions>
			<Style.MainButton
				isOpen={nodeActionsOpen}
				onClick={() => {
					resetPathActionsOpen();
					setNodeActionsOpen(prevState => !prevState);
				}}
				data-testid={TREE_TESTS_IDS.NODE_ACTIONS}
			>
				{nodeActionsOpen ? <ArrowUp /> : <ArrowDown />}
				<Style.Heading>Node Actions</Style.Heading>
			</Style.MainButton>
			{nodeActionsOpen && (
				<Style.ActionsList>
					<Style.ActionItem>
						<Style.Action
							onClick={() => {
								resetActionsOpen();
								setAddNodeModalAction(selectedNode?.text);
							}}
							disabled={!selectedNode}
							data-testid={TREE_TESTS_IDS.ADD_NODE}
						>
							<Add />
							<Style.Text>Add Child</Style.Text>
						</Style.Action>
					</Style.ActionItem>
					<Style.ActionItem>
						<Style.Action
							onClick={() => {
								resetActionsOpen();
								setRenameNodeModalAction(selectedNode?.text);
							}}
							disabled={!selectedNode || selectedNode?.id === rootNode?.id}
							data-testid={TREE_TESTS_IDS.RENAME_NODE}
						>
							<Edit />
							<Style.Text>Rename</Style.Text>
						</Style.Action>
					</Style.ActionItem>
					<Style.ActionItem>
						<Style.Action
							onClick={() => nodePathToRoot(selectedNode?.id as string)}
							disabled={!selectedNode || selectedNode?.id === rootNode?.id}
							pressed={undoControlButtons.type === UNDO_CONTROL.PATH}
							data-testid={TREE_TESTS_IDS.PATH_TO_ROOT}
						>
							<ShowPath />
							<Style.Text pressed={undoControlButtons.type === UNDO_CONTROL.PATH}>
								{undoControlButtons.type === UNDO_CONTROL.PATH ? 'All Paths' : 'Path to Root'}
							</Style.Text>
						</Style.Action>
					</Style.ActionItem>
					<Style.ActionItem>
						<Style.Action
							onClick={() => hideNodeNeighbours(selectedNode?.id as string)}
							disabled={!selectedNode || selectedNode?.id === rootNode?.id || multipleParents(selectedNode?.id)}
							pressed={undoControlButtons.type === UNDO_CONTROL.HIDE}
							data-testid={TREE_TESTS_IDS.HIDE_NEIGHBOURS}
						>
							<Hide />
							<Style.Text pressed={undoControlButtons.type === UNDO_CONTROL.HIDE}>
								{undoControlButtons.type === UNDO_CONTROL.HIDE ? 'Show Neighbours' : 'Hide Neighbours'}
							</Style.Text>
						</Style.Action>
					</Style.ActionItem>
					<Style.ActionItem>
						<Style.ConnectAction
							onClick={handleConnection}
							pressed={connectionMode.active}
							data-testid={TREE_TESTS_IDS.CONNECT_NODES}
						>
							<Connect />
							<Style.Text pressed={connectionMode.active}>Connect nodes</Style.Text>
						</Style.ConnectAction>
					</Style.ActionItem>
					<Style.ActionItem>
						<Style.DeleteAction
							onClick={() => {
								resetActionsOpen();
								setDeleteNodeModalAction();
							}}
							disabled={!selectedNode || selectedNode?.id === rootNode?.id}
							data-testid={TREE_TESTS_IDS.DELETE_NODE}
						>
							<Delete />
							<Style.DeleteText>Delete Node</Style.DeleteText>
						</Style.DeleteAction>
					</Style.ActionItem>
				</Style.ActionsList>
			)}
		</Style.Actions>
	);
};
