import { useRecoilState, useRecoilValue } from 'recoil';

import * as Icon from '../../../../assets/icons/header/actionBar';
import { infoBarOpenState } from '../../../../state/infoBar.state';
import { isRootNode, selectedNodeState } from '../../../../state/tree.state';
import { Colors } from '../../../../styles/colors.styles';
import { ACTION_BAR_TESTS_IDS } from '../../actionBar.constants';
import * as Style from './infoButton.styles';

export const InfoButton = () => {
	const [infoBarOpen, setInfoBarOpen] = useRecoilState(infoBarOpenState);
	const selectedNode = useRecoilValue(selectedNodeState);

	return (
		<Style.Background data-testid={ACTION_BAR_TESTS_IDS.INFO_ICON} visible={infoBarOpen}>
			<Style.InfoButton
				data-testid={ACTION_BAR_TESTS_IDS.INFO_BUTTON}
				disabled={!selectedNode || isRootNode(selectedNode)}
				onClick={() => setInfoBarOpen(prevState => !prevState)}
			>
				<Style.InfoIcon
					width="24px"
					height="24px"
					fill={infoBarOpen ? Colors.primary500 : Colors.secondary100}
					fillHover={Colors.primary500}
				>
					{infoBarOpen ? <Icon.InfoIconActive /> : <Icon.InfoIcon />}
				</Style.InfoIcon>
			</Style.InfoButton>
		</Style.Background>
	);
};
