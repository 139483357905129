import styled from 'styled-components';

import { Colors } from '../../styles/colors.styles';

export const TreeMenuBar = styled.aside<{ treeMenuOpen: boolean }>`
	background: ${Colors.secondary900};
	color: ${Colors.white};
	grid-area: tree-menu;
	display: ${({ treeMenuOpen }) => (treeMenuOpen ? 'flex' : 'block')};
	flex-direction: column;
	height: calc(100vh - 48px);
	width: ${({ treeMenuOpen }) => (treeMenuOpen ? '100%' : '56px')};
	text-align: ${({ treeMenuOpen }) => (treeMenuOpen ? 'unset' : 'center')};
	transition: width 0.3s;
	position: relative;
	z-index: 10;
`;
