import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';

export const ViewControls = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;
	display: flex;
	gap: 8px;
	max-height: 32px;
`;

export const ViewControlsButtons = styled.div`
	display: flex;
	background-color: ${Colors.white};
	border: 1px solid ${Colors.secondary800};
	border-radius: 4px;
	overflow: hidden;
`;

export const ViewControl = styled.button`
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.3s;
	background-color: ${Colors.white};
	min-width: 32px;
	padding-bottom: 2px;

	svg {
		pointer-events: none;
	}

	path {
		fill: ${Colors.secondary500};
		transition: 0.3s;
	}

	&:nth-child(2) {
		border-right: 1px solid ${Colors.secondary800};
		border-left: 1px solid ${Colors.secondary800};
	}

	&:hover {
		background-color: ${Colors.secondary500};

		path {
			fill: ${Colors.white};
		}
	}

	&:disabled {
		path {
			fill: ${Colors.secondary100};
		}
	}

	&:active {
		background-color: ${Colors.secondary800};
	}

	&:disabled:hover {
		background-color: ${Colors.white};

		path {
			fill: ${Colors.secondary100};
		}
	}
`;

export const ButtonText = styled.span`
	color: ${Colors.secondary500};
	font-size: ${FONT_SIZES.BODY};
	transition: 0.3s;
	padding: 8px;

	&:hover {
		color: ${Colors.white};
	}

	${ViewControl}:disabled & {
		color: ${Colors.secondary100};
	}
`;
