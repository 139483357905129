import { useRecoilValue } from 'recoil';

import { MODAL_TESTS_IDS } from '../../../../constants/modals.constants';
import { NodeRenameSchema } from '../../../../schemas/NodeRenameSchema';
import { useRenameNode } from '../../../../services/node.services';
import { selectedNodeState } from '../../../../state/tree.state';
import { BaseForm } from '../../atomsComponents/BaseForm/BaseForm';
import { BaseModal, BaseModalProps } from '../../BaseModal';
import * as Styles from '../../BaseModal/BaseModal.styles';

type RenameNodeModalProps = BaseModalProps & {
	defaultValue?: string | number;
	onClose: () => void;
};
export const RenameNodeModal = ({ defaultValue, onClose, showModal }: RenameNodeModalProps) => {
	const selectedNode = useRecoilValue(selectedNodeState);
	const renameNode = useRenameNode();

	const onSubmit = (node: Record<string, string>) => {
		selectedNode && renameNode(node.name, selectedNode.id);
		onClose();
	};

	return (
		<BaseModal title="Rename node" showModal={showModal} testid={MODAL_TESTS_IDS.RENAME_NODE}>
			<BaseForm
				description={
					defaultValue && (
						<>
							Type a new name for <Styles.Bolded>&quot;{defaultValue}&quot;</Styles.Bolded>
						</>
					)
				}
				fields={[{ fieldName: 'name', fieldType: 'input', defaultValue }]}
				onSubmit={onSubmit}
				onDiscard={onClose}
				submitBtnText="UPDATE NAME"
				scheme={NodeRenameSchema()}
			/>
		</BaseModal>
	);
};
