import { selector } from 'recoil';

import { ITaxonomyNode } from '../types/taxonomy.types';
import { selectedTaxonomyState } from './global.state';

export const nodesMapState = selector<Map<string, ITaxonomyNode>>({
	key: 'nodesMap',
	get: ({ get }) => {
		const { tree } = get(selectedTaxonomyState);
		const nodesMap = new Map<string, ITaxonomyNode>();

		tree.nodes.forEach(node => {
			nodesMap.set(node.id, node);
		});

		return nodesMap;
	},
});
