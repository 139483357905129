import { MODAL_TESTS_IDS } from '../../../../constants/modals.constants';
import { TaxonomySchema } from '../../../../schemas/TaxonomySchema';
import { useRenameImportedTaxonomy } from '../../../../services/tree.services';
import { BaseForm } from '../../atomsComponents/BaseForm/BaseForm';
import { BaseModal, BaseModalProps } from '../../BaseModal';

type ImportTaxonomyModalProps = BaseModalProps & {
	onClose: () => void;
	defaultValue?: string;
};
export const ImportTaxonomyModal = ({ defaultValue, onClose, showModal }: ImportTaxonomyModalProps) => {
	const renameImportedTaxonomy = useRenameImportedTaxonomy();

	const onSubmit = (taxonomy: Record<string, string>) => {
		renameImportedTaxonomy(taxonomy);
		onClose();
	};

	return (
		<BaseModal title="Import and rename taxonomy" showModal={showModal} testid={MODAL_TESTS_IDS.RENAME_TAXONOMY_MODAL}>
			<BaseForm
				description="Imported taxonomy name is already taken."
				fields={[{ fieldName: 'name', fieldType: 'input', defaultValue }]}
				onSubmit={onSubmit}
				onDiscard={onClose}
				submitBtnText="RENAME"
				scheme={TaxonomySchema()}
				validateOnLoad
			/>
		</BaseModal>
	);
};
