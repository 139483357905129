import { useRecoilValue } from 'recoil';

import { MODAL_TESTS_IDS } from '../../../../constants/modals.constants';
import { useDeleteTaxonomy } from '../../../../services/tree.services';
import { taxonomiesSelector } from '../../../../state/global.state';
import { modalPropsState } from '../../../../state/modal.state';
import { Colors } from '../../../../styles/colors.styles';
import { BaseModalContent } from '../../atomsComponents/BaseForm/BaseModalContent';
import { FormAlert, FormDescription } from '../../atomsComponents/modalAtoms';
import { BaseModal, BaseModalProps } from '../../BaseModal';
import * as Styles from '../../BaseModal/BaseModal.styles';

type DeleteTaxonomyModalProps = BaseModalProps & { onClose: () => void };
export const DeleteTaxonomyModal = ({ onClose, showModal }: DeleteTaxonomyModalProps) => {
	const modalProps = useRecoilValue(modalPropsState);
	const taxonomies = useRecoilValue(taxonomiesSelector);

	const deleteTaxonomy = useDeleteTaxonomy();

	const onSubmit = async () => {
		if (modalProps?.id) {
			const taxonomiesToDelete = taxonomies.filter(({ originId }) => originId === modalProps?.id);

			await deleteTaxonomy(modalProps?.id);

			if (taxonomiesToDelete.length > 0) {
				for await (const taxonomy of taxonomiesToDelete) {
					await deleteTaxonomy(taxonomy.taxonomyId);
				}
			}
		}
		onClose();
	};

	return (
		<BaseModal title="Delete this taxonomy?" showModal={showModal} testid={MODAL_TESTS_IDS.DELETE_TAXONOMY_MODAL}>
			<BaseModalContent discardBtnText="CANCEL" submitBtnText="DELETE" onSubmit={onSubmit} onDiscard={onClose} isValid>
				{modalProps?.input && (
					<>
						<FormDescription>
							Are you sure you want to delete <Styles.Bolded>&quot;{modalProps?.input}&quot;</Styles.Bolded>?
						</FormDescription>
						<FormAlert color={Colors.alert800}>This action cannot be undone.</FormAlert>
					</>
				)}
			</BaseModalContent>
		</BaseModal>
	);
};
