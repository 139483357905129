import { ChangeEvent, RefObject, useRef } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { UploadIcon } from '../../assets/icons/tree';
import { TAXONOMY_ERRORS } from '../../constants/errors.constants';
import { useSetRenameImportedTaxonomyModalAction } from '../../services/modal.services';
import {
	selectedTaxonomyState,
	taxonomiesNamesState,
	taxonomyErrorState,
	taxonomyToImportState,
	tempFileNameState,
} from '../../state/global.state';
import { headerDropdownOpenState } from '../../state/header.state';
import { isRootNode } from '../../state/tree.state';
import type { ITaxonomy } from '../../types/taxonomy.types';
import { UPLOAD_TESTS_IDS } from './uploadJsonFile.constants';
import * as Style from './uploadJsonFile.styles';

interface FileInputProps {
	acceptedFileTypes: string[];
	inputRef: RefObject<HTMLInputElement>;
}

export const isTaxonomyValid = (importedTaxonomy: ITaxonomy) =>
	importedTaxonomy &&
	importedTaxonomy.taxonomyName &&
	importedTaxonomy.tree.nodes &&
	importedTaxonomy.tree.edges &&
	importedTaxonomy.tree.logs;

const FileInput = ({ acceptedFileTypes, inputRef }: FileInputProps) => {
	const resetHeaderDropdownOpen = useResetRecoilState(headerDropdownOpenState);
	const resetSelectedTaxonomy = useResetRecoilState(selectedTaxonomyState);
	const taxonomiesNames = useRecoilValue(taxonomiesNamesState);
	const setTaxonomyError = useSetRecoilState(taxonomyErrorState);
	const resetTaxonomyError = useResetRecoilState(taxonomyErrorState);
	const setTaxonomyToImport = useSetRecoilState(taxonomyToImportState);
	const resetTaxonomyToImport = useResetRecoilState(taxonomyToImportState);
	const setTempFileName = useSetRecoilState(tempFileNameState);
	const resetTempFileName = useResetRecoilState(tempFileNameState);

	const setRenameImportedTaxonomyModalAction = useSetRenameImportedTaxonomyModalAction();

	const setError = (errorText: string, e: ChangeEvent<HTMLInputElement>) => {
		//set error after progress bar is finished
		setTimeout(() => {
			setTaxonomyError(errorText);
			e.target.value = '';
		}, 2000);
	};

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		resetTaxonomyError();
		resetTempFileName();

		const file = e.target.files?.[0];

		if (file) {
			resetSelectedTaxonomy();
			resetHeaderDropdownOpen();
			resetTaxonomyToImport();
			setTempFileName(file.name.replace('.json', ''));
		}

		const reader = new FileReader();

		reader.addEventListener(
			'load',
			() => {
				if (file?.type !== 'application/json') {
					setError(TAXONOMY_ERRORS.NOT_JSON, e);
					return;
				}

				try {
					const importedTree = JSON.parse(reader.result as string) as ITaxonomy;

					if (!isTaxonomyValid(importedTree)) {
						setError(TAXONOMY_ERRORS.BAD_JSON, e);
						return;
					}

					const newTaxonomy = {
						taxonomyId: '',
						taxonomyName: importedTree.taxonomyName,
						tree: {
							...importedTree.tree,
							nodes: importedTree.tree.nodes.map(node => ({
								...node,
								text: isRootNode(node) ? importedTree.taxonomyName : node.text,
							})),
						},
					};

					if (taxonomiesNames.includes(importedTree?.taxonomyName)) {
						resetTempFileName();
						setRenameImportedTaxonomyModalAction(importedTree?.taxonomyName);
					}

					setTaxonomyToImport(newTaxonomy);
					e.target.value = '';
				} catch {
					setError(TAXONOMY_ERRORS.BAD_JSON, e);
				}
			},
			false,
		);

		file && reader.readAsText(file);
	};

	return (
		<Style.ImportJsonFileInput
			data-testid={UPLOAD_TESTS_IDS.INPUT_TYPE_FILE}
			id="importJsonFile"
			type="file"
			accept={acceptedFileTypes.join(', ')}
			onChange={onChange}
			ref={inputRef}
		/>
	);
};

const Browse = () => {
	const inputRef = useRef<HTMLInputElement>(null);

	return (
		<Style.UploadContainer>
			<UploadIcon data-testid={UPLOAD_TESTS_IDS.UPLOAD_ICON} />
			<Style.UploadJsonText data-testid={UPLOAD_TESTS_IDS.UPLOAD_A_JSON_FILE}>Upload a JSON File</Style.UploadJsonText>
			<Style.BrowseButton data-testid={UPLOAD_TESTS_IDS.BROWSE_BUTTON} onClick={() => inputRef.current?.click()}>
				Browse
			</Style.BrowseButton>
			<FileInput acceptedFileTypes={['application/JSON']} inputRef={inputRef} />
		</Style.UploadContainer>
	);
};

const ImportTaxonomy = () => {
	const inputRef = useRef<HTMLInputElement>(null);

	return (
		<Style.Button data-testid={UPLOAD_TESTS_IDS.IMPORT_JSON_FILE} onClick={() => inputRef.current?.click()}>
			Import taxonomy (JSON)
			<FileInput acceptedFileTypes={['application/JSON']} inputRef={inputRef} />
		</Style.Button>
	);
};

export const UploadJsonFile = ({ isTreeComponent }: { isTreeComponent: boolean }) =>
	isTreeComponent ? <Browse /> : <ImportTaxonomy />;
