import { MouseEvent } from 'react';
import { useRecoilValue } from 'recoil';

import * as Icon from '../../../../assets/icons/header/taxonomySelector';
import { SmallBell } from '../../../../assets/icons/header/taxonomySelector';
import { useHandleTaxonomyClick } from '../../../../services/header.services';
import { useSetRemoveTaxonomyModalAction } from '../../../../services/modal.services';
import { selectedTaxonomyState, taxonomiesSelector, taxonomiesWithChangesSelector } from '../../../../state/global.state';
import { isAdminState } from '../../../../state/user.state';
import { Colors } from '../../../../styles/colors.styles';
import { ITaxonomy } from '../../../../types/taxonomy.types';
import { TAXONOMY_SELECTOR_TESTS_IDS } from '../../taxonomySelector.constants';
import * as Style from './treesOptions.styles';

export const TreesOptions = () => {
	const isAdmin = useRecoilValue(isAdminState);
	const taxonomies = useRecoilValue(taxonomiesSelector);
	const taxonomiesWithChanges = useRecoilValue(taxonomiesWithChangesSelector);
	const { taxonomyId } = useRecoilValue(selectedTaxonomyState);

	const setRemoveTaxonomyModalAction = useSetRemoveTaxonomyModalAction();

	const handleTaxonomyClick = useHandleTaxonomyClick();

	const deleteTaxonomy = async (e: MouseEvent, id: string, name: string) => {
		e.stopPropagation();
		setRemoveTaxonomyModalAction(name, id);
	};

	const validateTaxonomyRemover = (taxonomy: ITaxonomy) => {
		if (isAdmin) return true;
		if (taxonomy.isDraft) return true;
	};

	const validateTaxonomyNotification = (taxonomy: ITaxonomy) => {
		return (
			!taxonomy.isDraft &&
			isAdmin &&
			taxonomiesWithChanges &&
			taxonomiesWithChanges.find(taxonomyWithChange => taxonomyWithChange.taxonomyId === taxonomy.taxonomyId)
		);
	};

	if (taxonomies.length === 0) return <Style.EmptyContainer data-testid={TAXONOMY_SELECTOR_TESTS_IDS.TREES_OPTIONS} />;

	return (
		<Style.OptionsContainer data-testid={TAXONOMY_SELECTOR_TESTS_IDS.TREES_OPTIONS}>
			{taxonomies.map((taxonomy, index: number) => (
				<Style.Option
					data-testid={`${TAXONOMY_SELECTOR_TESTS_IDS.TREES_OPTION}_${index}`}
					key={taxonomy.taxonomyId}
					onClick={() => handleTaxonomyClick(taxonomy)}
				>
					<Style.Name selected={taxonomy.taxonomyId === taxonomyId}>
						&lsquo;&lsquo;{taxonomy.taxonomyName}&rsquo;&rsquo;
						{taxonomy.isDraft && ' (Draft)'}
					</Style.Name>
					{validateTaxonomyNotification(taxonomy) && (
						<Style.BellIcon width="12px" height="12px" fill={Colors.selected300} fillHover={Colors.selected300}>
							<SmallBell />
						</Style.BellIcon>
					)}
					{validateTaxonomyRemover(taxonomy) && (
						<Style.DeleteButton
							onClick={e => deleteTaxonomy(e, taxonomy.taxonomyId, taxonomy.taxonomyName)}
							data-testid={TAXONOMY_SELECTOR_TESTS_IDS.DELETE_BUTTON}
						>
							<Style.DeleteIcon width="24px" height="24px" fill={Colors.secondary300} fillHover={Colors.white}>
								<Icon.Delete />
							</Style.DeleteIcon>
						</Style.DeleteButton>
					)}
				</Style.Option>
			))}
		</Style.OptionsContainer>
	);
};
