import * as yup from 'yup';

import { NODE_ERRORS } from '../constants/errors.constants';
import { useIsNodeSiblingNameExist } from '../services/validation.services';
import { NodeNameSchema } from './NodeNameSchema';

declare module 'yup' {
	interface StringSchema {
		isSiblingNodeNameTaken(format: string): StringSchema;
	}
}

export const NodeRenameSchema = () => {
	const nodeNameSchema = NodeNameSchema();
	const isNodeSiblingNameExist = useIsNodeSiblingNameExist();

	yup.addMethod(yup.string, 'isSiblingNodeNameTaken', function (message) {
		return this.test(`isSiblingNodeNameTaken`, message, function (value) {
			const { path, createError } = this;
			return (value && !isNodeSiblingNameExist(value)) || createError({ path, message });
		});
	});

	return yup.object().shape({
		name: nodeNameSchema.label('Node name').isSiblingNodeNameTaken(NODE_ERRORS.NODE_EXIST_IN_THE_SAME_LEVEL),
	});
};
