import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { synonymsUrl } from '../../../constants/api.constants';
import { useGetAllPendingChangesRequest, useGetTaxonomiesRequest } from '../../../services/api.services';
import { myTaxonomies } from '../../../services/myTaxonomies.services';
import { taxonomiesSelector, taxonomiesWithChangesState } from '../../../state/global.state';
import { isPendingChangesLoadingState } from '../../../state/history.state';
import { userState } from '../../../state/user.state';
import type { ITaxonomy, ITaxonomyWithChanges } from '../../../types/taxonomy.types';

export const queryConfig = {
	refetchOnWindowFocus: false,
	refetchOnReconnect: false,
	refetchOnMount: false,
	cacheTime: 0,
};

export const useInit = () => {
	const setTaxonomies = useSetRecoilState(taxonomiesSelector);
	const setIsPendingChangesLoading = useSetRecoilState(isPendingChangesLoadingState);
	const setTaxonomiesWithChanges = useSetRecoilState(taxonomiesWithChangesState);
	const user = useRecoilValue(userState);
	const [isReadyToFetchChanges, setIsReadyToFetchChanges] = useState(false);

	const getTaxonomiesRequest = useGetTaxonomiesRequest();
	const getAllPendingChangesRequest = useGetAllPendingChangesRequest();

	const { isLoading, error, data } = useQuery<{ count: number; items: ITaxonomy[] }>({
		onSuccess: data => data.items.length > 0 && setIsReadyToFetchChanges(true),
		queryFn: getTaxonomiesRequest,
		queryKey: ['getTaxonomies'],
	});

	const { isInitialLoading: isPendingLoading, isFetching: isPendingFetching } = useQuery<ITaxonomyWithChanges[]>({
		...queryConfig,
		enabled: !!isReadyToFetchChanges,
		onSuccess: (pendingChanges: ITaxonomyWithChanges[]) => {
			setTaxonomiesWithChanges(
				pendingChanges && [
					...pendingChanges.sort((a, b) => a?.taxonomyName?.toLocaleString().localeCompare(b?.taxonomyName?.toLocaleString())),
				],
			);
			setIsReadyToFetchChanges(false);
		},
		queryFn: getAllPendingChangesRequest,
		queryKey: ['getPendingChanges'],
	});

	useEffect(() => {
		setIsPendingChangesLoading(isPendingLoading || isPendingFetching);
	}, [isPendingLoading, isPendingFetching, setIsPendingChangesLoading]);

	useEffect(() => {
		const init = async () => {
			data && setTaxonomies(await myTaxonomies.init(data.items, user.nickname || ''));
		};
		init();
	}, [data, user.nickname, setTaxonomies]);

	// warming up lambda for synonyms suggestions
	useEffect(() => {
		axios.get(`${synonymsUrl}/sample`);
	}, []);

	return { isLoading, error };
};
