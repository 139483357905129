import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';

export const Descriptions = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	scrollbar-width: thin;
	max-height: 20%;
	border-bottom: 1px solid ${Colors.secondary300};
	padding: 0 8px 8px;
`;

export const InnerTitles = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 12px 0;
`;

export const Title = styled.h6`
	margin: 0;
	font-size: ${FONT_SIZES.H6};
	font-weight: 500;
	line-height: 18px;
	color: ${Colors.primary500};
`;

export const Description = styled.p`
	margin-top: 0;
	margin-bottom: 0;
	font-size: ${FONT_SIZES.BODY};
	line-height: 16px;
	color: ${Colors.secondary500};
`;

export const EmptyDescription = styled(Description)`
	color: ${Colors.secondary300};
`;

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 8px 0;
`;

export const SaveButton = styled.button`
	color: ${Colors.secondary800};
	font-size: ${FONT_SIZES.BODY};
	line-height: 16px;
	text-decoration: underline;
	transition: color 0.3s;

	&:disabled {
		color: ${Colors.secondary300};
	}
`;

export const DiscardButton = styled.button`
	color: ${Colors.secondary800};
	font-size: ${FONT_SIZES.BODY};
	line-height: 16px;
	text-decoration: underline;
`;

export const DescriptionTextArea = styled(TextareaAutosize)`
	font-size: ${FONT_SIZES.BODY};
	color: ${Colors.secondary900};
	line-height: 16px;
	border: 1px solid ${Colors.secondary500};
	border-radius: 4px;
	transition: border-color 0.3s;
	resize: none;

	&:focus {
		border-color: ${Colors.primary300};
	}

	&::placeholder {
		color: ${Colors.secondary300};
	}
`;
