import { useCallback } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { IModalProps, NODES_FUNCTIONALITY, PATH_FUNCTIONALITY, TAXONOMY_MODALS } from '../constants/modals.constants';
import { headerDropdownOpenState } from '../state/header.state';
import { DialogConfigProps, dialogConfigState, isModalOpenState, modalPropsState } from '../state/modal.state';
import { useResetSelections } from './tree.services';
import { useNodeValidationHasNoSiblings, useNodeValidationIsParent } from './validation.services';

export const useSetModalAction = () => {
	const setModalProps = useSetRecoilState(modalPropsState);
	const setModalOpen = useSetRecoilState(isModalOpenState);

	return (type: string, input?: string, id?: string) => {
		setModalProps({ type, input, id } as IModalProps);
		setModalOpen(true);
	};
};

export const useSetDeleteNodeModalAction = () => {
	const setModalAction = useSetModalAction();

	return () => {
		setModalAction(NODES_FUNCTIONALITY.DELETE_NODE);
	};
};

export const useSetRenameNodeModalAction = () => {
	const setModalAction = useSetModalAction();

	return (input: string) => {
		setModalAction(NODES_FUNCTIONALITY.RENAME_NODE, input);
	};
};

export const useSetAddNodeModalAction = () => {
	const setModalAction = useSetModalAction();

	return (input: string) => {
		setModalAction(NODES_FUNCTIONALITY.ADD_NODE, input);
	};
};

export const useSetMoveNodeModalAction = () => {
	const setModalAction = useSetModalAction();

	return (input: string, id: string) => {
		setModalAction(NODES_FUNCTIONALITY.MOVE_NODE, input, id);
	};
};

export const useSetPrimaryModalAction = () => {
	const setModalAction = useSetModalAction();

	return (isPrimary: boolean) => {
		setModalAction(PATH_FUNCTIONALITY.PATH_TOGGLE, isPrimary ? 'primary' : 'secondary');
	};
};

export const useSetDeletePathModalAction = () => {
	const setModalAction = useSetModalAction();

	return () => {
		setModalAction(PATH_FUNCTIONALITY.DELETE_PATH);
	};
};

const useSetModalActionWithMandatoryOptions = () => {
	const resetHeaderDropdownOpen = useResetRecoilState(headerDropdownOpenState);

	const resetSelections = useResetSelections();
	const setModalAction = useSetModalAction();

	return (type: string, input?: string, id?: string) => {
		setModalAction(type, input, id);

		resetHeaderDropdownOpen();
		resetSelections();
	};
};

export const useSetRemoveTaxonomyModalAction = () => {
	const setModalAction = useSetModalActionWithMandatoryOptions();

	return (input: string, id: string) => {
		setModalAction(TAXONOMY_MODALS.REMOVE_TAXONOMY, input, id);
	};
};

export const useSetRenameImportedTaxonomyModalAction = () => {
	const setModalAction = useSetModalAction();

	return (importedTaxonomyName: string) => {
		setModalAction(TAXONOMY_MODALS.RENAME_IMPORTED_TAXONOMY, importedTaxonomyName);
	};
};

export const useSetRenameTaxonomyModalAction = () => {
	const setModalAction = useSetModalActionWithMandatoryOptions();

	return (input: string) => {
		setModalAction(TAXONOMY_MODALS.RENAME_TAXONOMY, input);
	};
};

export const useSetCopyTaxonomyModalAction = () => {
	const setModalAction = useSetModalActionWithMandatoryOptions();

	return (input: string) => {
		setModalAction(TAXONOMY_MODALS.COPY_TAXONOMY, input);
	};
};

export const useSetCreateTaxonomyModalAction = () => {
	const setModalAction = useSetModalActionWithMandatoryOptions();

	return () => {
		setModalAction(TAXONOMY_MODALS.CREATE_TAXONOMY);
	};
};

export const useDeleteModalType = () => {
	const nodeValidationHasNoSiblings = useNodeValidationHasNoSiblings();
	const nodeValidationIsParent = useNodeValidationIsParent();

	return useCallback(() => {
		if (nodeValidationIsParent()) return NODES_FUNCTIONALITY.DELETE_NODE_WITH_CHILDREN;
		if (nodeValidationHasNoSiblings()) return NODES_FUNCTIONALITY.DELETE_LAST_NODE_CHILD;

		return NODES_FUNCTIONALITY.DELETE_NODE;
	}, [nodeValidationHasNoSiblings, nodeValidationIsParent]);
};

export const useResetModalProps = () => {
	const resetModal = useResetRecoilState(modalPropsState);
	return useCallback(() => {
		resetModal();
	}, [resetModal]);
};

export const useConfirmationModal = () => {
	const setDialogConfig = useSetRecoilState(dialogConfigState);

	const openDialog = (options: DialogConfigProps) => {
		setDialogConfig(options);
	};

	return { openDialog };
};

export const useExportCsvErrorModal = () => {
	const setModalAction = useSetModalAction();

	return (input: string) => {
		setModalAction(TAXONOMY_MODALS.EXPORT_CSV_ERROR, input);
	};
};

export const useExportCsvModal = () => {
	const setModalAction = useSetModalAction();

	return () => {
		return new Promise<void>(resolve => {
			setModalAction(TAXONOMY_MODALS.EXPORT_CSV);

			setTimeout(() => {
				resolve();
			}, 0);
		});
	};
};
