import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';

export const SearchResults = styled.div<{ resultsOpened: boolean; width?: string }>`
	display: flex;
	flex-direction: column;
	background-color: ${Colors.secondary800};
	position: absolute;
	top: 0;
	right: ${({ resultsOpened, width }) => (resultsOpened ? `-${width}` || '-354px' : '0')};
	z-index: 20;
	height: 100%;
	width: ${({ resultsOpened, width }) => (resultsOpened ? width || '354px' : '0')};
	border-right: ${({ resultsOpened }) => (resultsOpened ? `1px solid ${Colors.background}` : 'none')};
	border-radius: 0px 8px 8px 0px;
	box-shadow: inset 30px 0px 50px 0px rgb(0 0 0 / 15%);
	padding-right: ${({ resultsOpened }) => (resultsOpened ? '4px' : '0')};
	padding-block: 8px;
`;

export const ResultsList = styled.ul`
	width: 100%;
	flex: 1;
	list-style: none;
	margin-block: 0;
	padding-left: 0;
	overflow-y: auto;
	scrollbar-width: 4px;

	&:nth-of-type(2) {
		border-top: 1px solid ${Colors.secondary300};
	}

	::-webkit-scrollbar {
		background-color: ${Colors.secondary800};
		margin-right: 4px;
	}
`;

export const PinnedTitle = styled.p`
	color: ${Colors.primary500};
	font-weight: 500;
	padding-left: 8px;
	margin-bottom: 14px;
	margin-top: 14px;
`;

export const MoreResults = styled.div`
	color: ${Colors.secondary300};
	font-size: ${FONT_SIZES.XS};
	line-height: 1.2;
	border-top: 1px solid ${Colors.secondary300};
	margin-top: 4px;
	padding: 8px 8px 16px 8px;
`;

export const MoreText = styled.span`
	display: block;
`;
