import { usePapaParse } from 'react-papaparse';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { CSV, TIME } from '../../../../constants/csv.constants';
import { TAXONOMY_ERRORS } from '../../../../constants/errors.constants';
import { usePrepareCsvToExport } from '../../../../services/csv.services';
import { useExportCsvModal } from '../../../../services/modal.services';
import { nodesMapState } from '../../../../state/csv.state';
import { selectedTaxonomyState } from '../../../../state/global.state';
import { isModalOpenState } from '../../../../state/modal.state';
import { ACTION_BAR_TESTS_IDS } from '../../actionBar.constants';

export const ExportCSV = () => {
	const taxonomy = useRecoilValue(selectedTaxonomyState);
	const nodesMap = useRecoilValue(nodesMapState);
	const setModalOpen = useSetRecoilState(isModalOpenState);

	const prepareCsv = usePrepareCsvToExport();
	const { jsonToCSV } = usePapaParse();
	const showExportCsvModal = useExportCsvModal();

	const handleDownload = async () => {
		await showExportCsvModal();
		const csv = nodesMap && prepareCsv({ nodesMap });

		if (!csv) throw new Error(TAXONOMY_ERRORS.EXPORT_CSV);

		const CSVData = jsonToCSV(
			{ fields: CSV.HEADERS, data: csv },
			{
				header: true,
			},
		);
		exportCsv(CSVData, `${taxonomy.taxonomyName}-${TIME.TODAY_US_DATE}.csv`);
	};

	const exportCsv = (data: string, fileName: string) => {
		const url = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName;
		a.click();
		setModalOpen(false);
		window.URL.revokeObjectURL(url);
	};

	return (
		<div onClick={handleDownload} data-testid={ACTION_BAR_TESTS_IDS.EXPORT_TO_CSV}>
			Export as CSV file
		</div>
	);
};
