import { atom, selector } from 'recoil';

import { LOG_STATUS } from '../constants/history.constants';
import type { IPendingChange, ITreeLog } from '../types/taxonomy.types';
import { selectedTaxonomyState } from './global.state';
import { userState } from './user.state';

export const historyMenuState = atom<{ type: 'history' | 'suggested' | 'all' | null; open: boolean }>({
	key: 'historyMenu',
	default: { type: null, open: false },
});

export const tempLogsState = atom<ITreeLog[]>({
	key: 'tempLogs',
	default: [],
});

export const pendingChangesState = atom<IPendingChange[]>({
	key: 'pendingChanges',
	default: [],
});

export const pendingChangesCopyState = atom<IPendingChange[]>({
	key: 'pendingChangesCopy',
	default: [],
});

export const currentPendingChangesSetState = atom<IPendingChange>({
	key: 'CurrentPendingChangesSet',
	default: undefined,
});

export const unHandledPendingChangesState = selector<number>({
	key: 'unhandledPendingChanges',
	get: ({ get }) => {
		const pendingChanges = get(pendingChangesState);
		const unhandledPendingChanges = pendingChanges?.reduce(
			(acc, set) =>
				acc + set.data.filter(change => change.status !== LOG_STATUS.APPROVED && change.status !== LOG_STATUS.REJECTED).length,
			0,
		);

		return unhandledPendingChanges;
	},
});

export const isPendingChangesLoadingState = atom<boolean>({
	key: 'isPendingChangesLoading',
	default: false,
});

export const isReadyToFetchChangesState = atom<boolean>({
	key: 'isReadyToFetchChanges',
	default: false,
});

export const tempPendingChangesState = atom<ITreeLog[]>({
	key: 'tempPendingChanges',
	default: [],
});

export const fetchPendingChangesState = atom<{ taxonomyId: string; versionId: string }>({
	key: 'fetchPendingChanges',
	default: { taxonomyId: '', versionId: '' },
});

export const logsToSendState = selector<ITreeLog[]>({
	key: 'logsToSend',
	get: ({ get }) => {
		const { logs } = get(selectedTaxonomyState).tree;
		const user = get(userState);

		return logs.filter(({ editor, status }) => editor === user.nickname && status === LOG_STATUS.NEW);
	},
});
