import { useResetRecoilState } from 'recoil';

import { useOutsideClick } from '../../../../hooks';
import { headerDropdownOpenState } from '../../../../state/header.state';
import { TAXONOMY_SELECTOR_TESTS_IDS } from '../../taxonomySelector.constants';
import { MandatoryOptions, TreesOptions } from '../';
import * as Style from './dropDown.styles';

export const DropDown = () => {
	const resetHeaderDropdownOpen = useResetRecoilState(headerDropdownOpenState);

	const dropDownRef = useOutsideClick(() => resetHeaderDropdownOpen());

	return (
		<Style.DropDownContainer data-testid={TAXONOMY_SELECTOR_TESTS_IDS.DROP_DOWN} ref={dropDownRef}>
			<TreesOptions />
			<MandatoryOptions />
		</Style.DropDownContainer>
	);
};
