import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';

export const Button = styled.button`
	color: ${Colors.secondary50};
	font-size: ${FONT_SIZES.XS};
	text-align: left;
`;
