import { useRecoilValue } from 'recoil';

import { MODAL_TESTS_IDS } from '../../../../constants/modals.constants';
import { useToggleEdgeType } from '../../../../services/edge.services';
import { modalPropsState } from '../../../../state/modal.state';
import { selectedEdgeState } from '../../../../state/tree.state';
import { BaseModalContent } from '../../atomsComponents/BaseForm/BaseModalContent';
import { FormDescription } from '../../atomsComponents/modalAtoms';
import { BaseModal, BaseModalProps } from '../../BaseModal';

type SetPrimaryModalProps = BaseModalProps & { onClose: () => void };

export const SetPrimaryModal = ({ onClose, showModal }: SetPrimaryModalProps) => {
	const { input } = useRecoilValue(modalPropsState);
	const selectedEdge = useRecoilValue(selectedEdgeState);

	const targetPathType = input === 'primary' ? 'Secondary' : 'Primary';

	const setPrimaryEdge = useToggleEdgeType();

	const onSubmit = () => {
		selectedEdge && input && setPrimaryEdge(selectedEdge, input);
		onClose();
	};

	return (
		<BaseModal title={`Set as ${targetPathType}`} showModal={showModal} testid={MODAL_TESTS_IDS.PATH_TOGGLE_MODAL}>
			<BaseModalContent discardBtnText="CANCEL" submitBtnText="CONTINUE" onSubmit={onSubmit} onDiscard={onClose} isValid>
				<FormDescription>This will change the current connection to {targetPathType}.</FormDescription>
			</BaseModalContent>
		</BaseModal>
	);
};
