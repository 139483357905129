import { TREE_SETTINGS } from '../components/tree/tree.constants';
import { TAXONOMY_ERRORS } from '../constants/errors.constants';
import { db } from '../db';
import sampleTaxonomy from '../taxonomies/Sample taxonomy.json';
import type { ITaxonomy } from '../types/taxonomy.types';

class Taxonomies {
	private taxonomiesList: ITaxonomy[];
	private savedTaxonomies?: ITaxonomy[];

	constructor() {
		this.taxonomiesList = [];
		this.savedTaxonomies = undefined;
	}

	get list() {
		return this.onConnect(() => this.taxonomiesList);
	}

	async init(data: ITaxonomy[], nickname: string) {
		return this.onConnect(async savedTaxonomies => {
			const mappedTaxonomies = await this.map(data, savedTaxonomies, nickname);
			this.taxonomiesList = mappedTaxonomies;

			return mappedTaxonomies;
		});
	}

	async getSavedTaxonomy(id: string) {
		return await db.getOne(id);
	}

	async save(newList: ITaxonomy[]) {
		return this.onConnect(() => (this.taxonomiesList = newList));
	}

	create(orgId = '', taxonomyId: string, taxonomyName: string) {
		return {
			orgId,
			taxonomyName,
			taxonomyId,
			tree: {
				nodes: [{ text: taxonomyName, id: TREE_SETTINGS.ROOT_NODE }],
				edges: [],
				logs: [],
			},
		};
	}

	add(orgId = '', taxonomy: ITaxonomy, taxonomyId: string) {
		return {
			...taxonomy,
			orgId,
			taxonomyId,
		};
	}

	async rename(id: string, newName: string) {
		return this.onConnect<Promise<ITaxonomy[]>>(async savedTaxonomies => {
			let newTaxonomy;
			let isDraft;

			const updatedList = this.taxonomiesList.map(taxonomy => {
				if (taxonomy.taxonomyId === id) {
					newTaxonomy = {
						...taxonomy,
						taxonomyName: newName,
					};
					isDraft = taxonomy.isDraft;

					return newTaxonomy;
				}
				return taxonomy;
			});

			isDraft && (await this.saveLocally({ id, savedTaxonomies, taxonomy: newTaxonomy }));

			return updatedList;
		});
	}

	async update(taxonomy: ITaxonomy, id: string) {
		return this.onConnect<Promise<ITaxonomy[]>>(async savedTaxonomies => {
			const taxonomyToUpdate = this.find(this.taxonomiesList, id);
			const newTaxonomy = {
				...taxonomyToUpdate,
				tree: taxonomy.tree,
				originId: taxonomy.originId,
				taxonomyName: taxonomy.taxonomyName,
				taxonomyId: taxonomy.taxonomyId,
				isDraft: true,
				owner: taxonomy.owner,
			};

			await this.saveLocally({ id, savedTaxonomies, taxonomy: newTaxonomy });

			if (taxonomyToUpdate && taxonomyToUpdate.isDraft) {
				return this.taxonomiesList.map(taxonomy => {
					if (taxonomy.taxonomyId === id) {
						return newTaxonomy;
					}
					return taxonomy;
				});
			} else {
				return [...this.taxonomiesList, newTaxonomy];
			}
		});
	}

	async delete(id: string) {
		return this.onConnect<Promise<ITaxonomy[]>>(async savedTaxonomies => {
			await db.deleteOne(id);
			await this.saveLocally({ id, savedTaxonomies });

			return this.filter(this.taxonomiesList, id);
		});
	}

	private async connect() {
		if (!this.savedTaxonomies) {
			this.savedTaxonomies = (await db.get()) as ITaxonomy[];
		}

		return this.savedTaxonomies;
	}

	private async onConnect<T>(callback: (savedTaxonomies: ITaxonomy[]) => T): Promise<T> {
		try {
			const savedTaxonomies = await this.connect();
			return callback(savedTaxonomies);
		} catch {
			throw new Error(TAXONOMY_ERRORS.TAXONOMY);
		}
	}

	private async map(all: ITaxonomy[], savedTaxonomies: ITaxonomy[], nickname: string) {
		const originIdsSet = new Set(all.map(({ taxonomyId }) => taxonomyId));
		const filteredSavedTaxonomies = savedTaxonomies.filter(
			({ originId, owner }) => originId && originIdsSet.has(originId) && owner === nickname,
		);

		const mappedTaxonomies = [...all, ...filteredSavedTaxonomies];

		if (mappedTaxonomies.length === 0) {
			await db.put(sampleTaxonomy);
			return [sampleTaxonomy];
		}
		return mappedTaxonomies;
	}

	private filter(list: ITaxonomy[], id: string) {
		return list.filter(item => item.taxonomyId !== id);
	}

	private find(list: ITaxonomy[], id: string) {
		return list.find(item => item.taxonomyId === id);
	}

	private async saveLocally({
		id,
		savedTaxonomies,
		taxonomy,
	}: {
		id?: string;
		savedTaxonomies: ITaxonomy[];
		taxonomy?: ITaxonomy;
	}) {
		const filteredTaxonomies = id ? this.filter(savedTaxonomies, id) : savedTaxonomies;
		let taxonomiesToSave = filteredTaxonomies;

		if (taxonomy) {
			await db.put(taxonomy);
			taxonomiesToSave = [...filteredTaxonomies, taxonomy];
		}

		this.savedTaxonomies = taxonomiesToSave;
	}
}

export const myTaxonomies = new Taxonomies();
