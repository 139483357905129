import { useRecoilState, useRecoilValue } from 'recoil';

import { selectedTaxonomyState } from '../state/global.state';
import { rootNodeState, selectedNodeState, treeNodesState } from '../state/tree.state';
import type { ITaxonomyEdge } from '../types/taxonomy.types';
import { useDependantsOf, useDirectDependantsOf } from './graph.services';
import { useLevelsForLogs } from './history.services';
import { useCreateLog, useSaveTaxonomy } from './tree.services';

export const useToggleEdgeType = () => {
	const rootNode = useRecoilValue(rootNodeState);
	const [selectedNode, setSelectedNode] = useRecoilState(selectedNodeState);
	const { tree } = useRecoilValue(selectedTaxonomyState);
	const treeNodes = useRecoilValue(treeNodesState);

	const createLog = useCreateLog();
	const levelsForLogs = useLevelsForLogs();
	const saveTaxonomy = useSaveTaxonomy();
	const dependantsOf = useDependantsOf();
	const directDependantsOf = useDirectDependantsOf();

	return async (edge: ITaxonomyEdge, edgeType: string) => {
		const from = tree.nodes.find(node => node.id === edge.from);
		const to = tree.nodes.find(node => node.id === edge.to) || rootNode;
		const { fromLevels, toLevels } = levelsForLogs(edge.from, edge.to);

		const updatedEdges = tree.edges.map(e => {
			if (e.id === edge.id) return { ...e, primary: edgeType === 'primary' ? false : true };
			return e;
		});

		const updatedTree = { ...tree, edges: updatedEdges };
		const log = createLog('setAsPrimary', { from, to, fromLevels, toLevels, edge });

		const nodesToExpand = new Set([edge.from, edge.to, edge.from && dependantsOf(edge.from)]);
		const nodesToShow = new Set([...nodesToExpand, edge.from && directDependantsOf(edge.from)]);

		const updatedTreeNodesWithShow = treeNodes.map(node => ({
			...node,
			expanded: nodesToExpand.has(node.id) || node.expanded,
			show: nodesToShow.has(node.id) || node.show,
		}));

		await saveTaxonomy(updatedTree, log, updatedTreeNodesWithShow);

		setSelectedNode(selectedNode || treeNodes.find(node => node.id === edge.to));
	};
};

export const useDeleteEdge = () => {
	const rootNode = useRecoilValue(rootNodeState);
	const { tree } = useRecoilValue(selectedTaxonomyState);
	const treeNodes = useRecoilValue(treeNodesState);
	const [selectedNode, setSelectedNode] = useRecoilState(selectedNodeState);

	const createLog = useCreateLog();
	const levelsForLogs = useLevelsForLogs();
	const saveTaxonomy = useSaveTaxonomy();
	const dependantsOf = useDependantsOf();
	const directDependantsOf = useDirectDependantsOf();

	return async (edge: ITaxonomyEdge) => {
		const from = tree.nodes.filter(node => node.id === edge.from)[0];
		const to = tree.nodes.filter(node => node.id === edge.to)[0] || rootNode;
		const { fromLevels, toLevels } = levelsForLogs(edge.from, edge.to);

		const updatedTree = {
			...tree,
			edges: tree.edges.filter(e => e.id !== edge.id),
		};

		const log = createLog('edgeDelete', { edge, from, to, fromLevels, toLevels });

		const nodesToExpand = new Set([...directDependantsOf(to.id), ...directDependantsOf(from.id), to.id, from.id]);
		const nodesToShow = new Set([...dependantsOf(to.id), ...dependantsOf(from.id), ...nodesToExpand, rootNode]);

		const updatedTreeNodesWithShow = treeNodes.map(node => ({
			...node,
			expanded: nodesToExpand.has(node.id) || node.expanded,
			show: nodesToShow.has(node.id) || node.show,
		}));

		await saveTaxonomy(updatedTree, log, updatedTreeNodesWithShow);

		setSelectedNode(selectedNode || treeNodes.find(node => node.id === to.id));
	};
};
