import { NodeModel, useDragOver } from '@minoru/react-dnd-treeview';
import { memo, MouseEvent, useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import * as Icon from '../../../../assets/icons/treeMenuBar';
import { useDependantsOf, useDirectDependenciesOf } from '../../../../services/graph.services';
import { useResetActionsOpen } from '../../../../services/tree.services';
import { useExpandSelectedTreeNode } from '../../../../services/treeMenuBar.services';
import { selectedTaxonomyState } from '../../../../state/global.state';
import { connectionModeState, granularTopicsState, selectedNodeState } from '../../../../state/tree.state';
import { kebabOpenedState, kebabPositionState, selectedTreeMenuNodeState } from '../../../../state/treeMenuBar.state';
import { Colors } from '../../../../styles/colors.styles';
import { TREE_MENU_TESTS_IDS } from '../../treeMenuBar.constants';
import * as Style from './treeMenuNode.styles';

interface ITreeMenuNodeProps {
	node: NodeModel;
	depth: number;
	isOpen: boolean;
	onToggle: () => void;
	isDragging: boolean;
	isDropTarget: boolean;
}
export const TreeMenuNodeBody = ({ node, depth, isOpen, onToggle, isDragging, isDropTarget }: ITreeMenuNodeProps) => {
	const connectionMode = useRecoilValue(connectionModeState);
	const granularTopics = useRecoilValue(granularTopicsState);
	const setKebabOpened = useSetRecoilState(kebabOpenedState);
	const resetKebabOpened = useResetRecoilState(kebabOpenedState);
	const setKebabPosition = useSetRecoilState(kebabPositionState);
	const setSelectedNode = useSetRecoilState(selectedNodeState);
	const resetSelectedNode = useResetRecoilState(selectedNodeState);
	const { nodes } = useRecoilValue(selectedTaxonomyState).tree;
	const selectedTreeMenuNode = useRecoilValue(selectedTreeMenuNodeState);
	const [expandable, setExpandable] = useState(false);
	const [selectedStyle, setSelectedStyle] = useState(false);

	const directDependenciesOf = useDirectDependenciesOf();
	const expandSelectedTreeNode = useExpandSelectedTreeNode();
	const resetActionsOpen = useResetActionsOpen();

	const dragOverProps = useDragOver(node.id, isOpen, onToggle);

	const dependantsOf = useDependantsOf();

	useEffect(() => setExpandable(!granularTopics?.some(({ id }) => id === String(node.id))), [granularTopics, node.id]);

	useEffect(() => {
		const parentsOfSelectedNode = dependantsOf(selectedTreeMenuNode?.id as string);
		const handleNodeSelection = () => {
			setSelectedStyle(parentsOfSelectedNode.includes(node.id as string) || node.id === selectedTreeMenuNode?.id);
		};
		selectedTreeMenuNode ? handleNodeSelection() : setSelectedStyle(false);
	}, [node.id, selectedTreeMenuNode, dependantsOf]);

	const handleNodeClick = () => {
		if (connectionMode.active) return;

		if (selectedTreeMenuNode?.id === node.id) {
			resetSelectedNode();
		} else {
			const nodeToSelect = nodes.find(({ id }) => id === node.id);
			if (nodeToSelect) {
				setSelectedNode(nodeToSelect);
				expandSelectedTreeNode(nodeToSelect.id);
			}
		}
	};

	const handleKebabClick = (e: MouseEvent) => {
		e.stopPropagation();

		if (connectionMode.active) return;

		resetActionsOpen();

		setKebabPosition(e.clientY);

		if (selectedTreeMenuNode?.id === node.id) {
			setKebabOpened(currentState => !currentState);
		} else {
			const nodeToSelect = nodes.find(({ id }) => id === node.id);
			if (nodeToSelect) {
				setSelectedNode(nodeToSelect);
				setKebabOpened(true);
				expandSelectedTreeNode(nodeToSelect.id);
			}
		}
	};

	const handleToggle = (e: MouseEvent) => {
		e.stopPropagation();

		onToggle();
		resetKebabOpened();
	};

	const countOfChildren = directDependenciesOf(String(node.id)).length;

	return (
		<Style.ListItem
			data-testid={TREE_MENU_TESTS_IDS.LIST_ITEM}
			depth={depth}
			droppable={node.droppable as boolean}
			selected={selectedStyle}
			isDragging={isDragging}
			isDropTarget={isDropTarget}
			{...dragOverProps}
			onClick={handleNodeClick}
		>
			<Style.IconContainer onClick={e => handleToggle(e)} data-testid={TREE_MENU_TESTS_IDS.LIST_ITEM_ARROW}>
				<Style.DragIcon width="16px" height="16px" fill={Colors.secondary100} fillHover={Colors.secondary100} depth={depth}>
					<Icon.Drag />
				</Style.DragIcon>
				{expandable && (
					<Style.ArrowIcon
						width="16px"
						height="16px"
						fill={Colors.white}
						fillHover={Colors.secondary300}
						selected={selectedStyle}
						isDragging={isDragging}
						isDropTarget={isDropTarget}
						depth={depth}
					>
						{isOpen ? <Icon.ArrowDown /> : <Icon.ArrowRight />}
					</Style.ArrowIcon>
				)}
			</Style.IconContainer>
			<Style.NodeText depth={depth}>
				<Style.NodeName>{node.text}</Style.NodeName>
				<Style.Count>{countOfChildren !== 0 && `(${countOfChildren})`}</Style.Count>
			</Style.NodeText>
			<Style.KebabButton onClick={e => handleKebabClick(e)} data-testid={TREE_MENU_TESTS_IDS.KEBAB_BUTTON}>
				<Style.KebabIcon width="24px" height="24px" fill={Colors.white} fillHover={Colors.secondary300}>
					<Icon.Kebab />
				</Style.KebabIcon>
			</Style.KebabButton>
			<Style.HoverInfo data-testid={TREE_MENU_TESTS_IDS.HOVER_INFO}>
				<Style.Square />
				<Style.HoverText>Level {depth + 1}</Style.HoverText>
			</Style.HoverInfo>
		</Style.ListItem>
	);
};

export const TreeMenuNode = memo(TreeMenuNodeBody);
