import { MouseEvent, useCallback, useRef, useState } from 'react';

const preventDefault = (event: Event) => {
	if (event.preventDefault) {
		event.preventDefault();
	}
};

export const useLongPress = (
	onLongPress?: (event: MouseEvent) => void,
	onClick?: (event: MouseEvent) => void,
	{ shouldPreventDefault = true, delay = 150 } = {},
) => {
	const [longPressTriggered, setLongPressTriggered] = useState(false);

	const timeout = useRef<NodeJS.Timeout>();
	const target = useRef<EventTarget>();

	const start = useCallback(
		(event: MouseEvent) => {
			if (shouldPreventDefault && event.target) {
				event.target.addEventListener('touchend', preventDefault, {
					passive: false,
				});
				target.current = event.target;
			}

			timeout.current = setTimeout(() => {
				onLongPress?.(event);
				setLongPressTriggered(true);
			}, delay);
		},
		[onLongPress, delay, shouldPreventDefault],
	);

	const clear = useCallback(
		(event: MouseEvent, shouldTriggerClick = true) => {
			timeout.current && clearTimeout(timeout.current);
			shouldTriggerClick && !longPressTriggered && onClick?.(event);
			setLongPressTriggered(false);

			if (shouldPreventDefault && target.current) {
				target.current.removeEventListener('touchend', preventDefault);
			}
		},
		[shouldPreventDefault, onClick, longPressTriggered],
	);

	return {
		onMouseDown: (e: MouseEvent) => start(e),
		onMouseUp: (e: MouseEvent) => clear(e),
		onMouseLeave: (e: MouseEvent) => clear(e, false),
	};
};
