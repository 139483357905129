import { ColDef } from 'ag-grid-community';

const getStatusColor = (value: string) => {
	let color = '#536273';
	if (value === 'Approved') color = '#00CA62';
	if (value === 'Rejected') color = '#F34A41';

	return color;
};

export const suggestedChangesMock = [
	{
		date: '03-20-2022',
		type: 'Add',
		description: 'Node “MS Products/Publications”[3] was added to “Business Activity/Management”[2]',
		status: 'Pending',
	},
	{
		date: '03-20-2022',
		type: 'Rename',
		description: 'Node “MS Products/Publications”[3] renamed to “MS Products/Publications and more”',
		status: 'Approved',
	},
	{
		date: '03-15-2022',
		type: 'Connection',
		description: 'Node “MS Products/Publications”[3] has been deleted',
		status: 'Approved',
	},
	{
		date: '02-11-2022',
		type: 'Disconnection',
		description: 'Node “MS Products/Publications”[3]  has been connected to “Business Activity/Management”[2]',
		status: 'Pending',
	},
	{
		date: '03-25-2022',
		type: 'Drag & Drop',
		description: 'Node “MS Products/Publications”[3] was disconnected from “Business Activity/Management”[2]',
		status: 'Rejected',
	},
	{
		date: '03-22-2022',
		type: 'Drag & Drop',
		description: 'Node “MS Products/Publications”[3] was added to “Business Activity/Management”[2]',
		status: 'Pending',
	},
	{
		date: '03-21-2022',
		type: 'Drag & Drop',
		description: 'Node “MS Products/Publications”[3] was added to “Business Activity/Management”[2]',
		status: 'Approved',
	},
	{
		date: '03-21-2022',
		type: 'Drag & Drop',
		description: 'Node “MS Products/Publications”[3] was added to “Business Activity/Management”[2]',
		status: 'Rejected',
	},
	{
		date: '03-21-2022',
		type: 'Drag & Drop',
		description: 'Node “MS Products/Publications”[3] was added to “Business Activity/Management”[2]',
		status: 'Approved',
	},
];

export const suggestedChangesColumns: ColDef[] = [
	{
		checkboxSelection: true,
		headerCheckboxSelection: true,
		width: 40,
	},
	{ headerName: 'Date', field: 'date', sort: 'desc', unSortIcon: true, width: 110 },
	{ headerName: 'Type', field: 'type', unSortIcon: true, width: 110 },
	{ headerName: 'Description', field: 'description', filter: true, flex: 1 },
	{
		headerName: '',
		field: 'status',
		width: 110,
		cellStyle: params => ({ textAlign: 'right', paddingRight: '16px', color: getStatusColor(params.value) }),
	},
];
