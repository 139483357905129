import styled from 'styled-components';

import { Colors } from '../../styles/colors.styles';
import { FONT_SIZES } from '../../styles/fonts.styles';

export const InfoBar = styled.aside<{ open: boolean }>`
	display: flex;
	flex-direction: column;
	background: ${Colors.white};
	font-size: ${FONT_SIZES.BODY};
	grid-area: info;
	max-height: calc(100vh - 48px);
	overflow-y: hidden;
	transition: 0.3s;
	z-index: 10;
	margin-left: ${({ open }) => (open ? '0' : '100%')};
	width: ${({ open }) => (open ? '100%' : '0')};
	border-left: ${({ open }) => (open ? `1px solid ${Colors.secondary300}` : 'none')};
`;

export const DataContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-right: 4px;
	height: 100%;
`;
