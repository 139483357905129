import { memo, MouseEvent, useCallback, useEffect, useState } from 'react';
import { Edge, EdgeProps, Remove } from 'reaflow';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { useMultipleParents } from '../../../../services/graph.services';
import {
	connectionModeState,
	granularTopicsState,
	selectedEdgeState,
	selectedNodeState,
	visibleTreeState,
} from '../../../../state/tree.state';
import { Colors } from '../../../../styles/colors.styles';
import { ITaxonomyEdge } from '../../../../types/taxonomy.types';
import style from './taxonomyEdge.module.css';

export const Root = styled(Edge)<{ isSelected: boolean; isPrimary?: boolean }>`
	stroke: ${({ isSelected, isPrimary }) =>
		isSelected ? Colors.primary500 : isPrimary ? Colors.secondary500 : Colors.secondary300};
	stroke-width: ${({ isPrimary }) => isPrimary && '2pt'};
	transition: stroke 0.3s;
`;

export const RemoveButton = styled(Remove)`
	rect {
		fill: ${Colors.alert500};
	}

	line {
		stroke: ${Colors.white};
	}
`;

export const TaxonomyEdgeBody = ({ target, ...edgeProps }: EdgeProps) => {
	const connectionMode = useRecoilValue(connectionModeState);
	const [selectedEdge, setSelectedEdge] = useRecoilState(selectedEdgeState);
	const [selectedNode, setSelectedNode] = useRecoilState(selectedNodeState);
	const granularTopics = useRecoilValue(granularTopicsState);
	const { edges, nodes } = useRecoilValue(visibleTreeState);
	const [multiple, setMultiple] = useState(false);
	const [primary, setPrimary] = useState(false);

	const multipleParents = useMultipleParents();

	const isSelected = selectedEdge?.id === edgeProps.properties?.id;

	const isGranularTopic = useCallback(
		(nodeId: string) => nodeId && granularTopics?.find(node => node.id === nodeId),
		[granularTopics],
	);

	useEffect(() => {
		if (edges.filter(edge => edge.to === target).length > 1) {
			setMultiple(multipleParents(target as string));
		}
	}, [multipleParents, target, edges]);

	useEffect(() => {
		setPrimary(edges.find(edge => edge.to && isGranularTopic(edge.to) && edge.id === edgeProps.properties?.id)?.primary || false);
	}, [edgeProps.properties?.id, edges, selectedNode?.id, target, isGranularTopic]);

	const handleClick = (event: MouseEvent, edge: ITaxonomyEdge) => {
		setSelectedEdge(edge);

		const targetNode = nodes.find(node => node.id === target);
		setSelectedNode(targetNode);
	};

	return (
		<Root
			{...edgeProps}
			containerClassName={
				!connectionMode.active && multiple && !isSelected && !primary
					? style.hover
					: isSelected
					? style.selectedHover
					: primary
					? style.primaryHover
					: ''
			}
			isPrimary={primary}
			isSelected={isSelected}
			onClick={handleClick}
			selectable={!connectionMode.active}
		/>
	);
};

export const TaxonomyEdge = memo(TaxonomyEdgeBody);
