import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { ComponentProps, ReactNode, useEffect } from 'react';
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import { AnyObject } from 'yup/lib/object';

import { isError } from '../../../../services/validation.services';
import { FormField } from '../modalAtoms';
import { BaseModalContent } from './BaseModalContent';

type TFormField = ComponentProps<typeof FormField>;
interface Field {
	fieldName: string;
	fieldType: TFormField['type'];
	defaultValue?: TFormField['defaultValue'];
	placeholder?: TFormField['placeholder'];
}

interface BaseFormProps {
	description?: ReactNode;
	disableEnter?: boolean;
	isLoading?: boolean;
	fields: Field[];
	//eslint-disable-next-line  @typescript-eslint/no-explicit-any
	onSubmit: (data: Record<string, any>) => void;
	onDiscard: () => void;
	scheme: AnyObjectSchema;
	submitBtnText: string;
	validateOnLoad?: boolean;
}
export const BaseForm = ({
	description,
	fields,
	isLoading,
	onSubmit,
	onDiscard,
	scheme,
	submitBtnText,
	validateOnLoad,
	disableEnter = false,
}: BaseFormProps) => {
	const formMethods = useForm({
		mode: 'onChange',
		resolver: yupResolver(scheme),
	});

	const {
		control,
		getValues,
		formState: { errors },
		trigger,
	} = formMethods;

	useEffect(() => {
		validateOnLoad && trigger();
	}, [trigger, validateOnLoad]);

	const isValid =
		isEmpty(errors) ||
		Object.values(errors).some(error => {
			if (error.type && isError(error.type)) return false;

			try {
				const deepErrors = Object.values(error) as FieldError[];
				return !deepErrors.some(({ type }) => type && isError(type));
				// eslint-disable-next-line no-empty
			} catch {}

			return true;
		});

	return (
		<FormProvider {...formMethods}>
			<form
				onSubmit={event => {
					if (isValid) {
						event.preventDefault();
						onSubmit(getValues());
					}
				}}
				onKeyPress={event => {
					disableEnter && event.key === 'Enter' && event.preventDefault();
				}}
			>
				<BaseModalContent
					description={description}
					submitBtnText={submitBtnText}
					onDiscard={onDiscard}
					isValid={isValid}
					isLoading={isLoading}
				>
					{fields.map(({ fieldName, fieldType, defaultValue, placeholder }, idx) => {
						const schemeField = scheme.fields[fieldName] as AnyObject;

						if (!schemeField) {
							return null;
						}

						return (
							<Controller
								key={fieldName}
								control={control}
								name={fieldName}
								defaultValue={defaultValue}
								render={({ field: { name, onBlur, onChange }, fieldState: { invalid } }) => (
									<FormField
										key={idx}
										autoFocus={idx === 0}
										defaultValue={defaultValue}
										invalid={invalid}
										name={name}
										onBlur={onBlur}
										onChange={onChange}
										placeholder={placeholder}
										label={schemeField.spec?.label}
										type={fieldType}
										isLoading={isLoading}
									/>
								)}
							/>
						);
					})}
				</BaseModalContent>
			</form>
		</FormProvider>
	);
};
