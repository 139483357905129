import { useUndo } from 'reaflow';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { LOG_STATUS } from '../constants/history.constants';
import { selectedTaxonomyState } from '../state/global.state';
import { currentPendingChangesSetState, pendingChangesState, tempPendingChangesState } from '../state/history.state';
import type { ITaxonomyNode, ITreeLog } from '../types/taxonomy.types';
import { useDeleteEdge, useToggleEdgeType } from './edge.services';
import { useUpdateNode } from './infoBar.services';
import { useCreateNode, useDeleteNode, useMoveNode, useNodeLevels, useRenameNode } from './node.services';
import { useConnectNodes, useCreateLog, useOnUndoRedo, useResetTaxonomyChanges, useSaveTaxonomy } from './tree.services';

const generateChangeDescription = (log: ITreeLog) => {
	switch (log.type) {
		case 'nodeAdd':
			return `Node ~“${log.data.children.text}”[${log.data.childLevels}]~ was added to ~“${log.data.parent.text}”[${log.data.parentLevels}]~`;
		case 'nodeMove':
			return `Node “~${log.data.from.text}”[${log.data.fromLevels}]~ moved from ~"${log.data.oldParents[0].text}" (and ${
				log.data.oldParents.length - 1
			} more parent${log.data.oldParents.length > 2 ? 's' : ''}) [${log.data.fromLevels}]~ to ~“${log.data.to.text}”[${
				log.data.toLevels
			}]~`;
		case 'nodeRename':
			return `Node ~“${log.data.oldName}”[${log.data.levels}]~ renamed to ~“${log.data.name}”~`;
		case 'nodeDelete': {
			const parentsCount: number = log.data.parents.length;
			return `Node ~“${log.data.node.text}”[${log.data.levels}]~ has been deleted from ~“${log.data.parents[0].text}” ${
				parentsCount > 1 ? `(and ${parentsCount - 1} more parent${parentsCount > 2 ? 's' : ''})` : ''
			} [${log.data.levels}]~`;
		}
		case 'nodeDataEdit':
			return `Metadata of node ~“${log.data.node.text}”[${log.data.levels}]~ has been changed`;
		case 'edgeDelete':
			return `Node “~${log.data.to.text}”[${log.data.toLevels}]~ was disconnected from ~“${log.data.from.text}”[${log.data.fromLevels}]~`;
		case 'nodesConnect':
			return `Node/s ~“${log.data.children.map((child: ITaxonomyNode) => child.text).join(', ')}”~ has been connected to ~“${
				log.data.parent.text
			}”[${log.data.levels}]~`;
		case 'setAsPrimary':
			return `Path “~${log.data.to.text}”[${log.data.toLevels}]~ → ~“${log.data.from.text}”[${log.data.fromLevels}]~ was set as Primary`;
		default:
			return '';
	}
};

export const useLevelsForLogs = () => {
	const nodeLevels = useNodeLevels();

	return (from?: string, to?: string) => {
		const fromLevels = nodeLevels(from) || '0';
		const toLevels = nodeLevels(to)?.map(level => level + 1) || '0';

		return { fromLevels, toLevels };
	};
};
export const convertLogs = (logs: ITreeLog[]) => {
	return logs?.map(log => {
		const logData = {
			editedBy: log.editor,
			data: log.data,
			date: log.date,
			id: log.id,
			status: log.status,
		};
		switch (log.type) {
			case 'nodeAdd':
				return {
					...logData,
					type: 'Add',
					description: generateChangeDescription(log),
				};
			case 'nodeMove':
				return {
					...logData,
					type: 'Move',
					description: generateChangeDescription(log),
				};
			case 'nodeRename':
				return {
					...logData,
					type: 'Rename',
					description: generateChangeDescription(log),
				};
			case 'nodeDelete':
				return {
					...logData,
					type: 'Delete',
					description: generateChangeDescription(log),
				};
			case 'nodeDataEdit':
				return {
					...logData,
					type: 'Edit',
					description: generateChangeDescription(log),
				};
			case 'edgeDelete':
				return {
					...logData,
					type: 'Disconnection',
					description: generateChangeDescription(log),
				};
			case 'nodesConnect':
				return {
					...logData,
					type: 'Connection',
					description: generateChangeDescription(log),
				};
			case 'setAsPrimary':
				return {
					...logData,
					type: 'Primary path',
					description: generateChangeDescription(log),
				};
		}
	});
};

export const useApplyChange = () => {
	const setPendingChanges = useSetRecoilState(pendingChangesState);
	const pendingChangesSet = useRecoilValue(currentPendingChangesSetState);
	const [tempPendingChanges, setTempPendingChanges] = useRecoilState(tempPendingChangesState);
	const { tree } = useRecoilValue(selectedTaxonomyState);
	const { nodes, edges } = tree;

	const onUndoRedo = useOnUndoRedo();
	const { undo } = useUndo({
		nodes,
		edges,
		onUndoRedo,
	});

	const connectNodes = useConnectNodes();
	const createNode = useCreateNode();
	const deleteEdge = useDeleteEdge();
	const deleteNode = useDeleteNode();
	const moveNode = useMoveNode();
	const renameNode = useRenameNode();
	const toggleEdgeType = useToggleEdgeType();
	const updateNode = useUpdateNode();
	const saveTaxonomy = useSaveTaxonomy();
	const createLog = useCreateLog();

	return async (log: ITreeLog, changeStatus: LOG_STATUS) => {
		if (!tempPendingChanges.some(tempLog => tempLog.id === log.id)) {
			if (changeStatus && changeStatus !== LOG_STATUS.REJECTED) {
				switch (log.type) {
					case 'Add': {
						await createNode(log.data.parent, undefined, log.data.children);
						break;
					}
					case 'Move': {
						await moveNode(log.data.from.id, log.data.to.id);
						break;
					}
					case 'Rename': {
						await renameNode(log.data.name, log.data.id);
						break;
					}
					case 'Delete': {
						await deleteNode(log.data.node);
						break;
					}
					case 'Edit': {
						await updateNode(log.data.content, log.data.dataType, log.data.node);
						break;
					}
					case 'Disconnection': {
						await deleteEdge(log.data.edge);
						break;
					}
					case 'Connection': {
						await connectNodes(log.data.connectionMode, log.data.newEdges);
						break;
					}
					case 'Primary path': {
						await toggleEdgeType(log.data.edge, 'primary');
						break;
					}
				}
			}
		}

		const currentChange = pendingChangesSet && pendingChangesSet.data?.find(change => change.id === log.id);

		const modifiedChange =
			currentChange &&
			({
				...currentChange,
				status: changeStatus,
			} as ITreeLog);

		setTempPendingChanges(prevState => {
			if (modifiedChange && !tempPendingChanges?.includes(modifiedChange)) {
				return [modifiedChange, ...prevState];
			}
			return prevState;
		});

		setPendingChanges(prevState => {
			return prevState.map(set =>
				set.changeId === pendingChangesSet.changeId
					? {
							...set,
							data: set.data.map(change => (change.id === log.id ? { ...change, status: changeStatus } : change)),
					  }
					: {
							...set,
							data: set.data.map(change => ({
								...change,
								status: changeStatus === LOG_STATUS.APPROVED ? LOG_STATUS.REJECTED : change.status,
							})),
					  },
			);
		});

		if (changeStatus === LOG_STATUS.REJECTED) {
			const log = createLog('change rejected', LOG_STATUS.REJECTED);
			const currentChange = tempPendingChanges?.find(({ id, status }) => id === modifiedChange?.id && status === LOG_STATUS.NEW);

			if (modifiedChange && currentChange) {
				undo();
			}

			setTempPendingChanges(prevState => prevState.filter(({ id }) => id !== modifiedChange?.id));
			saveTaxonomy(tree, log);
		}
	};
};

export const useResetChanges = () => {
	const resetTempPendingChanges = useResetRecoilState(tempPendingChangesState);

	const resetTaxonomyChanges = useResetTaxonomyChanges();

	return () => {
		resetTempPendingChanges();
		resetTaxonomyChanges();
	};
};
