import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { RecoilRoot } from 'recoil';
import { StyleSheetManager } from 'styled-components';

import { AuthProvider } from './auth0/AuthProvider';
import { App } from './components/app';
import { AppFallback } from './components/app/atomComponents';
import { reactQueryLogger } from './tests/utils';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

const queryClient = new QueryClient({ logger: reactQueryLogger });

root.render(
	<React.StrictMode>
		<ErrorBoundary FallbackComponent={AppFallback}>
			<AuthProvider>
				<RecoilRoot>
					<QueryClientProvider client={queryClient}>
						<StyleSheetManager disableVendorPrefixes={process.env.NODE_ENV === 'development'}>
							<App />
						</StyleSheetManager>
						<ReactQueryDevtools initialIsOpen={false} />
					</QueryClientProvider>
				</RecoilRoot>
			</AuthProvider>
		</ErrorBoundary>
	</React.StrictMode>,
);
