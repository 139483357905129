import * as Style from './appFallback.styles';

export const AppFallback = ({ error }: { error: Error }) => {
	const refreshPage = () => {
		window.location.reload();
	};

	return (
		<Style.AppFallback>
			<Style.FallbackTitle>Something went wrong...</Style.FallbackTitle>
			<Style.FallbackText>The application has encountered an unknown error. Please refresh the page.</Style.FallbackText>
			<Style.FallbackButton onClick={refreshPage}>Refresh the page</Style.FallbackButton>
			<Style.FallbackText>details: {error.message}</Style.FallbackText>
		</Style.AppFallback>
	);
};
