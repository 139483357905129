import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_FAMILIES, FONT_SIZES } from '../../../../styles/fonts.styles';

export const AppFallback = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 120px;
	font-family: ${FONT_FAMILIES.REGULAR};
`;

export const FallbackTitle = styled.h4`
	color: ${Colors.alert500};
	font-weight: 700;
	font-size: ${FONT_SIZES.H3};
	line-height: 24px;
	margin-top: 0;
	margin-bottom: 16px;
`;

export const FallbackText = styled.p`
	font-size: ${FONT_SIZES.H4};
	line-height: 21px;
	margin-top: 0;
	margin-bottom: 16px;
	max-width: 400px;
	text-align: center;
`;

export const FallbackButton = styled.button`
	border: 0;
	border-radius: 4px;
	padding: 10px 8px;
	margin-top: 8px;
	margin-bottom: 32px;
	color: ${Colors.white};
	background-color: ${Colors.primary500};
	font-weight: 500;
	font-size: ${FONT_SIZES.BODY};
	transition: background-color 0.3s;
	text-transform: uppercase;
	cursor: pointer;

	&:hover {
		background: ${Colors.primary800};
	}
`;
