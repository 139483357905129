import * as yup from 'yup';

import { ERROR_TYPE, NODE_ERRORS } from '../constants/errors.constants';
import { useCheckTag, useIsNodeChildNameExist } from '../services/validation.services';
import { NodeNameSchema } from './NodeNameSchema';

declare module 'yup' {
	interface StringSchema {
		isNodeNameTaken(message: string): StringSchema;
		isNodeNameExists(message: string): StringSchema;
	}
}

export const NodeNameListSchema = () => {
	const nodeNameSchema = NodeNameSchema();
	const isNodeChildNameExist = useIsNodeChildNameExist();
	const checkTag = useCheckTag();

	yup.addMethod(yup.string, 'isNodeNameTaken', function (message) {
		return this.test(`isNodeNameTaken`, message, function (value) {
			const { path, createError } = this;
			return (value && !isNodeChildNameExist(value)) || createError({ path, message });
		});
	});

	yup.addMethod(yup.string, 'isNodeNameExists', function (message) {
		return this.test(`isNodeNameExists`, message, function (value) {
			if (!value) return true;

			const { paths, matches } = checkTag(value);

			if (paths.length === 0) return true;

			const matchedNodes = [`<ul>${paths.map(node => `<li>${node}</li>`).join('')}</ul`];

			if (matches > 0) {
				matchedNodes.push(
					`<span>${
						matches === 1 ? '1 more path is not shown.' : `${matches} more paths aren’t shown.`
					} Use the search bar to see all paths.</span>`,
				);
			}

			const { path, createError } = this;

			return (
				(value && paths.length === 0) ||
				createError({
					path,
					message: message.replace('{{matched-nodes}}', matchedNodes.join('')),
					type: `node-name-exists-${ERROR_TYPE.warningSuffix}`,
				})
			);
		});
	});

	return yup.object().shape({
		names: yup.lazy(val =>
			Array.isArray(val)
				? yup
						.array()
						.label('Node name(s)')
						.of(
							nodeNameSchema
								.isNodeNameTaken(NODE_ERRORS.NODE_EXIST_IN_THE_SAME_LEVEL)
								.isNodeNameExists(NODE_ERRORS.NODE_NAME_EXIST),
						)
						.required(NODE_ERRORS.REQUIRED)
						.min(1, NODE_ERRORS.REQUIRED)
				: nodeNameSchema.label('Node name(s)').isNodeNameTaken(NODE_ERRORS.NODE_EXIST_IN_THE_SAME_LEVEL),
		),
	});
};
