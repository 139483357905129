import { useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { useNodeContent } from '../../../../hooks';
import { useUpdateNode } from '../../../../services/infoBar.services';
import { infoBarModeState } from '../../../../state/infoBar.state';
import { selectedNodeState } from '../../../../state/tree.state';
import type { IExamplesSynonyms, MODE_CONFIG } from '../../../../types/taxonomy.types';
import { synonymsConfig } from '../../infoBar.constants';
import { EditButton } from '../editButton';
import { EditNodeContent } from '../editNodeContent';
import * as Style from './nodeContentList.styles';
import { RenderNoEditModeDefaultData } from './renderNoEditModeDefaultData';
import { RenderNotEditModeWithExistingData } from './renderNotEditModeWithExistingData';

interface EditNodeContentListProps {
	currentConfig: MODE_CONFIG;
	formType: string;
	nodeContentList: IExamplesSynonyms;
	onNodeContentUpdate: (nodeContentToUpdate: IExamplesSynonyms) => void;
	updateData: () => void;
}
const EditNodeContentList = ({
	currentConfig,
	formType,
	nodeContentList,
	onNodeContentUpdate,
	updateData,
}: EditNodeContentListProps) => {
	const infoBarMode = useRecoilValue(infoBarModeState);
	const resetInfoBarMode = useResetRecoilState(infoBarModeState);
	const selectedNode = useRecoilValue(selectedNodeState);

	const updateNode = useUpdateNode();

	const discardEdit = () => {
		updateData();
		resetInfoBarMode();
	};

	const updateCurrentNodes = (updatedNodeContentList: IExamplesSynonyms) => {
		const nodeDataToUpdate = updatedNodeContentList.filter(nodeContent => nodeContent.content !== '');

		selectedNode && updateNode(nodeDataToUpdate, formType, selectedNode);
		onNodeContentUpdate(nodeDataToUpdate);

		resetInfoBarMode();
	};

	if (infoBarMode === currentConfig.editMode)
		return (
			<EditNodeContent
				nodesContentList={nodeContentList}
				handleOnSaveNodeContent={updateCurrentNodes}
				discardEdit={discardEdit}
				selectedNode={selectedNode}
				config={currentConfig}
			/>
		);

	if (nodeContentList.length !== 0) {
		return <RenderNotEditModeWithExistingData currentConfig={currentConfig} nodeContentList={nodeContentList} />;
	}

	return <RenderNoEditModeDefaultData currentConfig={currentConfig} />;
};

export const NodeContentList = ({ formType }: { formType: string }) => {
	const selectedNode = useRecoilValue(selectedNodeState);
	const [nodeContentList, setNodeContentList] = useState<IExamplesSynonyms>([]);
	const [currentConfig, setConfig] = useState(synonymsConfig);

	const updateData = useNodeContent({
		formType,
		currentConfig,
		setConfig,
		setSynonyms: setNodeContentList,
		selectedNodeData: selectedNode?.data,
	});

	useEffect(() => updateData(), [formType, selectedNode?.data, updateData]);

	return (
		<Style.NodeContentListContainer data-testid={currentConfig.dataTestIdComponent}>
			<Style.InnerTitles data-testid={currentConfig.dataTestIdInnerTitles}>
				<Style.Title data-testid={currentConfig.dataTestIdTitle}>{currentConfig.title}</Style.Title>
				<EditButton editMode={currentConfig.editMode} />
			</Style.InnerTitles>
			<EditNodeContentList
				currentConfig={currentConfig}
				formType={formType}
				nodeContentList={nodeContentList}
				onNodeContentUpdate={(nodeContentToUpdate: IExamplesSynonyms) => setNodeContentList(nodeContentToUpdate)}
				updateData={updateData}
			/>
		</Style.NodeContentListContainer>
	);
};
