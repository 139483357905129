import { ChangeEvent, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useUpdateNode } from '../../../../services/infoBar.services';
import { EDITED_SECTION, infoBarModeState } from '../../../../state/infoBar.state';
import { selectedNodeState } from '../../../../state/tree.state';
import { INFO_BAR, INFO_BAR_TESTS_IDS } from '../../infoBar.constants';
import { EditButton } from '../editButton';
import * as Style from './description.styles';

interface IContent {
	defaultDescription: string;
}
const EditableContent = ({ defaultDescription }: IContent) => {
	const setInfoBarMode = useSetRecoilState(infoBarModeState);
	const selectedNode = useRecoilValue(selectedNodeState);

	const [description, setDescription] = useState(defaultDescription);
	const [isValid, setIsValid] = useState(false);

	const updateNode = useUpdateNode();

	useEffect(() => setDescription(defaultDescription), [defaultDescription, setInfoBarMode]);

	useEffect(() => setIsValid(defaultDescription !== description), [defaultDescription, description]);

	const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value);

	const onSave = () => {
		selectedNode && updateNode(description, INFO_BAR.DESCRIPTION, selectedNode);
		setInfoBarMode(undefined);
	};

	const onDiscard = () => {
		setDescription(defaultDescription);
		setInfoBarMode(undefined);
	};

	return (
		<>
			<Style.DescriptionTextArea
				data-testid={INFO_BAR_TESTS_IDS.TEXT_AREA}
				onChange={onChange}
				value={description}
				placeholder="Add description"
				minRows={6}
			/>
			<Style.ButtonsContainer>
				<Style.SaveButton data-testid={INFO_BAR_TESTS_IDS.INFO_BAR_DESCRIPTION_SAVE} disabled={!isValid} onClick={onSave}>
					{INFO_BAR.SAVE}
				</Style.SaveButton>
				<Style.DiscardButton data-testid={INFO_BAR_TESTS_IDS.INFO_BAR_DESCRIPTION_DISCARD} onClick={onDiscard}>
					{INFO_BAR.DISCARD_CHANGES}
				</Style.DiscardButton>
			</Style.ButtonsContainer>
		</>
	);
};

const ReadableContent = ({ defaultDescription }: IContent) => {
	const Body = defaultDescription ? Style.Description : Style.EmptyDescription;

	return <Body data-testid={INFO_BAR_TESTS_IDS.INFO_BAR_DESCRIPTION}>{defaultDescription || INFO_BAR.ADD_DESCRIPTION}</Body>;
};

const Content = () => {
	const infoBarMode = useRecoilValue(infoBarModeState);
	const selectedNode = useRecoilValue(selectedNodeState);
	const defaultDescription = selectedNode?.data?.description;

	const ContentComponent = infoBarMode === EDITED_SECTION.DESCRIPTION ? EditableContent : ReadableContent;

	return <ContentComponent defaultDescription={defaultDescription || ''} />;
};

export const Description = () => (
	<Style.Descriptions data-testid={INFO_BAR_TESTS_IDS.DESCRIPTIONS}>
		<Style.InnerTitles data-testid={INFO_BAR_TESTS_IDS.INNER_TITLES}>
			<Style.Title data-testid={INFO_BAR_TESTS_IDS.DESCRIPTION_TITLE}>{INFO_BAR.DESCRIPTION_TITLE}</Style.Title>
			<EditButton editMode={EDITED_SECTION.DESCRIPTION} />
		</Style.InnerTitles>
		<Content />
	</Style.Descriptions>
);
