import { useRecoilValue } from 'recoil';

import { MODAL_TESTS_IDS } from '../../../../constants/modals.constants';
import { useMoveNode } from '../../../../services/node.services';
import { selectedTaxonomyState } from '../../../../state/global.state';
import { modalPropsState } from '../../../../state/modal.state';
import { BaseModalContent } from '../../atomsComponents/BaseForm/BaseModalContent';
import { FormDescription } from '../../atomsComponents/modalAtoms';
import { BaseModal, BaseModalProps } from '../../BaseModal';
import * as Styles from '../../BaseModal/BaseModal.styles';

type MoveNodeModalProps = BaseModalProps & { onClose: () => void };

export const MoveNodeModal = ({ onClose, showModal }: MoveNodeModalProps) => {
	const { input: from, id: to } = useRecoilValue(modalPropsState);
	const { nodes } = useRecoilValue(selectedTaxonomyState).tree;
	const moveNode = useMoveNode();

	const selectedNode = nodes?.filter(node => node.id === from)[0];
	const dropNode = nodes?.find(node => node.id === to);
	const onSubmit = () => {
		moveNode(from as string, to as string);
		onClose();
	};

	return (
		<BaseModal title="Move node" showModal={showModal} testid={MODAL_TESTS_IDS.MOVE_NODE}>
			<BaseModalContent discardBtnText="CANCEL" submitBtnText="YES" onSubmit={onSubmit} onDiscard={onClose} isValid>
				<FormDescription>
					You are about to move the node <Styles.Bolded>&quot;{selectedNode?.text}&quot;</Styles.Bolded> to{' '}
					<Styles.Bolded>&quot;{dropNode?.text}&quot;</Styles.Bolded>.
				</FormDescription>
				<FormDescription>Would you like to continue?</FormDescription>
			</BaseModalContent>
		</BaseModal>
	);
};
