import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { icon } from '../../../../styles/global.styles';

export const Header = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 60px;
	padding: 12px 8px;
	background: ${Colors.secondary800};
`;

export const TitleWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Title = styled.h6`
	margin-top: 0;
	margin-bottom: 6px;
	font-weight: 500;
	font-size: ${FONT_SIZES.H6};
	line-height: 18px;
	color: ${Colors.white};
`;

export const Depth = styled.span`
	font-size: ${FONT_SIZES.BODY};
	line-height: 16px;
	color: ${Colors.secondary300};
`;

export const EditIcon = styled.svg`
	${icon};

	cursor: pointer;
	margin-right: 4px;
`;
