import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { MODAL_TESTS_IDS } from '../../../../../constants/modals.constants';
import { Colors } from '../../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../../styles/fonts.styles';

const Root = styled.button`
	font-weight: 500;
	font-size: ${FONT_SIZES.BODY};
	line-height: 16px;
	color: ${Colors.primary500};
	padding: 10px 8px;
	transition: background-color 0.3s;

	&:hover {
		background-color: ${Colors.primary50};
	}

	&:active {
		background-color: ${Colors.primary100};
	}
`;

export const DiscardButton = ({ onDiscard, children }: PropsWithChildren<{ onDiscard: () => void }>) => (
	<Root type="button" data-testid={MODAL_TESTS_IDS.CLOSE} onClick={onDiscard}>
		{children || 'DISCARD'}
	</Root>
);
