import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { MODAL_TESTS_IDS } from '../../../../constants/modals.constants';
import { NodeNameListSchema } from '../../../../schemas/NodeNameListSchema';
import { useCreateNode } from '../../../../services/node.services';
import { selectedNodeState } from '../../../../state/tree.state';
import { BaseForm } from '../../atomsComponents/BaseForm/BaseForm';
import { BaseModal, BaseModalProps } from '../../BaseModal';
import * as Styles from '../../BaseModal/BaseModal.styles';

const Note = styled.em`
	font-size: 14px;
`;

type CreateNodeModalProps = BaseModalProps & { onClose: () => void };
export const CreateNodeModal = ({ onClose, showModal }: CreateNodeModalProps) => {
	const createNode = useCreateNode();
	const selectedNode = useRecoilValue(selectedNodeState);

	const onSubmit = (nodes: Record<string, string>) => {
		selectedNode && createNode(selectedNode, nodes);
		onClose();
	};

	return (
		<BaseModal title="Add New Node" showModal={showModal} testid={MODAL_TESTS_IDS.ADD_NODE}>
			<BaseForm
				description={
					selectedNode && (
						<>
							<div>
								Add new node under <Styles.Bolded>&quot;{selectedNode?.text}&quot;</Styles.Bolded>.
							</div>
							<Note> Add nodes by pressing &laquo;enter&raquo; or &laquo;;&raquo; on your keyboard</Note>
						</>
					)
				}
				fields={[{ fieldName: 'names', fieldType: 'multiTagsInput', placeholder: 'Add new node(s)' }]}
				onSubmit={onSubmit}
				onDiscard={onClose}
				submitBtnText="ADD"
				scheme={NodeNameListSchema()}
			/>
		</BaseModal>
	);
};
