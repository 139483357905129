import { useMutation, useQuery } from '@tanstack/react-query';
import { MouseEvent, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import * as Icon from '../../assets/icons/header/taxonomySelector';
import { TAXONOMY_ERRORS } from '../../constants/errors.constants';
import { useDeleteTaxonomyRequest, useGetPendingChangesRequest, useGetTaxonomyRequest } from '../../services/api.services';
import { useCloseHeaderDropdowns, useSelectTaxonomy } from '../../services/header.services';
import { useResetActionsOpen } from '../../services/tree.services';
import {
	selectedTaxonomyState,
	taxonomyIdToDeleteState,
	taxonomyToFetchState,
	tempFileNameState,
} from '../../state/global.state';
import { headerDropdownOpenState, isTaxonomyErrorState } from '../../state/header.state';
import { fetchPendingChangesState, isPendingChangesLoadingState, pendingChangesState } from '../../state/history.state';
import { isAdminState } from '../../state/user.state';
import type { IPendingChange, ITaxonomyTree } from '../../types/taxonomy.types';
import { queryConfig } from '../app/init/useInit';
import { DropDown } from './atomComponents';
import { TAXONOMY_SELECTOR, TAXONOMY_SELECTOR_TESTS_IDS } from './taxonomySelector.constants';
import * as Style from './taxonomySelector.styles';

const Preloader = () => (
	<Style.Spinner>
		<Style.Circle />
	</Style.Spinner>
);

export const TaxonomySelector = () => {
	const isAdmin = useRecoilValue(isAdminState);
	const { taxonomyId: idForFetchPending } = useRecoilValue(fetchPendingChangesState);
	const setIsPendingChangesLoading = useSetRecoilState(isPendingChangesLoadingState);
	const [isTaxonomyError, setIsTaxonomyError] = useRecoilState(isTaxonomyErrorState);
	const [headerDropdownOpen, setHeaderDropdownOpen] = useRecoilState(headerDropdownOpenState);
	const setPendingChanges = useSetRecoilState(pendingChangesState);
	const { taxonomyName } = useRecoilValue(selectedTaxonomyState);
	const taxonomyIdToDelete = useRecoilValue(taxonomyIdToDeleteState);
	const resetIdToDelete = useResetRecoilState(taxonomyIdToDeleteState);
	const taxonomyToFetch = useRecoilValue(taxonomyToFetchState);
	const tempFileName = useRecoilValue(tempFileNameState);

	const deleteTaxonomyRequest = useDeleteTaxonomyRequest();
	const getPendingChangesRequest = useGetPendingChangesRequest();
	const getTaxonomyRequest = useGetTaxonomyRequest();
	const resetActionsOpen = useResetActionsOpen();
	const selectTaxonomy = useSelectTaxonomy();
	const closeDropdowns = useCloseHeaderDropdowns();

	const { isInitialLoading, isFetching } = useQuery<ITaxonomyTree>({
		...queryConfig,
		enabled: !!taxonomyToFetch.taxonomyId,
		onError: () => setIsTaxonomyError(true),
		onSuccess: tree => selectTaxonomy({ ...taxonomyToFetch, tree }),
		queryFn: () => getTaxonomyRequest(taxonomyToFetch.taxonomyId),
		queryKey: ['getTaxonomy', taxonomyToFetch.taxonomyId],
	});

	const { isInitialLoading: isPendingLoading, isFetching: isPendingFetching } = useQuery<IPendingChange[]>({
		enabled: isAdmin && !!idForFetchPending,
		onSuccess: pendingChanges =>
			setPendingChanges(
				pendingChanges.sort((a, b) => a.submittedAt.toLocaleString().localeCompare(b.submittedAt.toLocaleString())),
			),
		queryFn: getPendingChangesRequest,
		queryKey: ['getPendingChanges', idForFetchPending],
	});

	const { isLoading, mutate } = useMutation(['deleteTaxonomy', taxonomyIdToDelete], deleteTaxonomyRequest, {
		onMutate: () => resetIdToDelete(),
	});

	useEffect(() => {
		setIsPendingChangesLoading(isPendingLoading || isPendingFetching);
	}, [isPendingLoading, isPendingFetching, setIsPendingChangesLoading]);

	useEffect(() => {
		taxonomyIdToDelete && mutate();
	}, [taxonomyIdToDelete, mutate]);

	const handleDropdownClick = async (e: MouseEvent) => {
		e.stopPropagation();

		resetActionsOpen();
		closeDropdowns();
		setHeaderDropdownOpen(!headerDropdownOpen);
	};

	if (isTaxonomyError) throw new Error(TAXONOMY_ERRORS.TAXONOMY);

	return (
		<Style.TaxonomySelectorContainer data-testid={TAXONOMY_SELECTOR_TESTS_IDS.TAXONOMY_SELECTOR}>
			<Style.HeaderTitle data-testid={TAXONOMY_SELECTOR_TESTS_IDS.TITLE}>{TAXONOMY_SELECTOR.TAXONOMY_EDITOR}</Style.HeaderTitle>
			<Style.ShortSeparationLine data-testid={TAXONOMY_SELECTOR_TESTS_IDS.SEPARATION_LINE} />
			{isInitialLoading || isFetching || tempFileName || isLoading ? (
				<Preloader />
			) : (
				<Style.SelectorWrapper onClick={e => handleDropdownClick(e)}>
					<Style.TaxonomyName length={taxonomyName?.length} data-testid={TAXONOMY_SELECTOR_TESTS_IDS.NAME}>
						{taxonomyName}
					</Style.TaxonomyName>
					<Style.IconsButtonContainer data-testid={TAXONOMY_SELECTOR_TESTS_IDS.TAXONOMY_SELECTOR_BUTTON}>
						<Style.ArrowDown data-testid={TAXONOMY_SELECTOR_TESTS_IDS.ARROW_ICON}>
							{headerDropdownOpen ? <Icon.ArrowUp /> : <Icon.ArrowDown />}
						</Style.ArrowDown>
					</Style.IconsButtonContainer>
				</Style.SelectorWrapper>
			)}

			{headerDropdownOpen && <DropDown />}
		</Style.TaxonomySelectorContainer>
	);
};
