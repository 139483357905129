import { useRecoilValue } from 'recoil';

import { selectedTaxonomyState } from '../../../../state/global.state';
import { ACTION_BAR_TESTS_IDS } from '../../actionBar.constants';
import { ExportCSV } from '../exportTaxonomy/exportCSV';
import * as Style from './exportDropdown.styles';

export const ExportDropdown = () => {
	const selectedTaxonomy = useRecoilValue(selectedTaxonomyState);

	const selectedTaxonomyToJson = JSON.stringify(selectedTaxonomy);

	return (
		<Style.Root>
			<Style.ListItem
				href={'data:text/json;charset=utf-8,' + encodeURIComponent(selectedTaxonomyToJson)}
				download={`${selectedTaxonomy?.taxonomyName}.json`}
				data-testid={ACTION_BAR_TESTS_IDS.EXPORT_TO_JSON}
			>
				Export as JSON file
			</Style.ListItem>
			<ExportCSV />
		</Style.Root>
	);
};
