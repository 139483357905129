import Dexie, { Table } from 'dexie';

import type { ITaxonomy } from './types/taxonomy.types';

export class TaxonomiesDexie extends Dexie {
	taxonomies!: Table<ITaxonomy>;

	constructor() {
		super('taxonomiesIDB');
		this.version(1).stores({
			taxonomies: '&taxonomyId',
		});
	}

	async get() {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		return db.taxonomies.toArray().then(taxonomies => taxonomies.map(({ tree, ...taxonomy }) => taxonomy));
	}

	getOne(id: string) {
		return db.taxonomies.get(id);
	}

	put(taxonomy: ITaxonomy) {
		return db.taxonomies.put(taxonomy);
	}

	deleteOne(id: string) {
		return db.taxonomies.delete(id);
	}
}

export const db = new TaxonomiesDexie();
