import styled from 'styled-components';

import { Colors } from '../../styles/colors.styles';
import { FONT_SIZES } from '../../styles/fonts.styles';
import { SpinnerCircle } from '../../styles/global.styles';

export const TaxonomySelectorContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

export const HeaderTitle = styled.p`
	margin: 0;
	font-size: ${FONT_SIZES.BODY};
	line-height: 16.41px;
	font-weight: 400;
	color: ${Colors.secondary300};
`;

export const ShortSeparationLine = styled.span`
	height: 16px;
	width: 1px;
	line-height: 16px;
	background-color: ${Colors.secondary300};
	margin: 0 8px;
`;

export const SelectorWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

export const TaxonomyName = styled.p<{ length: number }>`
	margin: 0;
	font-weight: 400;
	font-size: ${FONT_SIZES.BODY};
	line-height: 16.41px;
	color: ${Colors.secondary50};
	max-width: 210px;
	text-overflow: ${props => (props.length > 30 ? `ellipsis` : null)};
	white-space: ${props => (props.length > 30 ? `nowrap` : null)};
	overflow: ${props => (props.length > 30 ? `hidden` : null)};
`;

export const IconsButtonContainer = styled.button`
	display: flex;
`;

export const ArrowDown = styled.svg`
	width: 12px;
	height: 8px;
	margin: 10px;
`;

export const Spinner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: 40px;
`;

export const Circle = styled(SpinnerCircle)`
	width: 16px;
	height: 16px;
	border: 2px solid ${Colors.secondary300};
	border-bottom-color: ${Colors.secondary800};
`;
