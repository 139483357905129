import { getBackendOptions, MultiBackend, NodeModel, Tree, TreeMethods } from '@minoru/react-dnd-treeview';
import cn from 'classnames';
import { memo, useCallback, useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { useRecoilValue, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE, useSetRecoilState } from 'recoil';

import { useDependantsOf } from '../../../../services/graph.services';
import { useSetMoveNodeModalAction } from '../../../../services/modal.services';
import { connectionModeState, selectedNodeState } from '../../../../state/tree.state';
import {
	closeTreeMenuNodesState,
	closeTreeMenuState,
	kebabOpenedState,
	openTreeMenuNodesState,
	resultsOpenedState,
	treeMenuNodesState,
} from '../../../../state/treeMenuBar.state';
import { TREE_MENU_TESTS_IDS } from '../../treeMenuBar.constants';
import { KebabMenu } from '../kebabMenu';
import { TreeMenuBarLoader } from '../treeMenuBarLoader/TreeMenuBarLoader';
import { TreeMenuNode } from '../treeMenuNode';
import style from './treeMenu.module.css';

export const TreeMenuBody = () => {
	const connectionMode = useRecoilValue(connectionModeState);
	const setCloseTreeMenu = useSetRecoilState(closeTreeMenuState);
	const setCloseTreeMenuNodes = useSetRecoilState(closeTreeMenuNodesState);
	const kebabOpened = useRecoilValue(kebabOpenedState);
	const resultsOpened = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(resultsOpenedState);
	const setExpandTreeMenuNodes = useSetRecoilState(openTreeMenuNodesState);
	const selectedNode = useRecoilValue(selectedNodeState);
	const treeMenuNodes = useRecoilValue(treeMenuNodesState);

	const findAllParentsIds = useDependantsOf();
	const ref = useRef<TreeMethods>(null);

	const handleExpandNodes = useCallback((nodes: string[]) => ref.current?.open(nodes), []);
	const handleCloseNodes = useCallback((nodes: string[]) => ref.current?.close(nodes), []);
	const handleCloseAll = useCallback(() => ref.current?.closeAll(), []);
	const nodeMove = useSetMoveNodeModalAction();

	useEffect(() => {
		if (selectedNode) {
			const parents = findAllParentsIds(selectedNode?.id);
			setTimeout(() => handleExpandNodes(parents), 300);
		}
	}, [findAllParentsIds, handleExpandNodes, selectedNode]);

	useEffect(() => {
		setCloseTreeMenuNodes(() => handleCloseNodes);
		setExpandTreeMenuNodes(() => handleExpandNodes);
		setCloseTreeMenu(() => handleCloseAll);
	}, [handleExpandNodes, handleCloseAll, handleCloseNodes, setCloseTreeMenu, setCloseTreeMenuNodes, setExpandTreeMenuNodes]);

	const handleDrop = (
		_: NodeModel[],
		{ dragSourceId, dropTargetId }: { dragSourceId?: string | number; dropTargetId?: string | number },
	) => {
		if (connectionMode.active) return;

		nodeMove(dragSourceId as string, (dropTargetId as string) || '100');
	};

	if (!treeMenuNodes) return <TreeMenuBarLoader data-testid={TREE_MENU_TESTS_IDS.LOADER} />;

	return (
		<DndProvider backend={MultiBackend} options={getBackendOptions()}>
			<Tree
				tree={treeMenuNodes as NodeModel[]}
				ref={ref}
				rootId={0}
				onDrop={handleDrop}
				classes={{
					root: cn(style.root, { [style.overlay]: resultsOpened }),
					container: style.container,
					listItem: style.listItem,
				}}
				render={(node, { depth, isOpen, onToggle, isDragging, isDropTarget }) => (
					<TreeMenuNode
						node={node}
						depth={depth}
						isOpen={isOpen}
						onToggle={onToggle}
						isDragging={isDragging}
						isDropTarget={isDropTarget}
					/>
				)}
			/>
			{kebabOpened && <KebabMenu />}
		</DndProvider>
	);
};

export const TreeMenu = memo(TreeMenuBody);
