import { ReactZoomPanPinchRef } from '@pronestor/react-zoom-pan-pinch';
import { memo, MouseEvent, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { useLongPress, useResize } from '../../hooks';
import { useResetSelections } from '../../services/tree.services';
import { selectedTaxonomyState } from '../../state/global.state';
import { infoBarOpenState } from '../../state/infoBar.state';
import { connectionModeState, onCanvasClickState } from '../../state/tree.state';
import { treeMenuOpenState } from '../../state/treeMenuBar.state';
import { History } from '../history';
import { Actions, ConnectMenu, ImportTaxonomy, PaginationControls, TaxonomyTree, ViewControls } from './atomComponents';
import { TREE_TESTS_IDS } from './tree.constants';

interface TreeProps {
	treeMenuOpen: boolean;
	infoBarOpen: boolean;
}

const calcTreeGridArea = ({ treeMenuOpen, infoBarOpen }: TreeProps) => {
	if (!infoBarOpen && !treeMenuOpen) return '2/1/2/4';
	if (!infoBarOpen) return '2/2/2/4';
	if (!treeMenuOpen) return '2/1/2/3';
	return 'main';
};

export const Root = styled.main<TreeProps>`
	height: calc(100vh - 48px);
	position: relative;
	display: flex;
	flex-direction: column;
	padding-left: ${({ treeMenuOpen }) => (treeMenuOpen ? '0' : '60px')};
	grid-area: ${props => calcTreeGridArea(props)};
`;

const TreeRoot = () => {
	const [connectionMode, setConnectionMode] = useRecoilState(connectionModeState);
	const infoBarOpen = useRecoilValue(infoBarOpenState);
	const onCanvasClick = useRecoilValue(onCanvasClickState);
	const { tree } = useRecoilValue(selectedTaxonomyState);
	const treeMenuOpen = useRecoilValue(treeMenuOpenState);

	const resetSelections = useResetSelections();

	const isTaxonomySelected = tree.nodes.length > 0;

	const transformRef = useRef<ReactZoomPanPinchRef | null>(null);

	const { containerRef, treeSize } = useResize();

	const onClick = (e: MouseEvent) => {
		if (isTaxonomySelected) {
			if (!['DIV', 'svg'].includes((e.target as HTMLElement).nodeName)) return;

			if (connectionMode.activeParent) {
				setConnectionMode(prevState => ({ ...prevState, parent: undefined }));
				return;
			}

			if (connectionMode.activeChildren) {
				setConnectionMode(prevState => ({ ...prevState, children: [] }));
				onCanvasClick?.();
				return;
			}

			onCanvasClick?.();
			resetSelections();

			return;
		}
		return;
	};

	const longPressEvent = useLongPress(undefined, onClick);

	useEffect(() => transformRef?.current?.centerView(), [treeSize]);

	return (
		<Root
			data-testid={isTaxonomySelected ? TREE_TESTS_IDS.TREE : TREE_TESTS_IDS.SHOW_NO_TREE}
			ref={containerRef}
			infoBarOpen={infoBarOpen}
			treeMenuOpen={treeMenuOpen}
			{...longPressEvent}
		>
			<ImportTaxonomy />
			<TaxonomyTree transformRef={transformRef} treeSize={treeSize} />
			{isTaxonomySelected && (
				<>
					<ViewControls transformRef={transformRef} />
					<Actions />
					<PaginationControls />
					<ConnectMenu />
				</>
			)}
			<History />
		</Root>
	);
};

export const Tree = memo(TreeRoot);
