import styled, { css } from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';

const actionStyles = css`
	display: flex;
	align-items: center;
	gap: 8px;
	width: 160px;
	padding: 8px;
	transition: background-color 0.3s;

	svg {
		pointer-events: none;
	}

	path {
		transition: 0.3s;
	}

	rect {
		transition: 0.3s;
	}
`;

export const Actions = styled.div`
	display: flex;
	flex-direction: column;
	height: fit-content;
	background-color: ${Colors.white};
	border-radius: 4px;
	border: 1px solid ${Colors.secondary100};
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
`;

export const MainButton = styled.button<{ isOpen: boolean }>`
	${actionStyles};
	border-bottom: ${({ isOpen }) => (isOpen ? `1px solid ${Colors.secondary100}` : `0px solid ${Colors.secondary100}`)};

	&:disabled {
		path {
			fill: ${Colors.secondary100};
		}
	}
`;

export const Heading = styled.h6`
	font-size: ${FONT_SIZES.H6};
	line-height: 1.2;
	font-weight: 500;
	color: ${Colors.secondary500};
	margin-block: 0;
	transition: color 0.3s;

	${MainButton}:hover & {
		color: ${Colors.secondary800};
	}

	${MainButton}:active & {
		color: ${Colors.secondary500};
	}

	${MainButton}:disabled & {
		color: ${Colors.secondary100};
	}
`;

export const ActionsList = styled.ul`
	list-style: none;
	padding-left: 0;
	margin-block: 0;
`;

export const ActionItem = styled.li``;

export const Action = styled.button<{ pressed?: boolean }>`
	${actionStyles};

	path {
		fill: ${({ pressed }) => (pressed ? Colors.primary500 : Colors.secondary500)};
	}

	&:hover {
		background-color: ${Colors.secondary50};

		path {
			fill: ${Colors.primary500};
		}
	}

	&:active {
		background-color: ${Colors.secondary50};

		path {
			fill: ${Colors.primary800};
		}
	}

	&:disabled {
		background-color: ${Colors.white};

		path {
			fill: ${Colors.secondary300};
		}
	}
`;

export const ConnectAction = styled(Action)`
	path {
		fill: none;
		stroke: ${Colors.secondary500};
	}

	rect {
		fill: ${Colors.secondary500};
	}

	&:hover {
		path {
			fill: none;
			stroke: ${Colors.primary500};
		}

		rect {
			fill: ${Colors.primary500};
		}
	}

	&:active {
		path {
			fill: none;
			stroke: ${Colors.primary800};
		}

		rect {
			fill: ${Colors.primary800};
		}
	}

	&:disabled {
		path {
			fill: none;
			stroke: ${Colors.secondary300};
		}

		rect {
			fill: ${Colors.secondary300};
		}
	}
`;

export const Text = styled.span<{ pressed?: boolean }>`
	font-size: ${FONT_SIZES.BODY};
	line-height: 1.2;
	color: ${({ pressed }) => (pressed ? Colors.primary500 : Colors.secondary500)};
	transition: color 0.3s;
	white-space: nowrap;

	${Action}:hover & {
		color: ${Colors.primary500};
	}

	${Action}:active & {
		color: ${Colors.primary800};
	}

	${Action}:disabled & {
		color: ${Colors.secondary300};
	}
`;

export const DeleteAction = styled(Action)`
	path {
		fill: ${Colors.alert500};
	}

	&:hover {
		path {
			fill: ${Colors.alert500};
		}
	}

	&:active {
		path {
			fill: ${Colors.alert800};
		}
	}

	&:disabled {
		path {
			fill: ${Colors.alert100};
		}
	}
`;

export const DeleteText = styled(Text)`
	color: ${Colors.alert500};

	${Action}:hover & {
		color: ${Colors.alert500};
	}

	${Action}:active & {
		color: ${Colors.alert800};
	}

	${Action}:disabled & {
		color: ${Colors.alert100};
	}
`;

export const CancelButton = styled.button`
	${actionStyles};

	width: 175px;
	background-color: ${Colors.alert500};

	path {
		stroke: ${Colors.white};
	}

	rect {
		fill: ${Colors.white};
	}

	&:hover {
		background-color: ${Colors.alert800};
	}
`;

export const CancelText = styled.h6`
	font-size: ${FONT_SIZES.H6};
	line-height: 1.2;
	font-weight: 500;
	color: ${Colors.white};
	margin-block: 0;

	${CancelButton}:active & {
		color: ${Colors.secondary50};
	}
`;
