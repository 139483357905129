import { useCallback } from 'react';

import { examplesConfig, INFO_BAR, synonymsConfig } from '../../components/infoBar/infoBar.constants';
import type { IExamplesSynonyms, ISynonym, ITaxonomyNode, MODE_CONFIG } from '../../types/taxonomy.types';

interface useNodeContentProps {
	formType: string;
	currentConfig: MODE_CONFIG;
	setConfig: (config: MODE_CONFIG) => void;
	setSynonyms: (data: ISynonym[]) => void;
	selectedNodeData: ITaxonomyNode['data'];
}

export const useNodeContent = ({ formType, currentConfig, setConfig, setSynonyms, selectedNodeData }: useNodeContentProps) => {
	const setData = useCallback(
		(dataConfig: MODE_CONFIG, data: IExamplesSynonyms | undefined) => {
			currentConfig.title !== dataConfig.title && setConfig(dataConfig);
			setSynonyms(data || []);
		},
		[currentConfig.title, setConfig, setSynonyms],
	);

	const setExamplesData = useCallback(() => {
		setData(examplesConfig, selectedNodeData?.examples);
	}, [selectedNodeData?.examples, setData]);

	const setSynonymsData = useCallback(() => {
		setData(synonymsConfig, selectedNodeData?.synonyms);
	}, [selectedNodeData?.synonyms, setData]);

	return {
		[INFO_BAR.EXAMPLES]: setExamplesData,
		[INFO_BAR.SYNONYMS]: setSynonymsData,
	}[formType];
};
