import { ComponentProps, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

import { MODAL_TESTS_IDS } from '../../../../constants/modals.constants';
import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { SpinnerCircle } from '../../../../styles/global.styles';
import { ConfirmButton, DiscardButton, FormDescription } from '../modalAtoms';

const Content = styled.div`
	width: 100%;
	color: ${Colors.secondary800};
	padding: 16px 24px;
	font-size: ${FONT_SIZES.H4};
	line-height: 21px;
`;

const InputErrorMessageWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const Footer = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-right: 8px;
`;

const FooterButtons = styled.div`
	display: flex;
	justify-content: end;
	align-items: center;
	height: 52px;
	border-radius: 6px;
	padding: 8px;
`;

export const Spinner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const Circle = styled(SpinnerCircle)`
	width: 28px;
	height: 28px;
	border: 3px solid ${Colors.secondary100};
	border-bottom-color: ${Colors.primary300};
	margin-right: 16px;
	margin-bottom: 8px;
`;

const Preloader = () => (
	<Spinner>
		<Circle />
	</Spinner>
);

type TConfirmButton = ComponentProps<typeof ConfirmButton>;
type TDiscardButton = ComponentProps<typeof DiscardButton>;
interface BaseModalContentProps {
	description?: ReactNode;
	discardBtnText?: TDiscardButton['children'];
	isLoading?: boolean;
	isValid: TConfirmButton['isValid'];
	onDiscard: TDiscardButton['onDiscard'];
	onSubmit?: TConfirmButton['onSubmit'];
	submitBtnText?: TConfirmButton['text'];
}
export const BaseModalContent = ({
	children,
	description,
	discardBtnText,
	isLoading,
	isValid,
	onSubmit,
	onDiscard,
	submitBtnText,
}: PropsWithChildren<BaseModalContentProps>) => (
	<>
		<Content
			data-testid={MODAL_TESTS_IDS.CONTENT}
			onKeyPress={({ key }) => {
				key === 'Enter' && onSubmit?.();
			}}
		>
			{description && <FormDescription>{description}</FormDescription>}
			<InputErrorMessageWrapper>{children}</InputErrorMessageWrapper>
		</Content>
		<Footer>
			<FooterButtons>
				{isLoading ? (
					<Preloader />
				) : (
					<>
						<DiscardButton onDiscard={onDiscard}>{discardBtnText}</DiscardButton>
						{submitBtnText && <ConfirmButton text={submitBtnText} isValid={isValid} onSubmit={onSubmit} />}
					</>
				)}
			</FooterButtons>
		</Footer>
	</>
);
