import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { treeNodesState } from '../state/tree.state';
import { useDependantsOf } from './graph.services';

export const useExpandSelectedTreeNode = () => {
	const setTreeNodes = useSetRecoilState(treeNodesState);

	const dependantsOf = useDependantsOf();

	return useCallback(
		(nodeId: string) => {
			const nodesToExpand = dependantsOf(nodeId);

			if (nodesToExpand.length === 0) return;

			const nodesToShow = [nodeId, ...nodesToExpand];

			setTreeNodes(currentState =>
				currentState.map(node => ({
					...node,
					expanded: nodesToExpand.includes(node.id) || node.expanded,
					show: nodesToShow.includes(node.id) || node.show,
				})),
			);
		},
		[dependantsOf, setTreeNodes],
	);
};
