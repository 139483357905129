export const TIME = {
	TODAY_US_DATE: new Intl.DateTimeFormat('en-US').format(new Date()),
	TODAY_US_DATE_TIME: new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(new Date()),
};

export const CSV = {
	HEADERS: [
		'MatchType',
		'GranularTopics',
		'Level 5',
		'Level 4',
		'Level 3',
		'Level 2',
		'Level 1',
		'Synonym_1',
		'Synonym_2',
		'Synonym_3',
		'Synonym_4',
		'Synonym_5',
		'Synonym_6',
		'Synonym_7',
		'Synonym_8',
		'Synonym_9',
		'Synonym_10',
		'Synonym_11',
		'Synonym_12',
		'Synonym_13',
		'Synonym_14',
		'Synonym_15',
		'Synonym_16',
		'Synonym_17',
		'Synonym_18',
		'Synonym_19',
		'Synonym_20',
	],
	LEVELS_COUNT: 6,
	LEVELS: ['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5', 'GranularTopics'],
	SYNONYMS_COUNT: 20,
};

export const CSV_ERRORS = {
	NO_DATA: 'No data to export',
	EDIT_TAXONOMY: 'Please edit taxonomy before exporting',
	ERROR_EXPORTING: 'There was an error exporting the file',
};
