import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode } from 'react';
import { RecoilRoot } from 'recoil';

/* eslint-disable no-console */
export const reactQueryLogger = {
	log: console.log,
	warn: console.warn,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	error: () => {},
};

const createTestQueryClient = () =>
	new QueryClient({
		defaultOptions: {
			queries: {
				retry: false,
			},
		},
	});

export function queryWrapper() {
	const testQueryClient = createTestQueryClient();

	// eslint-disable-next-line react/display-name
	return ({ children }: { children: ReactNode }) => (
		<QueryClientProvider client={testQueryClient}>{children}</QueryClientProvider>
	);
}

export const recoilWrapper = () => {
	// eslint-disable-next-line react/display-name
	return ({ children }: { children: ReactNode }) => <RecoilRoot>{children}</RecoilRoot>;
};

export const testComponentWrapper = (children: ReactNode) => {
	const testQueryClient = createTestQueryClient();
	return (
		<RecoilRoot>
			<QueryClientProvider client={testQueryClient}>{children}</QueryClientProvider>
		</RecoilRoot>
	);
};

export const hexToRgb = (h: string) => {
	let r = '0',
		g = '0',
		b = '0';

	// 3 digits
	if (h.length == 4) {
		r = '0x' + h[1] + h[1];
		g = '0x' + h[2] + h[2];
		b = '0x' + h[3] + h[3];

		// 6 digits
	} else if (h.length == 7) {
		r = '0x' + h[1] + h[2];
		g = '0x' + h[3] + h[4];
		b = '0x' + h[5] + h[6];
	}

	return `rgb(${+r}, ${+g}, ${+b})`;
};
