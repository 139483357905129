import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { MODAL_TESTS_IDS, NODES_FUNCTIONALITY } from '../../../../constants/modals.constants';
import { useDeleteModalType } from '../../../../services/modal.services';
import { useDeleteNode, useNodeSiblingsPerParent } from '../../../../services/node.services';
import { selectedTaxonomyState } from '../../../../state/global.state';
import { selectedNodeState } from '../../../../state/tree.state';
import { BaseModalContent } from '../../atomsComponents/BaseForm/BaseModalContent';
import { FormDescription } from '../../atomsComponents/modalAtoms';
import { BaseModal, BaseModalProps } from '../../BaseModal';
import * as Styles from '../../BaseModal/BaseModal.styles';

export const Text = styled.p`
	margin-top: 0;
	margin-bottom: 0;
`;

const DeleteNodeContent = () => {
	const selectedNode = useRecoilValue(selectedNodeState);

	return (
		<>
			Are you sure you want to delete <Styles.Bolded>{selectedNode?.text}</Styles.Bolded>?
		</>
	);
};

const ParentNodesWarning = ({ nodes }: { nodes: string[] }) => (
	<Text>
		This action will convert{' '}
		{nodes.map((node, i, arr) => (
			<Styles.Bolded key={i}>
				&quot;{node}&quot;{i !== arr.length - 1 && ','}{' '}
			</Styles.Bolded>
		))}
		to {nodes.length === 1 && 'a'} granular topic{nodes.length > 1 && 's'}.
	</Text>
);

const DeleteLastNode = () => {
	const { nodes } = useRecoilValue(selectedTaxonomyState).tree;
	const selectedNode = useRecoilValue(selectedNodeState);

	const nodeSiblingsPerParent = useNodeSiblingsPerParent();

	const childlessParentsIds = nodeSiblingsPerParent(selectedNode?.id as string)
		.filter(parent => parent.siblings.length === 0)
		.map(parent => parent.id);

	const childlessParentsNames = childlessParentsIds
		.map(id => nodes.find(node => node.id === id))
		.map(node => node?.text as string);

	return (
		<>
			<Text>
				Are you sure you want to delete <Styles.Bolded>&quot;{selectedNode?.text}&quot;</Styles.Bolded>?
			</Text>
			<ParentNodesWarning nodes={childlessParentsNames} />
		</>
	);
};

const DeleteNodeWithChild = () => (
	<>
		You cannot delete a node that has children. In order to delete such kind of node, first move all children to another node that
		it’s not under this parent.
	</>
);

const deleteNodeModalDefinitions = (type: string, onSubmit: () => void) => {
	if (type === NODES_FUNCTIONALITY.DELETE_NODE) {
		return {
			description: <DeleteNodeContent />,
			modalProps: { discardBtnText: 'CANCEL', onSubmit, submitBtnText: 'DELETE' },
			testId: MODAL_TESTS_IDS.DELETE_NODE,
			title: 'Delete node',
		};
	} else if (type === NODES_FUNCTIONALITY.DELETE_LAST_NODE_CHILD) {
		return {
			description: <DeleteLastNode />,
			modalProps: { discardBtnText: 'CANCEL', onSubmit, submitBtnText: 'DELETE' },
			testId: MODAL_TESTS_IDS.DELETE_LAST_NODE_CHILD,
			title: 'Delete last node',
		};
	} else if (type === NODES_FUNCTIONALITY.DELETE_NODE_WITH_CHILDREN) {
		return {
			description: <DeleteNodeWithChild />,
			modalProps: { discardBtnText: 'GOT IT' },
			testId: MODAL_TESTS_IDS.DELETE_NODE_WITH_CHILDREN,
			title: 'Delete parent node',
		};
	}
};

type DeleteNodeModalProps = BaseModalProps & { onClose: () => void };
export const DeleteNodeModal = ({ onClose, showModal }: DeleteNodeModalProps) => {
	const selectedNode = useRecoilValue(selectedNodeState);

	const deleteNode = useDeleteNode();
	const deleteModalType = useDeleteModalType();

	const onSubmit = () => {
		selectedNode && deleteNode(selectedNode);
		onClose();
	};

	const modalDefinitions = deleteNodeModalDefinitions(deleteModalType(), onSubmit);

	if (!modalDefinitions) return null;

	return (
		<BaseModal title={modalDefinitions.title} showModal={showModal} testid={modalDefinitions.testId}>
			<BaseModalContent {...{ onDiscard: onClose, isValid: true, ...modalDefinitions.modalProps }}>
				<FormDescription>{modalDefinitions.description}</FormDescription>
			</BaseModalContent>
		</BaseModal>
	);
};
