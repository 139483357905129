import styled from 'styled-components';

export const ActionsBarContainer = styled.div`
	display: flex;
	justify-self: flex-end;
	align-items: center;
	gap: 16px;
	margin-right: '0';
`;

export const InputFile = styled.input`
	display: none;
`;

export const Button = styled.button`
	background-color: red;
`;
