import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';

export const Root = styled.div`
	width: 250px;
	position: absolute;
	top: 50px;
	right: 102px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	background: ${Colors.secondary800};
	border: 1px solid ${Colors.secondary500};
	transform: translateX(calc(50% - 10px));
	padding: 16px;
	border-radius: 6px;
	z-index: 40;

	&:before {
		content: '';
		position: absolute;
		width: 16px;
		height: 16px;
		right: 16px;
		top: -8px;
		background: ${Colors.secondary800};
		border-top: 1px solid ${Colors.secondary500};
		border-left: 1px solid ${Colors.secondary500};
		transform: rotate(45deg);
		z-index: -10;
	}
`;

export const ListItem = styled.a<{ disabled?: boolean }>`
	color: ${Colors.secondary50};
	font-size: ${FONT_SIZES.XS};
	line-height: 1.15;
	transition: 0.3s;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
	text-decoration: none;
	&:hover {
		background-color: ${Colors.secondary900};
	}
`;
