import { atom } from 'recoil';

export const headerDropdownOpenState = atom<boolean>({
	key: 'headerDropdownOpen',
	default: false,
});

export const isTaxonomyErrorState = atom<boolean>({
	key: 'isTaxonomyError',
	default: false,
});

export const userMenuOpenState = atom<boolean>({
	key: 'userMenuOpen',
	default: false,
});

export const exportMenuOpenState = atom<boolean>({
	key: 'exportMenuOpen',
	default: false,
});

export const notificationsMenuOpenState = atom<boolean>({
	key: 'notificationsMenuOpen',
	default: false,
});
