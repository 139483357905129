import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { graphState } from '../state/tree.state';

export const useDirectDependantsOf = () => {
	const graph = useRecoilValue(graphState);

	return useCallback((id: string) => (graph.hasNode(id) ? graph.directDependantsOf(id) : []), [graph]);
};

export const useDependantsOf = () => {
	const graph = useRecoilValue(graphState);

	return useCallback((id: string) => (graph.hasNode(id) ? graph.dependantsOf(id) : []), [graph]);
};

export const useDirectDependenciesOf = () => {
	const graph = useRecoilValue(graphState);

	return useCallback((id: string) => (graph.hasNode(id) ? graph.directDependenciesOf(id) : []), [graph]);
};

export const useDependenciesOf = () => {
	const graph = useRecoilValue(graphState);

	return useCallback((id: string) => (graph.hasNode(id) ? graph.dependenciesOf(id) : []), [graph]);
};

export const useMultipleParents = () => {
	const graph = useRecoilValue(graphState);

	return useCallback((id: string) => graph.hasNode(id) && graph.directDependantsOf(id).length > 1, [graph]);
};

export const useEntryNodes = () => {
	const graph = useRecoilValue(graphState);

	return useCallback(() => graph.entryNodes(), [graph]);
};
