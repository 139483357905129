import styled, { createGlobalStyle, css, keyframes } from 'styled-components';

import { Colors } from './colors.styles';
import { FONT_FAMILIES, FONT_SIZES } from './fonts.styles';

export const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: inherit;
  }

  html,
  body {
    height: 100%;
    min-width: 1100px;
    margin: 0;
    font-family: ${FONT_FAMILIES.REGULAR};
    font-size: ${FONT_SIZES.BODY};
    background: ${Colors.background};
    box-sizing: border-box;
    user-select: none;
    scrollbar-color: ${Colors.secondary300} ${Colors.white};
    overflow-y: hidden;
  }

  input,
  select,
  textarea {
    font-family: inherit;
  }

	input {
	&:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${Colors.white} inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
	}

  button {
    font-family: inherit;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
  }

  button:disabled {
    cursor: default;
  }

  // prevent Chrome to apply auto highlighting
  button:focus, textarea:focus, input:focus {
    outline: none;
  }

  ::-webkit-scrollbar {
    background-color: ${Colors.white};
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    position: absolute;
    background: ${Colors.secondary300};
    border-radius: 3px;
  }
`;

export interface IIcon {
	width: string;
	height: string;
	fill: Colors;
	fillHover: Colors;
}

export const icon = css<IIcon>`
	width: ${({ width }) => width};
	height: ${({ height }) => height};

	path {
		fill: ${({ fill }) => fill};
		transition: 0.3s;
	}

	&:hover {
		path {
			fill: ${({ fillHover }) => fillHover};
		}
	}
`;

const spin = keyframes`
 100% { transform: rotate(360deg) }
`;

export const SpinnerCircle = styled.div`
	display: block;
	border-radius: 50%;
	animation: ${spin} 0.75s infinite linear;
`;
