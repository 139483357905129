import { useRecoilState, useRecoilValue } from 'recoil';

import { selectedNodeState, treeNodesState } from '../state/tree.state';
import type { ITreeNodesState } from '../types/taxonomy.types';

export const useNextClick = () => {
	const selectedNode = useRecoilValue(selectedNodeState);
	const [treeNodes, setTreeNodes] = useRecoilState(treeNodesState);

	return (targetNode: ITreeNodesState | undefined, directDeps: string[]) => {
		if (selectedNode?.id && targetNode?.chunk) {
			const currentNodesSet = new Set(directDeps.slice((targetNode.chunk - 1) * 25, targetNode.chunk * 25));
			const nextNodesSet = new Set(directDeps.slice(targetNode.chunk * 25, (targetNode.chunk + 1) * 25));

			if (nextNodesSet.size === 0) return;

			const updatedTreeNodes = treeNodes.map(node => ({
				...node,
				expanded: node.id === selectedNode.id ? true : node.expanded,
				show: nextNodesSet.has(node.id) ? true : currentNodesSet.has(node.id) ? false : node.show,
				chunk: node.id === selectedNode.id ? node.chunk + 1 : node.chunk,
			}));

			setTreeNodes(updatedTreeNodes);
		}
	};
};

export const usePreviousClick = () => {
	const selectedNode = useRecoilValue(selectedNodeState);
	const [treeNodes, setTreeNodes] = useRecoilState(treeNodesState);

	return (targetNode: ITreeNodesState | undefined, directDeps: string[]) => {
		if (selectedNode?.id && targetNode?.chunk) {
			if (targetNode.chunk === 1) return;

			const currentNodesSet = new Set(directDeps.slice((targetNode.chunk - 1) * 25, targetNode.chunk * 25));
			const previousNodesSet = new Set(directDeps.slice((targetNode.chunk - 2) * 25, (targetNode.chunk - 1) * 25));

			const updatedTreeNodes = treeNodes.map(node => ({
				...node,
				expanded: node.id === selectedNode.id ? true : node.expanded,
				show: previousNodesSet.has(node.id) ? true : currentNodesSet.has(node.id) ? false : node.show,
				chunk: node.id === selectedNode.id ? node.chunk - 1 : node.chunk,
			}));

			setTreeNodes(updatedTreeNodes);
		}
	};
};
