export const TAXONOMY_SELECTOR_TESTS_IDS = {
	TAXONOMY_SELECTOR: 'TAXONOMY_SELECTOR_CONTAINER',
	TITLE: 'TAXONOMY_SELECTOR_TITLE',
	SEPARATION_LINE: 'TAXONOMY_SELECTOR_SEPARATION_LINE',
	NAME: 'TAXONOMY_SELECTOR_NAME',
	TAXONOMY_SELECTOR_BUTTON: 'TAXONOMY_SELECTOR_BUTTON_ICON',
	ARROW_ICON: 'TAXONOMY_SELECTOR_ARROW_ICON',
	TREES_OPTIONS: 'TREES_OPTIONS_CONTAINER',
	TREES_OPTION: 'TREES_OPTIONS_OPTION',
	MANDATORY_OPTIONS: 'MANDATORY_OPTIONS_CONTAINER',
	RENAME: 'MANDATORY_OPTIONS_RENAME_HEADER_TAXONOMY',
	COPY: 'MANDATORY_OPTIONS_COPY_CURRENT_TAXONOMY',
	CREATE_NEW_TAXONOMY: 'MANDATORY_OPTIONS_CREATE_NEW_TAXONOMY',
	DOWNLOAD_BLANK_TAXONOMY_TEMPLATE: 'DOWNLOAD_BLANK_TAXONOMY_TEMPLATE',
	DELETE_TAXONOMY: 'MANDATORY_OPTIONS_DELETE_TAXONOMY',
	IMPORT_CSV: 'MANDATORY_OPTION_IMPORT_CSV',
	DROP_DOWN: 'DROP_DOWN_CONTAINER',
	DELETE_BUTTON: 'SELECTOR_DELETE_BUTTON',
};

export const TAXONOMY_SELECTOR = {
	TAXONOMY_EDITOR: 'Taxonomy Editor',
};
