import styled from 'styled-components';

export const PageContainer = styled.div<{ disabled: boolean }>`
	height: 100%;
	display: grid;
	grid-template:
		'header header header' 48px
		'tree-menu  main info' auto / minmax(280px, 15%) minmax(auto, calc(100vw - 560px)) minmax(280px, 15%);
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;
