import { EDITED_SECTION } from '../../state/infoBar.state';
import type { MODE_CONFIG } from '../../types/taxonomy.types';

export const INFO_BAR = {
	DESCRIPTION_TITLE: 'Description',
	EXAMPLES_TITLE: 'Examples',
	SYNONYMS_TITLE: 'Synonyms',
	SYNONYM: 'Synonym',
	ADD_DESCRIPTION: 'Add description',
	DESCRIPTION: 'description',
	EXAMPLES: 'examples',
	SYNONYMS: 'synonyms',
	SAVE: 'Save',
	DISCARD_CHANGES: 'Discard Changes',
};

export const INFO_BAR_TESTS_IDS = {
	DESCRIPTIONS: 'DESCRIPTIONS',
	INNER_TITLES: 'INNER_TITLES',
	HEADER_TITLE: 'HEADER_TITLE',
	EXAMPLES_TITLE: 'EXAMPLES_TITLE',
	SYNONYMS_TITLE: 'SYNONYMS_TITLE',
	DESCRIPTION_TITLE: 'DESCRIPTION_TITLE',
	EDIT_BUTTON: 'EDIT_BUTTON',
	EXAMPLE_TITLE: 'EXAMPLE_TITLE',
	TEXT_AREA: 'TEXT_AREA',
	EXAMPLE_CONTENT: 'EXAMPLE_CONTENT',
	EXAMPLES_COMPONENT: 'EXAMPLES_COMPONENT',
	EXAMPLES_CONTAINER: 'EXAMPLES_CONTAINER',
	EXAMPLE_CONTAINER: 'EXAMPLE_CONTAINER',
	INFO_BAR_EXAMPLES_SAVE: 'INFO_BAR_EXAMPLES_SAVE',
	INFO_BAR_EXAMPLES_DISCARD: 'INFO_BAR_EXAMPLES_DISCARD',
	INNER_TITLES_CONTAINER: 'INNER_TITLES_CONTAINER',
	HEADER_COMPONENT: 'HEADER_COMPONENT',
	INFO_BAR: 'INFO_BAR',
	INFO_BAR_DESCRIPTION: 'INFO_BAR_DESCRIPTION',
	INFO_BAR_DESCRIPTION_SAVE: 'INFO_BAR_DESCRIPTION_SAVE',
	INFO_BAR_DESCRIPTION_DISCARD: 'INFO_BAR_DESCRIPTION_DISCARD',
	INFO_BAR_EXAMPLES: 'INFO_BAR_EXAMPLES',
	INFO_BAR_SYNONYMS: 'INFO_BAR_SYNONYMS',
	SYNONYMS_COMPONENT: 'SYNONYMS_COMPONENT',
	SYNONYMS_CONTAINER: 'SYNONYMS_CONTAINER',
	SYNONYM_CONTAINER: 'SYNONYM_CONTAINER',
	SYNONYM_CONTENT: 'SYNONYM_CONTENT',
	INFO_BAR_SYNONYMS_SAVE: 'INFO_BAR_SYNONYMS_SAVE',
	INFO_BAR_SYNONYMS_DISCARD: 'INFO_BAR_SYNONYMS_DISCARD',
	SYNONYM_LABEL: 'SYNONYM_LABEL',
	INDEX: 2,
	ADD_NEW_ITEM: 'ADD_NEW_ITEM',
	ERROR_MESSAGE: 'ERROR_MESSAGE',
	SUGGESTIONS: 'SUGGESTIONS',
	SUGGESTION: 'SUGGESTION',
};

export const synonymsConfig: MODE_CONFIG = {
	title: INFO_BAR.SYNONYMS_TITLE,
	editMode: EDITED_SECTION.SYNONYMS,
	dataTestIdTitle: INFO_BAR_TESTS_IDS.SYNONYMS_TITLE,
	dataTestIdInnerTitles: INFO_BAR_TESTS_IDS.INNER_TITLES,
	dataTestIdComponent: INFO_BAR_TESTS_IDS.SYNONYMS_COMPONENT,
	dataTestIdItemsContainer: INFO_BAR_TESTS_IDS.SYNONYMS_CONTAINER,
	dataTestIdContainer: INFO_BAR_TESTS_IDS.SYNONYM_CONTAINER,
	dataTestIdLabel: INFO_BAR_TESTS_IDS.SYNONYM_LABEL,
	labelText: 'Synonym',
	dataTestIdTextArea: INFO_BAR_TESTS_IDS.SYNONYM_CONTENT,
	placeHolder: 'Add a synonym...',
	dataTestIdSaveBtn: INFO_BAR_TESTS_IDS.INFO_BAR_SYNONYMS_SAVE,
	dataTestIdDiscardBtn: INFO_BAR_TESTS_IDS.INFO_BAR_SYNONYMS_DISCARD,
};

export const examplesConfig: MODE_CONFIG = {
	title: INFO_BAR.EXAMPLES_TITLE,
	editMode: EDITED_SECTION.EXAMPLES,
	dataTestIdTitle: INFO_BAR_TESTS_IDS.EXAMPLES_TITLE,
	dataTestIdInnerTitles: INFO_BAR_TESTS_IDS.INNER_TITLES_CONTAINER,
	dataTestIdComponent: INFO_BAR_TESTS_IDS.EXAMPLES_COMPONENT,
	dataTestIdItemsContainer: INFO_BAR_TESTS_IDS.EXAMPLES_CONTAINER,
	dataTestIdContainer: INFO_BAR_TESTS_IDS.EXAMPLE_CONTAINER,
	dataTestIdLabel: INFO_BAR_TESTS_IDS.EXAMPLE_TITLE,
	labelText: 'Example',
	dataTestIdTextArea: INFO_BAR_TESTS_IDS.EXAMPLE_CONTENT,
	placeHolder: 'Add an example',
	dataTestIdSaveBtn: INFO_BAR_TESTS_IDS.INFO_BAR_EXAMPLES_SAVE,
	dataTestIdDiscardBtn: INFO_BAR_TESTS_IDS.INFO_BAR_EXAMPLES_DISCARD,
};
