import styled from 'styled-components';

export const InputField = styled.input`
	height: 40px;
	width: 100%;
	z-index: 0;
	border-radius: 4px;
	border: none;
	padding: 10px 14px;
`;
