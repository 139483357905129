import { useEffect } from 'react';
import { useUndo } from 'reaflow';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import * as Icon from '../../../../assets/icons/header/actionBar';
import { useOnUndoRedo } from '../../../../services/tree.services';
import { selectedTaxonomyState } from '../../../../state/global.state';
import { canUndoState, clearHistoryState, connectionModeState } from '../../../../state/tree.state';
import { Colors } from '../../../../styles/colors.styles';
import { ACTION_BAR_TESTS_IDS } from '../../actionBar.constants';
import * as Style from './undoRedo.styles';

export const UndoRedo = () => {
	const resetCanUndo = useResetRecoilState(canUndoState);
	const resetClearHistory = useResetRecoilState(clearHistoryState);
	const setClearHistory = useSetRecoilState(clearHistoryState);
	const setCanUndo = useSetRecoilState(canUndoState);
	const connectionMode = useRecoilValue(connectionModeState);
	const { nodes, edges } = useRecoilValue(selectedTaxonomyState).tree;

	const onUndoRedo = useOnUndoRedo();

	const { undo, redo, canUndo, canRedo, clear } = useUndo({
		nodes,
		edges,
		onUndoRedo,
	});

	useEffect(() => {
		setClearHistory(() => clear);
		return () => resetClearHistory();
	}, [clear, resetClearHistory, setClearHistory]);

	useEffect(() => {
		setCanUndo(canUndo);
		return () => resetCanUndo();
	}, [canUndo, resetCanUndo, setCanUndo]);

	return (
		<Style.ArrowsIcons>
			<Style.ArrowButton data-testid={ACTION_BAR_TESTS_IDS.UNDO} onClick={undo} disabled={!canUndo || connectionMode.active}>
				<Style.UndoIcon width="24px" height="24px" fill={Colors.secondary100} fillHover={Colors.primary500}>
					<Icon.Undo />
				</Style.UndoIcon>
			</Style.ArrowButton>
			<Style.ArrowButton data-testid={ACTION_BAR_TESTS_IDS.REDO} onClick={redo} disabled={!canRedo || connectionMode.active}>
				<Style.RedoIcon width="24px" height="24px" fill={Colors.secondary100} fillHover={Colors.primary500}>
					<Icon.Redo />
				</Style.RedoIcon>
			</Style.ArrowButton>
		</Style.ArrowsIcons>
	);
};
