export const ACTION_BAR_TESTS_IDS = {
	EXPORT_MENU: 'EXPORT_MENU',
	EXPORT_TO_JSON: 'EXPORT_TO_JSON',
	EXPORT_TO_CSV: 'EXPORT_TO_CSV',
	SEPARATION_LINE_LONG: 'SEPARATION_LINE_LONG',
	ACTION_BAR: 'ACTION_BAR_CONTAINER',
	UNDO: 'UNDO',
	REDO: 'REDO',
	INFO_ICON: 'INFO_ICON',
	INFO_BUTTON: 'INFO_BUTTON',
	SAVE_BUTTON: 'SAVE_BUTTON',
};
