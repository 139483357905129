import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { icon } from '../../../../styles/global.styles';

export const ArrowsIcons = styled.div`
	display: flex;
	gap: 16px;
`;

export const ArrowButton = styled.button`
	display: flex;
`;

export const UndoIcon = styled.svg`
	${icon};

	${ArrowButton}:disabled & {
		path {
			fill: ${Colors.secondary500};
		}
	}
	${ArrowButton}:active & {
		path {
			fill: ${Colors.secondary500};
		}
	}
`;

export const RedoIcon = styled.svg`
	${icon};

	${ArrowButton}:disabled & {
		path {
			fill: ${Colors.secondary500};
		}
	}
	${ArrowButton}:active & {
		path {
			fill: ${Colors.secondary500};
		}
	}
`;
