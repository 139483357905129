import styled from 'styled-components';

import { Colors } from '../../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../../styles/fonts.styles';

export const MultiTagsInputStyle = styled.div`
	width: 100%;

	.rti--container {
		min-height: 45px;
		align-content: center;
		border-radius: 4px;
		border: none;
		padding: 10px 12px;
		--rti-bg: ${Colors.white};
		--rti-border: 1px solid ${Colors.primary500};
		--rti-radius: 7px;
		--rti-s: 8px;
		--rti-tag: ${Colors.primary500};
		--rti-tag-remove: ${Colors.white};
	}

	.rti--tag {
		position: relative;
		border-radius: 20px;
		color: ${Colors.primary50};
		font-size: ${FONT_SIZES.S};
		padding: 3px 24px 3px 9px;
		z-index: 2;

		&:hover {
			background-color: ${Colors.primary800};

			& button {
				color: ${Colors.white};
				border-left: 0.5px solid ${Colors.primary50};
				background-color: ${Colors.primary800};
			}
		}

		& span {
			padding-right: 5px;
		}

		& button {
			position: absolute;
			top: 0;
			right: 0;
			justify-content: center;
			border-radius: 0 20px 20px 0;
			height: 100%;
			padding-left: 6px;
			padding-top: 2px;
			font-size: ${FONT_SIZES.XXS};
			color: ${Colors.primary50};
			border-left: 0.5px solid ${Colors.primary100};
			cursor: pointer;
		}

		&.invalid {
			background-color: ${Colors.alert500};
			color: ${Colors.primary50};
			cursor: pointer;

			& button {
				color: ${Colors.alert100};
				border-left: 0.5px solid ${Colors.alert50};
			}

			&:hover {
				background-color: ${Colors.alert100};
				color: ${Colors.white};

				& button {
					background-color: ${Colors.alert100};
					color: ${Colors.white};
				}
			}
		}

		&.warning {
			background-color: ${Colors.selected500};
			color: ${Colors.secondary50};
			cursor: pointer;

			& button {
				color: ${Colors.selected100};
				border-left: 0.5px solid ${Colors.selected50};
			}

			&:hover {
				background-color: ${Colors.selected300};
				color: ${Colors.white};

				& button {
					background-color: ${Colors.selected300};
					color: ${Colors.white};
					border-left: 0.5px solid ${Colors.selected100};
				}
			}
		}
	}

	.rti--input {
		flex: 1;
		width: fit-content;
		line-height: 1;
		font-size: ${FONT_SIZES.S};
	}
`;
