import { memo, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { isDefaultTaxonomyState } from '../../state/global.state';
import { treeMenuOpenState } from '../../state/treeMenuBar.state';
import { MenuButton, Search, SearchResults, TreeMenu } from './atomComponents';
import { TREE_MENU_TESTS_IDS } from './treeMenuBar.constants';
import * as Style from './treeMenuBar.styles';

const OpenedBar = ({ toggleMenu }: { toggleMenu: () => void }) => (
	<>
		<Search toggleMenu={toggleMenu} />
		<TreeMenu />
		<SearchResults />
	</>
);

const TreeMenuBarBody = () => {
	const [treeMenuOpen, setTreeMenuOpen] = useRecoilState(treeMenuOpenState);
	const isDefaultTaxonomy = useRecoilValue(isDefaultTaxonomyState);

	useEffect(() => {
		setTreeMenuOpen(!isDefaultTaxonomy);
	}, [isDefaultTaxonomy, setTreeMenuOpen]);

	const toggleMenu = () => {
		if (!isDefaultTaxonomy) {
			setTreeMenuOpen(currentState => !currentState);
		}
	};

	return (
		<Style.TreeMenuBar data-testid={TREE_MENU_TESTS_IDS.TREE_MENU} treeMenuOpen={treeMenuOpen}>
			{treeMenuOpen ? <OpenedBar toggleMenu={toggleMenu} /> : <MenuButton toggleMenu={toggleMenu} />}
		</Style.TreeMenuBar>
	);
};

export const TreeMenuBar = memo(TreeMenuBarBody);
