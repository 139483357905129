import styled from 'styled-components';

import { MODAL_TESTS_IDS } from '../../../../../constants/modals.constants';
import { Colors } from '../../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../../styles/fonts.styles';

const Root = styled.button<{ isWarning: boolean }>`
	font-weight: 500;
	font-size: ${FONT_SIZES.BODY};
	line-height: 16px;
	padding: 10px 8px;
	color: ${({ isWarning }) => (isWarning ? Colors.alert500 : Colors.primary500)};
	transition: background-color 0.3s;

	&:hover {
		background-color: ${({ isWarning }) => (isWarning ? Colors.alert50 : Colors.primary50)};
	}

	&:disabled {
		background-color: ${Colors.white};
		cursor: default;
		color: ${Colors.primary100};
	}

	&:active {
		background-color: ${({ isWarning }) => (isWarning ? Colors.alert100 : Colors.primary100)};
	}
`;

interface ConfirmButtonProps {
	isValid: boolean;
	text: string;
	onSubmit?: () => void;
}
export const ConfirmButton = ({ text, isValid, onSubmit }: ConfirmButtonProps) => (
	<Root
		type="submit"
		data-testid={MODAL_TESTS_IDS.CONFIRM_MODAL}
		disabled={!isValid}
		onClick={onSubmit}
		isWarning={text.toLocaleUpperCase().includes('DELETE')}
	>
		{text || 'SAVE'}
	</Root>
);
