import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Checked } from '../../../../assets/icons/tree';
import { useConnectNodes, useIsButtonDisabled } from '../../../../services/tree.services';
import { clearSelectionsState, connectionModeState } from '../../../../state/tree.state';
import { treeMenuOpenState } from '../../../../state/treeMenuBar.state';
import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { TREE_TESTS_IDS } from '../../tree.constants';

export const Root = styled.section<{ visible: boolean; treeMenuOpen: boolean }>`
	position: absolute;
	top: 0;
	left: ${({ treeMenuOpen }) => (treeMenuOpen ? '40%' : '50%')};
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 16px;
	background-color: ${Colors.white};
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
	border-radius: 0px 0px 6px 6px;
	opacity: ${({ visible }) => (visible ? 1 : 0)};
	pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
	transition: opacity 0.3s;
`;

export const Title = styled.h3`
	font-size: ${FONT_SIZES.H6};
	line-height: 18px;
	font-weight: 500;
	color: ${Colors.secondary500};
	margin-top: 0;
	margin-bottom: 8px;
	text-align: center;
`;

export const Text = styled.p`
	font-size: ${FONT_SIZES.H5};
	line-height: 19px;
	font-weight: 700;
	color: ${Colors.secondary900};
	margin-top: 0;
	margin-bottom: 0;
	text-align: center;
`;

export const Label = styled.label<{ disabled: boolean }>`
	margin-top: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const Input = styled.input`
	position: absolute;
	opacity: 0;
	height: 0;
	width: 0;
`;

export const Checkbox = styled.span<{ checked: boolean; disabled: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	border: ${({ disabled }) => (disabled ? `1px solid ${Colors.primary100}` : `1px solid ${Colors.primary500}`)};
	width: 16px;
	height: 16px;
	overflow: hidden;
	border-radius: 3px;
	background-color: ${({ checked }) => (checked ? Colors.primary500 : Colors.white)};
	transition-property: background-color, border;
	transition-duration: 0.3s;
`;

export const CheckboxText = styled.p<{ checked: boolean }>`
	font-size: ${FONT_SIZES.H6};
	line-height: 16px;
	color: ${({ checked }) => (checked ? Colors.secondary800 : Colors.secondary300)};
	margin-top: 0;
	margin-bottom: 0;
	transition: color 0.3s;
`;

export const CheckedIcon = styled(Checked)`
	pointer-events: none;
`;

export const Button = styled.button`
	width: 90px;
	height: 30px;
	border-radius: 4px;
	color: ${Colors.white};
	background-color: ${Colors.primary500};
	font-weight: 500;
	font-size: ${FONT_SIZES.S};
	line-height: 15px;
	margin-top: 8px;
	transition-property: background-color, color;
	transition-duration: 0.3s;

	&:hover {
		background-color: ${Colors.primary300};
	}

	&:active {
		background-color: ${Colors.primary800};
		color: ${Colors.primary100};
	}

	&:disabled {
		background-color: ${Colors.primary100};
		color: ${Colors.primary50};
	}
`;

export const ConnectMenu = () => {
	const [connectionMode, setConnectionMode] = useRecoilState(connectionModeState);
	const treeMenuOpen = useRecoilValue(treeMenuOpenState);
	const clearSelections = useRecoilValue(clearSelectionsState);

	const connectNodes = useConnectNodes();
	const resetConnectionMode = useResetRecoilState(connectionModeState);
	const isButtonDisabled = useIsButtonDisabled();

	useEffect(() => {
		if (connectionMode.children.length !== 1 && connectionMode.activeChildren) {
			setConnectionMode(prevState => ({ ...prevState, setIsPrimary: false }));
		}
	}, [connectionMode.activeChildren, connectionMode.children, setConnectionMode]);

	const handleChange = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
		if (connectionMode.children.length === 1) {
			setConnectionMode(prevState => ({ ...prevState, setIsPrimary: checked }));
		}
	};

	const handleConnect = () => {
		if (connectionMode) {
			if (connectionMode.children.find(child => child === connectionMode.parent)) {
				clearSelections?.();
				return resetConnectionMode();
			}
			const newEdges = connectionMode.children.map(child => ({
				from: connectionMode.parent,
				to: child,
				id: uuidv4(),
			}));
			return connectNodes(connectionMode, newEdges);
		}

		connectNodes(connectionMode);
	};

	return (
		<Root visible={connectionMode.active} treeMenuOpen={treeMenuOpen} data-testid={TREE_TESTS_IDS.CONNECT_MENU}>
			<Title>Connect Nodes to Additional Parent</Title>
			<Text>{connectionMode.activeParent ? 'Select the parent.' : 'Select node or nodes you want to add to a parent.'}</Text>
			{connectionMode.children.length < 2 && connectionMode.activeChildren && (
				<Label disabled={connectionMode.children.length !== 1} data-testid={TREE_TESTS_IDS.PRIMARY_CHECKBOX}>
					<Input type="checkbox" checked={connectionMode.setIsPrimary} onChange={handleChange} />
					<Checkbox checked={connectionMode.setIsPrimary} disabled={connectionMode.children.length !== 1}>
						{connectionMode.setIsPrimary && <CheckedIcon />}
					</Checkbox>
					<CheckboxText checked={connectionMode.setIsPrimary}>Set as primary</CheckboxText>
				</Label>
			)}
			<Button disabled={isButtonDisabled()} onClick={handleConnect} data-testid={TREE_TESTS_IDS.CONNECT_BUTTON}>
				{connectionMode.activeParent ? 'Finish' : 'Next'}
			</Button>
		</Root>
	);
};
