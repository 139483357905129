import { isEqual } from 'lodash';
import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { FieldError, useFormContext, useFormState } from 'react-hook-form';
import { TagsInput } from 'react-tag-input-component';

import { ERROR_TYPE, GENERAL_ERRORS } from '../../../../../constants/errors.constants';
import { MODAL_TESTS_IDS } from '../../../../../constants/modals.constants';
import { isError, isWarning } from '../../../../../services/validation.services';
import { MultiTagsInputStyle } from './MultiTagsInput.styles';

interface Separator {
	code: KeyboardEventInit['code'];
	key: KeyboardEventInit['key'];
	keyCode: KeyboardEventInit['keyCode'];
	which: UIEventInit['which'];
}
const separators: Separator[] = [
	{ code: 'Enter', key: 'Enter', keyCode: 13, which: 13 },
	{ code: ';', key: ';', keyCode: 186, which: 186 },
];

const inputSelector = (name: string, element?: HTMLElement | null) =>
	element?.querySelector<HTMLInputElement>(`.rti--container .rti--input[name="${name}"]`);

const triggerSeparators = (element: HTMLElement, { code, key, keyCode, which }: Separator) => {
	const keyboardEvent = new KeyboardEvent('keydown', {
		altKey: false,
		bubbles: true,
		cancelable: true,
		charCode: 0,
		code,
		composed: true,
		ctrlKey: false,
		detail: 0,
		isComposing: false,
		key,
		keyCode,
		location: 0,
		metaKey: false,
		repeat: false,
		shiftKey: false,
		which,
	});

	element.dispatchEvent(keyboardEvent);
};

interface MultiTagsInputProps {
	autocomplete?: string;
	autoFocus?: boolean;
	name: string;
	placeholder?: string;
}
export const MultiTagsInput = ({ autocomplete = 'off', autoFocus, name, placeholder }: MultiTagsInputProps) => {
	const { control, register, setError, trigger } = useFormContext();
	const { errors } = useFormState({ control });
	const [tags, setTags] = useState<string[]>([]);
	const [existingError, setExistingError] = useState(false);
	const multiTagsRef = createRef<HTMLDivElement>();
	const prevErrors = useRef();

	const { onChange } = register(name, { value: tags });

	useEffect(() => {
		const input = inputSelector(name, multiTagsRef.current);

		const handleEditLastTag = (e: KeyboardEvent) => {
			if (e.key === 'Backspace') {
				if (existingError) {
					setExistingError(false);
					trigger();
				}

				if (!input?.value) {
					handleOnChange(tags, true);
				}
			}

			if (tags.length === 0 && input?.value) {
				onChange({ target: { value: input.value, name } });
			}
		};

		input?.addEventListener('keyup', handleEditLastTag);

		return () => input?.removeEventListener('keyup', handleEditLastTag);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [multiTagsRef, name]);

	useEffect(() => {
		const input = inputSelector(name, multiTagsRef.current);
		if (input) {
			input.setAttribute('autocomplete', String(autocomplete));
			autoFocus && inputSelector(name, multiTagsRef.current)?.focus();
		}
	}, [autoFocus, autocomplete, multiTagsRef, name]);

	useEffect(() => {
		if (Array.isArray(errors?.[name]) && !isEqual(prevErrors.current, errors[name])) {
			errors[name].forEach(({ message, type }: FieldError, index: number) => {
				const tag = document.querySelector<HTMLElement>(`.rti--container span.rti--tag:nth-of-type(${1 + index}n)`);

				if (isError(type)) {
					tag?.classList.add('invalid');
				}

				if (isWarning(type)) {
					tag?.classList.add('warning');
				}

				const handleOnClick = () => {
					const err = { ...errors[name], type, message: `${tags[index]} - ${message}` };
					setError(name, err, { shouldFocus: false });
				};

				tag?.removeEventListener('click', handleOnClick);
				tag?.addEventListener('click', handleOnClick);
			});
		}

		prevErrors.current = errors[name];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errors?.[name], name, tags, setError]);

	useEffect(() => {
		const input = inputSelector(name, multiTagsRef.current);

		if (input?.value && input.value.includes(';')) {
			const newTags = input.value.split(';').map(tag => tag.trim());

			newTags.forEach(tag => {
				queueMicrotask(() => {
					flushSync(() => {
						input.value = tag;
						triggerSeparators(input, separators[0]);
					});
				});
			});
		}
	}, [multiTagsRef, name]);

	const handleBackspace = useCallback(
		(tag: string) => {
			if (tag) {
				const input = inputSelector(name, multiTagsRef.current);
				if (input && !input.value) {
					input.value = tag;
				}
			}
		},
		[multiTagsRef, name],
	);

	const handleOnChange = (newTags: string[], backspacePressed?: boolean) => {
		flushSync(() => {
			setTags(newTags);
			onChange({ target: { value: newTags, name } });
		});
		(backspacePressed || tags.length > newTags.length) && handleBackspace(tags[tags.length - 1]);
	};

	const handleOnExisting = (tag: string) => {
		setExistingError(true);
		setError(
			name,
			{ type: ERROR_TYPE.duplicate, message: `${tag} - ${GENERAL_ERRORS.MULTITAGS_EXISTS_IN_LIST}` },
			{ shouldFocus: false },
		);
	};

	const handleOnBlur = () => {
		const input = inputSelector(name, multiTagsRef.current);
		if (input?.value) {
			if (input.value.includes(';')) {
				const newTags = input.value.split(';').map(tag => tag.trim());

				return newTags.forEach(tag => {
					flushSync(() => {
						input.value = tag;
						triggerSeparators(input, separators[1]);
					});
				});
			}
			triggerSeparators(input, separators[0]);
		}
	};

	const handleOnRemoved = () => {
		const input = inputSelector(name, multiTagsRef.current);
		if (input) {
			setTimeout(() => {
				input.value = '';
				input.focus();
			}, 0);
		}
	};

	return (
		<MultiTagsInputStyle data-testid={MODAL_TESTS_IDS.MODAL_MULTI_TAGS_INPUT} ref={multiTagsRef}>
			<TagsInput
				name={name}
				onChange={handleOnChange}
				onExisting={handleOnExisting}
				onBlur={handleOnBlur}
				onRemoved={handleOnRemoved}
				placeHolder={tags.length === 0 ? placeholder : ''}
				seprators={separators.map(({ code }) => code as string)}
				value={tags}
			/>
		</MultiTagsInputStyle>
	);
};
