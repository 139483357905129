import { memo } from 'react';
import { useRecoilState } from 'recoil';

import { Edit } from '../../../../assets/icons/infoBar';
import { infoBarModeState } from '../../../../state/infoBar.state';
import { Colors } from '../../../../styles/colors.styles';
import { INFO_BAR_TESTS_IDS } from '../../infoBar.constants';
import * as Style from './editButton.styles';

const EditButtonBody = ({ editMode }: { editMode: typeof infoBarModeState['__tag'][0] }) => {
	const [infoBarMode, setInfoBarModeState] = useRecoilState(infoBarModeState);

	if (infoBarMode === editMode) {
		return null;
	}

	return (
		<Style.EditButton
			data-testid={INFO_BAR_TESTS_IDS.EDIT_BUTTON}
			disabled={infoBarMode !== undefined}
			onClick={() => setInfoBarModeState(prevState => (prevState ? undefined : editMode))}
		>
			<Style.EditIcon width="15px" height="15px" fill={Colors.primary500} fillHover={Colors.primary300}>
				<Edit />
			</Style.EditIcon>
		</Style.EditButton>
	);
};

export const EditButton = memo(EditButtonBody);
