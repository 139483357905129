import difference from 'lodash/difference';
import { useState } from 'react';
import { UndoRedoEvent } from 'reaflow';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import { LOG_STATUS } from '../constants/history.constants';
import {
	isSelectedDraftState,
	selectedTaxonomyCopyState,
	selectedTaxonomyState,
	taxonomiesSelector,
	taxonomyIdToDeleteState,
	taxonomyToImportState,
	tempFileNameState,
} from '../state/global.state';
import { pendingChangesCopyState, pendingChangesState, tempLogsState, tempPendingChangesState } from '../state/history.state';
import {
	clearHistoryState,
	clearSelectionsState,
	connectionModeState,
	isRootNode,
	nodeActionsOpenState,
	pathActionsOpenState,
	selectedEdgeState,
	selectedNodeState,
	treeNodesState,
} from '../state/tree.state';
import { userState } from '../state/user.state';
import type {
	IConnectionMode,
	ITaxonomy,
	ITaxonomyEdge,
	ITaxonomyNode,
	ITaxonomyTree,
	ITreeLog,
	ITreeNodesState,
} from '../types/taxonomy.types';
import { useDependantsOf, useDirectDependantsOf } from './graph.services';
import { useSelectTaxonomy } from './header.services';
import { myTaxonomies } from './myTaxonomies.services';
import { useNodeLevels } from './node.services';

export const useChangeTree = () => {
	const [{ tree }, setSelectedTaxonomy] = useRecoilState(selectedTaxonomyState);

	return () =>
		setSelectedTaxonomy(prevState => ({ ...prevState, tree: { ...prevState.tree, nodes: tree.nodes, edges: tree.edges } }));
};

export const useResetActionsOpen = () => {
	const resetNodeActionsOpen = useResetRecoilState(nodeActionsOpenState);
	const resetPathActionsOpen = useResetRecoilState(pathActionsOpenState);

	return () => {
		resetNodeActionsOpen();
		resetPathActionsOpen();
	};
};

export const useResetSelections = () => {
	const resetSelectedNode = useResetRecoilState(selectedNodeState);
	const resetSelectedEdge = useResetRecoilState(selectedEdgeState);

	const resetActionsOpen = useResetActionsOpen();

	return () => {
		resetSelectedNode();
		resetSelectedEdge();
		resetActionsOpen();
	};
};

export const useAddTaxonomy = () => {
	const setTaxonomies = useSetRecoilState(taxonomiesSelector);

	const selectTaxonomy = useSelectTaxonomy();

	return (taxonomy: ITaxonomy) => {
		selectTaxonomy(taxonomy);
		setTaxonomies(prevState => [...prevState, taxonomy]);
	};
};

export const useCreateLog = () => {
	const user = useRecoilValue(userState);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (type: string, data: any, changeId?: string) => {
		return {
			id: changeId || uuidv4(),
			type,
			data,
			editor: user.nickname || 'Anonymous',
			date: new Date(),
			status: LOG_STATUS.NEW,
		};
	};
};

const useHasLink = () => {
	const { edges } = useRecoilValue(selectedTaxonomyState).tree;

	return (from: string, to: string) =>
		edges.some(edge => (edge.from === from && edge.to === to) || (edge.to === from && edge.from === to));
};

const useDetectCircular = () => {
	const dependantsOf = useDependantsOf();

	return (from: string, to: string) => {
		const dependants = dependantsOf(to);
		return dependants.includes(from);
	};
};

export const useTreeNodeLinkCheck = () => {
	const hasLink = useHasLink();
	const detectCircular = useDetectCircular();

	return (from: string, to: string) => {
		if (from === to) return false;
		if (hasLink(from, to)) return false;
		if (detectCircular(from, to)) return false;

		return true;
	};
};

export const useOnUndoRedo = () => {
	const setSelectedNode = useSetRecoilState(selectedNodeState);
	const { tree } = useRecoilValue(selectedTaxonomyState);
	const treeNodes = useRecoilValue(treeNodesState);

	const createLog = useCreateLog();
	const resetSelections = useResetSelections();
	const saveTaxonomy = useSaveTaxonomy();

	return async (state: UndoRedoEvent) => {
		resetSelections();
		if (state.type !== 'clear') {
			const nodes = state.nodes as ITaxonomyNode[];

			const nodesToShow = difference(nodes, tree.nodes);
			const mappedTreeNodes = new Map(treeNodes.map(node => [node.id, node]));

			const updatedTree = {
				...tree,
				nodes: nodes,
				edges: state.edges as ITaxonomyEdge[],
				taxonomyName: nodes.find(node => isRootNode(node))?.text,
			};

			const updatedTreeNodes = nodes.map(({ id }) => ({
				id,
				chunk: 1,
				expanded: mappedTreeNodes.get(id)?.expanded ?? false,
				show: mappedTreeNodes.get(id)?.show ?? true,
			}));

			const log = createLog(state.type, {});

			await saveTaxonomy(updatedTree, log, updatedTreeNodes);
			setSelectedNode(prevState => nodesToShow[0] || prevState);
		}
	};
};

export const useIsButtonDisabled = () => {
	const connectionMode = useRecoilValue(connectionModeState);

	return () => {
		if (connectionMode.activeChildren) return connectionMode.children.length === 0;
		if (connectionMode.activeParent) return !connectionMode.parent;

		return true;
	};
};

export const useSaveTaxonomy = () => {
	const isSelectedDraft = useRecoilValue(isSelectedDraftState);
	const setPendingChanges = useSetRecoilState(pendingChangesState);
	const setTaxonomies = useSetRecoilState(taxonomiesSelector);
	const [tempLogs, setTempLogs] = useRecoilState(tempLogsState);
	const [tempPendingChanges, setTempPendingChanges] = useRecoilState(tempPendingChangesState);
	const setTreeNodes = useSetRecoilState(treeNodesState);
	const [selectedTaxonomy, setSelectedTaxonomy] = useRecoilState(selectedTaxonomyState);
	const user = useRecoilValue(userState);
	const [tempChanges, setTempChanges] = useState<ITreeLog[]>([]);

	const changeId = tempPendingChanges.length && tempPendingChanges[0]?.id;

	return async (taxonomy: ITaxonomyTree, log: ITreeLog | ITreeLog[], treeNodes?: ITreeNodesState[]) => {
		let logs = taxonomy.logs;

		if ((<ITreeLog>log).data) {
			const singleLog = <ITreeLog>log;

			switch (singleLog.type) {
				case 'undo': {
					setTempLogs(prevState => [logs[0], ...prevState]);
					logs = logs.slice(1);

					setPendingChanges(prev =>
						prev.map(pendingChange =>
							pendingChange.data.find(change => change.id === changeId)
								? {
										...pendingChange,
										data: pendingChange.data.map(change =>
											change.id === changeId ? { ...change, status: LOG_STATUS.NEW } : change,
										),
								  }
								: pendingChange,
						),
					);
					const tempChange = tempPendingChanges[0];
					setTempChanges(prevState => [tempChange, ...prevState]);
					setTempPendingChanges(prevState => prevState.slice(1));
					break;
				}
				case 'redo': {
					const redoLog = tempLogs[0];
					setTempLogs(prevState => prevState.slice(1));
					logs = [redoLog, ...logs];

					setTempPendingChanges(prevState => (tempChanges ? [tempChanges[0], ...prevState] : prevState));

					setPendingChanges(prevState =>
						prevState.map(pendingChange =>
							pendingChange.data.find(change => change.id === tempChanges[0].id)
								? {
										...pendingChange,
										data: pendingChange.data.map(change =>
											change.id === tempChanges[0].id ? { ...change, status: tempChanges[0].status } : change,
										),
								  }
								: pendingChange,
						),
					);

					setTempChanges(prevState => prevState.slice(1));

					break;
				}
				default: {
					logs = [singleLog, ...logs];
					break;
				}
			}
		} else {
			const multipleLogs = <ITreeLog[]>log;
			logs = [...multipleLogs, ...logs];
		}

		const treeWithLogs = { ...taxonomy, logs };
		const taxonomyToSave = isSelectedDraft
			? { ...selectedTaxonomy, tree: treeWithLogs }
			: {
					...selectedTaxonomy,
					taxonomyId: uuidv4(),
					originId: selectedTaxonomy.taxonomyId,
					tree: treeWithLogs,
					isDraft: true,
					owner: user.nickname,
					versionId: selectedTaxonomy.versionId,
			  };

		setTaxonomies(await myTaxonomies.update(taxonomyToSave, selectedTaxonomy.taxonomyId));
		setSelectedTaxonomy(taxonomyToSave);

		if (treeNodes) setTimeout(() => setTreeNodes(treeNodes), 0);
	};
};

export const useConnectNodes = () => {
	const clearSelections = useRecoilValue(clearSelectionsState);
	const setConnectionMode = useSetRecoilState(connectionModeState);
	const resetConnectionMode = useResetRecoilState(connectionModeState);
	const setSelectedEdge = useSetRecoilState(selectedEdgeState);
	const [selectedNode, setSelectedNode] = useRecoilState(selectedNodeState);
	const { tree } = useRecoilValue(selectedTaxonomyState);
	const treeNodes = useRecoilValue(treeNodesState);

	const createLog = useCreateLog();
	const nodeLevels = useNodeLevels();
	const saveTaxonomy = useSaveTaxonomy();
	const treeNodeLinkCheck = useTreeNodeLinkCheck();
	const dependantsOf = useDependantsOf();
	const directDependantsOf = useDirectDependantsOf();

	return async (connectionMode: IConnectionMode, newEdges?: ITaxonomyEdge[]) => {
		if (connectionMode.parent && newEdges) {
			newEdges.filter(edge => edge.to && edge.from && treeNodeLinkCheck(edge.to, edge.from));

			if (newEdges.length === 0) return resetConnectionMode();

			let updatedTree = { ...tree, edges: [...tree.edges, ...newEdges] };

			const parent = tree.nodes.find(node => node.id === connectionMode.parent);
			const levels = nodeLevels(connectionMode.parent) || '0';
			const children = connectionMode.children.map(child => {
				return tree.nodes.find(node => node.id === child);
			});
			const log = createLog('nodesConnect', { parent, children, levels, connectionMode, newEdges });

			if (connectionMode.setIsPrimary) {
				const newEdge = newEdges[0];

				const updatedEdges = updatedTree.edges.map(edge => {
					if (edge.id === newEdge.id) return { ...edge, primary: true };
					return edge;
				});

				updatedTree = { ...updatedTree, edges: updatedEdges };
				setSelectedEdge(newEdges[0]);
				setSelectedNode(children[0]);
			}

			const nodesToExpand = new Set([...connectionMode.children, connectionMode.children.map(child => dependantsOf(child))]);
			const nodesToShow = new Set([
				...nodesToExpand,
				connectionMode.parent,
				connectionMode.children.map(child => directDependantsOf(child)),
			]);

			const updatedTreeNodesWithShow = treeNodes.map(node => ({
				...node,
				expanded: nodesToExpand.has(node.id) || node.expanded,
				show: nodesToShow.has(node.id) || node.show,
			}));

			clearSelections?.();
			resetConnectionMode();

			await saveTaxonomy(updatedTree, log, updatedTreeNodesWithShow);

			setSelectedNode(selectedNode || treeNodes.find(node => node.id === parent?.id));
		}

		if (connectionMode.children.length !== 0 && !connectionMode.activeParent) {
			return setConnectionMode(prevState => ({ ...prevState, activeParent: true, activeChildren: false }));
		}
	};
};

export const useDeleteTaxonomy = () => {
	const [taxonomies, setTaxonomies] = useRecoilState(taxonomiesSelector);
	const setTaxonomyIdToDelete = useSetRecoilState(taxonomyIdToDeleteState);
	const { taxonomyId } = useRecoilValue(selectedTaxonomyState);
	const resetSelectedTaxonomy = useResetRecoilState(selectedTaxonomyState);

	return async (id: string, originId?: string) => {
		const isDraft = taxonomies.find(taxonomy => taxonomy.taxonomyId === id)?.isDraft;
		if (!isDraft) {
			setTaxonomyIdToDelete(id);
		}

		setTaxonomies(await myTaxonomies.delete(id));

		if (originId ? originId === id : taxonomyId === id) {
			resetSelectedTaxonomy();
		}
	};
};

export const useRenameTaxonomy = () => {
	const clearHistory = useRecoilValue(clearHistoryState);
	const [{ taxonomyId, tree }, setSelectedTaxonomy] = useRecoilState(selectedTaxonomyState);
	const setTaxonomies = useSetRecoilState(taxonomiesSelector);

	return async (name: string) => {
		setSelectedTaxonomy(({ tree, ...prevState }) => ({
			...prevState,
			taxonomyName: name,
			tree: { ...tree, nodes: tree.nodes.map(node => ({ ...node, text: isRootNode(node) ? name : node.text })) },
		}));

		setTaxonomies(await myTaxonomies.rename(taxonomyId, name));
		clearHistory?.(tree.nodes, tree.edges);
	};
};

export const useRenameImportedTaxonomy = () => {
	const [taxonomyToImport, setTaxonomyToImport] = useRecoilState(taxonomyToImportState);
	const resetTaxonomyToImport = useResetRecoilState(taxonomyToImportState);
	const setTempFileName = useSetRecoilState(tempFileNameState);

	return (taxonomy: Record<string, string>) => {
		const importedTaxonomy = taxonomyToImport;

		resetTaxonomyToImport();
		setTempFileName(taxonomy.name);

		setTaxonomyToImport({
			...importedTaxonomy,
			taxonomyName: taxonomy.name,
			tree: {
				...importedTaxonomy.tree,
				nodes: importedTaxonomy.tree.nodes.map(node => ({ ...node, text: isRootNode(node) ? taxonomy.name : node.text })),
			},
		});
	};
};

export const useResetTaxonomyChanges = () => {
	const selectedTaxonomyCopy = useRecoilValue(selectedTaxonomyCopyState);
	const setSelectedTaxonomy = useSetRecoilState(selectedTaxonomyState);
	const pendingChangesCopy = useRecoilValue(pendingChangesCopyState);
	const setPendingChanges = useSetRecoilState(pendingChangesState);
	const clearHistory = useRecoilValue(clearHistoryState);

	return () => {
		const { nodes, edges } = selectedTaxonomyCopy.tree;

		setPendingChanges(pendingChangesCopy);
		clearHistory?.(nodes, edges);
		setSelectedTaxonomy(selectedTaxonomyCopy);
	};
};
