export const NODES_FUNCTIONALITY = {
	ADD_NODE: 'ADD_NODE',
	MOVE_NODE: 'MOVE_NODE',
	DELETE_NODE: 'DELETE_NODE',
	RENAME_NODE: 'RENAME_NODE',
	DELETE_NODE_WITH_CHILDREN: 'DELETE_NODE_WITH_CHILDREN',
	DELETE_LAST_NODE_CHILD: 'DELETE_LAST_NODE_CHILD',
};

export const PATH_FUNCTIONALITY = {
	PATH_TOGGLE: 'PATH_TOGGLE',
	DELETE_PATH: 'DELETE_PATH',
};

export const MODAL_TESTS_IDS = {
	ERROR_MESSAGE: 'ERROR_MESSAGE',
	WARNING_MESSAGE: 'WARNING_MESSAGE',
	CONTENT: 'CONTENT',
	EXECUTION: 'EXECUTION',
	CANCELATION: 'CANCELATION',
	ADD_NODE: 'ADD_NODE',
	MOVE_NODE: 'MOVE_NODE',
	RENAME_NODE: 'RENAME_NODE',
	MODAL_INPUT: 'MODAL_INPUT',
	MODAL_MULTI_TAGS_INPUT: 'MODAL_MULTI_TAGS_INPUT',
	DELETE_NODE: 'DELETE_NODE',
	DELETE_NODE_WITH_CHILDREN: 'DELETE_NODE_WITH_CHILDREN',
	DELETE_LAST_NODE_CHILD: 'DELETE_LAST_NODE_CHILD',
	CREATE_TAXONOMY_MODAL: 'CREATE_TAXONOMY_MODAL',
	COPY_TAXONOMY_MODAL: 'COPY_TAXONOMY_MODAL',
	RENAME_TAXONOMY_MODAL: 'RENAME_TAXONOMY_MODAL',
	DELETE_TAXONOMY_MODAL: 'DELETE_TAXONOMY_MODAL',
	SAVE_TAXONOMY_MODAL: 'SAVE_TAXONOMY_MODAL',
	PATH_TOGGLE_MODAL: 'PATH_TOGGLE_MODAL',
	CLOSE: 'close-modal',
	CONFIRM_MODAL: 'confirm-modal',
	CONTAINER: 'MODAL_CONTAINER',
	EXPORT_CSV_MODAL: 'EXPORT_CSV_MODAL',
};

export interface IModalProps {
	type: string | null;
	input?: string;
	id?: string;
}

export const TAXONOMY_MODALS = {
	RENAME_TAXONOMY: 'RENAME_TAXONOMY',
	CREATE_TAXONOMY: 'CREATE_TAXONOMY',
	COPY_TAXONOMY: 'COPY_TAXONOMY',
	REMOVE_TAXONOMY: 'REMOVE_TAXONOMY',
	SAVE_TAXONOMY: 'SAVE_TAXONOMY',
	RENAME_IMPORTED_TAXONOMY: 'RENAME_IMPORTED_TAXONOMY',
	EXPORT_CSV: 'EXPORT_CSV',
	EXPORT_CSV_ERROR: 'EXPORT_CSV_ERROR',
};
