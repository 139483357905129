import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';

export const ProgressBar = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	margin: auto;
	min-width: 300px;
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
`;

export const FileName = styled.p`
	font-size: ${FONT_SIZES.H3};
	line-height: 23px;
	color: ${Colors.secondary500};
	margin-top: 0;
	margin-bottom: 16px;
`;

export const Error = styled.p`
	font-size: ${FONT_SIZES.BODY};
	line-height: 16px;
	color: ${Colors.alert800};
	margin-top: 16px;
	margin-bottom: 16px;
`;

export const RetryButton = styled.button`
	max-width: 165px;
	background-color: ${Colors.primary500};
	align-self: center;
	font-size: ${FONT_SIZES.BODY};
	line-height: 14px;
	font-weight: 500;
	text-transform: uppercase;
	color: ${Colors.white};
	padding: 12px 8px;
	border-radius: 4px;
`;
