import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { pinnedSearchResultsState } from '../../../../state/treeMenuBar.state';
import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { SpinnerCircle } from '../../../../styles/global.styles';

const Root = styled.div<{ withPinnedResults: boolean }>`
	position: absolute;
	top: ${({ withPinnedResults }) => (withPinnedResults ? '40%' : '10%')};
	left: 50%;
	transform: translate(-50%, -50%);
	width: 120px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Circle = styled(SpinnerCircle)`
	width: 60px;
	height: 60px;
	border: 3px solid ${Colors.secondary300};
	border-bottom-color: ${Colors.secondary800};
`;

const Text = styled.p`
	font-size: ${FONT_SIZES.BODY};
	line-height: 16px;
	margin-bottom: 0;
	margin-top: 40px;
`;

export const Preloader = () => {
	const pinnedSearchResults = useRecoilValue(pinnedSearchResultsState);

	return (
		<Root withPinnedResults={pinnedSearchResults.length !== 0}>
			<Circle />
			<Text>Loading results…</Text>
		</Root>
	);
};
