import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { icon, IIcon } from '../../../../styles/global.styles';

export const ExportLink = styled.a<{ disabled: boolean }>`
	height: 24px;
	width: 24px;

	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
	cursor: ${({ disabled }) => (disabled ? 'default' : 'none')};
`;

export const ExportIcon = styled.svg<{ disabled: boolean } & IIcon>`
	${icon};
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

	path {
		fill: ${({ disabled }) => (disabled ? Colors.secondary500 : Colors.secondary100)};
	}

	&:hover {
		path {
			fill: ${({ disabled }) => (disabled ? Colors.secondary500 : Colors.primary500)};
		}
	}

	&:active {
		path {
			fill: ${Colors.primary800};
		}
	}
`;

export const ExportMenuWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	position: relative;
	cursor: pointer;
	font-size: ${FONT_SIZES.XS};
`;

export const CsvDownloadButton = styled.a`
	display: none;
`;
