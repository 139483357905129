import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useMemo, useRef, useState } from 'react';

import { LOG_STATUS } from '../../../../constants/history.constants';
import { useApplyChange } from '../../../../services/history.services';
import { HISTORY_TESTS_IDS } from '../../history.constants';
import { GridBox } from './grid.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Grid = ({ data, columns, rowHeight = 40 }: { data: any; columns: ColDef[]; rowHeight?: number }) => {
	const gridRef = useRef<AgGridReact>(null);
	const [rowsClicked, setRowsClicked] = useState<Record<string, boolean>>({});

	const applyChange = useApplyChange();

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
		}),
		[],
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleRowClick = (row: any) => {
		setRowsClicked({ ...rowsClicked, [row.data.id]: true });
		isRowClickable(row) && applyChange(row.data, LOG_STATUS.NEW);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const isRowClickable = (row: any) => {
		if (rowsClicked[row.data.id]) {
			return false;
		}
		return row.data ? row.data.status === LOG_STATUS.NEW : false;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const generateRowStyle = (params: any) => {
		if (params.data.status !== LOG_STATUS.NEW) {
			return { opacity: '0.4', userEvents: 'none', cursor: 'default' };
		}
	};

	return (
		<GridBox data-testid={HISTORY_TESTS_IDS.GRID}>
			<AgGridReact
				ref={gridRef}
				defaultColDef={defaultColDef}
				rowData={data}
				columnDefs={columns}
				animateRows
				suppressCellFocus
				headerHeight={38}
				rowHeight={rowHeight}
				rowStyle={{ cursor: 'pointer' }}
				getRowStyle={generateRowStyle}
				onRowDoubleClicked={handleRowClick}
				suppressScrollOnNewData
			/>
		</GridBox>
	);
};
