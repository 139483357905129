import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { icon } from '../../../../styles/global.styles';

export const Background = styled.div<{ visible: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ visible }) => (visible ? Colors.secondary800 : Colors.secondary900)};
	border-left: 1px solid ${({ visible }) => (visible ? Colors.secondary900 : Colors.secondary500)};
	margin: ${({ visible }) => (visible ? '0' : '8px 0')};
	padding: ${({ visible }) => (visible ? '12px 16px' : '4px 16px')};
`;

export const InfoButton = styled.button`
	display: flex;
`;

export const InfoIcon = styled.svg`
	${icon};

	${InfoButton}:disabled & {
		path {
			fill: ${Colors.secondary500};
		}
	}
`;
