import styled from 'styled-components';

import { Logo } from '../../assets/icons/header/userMenu';
import { Colors } from '../../styles/colors.styles';

export const HeaderContainer = styled.header`
	display: flex;
	position: relative;
	justify-content: flex-end;
	align-items: center;
	min-height: 48px;
	width: 100%;
	background: ${Colors.secondary900};
	color: ${Colors.secondary50};
	grid-area: header;
	z-index: 20;
`;

export const LogoContainer = styled(Logo)`
	padding: 10px;
`;
