import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { SpinnerCircle } from '../../../../styles/global.styles';

export const Button = styled.button`
	min-width: 80px;
	height: 32px;
	color: ${Colors.white};
	font-weight: 500;
	font-size: ${FONT_SIZES.S};
	line-height: 15px;
	background-color: ${Colors.primary500};
	border: 2px solid ${Colors.primary500};
	border-radius: 4px;
	padding-inline: 10px;
	text-transform: uppercase;
	white-space: nowrap;
	transition-property: background-color, border, color;
	transition-duration: 0.3s;

	&:hover {
		border: 2px solid ${Colors.primary800};
		background: ${Colors.primary800};
	}

	&:disabled {
		border: 2px solid ${Colors.secondary500};
		background: ${Colors.secondary900};
		color: ${Colors.secondary500};
	}
`;

export const Spinner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: 40px;
`;

export const Circle = styled(SpinnerCircle)`
	width: 16px;
	height: 16px;
	border: 2px solid ${Colors.secondary300};
	border-bottom-color: ${Colors.secondary800};
`;
