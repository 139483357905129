import { useRecoilValue, useSetRecoilState } from 'recoil';

import { selectedTaxonomyState } from '../state/global.state';
import type { IExamplesSynonyms, ITaxonomyNode } from '../types/taxonomy.types';
import { selectedNodeState, treeNodesState } from './../state/tree.state';
import { useNodeLevels } from './node.services';
import { useCreateLog, useSaveTaxonomy } from './tree.services';

export const useUpdateNode = () => {
	const setSelectedNode = useSetRecoilState(selectedNodeState);
	const { tree } = useRecoilValue(selectedTaxonomyState);
	const treeNodes = useRecoilValue(treeNodesState);

	const createLog = useCreateLog();
	const nodeLevels = useNodeLevels();
	const saveTaxonomy = useSaveTaxonomy();

	return async (content: IExamplesSynonyms | string, dataType: string, node: ITaxonomyNode) => {
		let updatedNode = node;

		const updatedNodes = tree.nodes.map(n => {
			if (n.id === node.id && node.data) {
				updatedNode = { ...node, data: { ...node.data, [dataType]: content } };
				return { ...n, data: { ...n?.data, [dataType]: content } };
			}

			if (n.id === node.id && !node.data) {
				updatedNode = {
					...node,
					data: { description: '', examples: [], synonyms: [], [dataType]: content },
				};
				return { ...n, data: { description: '', examples: [], synonyms: [], [dataType]: content } };
			}

			return n;
		});

		const levels = nodeLevels(node.id);
		const log = createLog('nodeDataEdit', { node, content, dataType, levels });

		await saveTaxonomy({ ...tree, nodes: updatedNodes }, log, treeNodes);
		setSelectedNode(updatedNode);
	};
};
