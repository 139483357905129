import { useMutation } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { LOG_STATUS } from '../../../../constants/history.constants';
import { useCreateChangeRequest, useUpdateTaxonomyRequest } from '../../../../services/api.services';
import { useConfirmationModal } from '../../../../services/modal.services';
import { myTaxonomies } from '../../../../services/myTaxonomies.services';
import { useDeleteTaxonomy } from '../../../../services/tree.services';
import {
	isAppDisabledState,
	isSelectedDraftState,
	isSelectedSampleState,
	selectedTaxonomyState,
	taxonomiesSelector,
	taxonomiesWithChangesState,
} from '../../../../state/global.state';
import {
	historyMenuState,
	logsToSendState,
	pendingChangesState,
	unHandledPendingChangesState,
} from '../../../../state/history.state';
import { isConfirmationModalOpenState } from '../../../../state/modal.state';
import { clearHistoryState } from '../../../../state/tree.state';
import { isAdminState } from '../../../../state/user.state';
import { ACTION_BAR_TESTS_IDS } from '../../actionBar.constants';
import * as Style from './saveButton.styles';

type SaveButtonProps = {
	text?: string;
	disable?: boolean;
};

const Preloader = () => (
	<Style.Spinner>
		<Style.Circle />
	</Style.Spinner>
);

export const SaveButton = ({ text, disable }: SaveButtonProps) => {
	const clearHistory = useRecoilValue(clearHistoryState);
	const isAdmin = useRecoilValue(isAdminState);
	const setIsAppDisabled = useSetRecoilState(isAppDisabledState);
	const isSelectedDraft = useRecoilValue(isSelectedDraftState);
	const isSelectedSample = useRecoilValue(isSelectedSampleState);
	const logsToSend = useRecoilValue(logsToSendState);
	const [selectedTaxonomy, setSelectedTaxonomy] = useRecoilState(selectedTaxonomyState);
	const resetPendingChanges = useResetRecoilState(pendingChangesState);
	const setTaxonomies = useSetRecoilState(taxonomiesSelector);
	const setTaxonomiesWithChanges = useSetRecoilState(taxonomiesWithChangesState);
	const resetHistoryMenu = useResetRecoilState(historyMenuState);
	const taxonomies = useRecoilValue(taxonomiesSelector);
	const setIsConfirmationModalOpen = useSetRecoilState(isConfirmationModalOpenState);
	const unHandledChanges = useRecoilValue(unHandledPendingChangesState);

	const buttonText = text || (isAdmin ? 'save' : 'send to review');
	const modalMessage =
		unHandledChanges > 0 ? `There are ${unHandledChanges} unhandled changes, which will be automatically rejected.` : undefined;

	const deleteTaxonomy = useDeleteTaxonomy();
	const updateTaxonomyRequest = useUpdateTaxonomyRequest();
	const createChangeRequest = useCreateChangeRequest();
	const { openDialog } = useConfirmationModal();

	const { isLoading: isSaveLoading, mutateAsync: saveChanges } = useMutation(['updateTaxonomy'], updateTaxonomyRequest, {
		onSettled: () => setIsAppDisabled(false),
	});

	const { isLoading: isSendLoading, mutateAsync: sendChange } = useMutation(['sendChange'], createChangeRequest, {
		onSettled: () => setIsAppDisabled(false),
	});

	const resetChanges = () => {
		setTaxonomiesWithChanges(prev => prev.filter(({ taxonomyName }) => taxonomyName !== selectedTaxonomy.taxonomyName));
		resetPendingChanges();
		resetHistoryMenu();
	};

	const onAdminSave = () => {
		setIsConfirmationModalOpen(true);
		openDialog({
			title: 'Confirmation Dialog',
			message: modalMessage,
			actionCallback: (confirmed: boolean) => {
				confirmed && handleSave();
			},
		});
	};

	const handleSave = async () => {
		setIsAppDisabled(true);
		await saveChanges({});

		const taxonomiesToDelete = taxonomies.filter(({ originId }) => originId === selectedTaxonomy.originId);
		const { originId, ...taxonomyToSelect } = selectedTaxonomy;

		setSelectedTaxonomy({ ...taxonomyToSelect, taxonomyId: originId as string, isDraft: false });

		for await (const taxonomy of taxonomiesToDelete) {
			await deleteTaxonomy(taxonomy.taxonomyId, originId as string);
		}
		resetChanges();
	};

	const handleSendChanges = async () => {
		setIsAppDisabled(true);

		await sendChange(logsToSend);

		const sentLogsIds = new Set(logsToSend.map(({ id }) => id));

		const newLogs = selectedTaxonomy.tree.logs.map(log => {
			if (sentLogsIds.has(log.id)) {
				return { ...log, status: LOG_STATUS.PENDING };
			}
			return log;
		});
		const taxonomyToSave = { ...selectedTaxonomy, tree: { ...selectedTaxonomy.tree, logs: newLogs } };

		setTaxonomies(await myTaxonomies.update(taxonomyToSave, selectedTaxonomy.taxonomyId));
		setSelectedTaxonomy(taxonomyToSave);
		clearHistory?.(selectedTaxonomy.tree.nodes, selectedTaxonomy.tree.edges);
	};

	return (
		<Style.Button
			data-testid={ACTION_BAR_TESTS_IDS.SAVE_BUTTON}
			onClick={isAdmin ? onAdminSave : handleSendChanges}
			disabled={disable || !isSelectedDraft || isSaveLoading || isSendLoading || isSelectedSample || logsToSend.length === 0}
		>
			{isSaveLoading || isSendLoading ? <Preloader /> : buttonText}
		</Style.Button>
	);
};
