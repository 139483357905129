import compact from 'lodash/compact';
import { useCallback, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { selectedTaxonomyState, taxonomiesNamesState } from '../state/global.state';
import { granularTopicsState, isRootNode, selectedNodeState } from '../state/tree.state';
import type { IExamplesSynonyms, ISynonym, ITaxonomyNode } from '../types/taxonomy.types';
import { ERROR_TYPE, GENERAL_ERRORS, NODE_ERRORS, TAXONOMY_ERRORS } from './../constants/errors.constants';
import { NODES_FUNCTIONALITY, TAXONOMY_MODALS } from './../constants/modals.constants';
import { modalPropsState } from './../state/modal.state';
import { useDependenciesOf, useDirectDependantsOf, useDirectDependenciesOf } from './graph.services';
import { useNodeSiblings, useNodeSiblingsPerParent } from './node.services';

export const isError = (errorType: FieldError['type']) => !isWarning(errorType);
export const isWarning = (errorType: FieldError['type']) => errorType.endsWith(ERROR_TYPE.warningSuffix);

export const isContentLengthValid = (value: string) => value.length >= 2 && value.length <= 60;

export const isSynonymExistsInSynonymsList = (id: string, content: string, contentList: IExamplesSynonyms) =>
	contentList.findIndex(item => content === item.content && id !== item.id) > -1 ? GENERAL_ERRORS.DUPLICATE_VALUE : '';

export const useErrorMessage = () => {
	const isTaxonomyNameIncludeInTaxonomiesList = useIsTaxonomyNameIncludeInTaxonomiesList();
	const isNodeSiblingNameExist = useIsNodeSiblingNameExist();
	const isNodeChildNameExist = useIsNodeChildNameExist();

	return (value: string) => {
		if (!isContentLengthValid(value)) return GENERAL_ERRORS.INPUT_RANGE;
		if (isTaxonomyNameIncludeInTaxonomiesList(value)) return TAXONOMY_ERRORS.NAME_ALREADY_TAKEN;
		if (isNodeSiblingNameExist(value) || isNodeChildNameExist(value)) return NODE_ERRORS.NODE_EXIST_IN_THE_SAME_LEVEL;
		return '';
	};
};

export const useIsEqualToGranularTopic = () => {
	const granularTopics = useRecoilValue(granularTopicsState);
	const [granularTopicsToString, setGranularTopicsToString] = useState<string[]>();

	useEffect(() => setGranularTopicsToString(granularTopics?.map(node => node?.text?.toString())), [granularTopics]);

	return (value: string) => (granularTopicsToString?.includes(value) ? NODE_ERRORS.GRANULAR_TOPIC_REPETITION : '');
};

export const useIsModalTypeDelete = () => {
	const modalProps = useRecoilValue(modalPropsState);

	return () => modalProps?.type === NODES_FUNCTIONALITY.DELETE_NODE || modalProps?.type === TAXONOMY_MODALS.REMOVE_TAXONOMY;
};

export const useIsNodeChildNameExist = () => {
	const nodeValidationChildExists = useNodeValidationChildExists();
	const modalProps = useRecoilValue(modalPropsState);

	return (nodeName: string) => {
		if (modalProps?.type && [NODES_FUNCTIONALITY.ADD_NODE, NODES_FUNCTIONALITY.RENAME_NODE].includes(modalProps.type)) {
			return nodeValidationChildExists(nodeName);
		}
	};
};

export const useIsNodeSiblingNameExist = () => {
	const nodeValidationSiblingExists = useNodeValidationSiblingExists();
	const modalProps = useRecoilValue(modalPropsState);

	return (nodeName: string) => {
		if (modalProps?.type && [NODES_FUNCTIONALITY.ADD_NODE, NODES_FUNCTIONALITY.RENAME_NODE].includes(modalProps.type)) {
			return nodeValidationSiblingExists(nodeName);
		}
	};
};

export const useIsTaxonomyNameIncludeInTaxonomiesList = () => {
	const taxonomiesNames = useRecoilValue(taxonomiesNamesState);

	return (taxonomyName: string) => taxonomiesNames.includes(taxonomyName);
};

export const useNodeValidationIsParent = () => {
	const selectedNode = useRecoilValue(selectedNodeState);

	const dependenciesOf = useDependenciesOf();

	return useCallback(
		() => selectedNode && dependenciesOf(selectedNode?.id as string)?.length !== 0,
		[dependenciesOf, selectedNode],
	);
};

export const useNodeValidationChildExists = () => {
	const { nodes } = useRecoilValue(selectedTaxonomyState).tree;
	const selectedNode = useRecoilValue(selectedNodeState);

	const directDependenciesOf = useDirectDependenciesOf();

	return (name: string) => {
		const childrenIds = new Set(directDependenciesOf(selectedNode?.id as string));

		return nodes.some(({ id, text }) => childrenIds.has(id) && text.toLowerCase() === name.toLowerCase());
	};
};

export const useNodeValidationSiblingExists = () => {
	const { nodes } = useRecoilValue(selectedTaxonomyState).tree;
	const selectedNode = useRecoilValue(selectedNodeState);
	const nodeSiblings = useNodeSiblings();

	return (name: string) => {
		const siblingsIds = new Set(selectedNode?.id ? [selectedNode.id, ...nodeSiblings(selectedNode.id)] : []);

		return nodes.some(({ id, text }) => siblingsIds.has(id) && text.toLowerCase() === name.toLowerCase());
	};
};

export const useNodeValidationHasNoSiblings = () => {
	const nodeSiblingsPerParent = useNodeSiblingsPerParent();
	const selectedNode = useRecoilValue(selectedNodeState);

	return () => {
		if (selectedNode) {
			return nodeSiblingsPerParent(selectedNode?.id).some(parent => parent.siblings.length === 0);
		}

		return false;
	};
};

const useFindPaths = () => {
	const { nodes } = useRecoilValue(selectedTaxonomyState).tree;

	const directDependantsOf = useDirectDependantsOf();

	return (match: ITaxonomyNode) => {
		const names = [match.text];

		const findParent = (item: ITaxonomyNode) => {
			const parent = nodes.find(node => node.id === directDependantsOf(item.id)[0]);
			names.push(parent?.text);
			if (parent && !isRootNode(parent)) {
				findParent(parent);
			}
		};

		findParent(match);

		return names;
	};
};

const useCheckNodesNames = () => {
	const { nodes } = useRecoilValue(selectedTaxonomyState).tree;

	const findPaths = useFindPaths();

	return (name: string, take: number) => {
		const matches = nodes.filter(node => node.text.toLowerCase() === name.toLowerCase());
		const firstMatches = matches.slice(0, take);

		const paths = firstMatches.map(match => compact(findPaths(match)).reverse().join(' / '));

		return { paths, matches: matches.length };
	};
};

const useCheckSynonymsNames = () => {
	const { nodes } = useRecoilValue(selectedTaxonomyState).tree;

	const findPaths = useFindPaths();

	return (name: string, take: number) => {
		const nodesWithSynonyms = nodes.filter(node => node.data?.synonyms && node.data?.synonyms?.length > 0);

		const matches = nodesWithSynonyms.filter(node =>
			node.data?.synonyms?.some((synonym: ISynonym) => synonym?.content?.toLowerCase() === name.toLowerCase()),
		);
		const firstMatches = matches.slice(0, take);

		let paths: string[] = [];

		if (firstMatches.length > 0) {
			paths = firstMatches.map(match => compact(findPaths(match)).reverse().join(' / ') + ' (synonym)');
		}

		return { paths, matches: matches.length };
	};
};

export const useCheckTag = () => {
	const checkNodesNames = useCheckNodesNames();
	const checkSynonymsNames = useCheckSynonymsNames();

	return useCallback(
		(tag: string, take = 3) => {
			const names = checkNodesNames(tag, take);
			const synonyms = checkSynonymsNames(tag, take - names.paths.length);

			const paths = [...names.paths, ...synonyms.paths];
			const matches = names.matches + synonyms.matches - take;

			if (paths.length > 0) return { paths, matches };

			return { paths: [], matches: 0 };
		},
		[checkSynonymsNames, checkNodesNames],
	);
};
