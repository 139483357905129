export const TREE_MENU_TESTS_IDS = {
	TREE_MENU: 'TREE_MENU',
	LOADER: 'LOADER',
	LIST_ITEM: 'LIST_ITEM',
	KEBAB_BUTTON: 'KEBAB_BUTTON',
	KEBAB_MENU: 'KEBAB_MENU',
	ADD_BUTTON: 'ADD_BUTTON',
	DELETE_BUTTON: 'DELETE_BUTTON',
	RENAME_BUTTON: 'RENAME_BUTTON',
	LIST_ITEM_ARROW: 'LIST_ITEM_ARROW',
	SEARCH_CANCEL_ICON: 'SEARCH_CANCEL_ICON',
	SEARCH_RESULTS: 'SEARCH_RESULTS',
	SEARCH_RESULT: 'SEARCH_RESULT',
	SEARCH_INPUT: 'SEARCH_INPUT',
	HOVER_INFO: 'HOVER_INFO',
	CHECKBOX: 'CHECKBOX',
};
