import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';

export const NodeContentList = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 22px;
	list-style: none;
	padding-left: 0;
	overflow-y: auto;
	scrollbar-width: thin;
	margin: 0;
`;

export const NodeContentListContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 37%;
	border-bottom: 1px solid ${Colors.secondary300};
	padding-bottom: 5px;
	margin: 0;
`;

export const InnerTitles = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 12px 8px 14px;
`;

export const Title = styled.h6`
	margin: 0;
	font-size: ${FONT_SIZES.H6};
	font-weight: 500;
	line-height: 18px;
	color: ${Colors.primary500};
`;
