import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { LOG_STATUS } from '../../../constants/history.constants';
import { useApplyChange } from '../../../services/history.services';
import { currentPendingChangesSetState } from '../../../state/history.state';
import { Colors } from '../../../styles/colors.styles';
import { FONT_SIZES } from '../../../styles/fonts.styles';
import { dateColumn, descriptionColumn, typeColumn } from './commonSchema';

const Button = styled.button<{ color: Colors; status: LOG_STATUS; value: string; fulfilled: string }>`
	color: ${({ color }) => color};
	justify-content: center;
	font-weight: 500;
	font-size: ${FONT_SIZES.S};
	line-height: 15px;
	margin-left: 8px;
	width: 70px;
	border-radius: 4px;
	padding: 4px;
	transition: background-color 0.3s;
	${({ status, value }) => {
		switch (status) {
			case LOG_STATUS.APPROVED:
				return value === 'Approve'
					? `background-color: ${Colors.success100}; display: flex;`
					: `background-color: transparent; display: none`;
			case LOG_STATUS.REJECTED:
				return value === 'Reject'
					? `background-color: ${Colors.alert100}; display: flex`
					: `background-color: transparent; display: none`;
			default:
				return `background-color: transparent; display: block`;
		}
	}}
`;

const ButtonRenderer = ({
	data,
	color,
	value,
	fulfilled,
}: ICellRendererParams & { color: Colors; value: string; fulfilled: string }) => {
	const applyChange = useApplyChange();
	const pendingChangesSet = useRecoilValue(currentPendingChangesSetState);
	const status = pendingChangesSet?.data.find(change => change.id === data.id)?.status || LOG_STATUS.NEW;

	const handleClick = () => {
		value === 'Approve' ? applyChange(data, LOG_STATUS.APPROVED) : applyChange(data, LOG_STATUS.REJECTED);
	};

	const renderDisabled = useCallback(() => {
		return status !== LOG_STATUS.PENDING && status !== LOG_STATUS.NEW;
	}, [status]);

	return (
		<Button color={color} onClick={handleClick} status={status} value={value} disabled={renderDisabled()} fulfilled={fulfilled}>
			{status === LOG_STATUS.NEW ? value : fulfilled}
		</Button>
	);
};

export const allChangesColumns: ColDef[] = [
	{ ...dateColumn, sortable: false, filter: false, sort: 'asc' },
	{ ...typeColumn, sortable: false, filter: false },
	{
		...descriptionColumn,
		sortable: false,
		filter: false,
		flex: 1,
	},
	{
		headerName: 'Approve All',
		field: 'approve',
		width: 110,
		cellClass: 'approve',
		headerClass: 'approve',
		cellRenderer: ButtonRenderer,
		cellRendererParams: {
			color: Colors.success500,
			value: 'Approve',
			fulfilled: 'Approved',
		},
		sortable: false,
	},
	{
		headerName: 'Reject All',
		field: 'reject',
		width: 110,
		cellClass: 'reject',
		headerClass: 'reject',
		cellRenderer: ButtonRenderer,
		cellRendererParams: {
			color: Colors.alert500,
			value: 'Reject',
			fulfilled: 'Rejected',
		},
		sortable: false,
	},
];
