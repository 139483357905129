import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import styled from 'styled-components';

import { Close } from '../../assets/icons/history';
import { LOG_STATUS } from '../../constants/history.constants';
import { convertLogs, useResetChanges } from '../../services/history.services';
import { isDefaultTaxonomyState, selectedTaxonomyState } from '../../state/global.state';
import {
	currentPendingChangesSetState,
	historyMenuState,
	pendingChangesState,
	unHandledPendingChangesState,
} from '../../state/history.state';
import { treeMenuOpenState } from '../../state/treeMenuBar.state';
import { userState } from '../../state/user.state';
import { Colors } from '../../styles/colors.styles';
import { FONT_SIZES } from '../../styles/fonts.styles';
import { icon } from '../../styles/global.styles';
import { IPendingChange } from '../../types/taxonomy.types';
import { SaveButton } from '../headerActionBar/atomsComponents/';
import { ConfirmationDialogProvider } from '../modals/confirmationModals';
import { Grid } from './atomComponents';
import { HISTORY_TESTS_IDS } from './history.constants';
import { allChangesColumns } from './schemas/allChanges';
import { historyColumns } from './schemas/history';
import { suggestedChangesColumns } from './schemas/suggestedChanges';

const Root = styled.aside<{ treeMenuOpen: boolean; historyOpen: boolean }>`
	position: absolute;
	bottom: 0;
	left: ${({ treeMenuOpen }) => (treeMenuOpen ? '50%' : 'calc(50% + 58px)')};
	width: ${({ treeMenuOpen }) => (treeMenuOpen ? 'calc(100% - 16px)' : 'calc(100% - 76px)')};
	height: ${({ historyOpen }) => (historyOpen ? 'calc(40% + 54px)' : '0')};
	margin-left: ${({ treeMenuOpen }) => (treeMenuOpen ? '0' : '-30px')};
	transform: translateX(-50%);
	background-color: ${Colors.secondary900};
	color: ${Colors.white};
	border-radius: 8px 8px 0px 0px;
	transition: height 0.3s;
	display: flex;
	flex-direction: column;
`;

const Title = styled.h2`
	font-size: ${FONT_SIZES.H4};
	line-height: 1.2;
	font-weight: 400;
	margin: 12px;
`;

const Subtitle = styled.div`
	display: flex;
	gap: 24px;
	font-size: ${FONT_SIZES.H6};
	line-height: 1.2;
	font-weight: 400;
	padding: 12px;
	border-top: 1px solid ${Colors.secondary800};
`;

const Divider = styled.span`
	width: 1px;
	height: 30px;
	background-color: ${Colors.secondary300};
`;

const SetDetails = styled.div`
	display: flex;
	font-weight: 300;
	font-size: ${FONT_SIZES.S};
	line-height: 1.2;
	gap: 24px;
	& > span {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}
	& > span:first-of-type > strong {
		color: ${Colors.selected500};
	}
`;

const Select = styled.select`
	border: none;
	outline: none;
	border-radius: 6px;
	padding: 0px 8px;
`;

const CloseButton = styled.button`
	${icon};
	position: absolute;
	top: 16px;
	right: 16px;
`;

const Buttons = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 8px;
	align-items: center;
	margin-left: auto;
`;

const ResetButton = styled.button`
	height: 32px;
	border-radius: 4px;
	border: 2px solid ${Colors.primary800};
	color: ${Colors.primary500};
	padding: 2px 8px;
	font-size: ${FONT_SIZES.S};
	text-transform: uppercase;
	white-space: nowrap;

	&:hover {
		cursor: pointer;
		border-color: ${Colors.primary100};
		color: ${Colors.primary300};
	}

	&:active {
		background-color: ${Colors.primary800};
		border-color: ${Colors.primary500};
		color: ${Colors.primary300};
	}

	&:disabled {
		border: 2px solid ${Colors.secondary500};
		background: ${Colors.secondary900};
		color: ${Colors.secondary500};
		cursor: default;
	}
`;

const generateSetTitle = (set: IPendingChange) => {
	const formattedDate = new Date(set.submittedAt).toLocaleString('en-US', { hour12: false });
	const formattedUser = set.submittedBy.split('@')[0];

	return `${formattedDate} | ${formattedUser}`;
};

const generateSetDetails = (set: IPendingChange, unhandled: number) => {
	const formattedUser = set.submittedBy.split('@')[0];

	return (
		<SetDetails>
			<span>
				Changes made:
				<strong>
					{set.data.length - unhandled}/{set.data.length}
				</strong>
			</span>
			<span>
				Made by: <strong>{formattedUser}</strong>
			</span>
			<span>
				Date: <strong>{new Date(set.submittedAt).toLocaleString('en-US', { hour12: false })}</strong>
			</span>
		</SetDetails>
	);
};

export const History = () => {
	const isDefaultTaxonomy = useRecoilValue(isDefaultTaxonomyState);
	const historyMenu = useRecoilValue(historyMenuState);
	const resetHistoryMenu = useResetRecoilState(historyMenuState);
	const pendingChanges = useRecoilValue(pendingChangesState);
	const [pendingChangesSet, setPendingChangesSet] = useRecoilState(currentPendingChangesSetState);
	const { logs } = useRecoilValue(selectedTaxonomyState).tree;
	const treeMenuOpen = useRecoilValue(treeMenuOpenState);
	const resetCurrentSet = useResetRecoilState(currentPendingChangesSetState);
	const user = useRecoilValue(userState);
	const [openTable, setOpenTable] = useState(false);
	const unHandledChanges = useRecoilValue(unHandledPendingChangesState);

	const resetChanges = useResetChanges();

	useEffect(() => {
		treeMenuOpen && historyMenu.open ? setOpenTable(true) : setOpenTable(false);
	}, [historyMenu.open, treeMenuOpen]);

	useEffect(() => {
		isDefaultTaxonomy && resetHistoryMenu();
	}, [isDefaultTaxonomy, resetHistoryMenu]);

	useEffect(() => {
		const set = pendingChanges?.find(set => set.changeId === pendingChangesSet?.changeId);
		if (pendingChanges.length > 0) {
			setPendingChangesSet(set || pendingChanges[0]);
		}

		return () => {
			resetCurrentSet();
		};
	}, [pendingChanges, pendingChangesSet, resetCurrentSet, setPendingChangesSet]);

	const handleClose = () => {
		resetHistoryMenu();
		setOpenTable(false);
		resetCurrentSet();
	};

	const handleReset = () => {
		resetChanges();
	};

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const index = parseInt(e.target.value);
		setPendingChangesSet(pendingChanges[index]);
	};

	return (
		<Root treeMenuOpen={treeMenuOpen} historyOpen={openTable} data-testid={HISTORY_TESTS_IDS.CONTAINER}>
			{historyMenu.type === 'history' && (
				<>
					<Title>Changes History Log</Title>
					<Grid columns={historyColumns} data={convertLogs(logs)} rowHeight={30} />
				</>
			)}

			{historyMenu.type === 'suggested' && (
				<>
					<Title>My Suggested Changes</Title>
					<Grid
						columns={suggestedChangesColumns}
						data={
							pendingChanges &&
							convertLogs(pendingChanges.filter(changes => changes.submittedBy === user.email).flatMap(change => change.data))
						}
					/>
				</>
			)}
			{historyMenu.type === 'all' && (
				<>
					<Title>All Changes Review</Title>
					<Subtitle>
						<Select title="ChangesSets" name="ChangesSets" onChange={handleChange}>
							{pendingChanges.map((set, index) => (
								<option key={set.changeId} value={index} title="currentSet">
									{generateSetTitle(set)}
								</option>
							))}
						</Select>
						<Divider />
						{pendingChangesSet && generateSetDetails(pendingChangesSet, unHandledChanges)}
						<Buttons>
							<ResetButton
								onClick={handleReset}
								disabled={pendingChanges.every(set => set.data.every(change => change.status === LOG_STATUS.NEW))}
							>
								reset changes
							</ResetButton>
							<ConfirmationDialogProvider>
								<SaveButton
									text="Save Changes"
									data-testid={HISTORY_TESTS_IDS.SAVE_BUTTON}
									disable={pendingChanges.every(set => set.data.every(change => change.status === LOG_STATUS.NEW))}
								/>
							</ConfirmationDialogProvider>
						</Buttons>
					</Subtitle>
					<Grid columns={allChangesColumns} data={pendingChangesSet?.data && convertLogs(pendingChangesSet.data)} />
				</>
			)}
			<CloseButton
				width="16px"
				height="16px"
				fill={Colors.secondary50}
				fillHover={Colors.secondary100}
				onClick={handleClose}
				data-testid={HISTORY_TESTS_IDS.CLOSE_BUTTON}
			>
				<Close />
			</CloseButton>
		</Root>
	);
};
