import * as yup from 'yup';

import { GENERAL_ERRORS, NODE_ERRORS } from '../constants/errors.constants';

declare module 'yup' {
	interface StringSchema {
		isContentIncludeSpecialChar(format: string): StringSchema;
	}
}

export const NodeNameSchema = () => {
	return yup.string().required(NODE_ERRORS.REQUIRED).min(2, GENERAL_ERRORS.INPUT_RANGE).max(60, GENERAL_ERRORS.INPUT_RANGE);
};
