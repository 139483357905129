import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { Next, Previous } from '../../../../assets/icons/tree';
import { useDirectDependenciesOf } from '../../../../services/graph.services';
import { useNextClick, usePreviousClick } from '../../../../services/pagination.services';
import { historyMenuState } from '../../../../state/history.state';
import { selectedNodeState, treeNodesState } from '../../../../state/tree.state';
import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { TREE_TESTS_IDS } from '../../tree.constants';

const Root = styled.div<{ historyOpen: boolean; visible: boolean }>`
	max-height: 32px;
	background-color: ${Colors.background};
	position: absolute;
	bottom: ${({ historyOpen }) => (historyOpen ? 'calc(8px + 40%)' : '8px')};
	right: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	opacity: ${({ visible }) => (visible ? '1' : '0')};
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
	border-radius: 15px;
	gap: 8px;
	transition: 0.3s;
`;

const PaginationControl = styled.button`
	width: 32px;
	height: 32px;
	box-sizing: content-box;
	transition: 0.3s;

	svg {
		outline: 0.5px solid ${Colors.primary300};
		background-color: ${Colors.primary300};
		border-radius: 50%;
		transition: 0.3s;
	}
	rect {
		fill: ${Colors.primary300};
		transition: fill 0.3s;
	}
	path {
		fill: ${Colors.white};
		transition: fill 0.3s;
	}

	&:hover {
		svg {
			background-color: ${Colors.primary500};
			outline-color: ${Colors.primary500};
		}
		rect {
			fill: ${Colors.primary500};
		}
	}

	&:active {
		svg {
			background-color: ${Colors.primary800};
			outline-color: ${Colors.primary800};
		}
		rect {
			fill: ${Colors.primary800};
		}
	}

	&:disabled {
		svg {
			background-color: ${Colors.secondary300};
			outline-color: ${Colors.secondary300};
		}
		rect {
			fill: ${Colors.white};
		}
		path {
			fill: ${Colors.secondary300};
		}
	}
`;

const CountText = styled.span`
	color: ${Colors.secondary500};
	font-size: ${FONT_SIZES.H6};
	line-height: 18px;
	white-space: nowrap;
`;

export const PaginationControls = () => {
	const [currentAmount, setCurrentAmount] = useState(1);
	const historyMenu = useRecoilValue(historyMenuState);
	const [nextDisabled, setNextDisabled] = useState(true);
	const [prevDisabled, setPrevDisabled] = useState(true);
	const [restAmount, setRestAmount] = useState(25);
	const selectedNode = useRecoilValue(selectedNodeState);
	const treeNodes = useRecoilValue(treeNodesState);

	const directDependenciesOf = useDirectDependenciesOf();
	const handleNextClick = useNextClick();
	const handlePreviousClick = usePreviousClick();

	const targetNode = useMemo(() => treeNodes.find(({ id }) => id === selectedNode?.id), [selectedNode?.id, treeNodes]);
	const directDeps = useMemo(() => directDependenciesOf(selectedNode?.id as string), [directDependenciesOf, selectedNode?.id]);

	useEffect(() => {
		if (targetNode?.chunk) {
			const restNodesSet = new Set(directDeps.slice(targetNode.chunk * 25));

			setNextDisabled(restNodesSet.size === 0);
			setPrevDisabled(targetNode.chunk === 1);
			setCurrentAmount(targetNode.chunk * 25 - 24);
			setRestAmount(restNodesSet.size === 0 ? directDeps.length : targetNode.chunk * 25);
		}
	}, [directDeps, selectedNode, targetNode]);

	return (
		<Root historyOpen={historyMenu.open} visible={(targetNode?.expanded && directDeps.length > 25) || false}>
			<PaginationControl
				onClick={() => handlePreviousClick(targetNode, directDeps)}
				disabled={prevDisabled}
				data-testid={TREE_TESTS_IDS.PAGINATION_PREVIOUS}
			>
				<Previous />
			</PaginationControl>
			<CountText data-testid={TREE_TESTS_IDS.PAGINATION_TEXT}>
				{currentAmount}-{restAmount} of {directDeps.length}
			</CountText>
			<PaginationControl
				onClick={() => handleNextClick(targetNode, directDeps)}
				disabled={nextDisabled}
				data-testid={TREE_TESTS_IDS.PAGINATION_NEXT}
			>
				<Next />
			</PaginationControl>
		</Root>
	);
};
