import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { NODES_FUNCTIONALITY, PATH_FUNCTIONALITY, TAXONOMY_MODALS } from '../../constants/modals.constants';
import { useResetModalProps } from '../../services/modal.services';
import { isModalOpenState, modalPropsState } from '../../state/modal.state';
import { ExportErrorModal } from './errorModal';
import { CreateNodeModal } from './nodeModals/CreateNode';
import { DeleteNodeModal } from './nodeModals/DeleteNode';
import { MoveNodeModal } from './nodeModals/MoveNode';
import { RenameNodeModal } from './nodeModals/RenameNode';
import { DeletePathModal } from './pathModals/DeletePath';
import { SetPrimaryModal } from './pathModals/SetPrimary';
import { CopyTaxonomyModal } from './taxonomyModals/CopyTaxonomy';
import { CreateTaxonomyModal } from './taxonomyModals/CreateTaxonomy';
import { DeleteTaxonomyModal } from './taxonomyModals/DeleteTaxonomy';
import { ExportModal } from './taxonomyModals/ExportTaxonomy';
import { ImportTaxonomyModal } from './taxonomyModals/ImportTaxonomy';
import { RenameTaxonomyModal } from './taxonomyModals/RenameTaxonomy';
import { SaveTaxonomyModal } from './taxonomyModals/SaveTaxonomy';

const Modals = {
	[TAXONOMY_MODALS.CREATE_TAXONOMY]: CreateTaxonomyModal,
	[TAXONOMY_MODALS.COPY_TAXONOMY]: CopyTaxonomyModal,
	[TAXONOMY_MODALS.RENAME_TAXONOMY]: RenameTaxonomyModal,
	[TAXONOMY_MODALS.REMOVE_TAXONOMY]: DeleteTaxonomyModal,
	[TAXONOMY_MODALS.SAVE_TAXONOMY]: SaveTaxonomyModal,
	[TAXONOMY_MODALS.RENAME_IMPORTED_TAXONOMY]: ImportTaxonomyModal,
	[TAXONOMY_MODALS.EXPORT_CSV]: ExportModal,
	[TAXONOMY_MODALS.EXPORT_CSV_ERROR]: ExportErrorModal,
	[NODES_FUNCTIONALITY.DELETE_NODE]: DeleteNodeModal,
	[NODES_FUNCTIONALITY.RENAME_NODE]: RenameNodeModal,
	[NODES_FUNCTIONALITY.ADD_NODE]: CreateNodeModal,
	[NODES_FUNCTIONALITY.MOVE_NODE]: MoveNodeModal,
	[PATH_FUNCTIONALITY.PATH_TOGGLE]: SetPrimaryModal,
	[PATH_FUNCTIONALITY.DELETE_PATH]: DeletePathModal,
};

export const ModalOverlay = () => {
	const [modalOpen, setModalOpen] = useRecoilState(isModalOpenState);
	const modalProps = useRecoilValue(modalPropsState);

	const resetModalProps = useResetModalProps();
	const ModalMapping = useCallback(() => {
		const onCloseModal = () => {
			resetModalProps();
			setModalOpen(false);
		};

		const Modal = modalProps?.type && Modals[modalProps?.type];
		if (Modal) {
			return <Modal onClose={onCloseModal} showModal={modalOpen} defaultValue={modalProps?.input} onConfirm={() => undefined} />;
		}

		return null;
	}, [modalOpen, modalProps, setModalOpen, resetModalProps]);

	return <ModalMapping />;
};
