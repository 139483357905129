import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { useOutsideClick } from '../../../../hooks';
import { useResetActionsOpen } from '../../../../services/tree.services';
import { connectionModeState } from '../../../../state/tree.state';
import { treeMenuOpenState } from '../../../../state/treeMenuBar.state';
import { CancelConnection, NodeActions } from './nodeActions';
import { PathActions } from './pathActions';

const Root = styled.div<{ treeMenuOpen: boolean }>`
	position: absolute;
	top: 8px;
	display: flex;
	gap: 8px;

	left: ${({ treeMenuOpen }) => (treeMenuOpen ? '8px' : '68px')};
`;

export const Actions = () => {
	const connectionMode = useRecoilValue(connectionModeState);
	const treeMenuOpen = useRecoilValue(treeMenuOpenState);

	const resetActionsOpen = useResetActionsOpen();

	const actionsRef = useOutsideClick(() => resetActionsOpen());

	return (
		<Root treeMenuOpen={treeMenuOpen} ref={actionsRef}>
			{connectionMode.active ? (
				<CancelConnection />
			) : (
				<>
					<NodeActions />
					<PathActions />
				</>
			)}
		</Root>
	);
};
