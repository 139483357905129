import { useRecoilValue } from 'recoil';

import { MenuClose, MenuOpen } from '../../../../assets/icons/treeMenuBar';
import { treeMenuOpenState } from '../../../../state/treeMenuBar.state';
import { Colors } from '../../../../styles/colors.styles';
import * as Style from './menuButton.styles';

export const MenuButton = ({ toggleMenu }: { toggleMenu: () => void }) => {
	const treeMenuOpen = useRecoilValue(treeMenuOpenState);

	return (
		<Style.MenuButton onClick={toggleMenu} treeMenuOpen={treeMenuOpen}>
			<Style.MenuIcon
				width="24px"
				height="24px"
				fill={treeMenuOpen ? Colors.secondary300 : Colors.secondary100}
				fillHover={treeMenuOpen ? Colors.secondary100 : Colors.secondary50}
			>
				{treeMenuOpen ? <MenuOpen /> : <MenuClose />}
			</Style.MenuIcon>
		</Style.MenuButton>
	);
};
