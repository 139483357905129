import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { Suspense, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { TaxonomyUser, userRoleTokenState, userState } from '../../state/user.state';
import { GlobalStyle } from '../../styles/global.styles';
import { Main, Preloader } from './atomComponents';
import { Init } from './init/Init';

const AppPreloader = () => (
	<>
		<GlobalStyle />
		<Preloader />
	</>
);

export const App = () => {
	const [user, setUser] = useRecoilState(userState);
	const setToken = useSetRecoilState(userRoleTokenState);

	const { loginWithRedirect, isAuthenticated, isLoading, user: auth0User, getIdTokenClaims } = useAuth0();

	const isTokenExpired = (token: IdToken) => {
		return token.exp && token.exp < Date.now() / 1000;
	};

	useEffect(() => {
		if (auth0User) {
			setUser(auth0User as TaxonomyUser);
			getIdTokenClaims().then(ans => {
				if (!ans || isTokenExpired(ans)) {
					loginWithRedirect();
				}
				setToken(ans?.__raw);
			});
		}
	}, [setUser, auth0User, getIdTokenClaims, setToken, loginWithRedirect]);

	if (isLoading) return <AppPreloader />;

	if (!isAuthenticated) {
		loginWithRedirect();
		return <AppPreloader />;
	}

	return (
		<Suspense fallback={<AppPreloader />}>
			{user && (
				<Init>
					{({ isLoading, error }) => {
						return <Main isLoading={isLoading} error={error} />;
					}}
				</Init>
			)}
		</Suspense>
	);
};
