import type { IExamplesSynonyms, MODE_CONFIG } from '../../../../types/taxonomy.types';
import { NodeContent } from '..';
import * as Style from './nodeContentList.styles';

interface ExistingDataProps {
	currentConfig: MODE_CONFIG;
	nodeContentList: IExamplesSynonyms;
}

export const RenderNotEditModeWithExistingData = ({ currentConfig, nodeContentList }: ExistingDataProps) => {
	return (
		<Style.NodeContentList data-testid={currentConfig.dataTestIdItemsContainer}>
			{nodeContentList?.map((synonym, index) => (
				<NodeContent content={synonym.content} index={index} key={synonym.id} title={currentConfig.labelText} />
			))}
		</Style.NodeContentList>
	);
};
