import { ConfirmationDialogProvider } from '../modals/confirmationModals';
import { ACTION_BAR_TESTS_IDS } from './actionBar.constants';
import * as Style from './actionBar.styles';
import { ExportTaxonomy, InfoButton, SaveButton, SeparationLine, UndoRedo } from './atomsComponents';

export const ActionBar = () => (
	<Style.ActionsBarContainer data-testid={ACTION_BAR_TESTS_IDS.ACTION_BAR}>
		<ConfirmationDialogProvider>
			<SeparationLine />
			<ExportTaxonomy />
			<SaveButton />
			<UndoRedo />
			<InfoButton />
		</ConfirmationDialogProvider>
	</Style.ActionsBarContainer>
);
