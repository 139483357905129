// eslint-disable-next-line import/no-unresolved
import { User } from 'auth0';
import { atom, selector } from 'recoil';

export type TaxonomyUser = User & AmenityRoles;
export interface AmenityRoles {
	'https://amenity/roles': [string];
}

export const userState = atom<TaxonomyUser>({
	key: 'user',
	default: undefined,
});

export const userIdState = selector<string | undefined>({
	key: 'userId',
	get: ({ get }) => get(userState)?.email,
});

export const orgIdState = selector<string | undefined>({
	key: 'orgId',
	get: ({ get }) => {
		const userId = get(userIdState);
		return userId?.substring(userId.indexOf('@') + 1);
	},
});

export const isAdminState = selector<boolean>({
	key: 'isAdmin',
	get: ({ get }) => {
		const user = get(userState);
		return user?.['https://amenity/roles']?.includes('taxonomy-admin');
	},
});

export const userRoleTokenState = atom<string | undefined>({
	key: 'token',
	default: undefined,
});
