export enum Colors {
	background = '#F7F8FC',
	white = '#fff',
	black = '#050C15',
	primary50 = '#E3F2FD',
	primary100 = '#BBDEFB',
	primary300 = '#64B5F6',
	primary500 = '#2196F3',
	primary800 = '#1565C0',
	secondary50 = '#FAFAFA',
	secondary100 = '#CCD8E6',
	secondary300 = '#9EAEC0',
	secondary500 = '#536273',
	secondary800 = '#233243',
	secondary900 = '#101D2C',
	selected50 = '#FFF3E0',
	selected100 = '#FFE0B2',
	selected300 = '#FFB74D',
	selected500 = '#FF9800',
	selected800 = '#EF6C00',
	success100 = '#C3EFD1',
	success500 = '#00CA62',
	success800 = '#00953E',
	alert50 = '#FFF3F5',
	alert100 = '#FDCFD5',
	alert500 = '#F34A41',
	alert800 = '#C53232',
	warningRed = '#EE5959',
}
