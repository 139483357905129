import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

export const TreeMenuBarLoader = (props: IContentLoaderProps) => (
	<ContentLoader
		speed={2}
		width={160}
		height={400}
		viewBox="0 0 160 400"
		backgroundColor="#E3F2FD"
		foregroundColor="#5e86b0"
		{...props}
	>
		<rect x="289" y="4" rx="3" ry="3" width="8" height="570" />
		<rect x="57" y="27" rx="3" ry="3" width="78" height="5" />
		<rect x="48" y="10" rx="3" ry="3" width="139" height="5" />
		<circle cx="15" cy="24" r="13" />
		<rect x="49" y="47" rx="3" ry="3" width="136" height="5" />
		<rect x="58" y="103" rx="3" ry="3" width="78" height="5" />
		<rect x="49" y="86" rx="3" ry="3" width="136" height="5" />
		<circle cx="18" cy="99" r="13" />
		<rect x="53" y="122" rx="3" ry="3" width="136" height="5" />
		<rect x="60" y="175" rx="3" ry="3" width="78" height="5" />
		<rect x="52" y="159" rx="3" ry="3" width="136" height="5" />
		<circle cx="19" cy="172" r="13" />
		<rect x="54" y="195" rx="3" ry="3" width="136" height="5" />
		<rect x="60" y="250" rx="3" ry="3" width="78" height="5" />
		<rect x="53" y="234" rx="3" ry="3" width="136" height="5" />
		<circle cx="19" cy="248" r="13" />
		<rect x="54" y="271" rx="3" ry="3" width="136" height="5" />
		<rect x="63" y="321" rx="3" ry="3" width="78" height="5" />
		<rect x="54" y="304" rx="3" ry="3" width="136" height="5" />
		<circle cx="21" cy="315" r="13" />
		<rect x="57" y="340" rx="3" ry="3" width="136" height="5" />
		<rect x="65" y="357" rx="3" ry="3" width="78" height="5" />
		<rect x="57" y="379" rx="3" ry="3" width="136" height="5" />
		<rect x="-183" y="-77" rx="3" ry="3" width="331" height="7" />
	</ContentLoader>
);
