import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import { isDefaultTaxonomyState } from '../../state/global.state';
import { ActionBar } from '../headerActionBar';
import { TaxonomySelector } from '../headerSelector';
import { UserMenu } from './atomComponents';
import { HEADER_TESTS_IDS } from './header.constants';
import * as Style from './header.styles';

export const HeaderBody = () => {
	const isDefaultTaxonomy = useRecoilValue(isDefaultTaxonomyState);

	return (
		<Style.HeaderContainer data-testid={HEADER_TESTS_IDS.HEADER_CONTAINER}>
			<Style.LogoContainer />
			<UserMenu />
			<TaxonomySelector />
			{!isDefaultTaxonomy && <ActionBar />}
		</Style.HeaderContainer>
	);
};

export const Header = memo(HeaderBody);
