import { examplesConfig, synonymsConfig } from '../../infoBar.constants';
import * as Style from './nodeContent.styles';

interface NodeContentProps {
	index: number;
	title: string;
	content?: string;
	placeholder?: boolean;
}

export const NodeContent = ({ content, index, placeholder, title }: NodeContentProps) => {
	const config = {
		[synonymsConfig.labelText]: synonymsConfig,
		[examplesConfig.labelText]: examplesConfig,
	};

	const curConfig = config[title];

	return (
		<Style.NodeContent data-testid={curConfig.dataTestIdContainer}>
			{placeholder ? (
				<Style.EmptyContent data-testid={curConfig.dataTestIdTextArea}>{content}</Style.EmptyContent>
			) : (
				<>
					<Style.Label data-testid={curConfig.dataTestIdLabel}>{`${title} ${index + 1}`}</Style.Label>
					<Style.Content data-testid={curConfig.dataTestIdTextArea}>{content}</Style.Content>
				</>
			)}
		</Style.NodeContent>
	);
};
