export enum FONT_FAMILIES {
	REGULAR = 'Roboto, Helvetica, Arial, sans-serif',
}

export enum FONT_SIZES {
	H1 = '36px',
	H2 = '24px',
	H3 = '20px',
	H4 = '18px',
	H5 = '16px',
	H6 = '15px',
	BODY = '14px',
	S = '13px',
	XS = '12px',
	XXS = '11px',
}
