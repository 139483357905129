import { atom, selector } from 'recoil';

import { IModalProps } from '../constants/modals.constants';

export type DialogConfigProps = {
	title?: string;
	message?: string;
	actionCallback: (value: boolean) => void;
};

export const modalPropsState = atom<IModalProps>({
	key: 'modalProps',
	default: {
		type: null,
		input: '',
	},
});

export const isModalOpenState = atom<boolean>({
	key: 'isModalOpen',
	default: false,
});

export const isConfirmationModalOpenState = atom<boolean>({
	key: 'isConfirmationModalOpen',
	default: false,
});

export const dialogConfigState = atom<DialogConfigProps | undefined>({
	key: 'dialogConfigState',
	default: undefined,
});

export const dialogConfigSelector = selector<DialogConfigProps | undefined>({
	key: 'dialogConfigSelector',
	get: ({ get }) => get(dialogConfigState),
});

export const confirmationResultState = atom<boolean | undefined>({
	key: 'confirmationResultState',
	default: undefined,
});
