import { ComponentProps, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from 'recoil';

import { searchResultsState, visibleTreeState } from '../../../../state/tree.state';
import { pinnedSearchResultsState, resultsOpenedState, searchValueState } from '../../../../state/treeMenuBar.state';
import { TREE_MENU_TESTS_IDS } from '../../treeMenuBar.constants';
import { Preloader } from '../preloader';
import { SearchResult } from '../searchResult';
import * as Style from './searchResults.styles';

interface SearchResultsListProps {
	nodes?: ComponentProps<typeof SearchResult>['node'][];
	showSynonyms: boolean;
}
const SearchResultsList = ({ nodes, showSynonyms }: SearchResultsListProps) => (
	<>
		{nodes && nodes.length > 0 && (
			<Style.ResultsList>
				{nodes?.map(node => (
					<SearchResult node={node} key={node.id} showSynonyms={showSynonyms} />
				))}
			</Style.ResultsList>
		)}
	</>
);

const AllResults = () => {
	const searchResults = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(searchResultsState);

	if (!searchResults.results) return <Preloader />;

	return (
		<>
			<SearchResultsList nodes={searchResults.results} showSynonyms />
			{searchResults.moreResults ? (
				<Style.MoreResults>
					<Style.MoreText>There are more {searchResults.moreResults} results.</Style.MoreText>
					<Style.MoreText>Add more characters to get accurate results</Style.MoreText>
				</Style.MoreResults>
			) : null}
		</>
	);
};

const PinnedResults = () => {
	const [pinnedSearchResults, setPinnedSearchResults] = useRecoilState(pinnedSearchResultsState);
	const { nodes } = useRecoilValue(visibleTreeState);

	useEffect(() => {
		const nodesSet = new Set(nodes.map(node => node.id));
		pinnedSearchResults && setPinnedSearchResults(prevState => prevState.filter(result => nodesSet.has(result.id)));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nodes]);

	return (
		<>
			{pinnedSearchResults.length > 0 && (
				<>
					<Style.PinnedTitle>Pinned topics paths</Style.PinnedTitle>
					<SearchResultsList nodes={pinnedSearchResults} showSynonyms={false} />
				</>
			)}
		</>
	);
};

export const SearchResults = () => {
	const searchValue = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(searchValueState);
	const resultsOpened = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(resultsOpenedState);

	return (
		<Style.SearchResults resultsOpened={resultsOpened} data-testid={TREE_MENU_TESTS_IDS.SEARCH_RESULTS} width="354px">
			{searchValue && (
				<>
					<PinnedResults />
					<AllResults />
				</>
			)}
		</Style.SearchResults>
	);
};
