import { useTransition } from 'react';
import { useRecoilState } from 'recoil';

import * as Icon from '../../../../assets/icons/treeMenuBar';
import { searchValueState } from '../../../../state/treeMenuBar.state';
import { Colors } from '../../../../styles/colors.styles';
import { TREE_MENU_TESTS_IDS } from '../../treeMenuBar.constants';
import { MenuButton } from '..';
import * as Style from './search.styles';

const Preloader = () => (
	<Style.Spinner>
		<Style.Circle />
	</Style.Spinner>
);

export const Search = ({ toggleMenu }: { toggleMenu: () => void }) => {
	const [searchValue, setSearchValue] = useRecoilState(searchValueState);

	const [isPending, startTransition] = useTransition();

	return (
		<Style.Search>
			<MenuButton toggleMenu={toggleMenu} />
			<Style.SearchForm onSubmit={e => e.preventDefault()}>
				<Style.Label>
					<Style.Input
						placeholder="Search..."
						onChange={({ target: { value } }) => startTransition(() => setSearchValue(value || null))}
						value={searchValue || ''}
						data-testid={TREE_MENU_TESTS_IDS.SEARCH_INPUT}
					/>
					<Style.SearchIcon
						width="24px"
						height="24px"
						fill={Colors.secondary300}
						fillHover={Colors.secondary300}
						isActive={!!searchValue}
					>
						<Icon.Search />
					</Style.SearchIcon>
					{isPending && <Preloader />}
					{searchValue && !isPending && (
						<Style.CancelButton onClick={() => setSearchValue(null)} data-testid={TREE_MENU_TESTS_IDS.SEARCH_CANCEL_ICON}>
							<Style.CancelIcon>
								<Icon.Cancel />
							</Style.CancelIcon>
						</Style.CancelButton>
					)}
				</Style.Label>
			</Style.SearchForm>
		</Style.Search>
	);
};
