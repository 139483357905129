import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';

export const Result = styled.li`
	font-size: ${FONT_SIZES.BODY};
	line-height: 2;
	display: flex;
	cursor: pointer;
	padding: 0 8px;

	&:hover {
		background-color: ${Colors.secondary900};
	}

	&:first-child {
		margin-top: 8px;
	}

	&:last-child {
		margin-bottom: 8px;
	}
`;

export const ResultWrapper = styled.div`
	display: grid;
	grid-template-areas:
		'text level'
		'synonyms synonyms';
	justify-content: space-between;
	flex-grow: 1;
`;

export const ResultText = styled.p`
	max-width: 264px;
	margin-bottom: 0;
	margin-top: 0;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	grid-area: text;
`;

export const Keyword = styled.span`
	text-decoration: underline;
	color: ${Colors.primary300};
`;

export const LevelText = styled(ResultText)`
	font-size: ${FONT_SIZES.XS};
	color: ${Colors.secondary300};
	min-width: fit-content;
	grid-area: level;
	justify-self: flex-end;
`;

export const Synonyms = styled.ul`
	list-style: none;
	padding-left: 6px;
	margin-bottom: 0;
	margin-top: 0;
	grid-area: synonyms;
`;

export const Synonym = styled.li`
	color: ${Colors.secondary300};
`;

export const Label = styled.label`
	display: inline-block;
	position: relative;
	margin-right: 8px;
	margin-top: 6px;
`;

export const Input = styled.input`
	position: absolute;
	opacity: 0;
	height: 0;
	width: 0;
`;

export const Checkbox = styled.span<{ checked: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid ${Colors.primary100};
	width: 16px;
	height: 16px;
	overflow: hidden;
	border-radius: 3px;
	background-color: ${({ checked }) => (checked ? Colors.primary500 : Colors.secondary800)};
	cursor: pointer;
	transition: background-color 0.3s;
`;
