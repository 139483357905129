import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';

export const KebabMenu = styled.div<{ positionY: number }>`
	width: 200px;
	height: 160px;
	padding-top: 8px;
	padding-bottom: 8px;
	position: absolute;
	top: ${({ positionY }) => `${positionY - 128}px`};
	right: -214px;
	background-color: ${Colors.secondary800};
	border: 1px solid ${Colors.secondary300};
	border-radius: 6px;
	z-index: 30;
	display: flex;
	flex-direction: column;
`;

export const Square = styled.span`
	position: absolute;
	width: 20px;
	height: 20px;
	left: -11px;
	top: 49%;
	background: ${Colors.secondary800};
	transform: translateY(-50%) rotate(45deg);
	border-left: 1px solid ${Colors.secondary300};
	border-bottom: 1px solid ${Colors.secondary300};
`;

export const KebabText = styled.span`
	color: ${Colors.secondary50};
	font-size: ${FONT_SIZES.XS};
	line-height: 14px;
	padding: 8px 16px;
	transition: 0.3s;
	cursor: pointer;

	&:hover {
		background-color: ${Colors.secondary900};
	}
`;

export const KebabDeleteText = styled(KebabText)`
	color: ${Colors.alert500};
`;

export const Hr = styled.hr`
	margin: 8px 0;
	width: 100%;
	border: 0.5px solid ${Colors.secondary900};
`;
