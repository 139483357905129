import { useRecoilValue } from 'recoil';

import { MODAL_TESTS_IDS } from '../../../../constants/modals.constants';
import { useDeleteEdge } from '../../../../services/edge.services';
import { selectedTaxonomyState } from '../../../../state/global.state';
import { selectedEdgeState, selectedNodeState } from '../../../../state/tree.state';
import { BaseModalContent } from '../../atomsComponents/BaseForm/BaseModalContent';
import { FormDescription } from '../../atomsComponents/modalAtoms';
import { BaseModal, BaseModalProps } from '../../BaseModal';
import * as Styles from '../../BaseModal/BaseModal.styles';

type DeletePathModalProps = BaseModalProps & { onClose: () => void };
export const DeletePathModal = ({ onClose, showModal }: DeletePathModalProps) => {
	const selectedNode = useRecoilValue(selectedNodeState);
	const selectedEdge = useRecoilValue(selectedEdgeState);
	const { nodes } = useRecoilValue(selectedTaxonomyState).tree;

	const deleteEdge = useDeleteEdge();

	const parentNode = nodes.find(node => node.id === selectedEdge?.from);

	const onSubmit = () => {
		selectedEdge && deleteEdge(selectedEdge);
		onClose();
	};

	return (
		<BaseModal title="Delete path" showModal={showModal} testid={MODAL_TESTS_IDS.PATH_TOGGLE_MODAL}>
			<BaseModalContent discardBtnText="CANCEL" submitBtnText="DELETE" onSubmit={onSubmit} onDiscard={onClose} isValid>
				<FormDescription>
					Are you sure you want to delete the path from <Styles.Bolded>&quot;{parentNode?.text}&quot;</Styles.Bolded> to{' '}
					<Styles.Bolded>&quot;{selectedNode?.text}&quot;</Styles.Bolded>?
				</FormDescription>
			</BaseModalContent>
		</BaseModal>
	);
};
