import { useRecoilValue } from 'recoil';

import {
	useSetCopyTaxonomyModalAction,
	useSetCreateTaxonomyModalAction,
	useSetRemoveTaxonomyModalAction,
	useSetRenameTaxonomyModalAction,
} from '../../../../services/modal.services';
import { isDefaultTaxonomyState, isSelectedDraftState, selectedTaxonomyState } from '../../../../state/global.state';
import { isAdminState } from '../../../../state/user.state';
import { UploadJsonFile } from '../../../uploadJsonFile';
import { TAXONOMY_SELECTOR_TESTS_IDS } from '../../taxonomySelector.constants';
import { CSV } from '..';
import * as Style from './mandatoryOption.styles';

export const MandatoryOptions = () => {
	const isDefaultTaxonomy = useRecoilValue(isDefaultTaxonomyState);
	const isSelectedDraft = useRecoilValue(isSelectedDraftState);
	const isAdmin = useRecoilValue(isAdminState);
	const { taxonomyName, taxonomyId } = useRecoilValue(selectedTaxonomyState);

	const setCopyTaxonomyModalAction = useSetCopyTaxonomyModalAction();
	const setCreateTaxonomyModalAction = useSetCreateTaxonomyModalAction();
	const setRenameTaxonomyModalAction = useSetRenameTaxonomyModalAction();
	const setRemoveTaxonomyModalAction = useSetRemoveTaxonomyModalAction();

	return (
		<Style.MandatoryOptionsContainer data-testid={TAXONOMY_SELECTOR_TESTS_IDS.MANDATORY_OPTIONS}>
			<Style.MandatoryOption
				onClick={() => setRenameTaxonomyModalAction(taxonomyName)}
				disabled={isDefaultTaxonomy || isSelectedDraft}
				data-testid={TAXONOMY_SELECTOR_TESTS_IDS.RENAME}
			>
				Rename current taxonomy
			</Style.MandatoryOption>
			<Style.MandatoryOption
				onClick={() => setCopyTaxonomyModalAction(taxonomyName)}
				disabled={isDefaultTaxonomy}
				data-testid={TAXONOMY_SELECTOR_TESTS_IDS.COPY}
			>
				Make a copy of the current taxonomy
			</Style.MandatoryOption>
			<Style.MandatoryOption
				onClick={() => setCreateTaxonomyModalAction()}
				data-testid={TAXONOMY_SELECTOR_TESTS_IDS.CREATE_NEW_TAXONOMY}
			>
				Create new taxonomy
			</Style.MandatoryOption>
			<Style.MandatoryOption
				onClick={() => {
					const URL = `./taxonomyTemplate.csv`;
					const link = document.createElement('a');
					link.href = URL;
					link.download = URL.split('/').pop() as string;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}}
				data-testid={TAXONOMY_SELECTOR_TESTS_IDS.DOWNLOAD_BLANK_TAXONOMY_TEMPLATE}
			>
				Download blank taxonomy template (CSV)
			</Style.MandatoryOption>
			<Style.ImportOption data-testid={TAXONOMY_SELECTOR_TESTS_IDS.IMPORT_CSV}>
				<CSV />
			</Style.ImportOption>
			<Style.ImportOption>
				<UploadJsonFile isTreeComponent={false} />
			</Style.ImportOption>
			<Style.MandatoryOption
				onClick={() => setRemoveTaxonomyModalAction(taxonomyName, taxonomyId)}
				disabled={isDefaultTaxonomy || !isAdmin}
				data-testid={TAXONOMY_SELECTOR_TESTS_IDS.DELETE_TAXONOMY}
			>
				Delete taxonomy
			</Style.MandatoryOption>
		</Style.MandatoryOptionsContainer>
	);
};
