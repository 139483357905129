import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';

export const NodeContent = styled.li`
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 0px 8px;

	&:first-child {
		margin-top: 5px;
	}
`;

export const Content = styled.p`
	color: ${Colors.secondary500};
	font-size: ${FONT_SIZES.XS};
	line-height: 14px;
	min-height: 40px;
	margin-top: 0;
	margin-bottom: 0;
	padding: 12px;
	border: 1px solid ${Colors.secondary300};
	border-radius: 4px;
`;

export const EmptyContent = styled(Content)`
	color: ${Colors.secondary300};
`;

export const Label = styled.span`
	color: ${Colors.secondary300};
	font-size: ${FONT_SIZES.XXS};
	line-height: 13px;
	position: absolute;
	top: -5px;
	left: 20px;
	z-index: 10;
	background: ${Colors.white};
`;
