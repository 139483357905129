import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from '@pronestor/react-zoom-pan-pinch';
import { memo, MutableRefObject, useCallback, useEffect } from 'react';
import { Canvas, CanvasDirection, NodeProps, useSelection } from 'reaflow';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { useResize } from '../../../../hooks';
import { useSetMoveNodeModalAction } from '../../../../services/modal.services';
import { useCollapseAllNodes } from '../../../../services/node.services';
import { useChangeTree, useTreeNodeLinkCheck } from '../../../../services/tree.services';
import { selectedTaxonomyState } from '../../../../state/global.state';
import { infoBarOpenState } from '../../../../state/infoBar.state';
import {
	clearSelectionsState,
	connectionModeState,
	isRootNode,
	onCanvasClickState,
	rootNodeState,
	selectedNodeState,
	undoControlButtonsState,
	visibleTreeState,
} from '../../../../state/tree.state';
import type { ITaxonomyNode } from '../../../../types/taxonomy.types';
import { TREE_SETTINGS, wrapperStyle } from '../../tree.constants';
import { TaxonomyEdge } from '../taxonomyEdge';
import { TaxonomyNode } from '../taxonomyNode';

interface NodesTreeProps {
	transformRef: MutableRefObject<ReactZoomPanPinchRef | null>;
	treeSize: ReturnType<typeof useResize>['treeSize'];
}

export const TaxonomyTree = memo(function TaxonomyTree({ transformRef, treeSize }: NodesTreeProps) {
	const connectionMode = useRecoilValue(connectionModeState);
	const setClearSelections = useSetRecoilState(clearSelectionsState);
	const resetClearSelections = useResetRecoilState(clearSelectionsState);
	const setInfoBarOpen = useSetRecoilState(infoBarOpenState);
	const setOnCanvasClick = useSetRecoilState(onCanvasClickState);
	const resetOnCanvasClick = useResetRecoilState(onCanvasClickState);
	const rootNode = useRecoilValue(rootNodeState);
	const selectedNode = useRecoilValue(selectedNodeState);
	const { taxonomyId } = useRecoilValue(selectedTaxonomyState);
	const resetUndoControlButtons = useResetRecoilState(undoControlButtonsState);
	const { edges, nodes } = useRecoilValue(visibleTreeState);

	const changeTree = useChangeTree();

	const nodeMove = useSetMoveNodeModalAction();
	const collapseAllNodes = useCollapseAllNodes();
	const treeNodeLinkCheck = useTreeNodeLinkCheck();
	const { selections, onKeyDown, setSelections, toggleSelection, onCanvasClick, clearSelections } = useSelection({
		nodes,
		edges,
		onDataChange: changeTree,
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleCanvasClick = useCallback(() => onCanvasClick?.(), []);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleClearSelections = useCallback(() => clearSelections(), []);

	useEffect(() => {
		setOnCanvasClick(() => handleCanvasClick);
		setClearSelections(() => handleClearSelections);
		return () => {
			resetOnCanvasClick();
			resetClearSelections();
		};
	}, [handleCanvasClick, handleClearSelections, resetClearSelections, resetOnCanvasClick, setClearSelections, setOnCanvasClick]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => collapseAllNodes(), [taxonomyId]);

	useEffect(() => {
		if (selectedNode) {
			setSelections([selectedNode.id]);
			setInfoBarOpen(!isRootNode(selectedNode));
		} else {
			setSelections([]);
			setInfoBarOpen(false);
		}
		return () => {
			setSelections([]);
			setInfoBarOpen(false);
		};
	}, [rootNode?.id, selectedNode, setInfoBarOpen, setSelections]);

	useEffect(() => resetUndoControlButtons(), [selectedNode, resetUndoControlButtons]);

	const handleNodeLink = (event: Event, fromNode: ITaxonomyNode, toNode: ITaxonomyNode) => {
		if (connectionMode.active) return;

		nodeMove(fromNode.id, toNode.id);
	};

	return (
		<TransformWrapper
			limitToBounds={false}
			maxScale={TREE_SETTINGS.MAX_SCALE}
			minScale={TREE_SETTINGS.MIN_SCALE}
			wheel={{ step: TREE_SETTINGS.WHEEL_STEP }}
			doubleClick={{ disabled: true }}
			panning={{ excluded: ['rect'], velocityDisabled: true }}
			ref={transformRef}
			zoomAnimation={{ size: 0 }}
		>
			<TransformComponent wrapperStyle={wrapperStyle}>
				<Canvas
					nodes={nodes}
					edges={edges}
					selections={selections}
					maxWidth={treeSize.width}
					maxHeight={treeSize.height}
					animated
					node={(nodeProps: NodeProps) => (
						<TaxonomyNode onKeyDown={onKeyDown} toggleSelection={toggleSelection} selections={selections} {...nodeProps} />
					)}
					dragNode={null}
					dragEdge={null}
					arrow={null}
					edge={edgeProps => <TaxonomyEdge {...edgeProps} />}
					onNodeLinkCheck={(event, fromNode, toNode) => treeNodeLinkCheck(fromNode.id, toNode.id)}
					onNodeLink={handleNodeLink}
					zoomable={false}
					fit
					direction={TREE_SETTINGS.DIRECTION as CanvasDirection}
					onMouseEnter={() => undefined}
					onMouseLeave={() => undefined}
				/>
			</TransformComponent>
		</TransformWrapper>
	);
});
