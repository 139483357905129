import { Colors } from '../../../../styles/colors.styles';

export const PROGRESS_BAR = {
	BG_COLOR: Colors.primary500,
	BASE_BG_COLOR: Colors.primary100,
	ERROR_COLOR: Colors.alert500,
	HEIGHT: '16px',
	BORDER_RADIUS: '8px',
	CUSTOM_LABEL: 'Loading...',
	BUTTON_TEXT: 'Select another file',
};

export const PROGRESS_BAR_TESTS_IDS = {
	PROGRESS_BAR: 'PROGRESS_BAR',
	FILE_NAME: 'FILE_NAME',
	ERROR: 'ERROR',
	RETRY_BUTTON: 'RETRY_BUTTON',
};
