import { useMutation } from '@tanstack/react-query';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { MODAL_TESTS_IDS } from '../../../../constants/modals.constants';
import { TaxonomySchema } from '../../../../schemas/TaxonomySchema';
import { useCreateTaxonomyRequest } from '../../../../services/api.services';
import { useAddTaxonomy } from '../../../../services/tree.services';
import { selectedTaxonomyState } from '../../../../state/global.state';
import { BaseForm } from '../../atomsComponents/BaseForm/BaseForm';
import { BaseModal, BaseModalProps } from '../../BaseModal';

type CopyTaxonomyModalProps = BaseModalProps & {
	defaultValue?: string | number;
	onClose: () => void;
};
export const CopyTaxonomyModal = ({ defaultValue, onClose, showModal }: CopyTaxonomyModalProps) => {
	const selectedTaxonomy = useRecoilValue(selectedTaxonomyState);
	const resetSelectedTaxonomy = useResetRecoilState(selectedTaxonomyState);

	const addTaxonomy = useAddTaxonomy();
	const createTaxonomyRequest = useCreateTaxonomyRequest();

	const { isLoading, mutateAsync } = useMutation(['copyTaxonomy'], createTaxonomyRequest);

	const onSubmit = async (taxonomy: Record<string, string>) => {
		resetSelectedTaxonomy();
		const newTaxonomy = await mutateAsync({ taxonomy: { ...selectedTaxonomy, taxonomyName: taxonomy.name } });
		addTaxonomy(newTaxonomy);

		onClose();
	};

	return (
		<BaseModal title="Make a copy" showModal={showModal} testid={MODAL_TESTS_IDS.COPY_TAXONOMY_MODAL}>
			<BaseForm
				description="Type a new name for the duplicated taxonomy"
				fields={[{ fieldName: 'name', fieldType: 'input', defaultValue: `Copy of ${defaultValue}` }]}
				isLoading={isLoading}
				onSubmit={onSubmit}
				onDiscard={onClose}
				submitBtnText="SAVE COPY"
				scheme={TaxonomySchema()}
				validateOnLoad
			/>
		</BaseModal>
	);
};
