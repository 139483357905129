import { memo, Suspense } from 'react';
import { useRecoilValue } from 'recoil';

import { CONNECTION_ERRORS } from '../../../../constants/errors.constants';
import { isAppDisabledState } from '../../../../state/global.state';
import { GlobalStyle } from '../../../../styles/global.styles';
import { Header } from '../../../header';
import { InfoBar } from '../../../infoBar';
import { ModalOverlay } from '../../../modals';
import { Tree } from '../../../tree';
import { TreeMenuBar } from '../../../treeMenuBar';
import * as Style from '../../app.styles';
import { Preloader } from '..';

const ContentBody = () => (
	<>
		<Header />
		<TreeMenuBar />
		<Tree />
		<InfoBar />
	</>
);

const Content = memo(ContentBody);

const AppPreloader = () => (
	<>
		<GlobalStyle />
		<Preloader />
	</>
);

export const Main = ({ isLoading, error }: { isLoading: boolean; error: unknown }) => {
	const isAppDisabled = useRecoilValue(isAppDisabledState);

	if (isLoading) return <AppPreloader />;

	if (error) throw new Error(CONNECTION_ERRORS.NETWORK);

	return (
		<Suspense fallback={<AppPreloader />}>
			<GlobalStyle />
			<Style.PageContainer disabled={isAppDisabled}>
				<Content />
			</Style.PageContainer>
			<ModalOverlay />
		</Suspense>
	);
};
