import styled, { css } from 'styled-components';

import { Colors } from '../../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../../styles/fonts.styles';

export const Label = styled.label<{ invalid: boolean }>`
	position: absolute;
	top: -14px;
	left: 10px;
	padding: 2px;
	font-size: ${FONT_SIZES.XXS};
	font-weight: 400;
	color: ${Colors.secondary500};
	background-color: ${Colors.white};
	z-index: 1;
	${({ invalid }) =>
		invalid &&
		css`
			 {
				color: ${Colors.alert500};
			}
		`}
`;
