import styled from 'styled-components';

import { Colors } from '../../../styles/colors.styles';
import { FONT_SIZES } from '../../../styles/fonts.styles';

export const ModalContainer = styled.aside`
	display: flex;
	flex-direction: column;
	background-color: ${Colors.white};
	margin: auto;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
	border-radius: 6px;
`;

export const Hr = styled.hr`
	margin: 0;
	width: 100%;
	background-color: ${Colors.secondary100};
	border: 0.7px solid ${Colors.secondary100};
`;

export const TitleWrapper = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;
	height: 64px;
`;

export const Title = styled.h2<{ color?: Colors }>`
	margin: 0;
	padding: 16px 24px;
	font-weight: 700;
	font-size: ${FONT_SIZES.H3};
	line-height: 23px;
	color: ${({ color }) => color || Colors.secondary800};
`;

export const Bolded = styled.strong`
	font-weight: 700;
`;
