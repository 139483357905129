import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { NodeContentList as container } from '../nodeContentList/nodeContentList.styles';

export const NodeContentList = container;

export const Suggestions = styled.div`
	padding: 0 8px 12px;
`;

export const Tip = styled.p`
	color: ${Colors.secondary300};
	font-size: ${FONT_SIZES.XS};
	line-height: 14px;
	margin-top: 0;
	margin-bottom: 8px;
`;

export const Tags = styled.div`
	display: flex;
	flex-wrap: wrap;
	column-gap: 4px;
	row-gap: 8px;
`;

export const Tag = styled.button`
	background-color: ${Colors.secondary300};
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color 0.3s;

	&:hover {
		background-color: ${Colors.secondary500};
	}
`;

export const TagText = styled.span`
	color: ${Colors.secondary50};
	font-size: ${FONT_SIZES.XXS};
	line-height: 13px;
	padding: 4px 0 4px 6px;

	${Tag}:active & {
		color: ${Colors.secondary300};
	}
`;

export const DeleteIcon = styled.span`
	padding: 4px 6px;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		svg {
			path {
				fill: ${Colors.secondary300};
			}
		}
	}
`;

export const NodeContent = styled.li`
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 0px 8px;

	&:first-child {
		margin-top: 5px;
	}
`;

export const TextArea = styled(TextareaAutosize)`
	color: ${Colors.secondary800};
	font-size: ${FONT_SIZES.XS};
	line-height: 14px;
	padding: 11px;
	border: 1px solid ${Colors.secondary500};
	border-radius: 4px;
	transition: border-color 0.3s;
	resize: none;

	&:focus {
		border-color: ${Colors.primary300};
	}

	&::placeholder {
		color: ${Colors.secondary300};
	}
`;

export const Label = styled.span`
	color: ${Colors.secondary500};
	font-size: ${FONT_SIZES.XXS};
	line-height: 13px;
	position: absolute;
	z-index: 10;
	background: ${Colors.white};
	top: -5px;
	left: 20px;
	padding: 0 2px;
`;

export const Error = styled.span`
	color: ${Colors.warningRed};
	font-size: ${FONT_SIZES.XXS};
	line-height: 13px;
	left: 20px;
	padding: 2px 12px;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 8px;
`;

export const SaveButton = styled.button`
	color: ${Colors.secondary800};
	font-size: ${FONT_SIZES.BODY};
	line-height: 16px;
	text-decoration: underline;
	transition: color 0.3s;

	&:disabled {
		color: ${Colors.secondary300};
	}
`;

export const DiscardButton = styled.button`
	color: ${Colors.secondary800};
	font-size: ${FONT_SIZES.BODY};
	line-height: 16px;
	text-decoration: underline;
`;
