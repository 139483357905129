import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { SpinnerCircle } from '../../../../styles/global.styles';

export const Preloader = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const Circle = styled(SpinnerCircle)`
	width: 60px;
	height: 60px;
	border: 3px solid ${Colors.secondary100};
	border-bottom-color: ${Colors.primary300};
`;
