import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';
import { icon } from '../../../../styles/global.styles';

const listItemsHeight = '30px';

export const EmptyContainer = styled.div`
	margin-top: 8px;
`;

export const OptionsContainer = styled.ul`
	background-color: ${Colors.secondary800};
	margin-top: 8px;
	margin-bottom: 0;
	padding-left: 0;
	border-bottom: 1px solid ${Colors.secondary900};
	max-height: calc(${listItemsHeight} * 14.5);
	overflow-y: auto;
	scrollbar-gutter: stable;

	&::-webkit-scrollbar {
		background-color: ${Colors.secondary800};
		margin-right: 4px;
	}
`;

export const Option = styled.li`
	max-width: 250px;
	min-height: ${listItemsHeight};
	display: flex;
	align-items: center;
	padding: 3px 8px;
	cursor: pointer;
	transition: background-color 0.3s;
	list-style: none;

	&:hover {
		background-color: ${Colors.secondary900};
	}
`;

export const Name = styled.span<{ selected: boolean }>`
	color: ${({ selected }) => (selected ? Colors.secondary50 : Colors.secondary300)};
	font-size: ${FONT_SIZES.XS};
	line-height: 14px;
	padding-left: 7px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	transition: color 0.3s;
	margin-right: auto;

	${Option}:active && {
		color: ${Colors.secondary50};
	}
`;

export const DeleteButton = styled.button`
	min-width: fit-content;
	display: flex;
`;

export const DeleteIcon = styled.svg`
	${icon};
	display: none;
	opacity: 0;
	transition: opacity 0.3s;

	${Option}:hover && {
		display: block;
		opacity: 1;
	}
`;

export const BellIcon = styled.svg`
	${icon};
`;
