import { useEffect, useRef, useState } from 'react';

export const useResize = () => {
	const containerRef = useRef<HTMLElement | null>(null);
	const currentContainer = containerRef.current;
	const currentTree = containerRef.current?.firstChild?.firstChild?.firstChild?.firstChild?.lastChild;

	const [containerWidth, setContainerWidth] = useState(0);

	const [treeSize, setTreeSize] = useState<{ width: number; height: number }>({
		width: 0,
		height: 0,
	});

	useEffect(() => {
		function watchSizeChanged(e: ResizeObserverEntry[]) {
			setContainerWidth(e[0].contentRect.width);
		}
		const observer = new ResizeObserver(watchSizeChanged);

		currentContainer instanceof Element && observer.observe(currentContainer);

		return () => {
			currentContainer instanceof Element && observer.unobserve(currentContainer);
		};
	}, [currentContainer]);

	useEffect(() => {
		function watchSizeChanged(e: ResizeObserverEntry[]) {
			setTreeSize({ width: e[0].contentRect.width, height: e[0].contentRect.height });
		}
		const observer = new ResizeObserver(watchSizeChanged);

		currentTree instanceof Element && observer.observe(currentTree);

		return () => {
			currentTree instanceof Element && observer.unobserve(currentTree);
		};
	}, [currentTree]);

	return { containerRef, treeSize, containerWidth };
};
