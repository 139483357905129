import axios from 'axios';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { mainUrl, synonymsUrl } from '../constants/api.constants';
import {
	selectedTaxonomyState,
	taxonomiesSelector,
	taxonomiesWithChangesState,
	taxonomyIdToDeleteState,
} from '../state/global.state';
import { fetchPendingChangesState } from '../state/history.state';
import { isAdminState, orgIdState, userIdState, userRoleTokenState } from '../state/user.state';
import type { IPendingChange, ITaxonomy, ITaxonomyTree, ITaxonomyWithChanges, ITreeLog } from '../types/taxonomy.types';
import { myTaxonomies } from './myTaxonomies.services';

const generateHeader = (token: string | undefined) => ({
	'Content-Type': 'application/json',
	Authorization: 'Bearer ' + token,
});

type postResponseType = {
	data: {
		url: string;
		fields: { [key: string]: string };
	};
};

const createRequest = async (
	method: string,
	token: string | undefined,
	url: string,
	fields?: { [key: string]: string | undefined },
) => {
	return await axios({
		method: method,
		url: url,
		headers: generateHeader(token),
		data: {
			...fields,
		},
	});
};

export const useGetTaxonomiesRequest = () => {
	const orgId = useRecoilValue(orgIdState);
	const token = useRecoilValue(userRoleTokenState);

	return async () => {
		const { data } = await createRequest('get', token, `${mainUrl}/${orgId}/taxonomies`);
		return data;
	};
};

export const useGetTaxonomyRequest = () => {
	const orgId = useRecoilValue(orgIdState);
	const token = useRecoilValue(userRoleTokenState);

	return async (taxonomyId: string) => {
		const { data } = await createRequest('get', token, `${mainUrl}/${orgId}/taxonomy/${taxonomyId}/download-url`);
		const { data: taxonomy } = await axios.get<ITaxonomyTree>(data.url);
		return taxonomy;
	};
};

export const useCreateTaxonomyRequest = () => {
	const orgId = useRecoilValue(orgIdState);
	const token = useRecoilValue(userRoleTokenState);

	return async ({ taxonomyName, taxonomy }: { taxonomyName?: string; taxonomy?: ITaxonomy }) => {
		const reqFields = {
			taxonomyName: taxonomyName ? taxonomyName : taxonomy?.taxonomyName,
		};
		const { data }: postResponseType = await createRequest(
			'post',
			token,
			`${mainUrl}/${orgId}/taxonomy/upload-url/create`,
			reqFields,
		);

		const { url, fields } = data;

		let newTaxonomy;
		if (taxonomyName) newTaxonomy = myTaxonomies.create(orgId, fields.key, taxonomyName);
		if (taxonomy) newTaxonomy = myTaxonomies.add(orgId, taxonomy, fields.key);

		const formData = new FormData();

		Object.entries(fields).forEach(([key, value]) => {
			formData.append(key, value);
		});

		const file = new Blob([JSON.stringify(newTaxonomy?.tree)], { type: 'application/json' });

		formData.append('file', file);

		await axios.post(url, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});

		return newTaxonomy as ITaxonomy;
	};
};

export const useUpdateTaxonomyRequest = () => {
	const orgId = useRecoilValue(orgIdState);
	const { taxonomyId, taxonomyName, tree, originId } = useRecoilValue(selectedTaxonomyState);
	const token = useRecoilValue(userRoleTokenState);

	return async ({ name }: { name?: string }) => {
		const newTaxonomyId = originId ? originId : taxonomyId;

		const { data }: postResponseType = await createRequest(
			'post',
			token,
			`${mainUrl}/${orgId}/taxonomy/${newTaxonomyId}/upload-url/update`,
			{
				taxonomyId: newTaxonomyId,
				taxonomyName: name ? name : taxonomyName,
				orgId,
			},
		);

		const { url, fields } = data;
		const formData = new FormData();

		Object.entries(fields).forEach(([key, value]) => {
			formData.append(key, value);
		});

		const file = new Blob([JSON.stringify(tree)], { type: 'application/json' });
		formData.append('file', file);

		await axios.post(url, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
	};
};

export const useRenameTaxonomyRequest = () => {
	const orgId = useRecoilValue(orgIdState);
	const { taxonomyId } = useRecoilValue(selectedTaxonomyState);
	const token = useRecoilValue(userRoleTokenState);

	return async ({ name }: { name: string }) => {
		await createRequest('put', token, `${mainUrl}/${orgId}/taxonomy/${taxonomyId}`, {
			taxonomyName: name,
		});
	};
};

export const useDeleteTaxonomyRequest = () => {
	const taxonomyIdToDelete = useRecoilValue(taxonomyIdToDeleteState);
	const orgId = useRecoilValue(orgIdState);
	const token = useRecoilValue(userRoleTokenState);

	return async () => await createRequest('delete', token, `${mainUrl}/${orgId}/taxonomy/${taxonomyIdToDelete}`);
};

export const getSynonymsRequest = async (query: string) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const res = await axios.get(`${synonymsUrl}/${query}`);
	return res.data;
};

//Not used
export const useGetDeletedTaxonomiesRequest = () => {
	const orgId = useRecoilValue(orgIdState);
	const token = useRecoilValue(userRoleTokenState);

	return async () => {
		const { data } = await createRequest('delete', token, `${mainUrl}/${orgId}/taxonomies/deleted`);
		return data;
	};
};

//Not used
export const useGetTaxonomyHistoryRequest = () => {
	const { taxonomyId } = useRecoilValue(selectedTaxonomyState);
	const orgId = useRecoilValue(orgIdState);
	const token = useRecoilValue(userRoleTokenState);

	return async () => {
		const { data } = await createRequest('get', token, `${mainUrl}/${orgId}/taxonomy/${taxonomyId}/audit-actions`);
		return data.items;
	};
};

export const useCreateChangeRequest = () => {
	let { versionId } = useRecoilValue(selectedTaxonomyState);
	const { originId } = useRecoilValue(selectedTaxonomyState);
	const setSelectedTaxonomy = useSetRecoilState(selectedTaxonomyState);

	const orgId = useRecoilValue(orgIdState);
	const token = useRecoilValue(userRoleTokenState);

	return async (change: ITreeLog[]) => {
		if (!versionId) {
			const { data } = await createRequest('get', token, `${mainUrl}/${orgId}/taxonomy/${originId}/download-url`);
			versionId = data.version_id;
			setSelectedTaxonomy(prev => ({ ...prev, versionId: data.version_id }));
		}

		const { data }: postResponseType = await createRequest(
			'post',
			token,
			`${mainUrl}/${orgId}/taxonomy/${originId}/version/${versionId}/change/create`,
			{
				change: JSON.stringify(change),
			},
		);

		return data;
	};
};

//Not used
export const useUpdateChangeRequest = () => {
	const isAdmin = useRecoilValue(isAdminState);
	const { versionId, originId, taxonomyId } = useRecoilValue(selectedTaxonomyState);
	const orgId = useRecoilValue(orgIdState);
	const userId = useRecoilValue(userIdState);
	const token = useRecoilValue(userRoleTokenState);

	return async (changeId: string, status: 'approved' | 'rejected') => {
		const { data }: postResponseType = await createRequest(
			'put',
			token,
			`${mainUrl}/${orgId}/taxonomy/${originId ? originId : taxonomyId}/version/${versionId}/change/${changeId}`,
			{
				userId,
				// waiting for change userRole to boolean in backend
				userRole: isAdmin ? 'admin' : 'member',
				status,
			},
		);
		return data;
	};
};

export const useGetPendingChangesRequest = () => {
	const { taxonomyId, versionId } = useRecoilValue(fetchPendingChangesState);
	const isAdmin = useRecoilValue(isAdminState);
	const orgId = useRecoilValue(orgIdState);
	const token = useRecoilValue(userRoleTokenState);

	return async () => {
		if (!isAdmin) return;

		const { data } = await createRequest(
			'get',
			token,
			`${mainUrl}/${orgId}/taxonomy/${taxonomyId}/version/${versionId}/changes/pending`,
		);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return data.items.map((change: any) => ({ ...change, data: JSON.parse(change.data) }));
	};
};

export const useGetAllPendingChangesRequest = (): (() => Promise<ITaxonomyWithChanges[]>) => {
	const taxonomies = useRecoilValue(taxonomiesSelector);
	const isAdmin = useRecoilValue(isAdminState);
	const orgId = useRecoilValue(orgIdState);
	const token = useRecoilValue(userRoleTokenState);
	const [taxonomiesWithChanges, setTaxonomiesWithChanges] = useRecoilState(taxonomiesWithChangesState);

	return async () => {
		if (!isAdmin || !taxonomies) return Promise.resolve([]);
		const originalTaxonomies = taxonomies.filter(taxonomy => !taxonomy.isDraft);
		try {
			for (const taxonomy of originalTaxonomies) {
				const { data } = await createRequest(
					'get',
					token,
					`${mainUrl}/${orgId}/taxonomy/${taxonomy.taxonomyId}/version/${taxonomy.versionId}/changes/pending`,
				);

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const changes: IPendingChange[] = data.items.map((change: any) => ({
					...change,
					data: JSON.parse(change.data),
				}));
				setTaxonomiesWithChanges(prev => (prev ? [...prev, { ...taxonomy, changes }] : [{ ...taxonomy, changes }]));
			}
		} catch (error) {
			//eslint-disable-next-line no-console
			console.log(error);
		}
		return Promise.resolve(taxonomiesWithChanges);
	};
};

//Not used
export const useGetPendingUserChangesRequest = () => {
	const orgId = useRecoilValue(orgIdState);
	const token = useRecoilValue(userRoleTokenState);
	const { taxonomyId, versionId } = useRecoilValue(selectedTaxonomyState);

	return async () => {
		const { data } = await createRequest(
			'get',
			token,
			`${mainUrl}/${orgId}/taxonomy/${taxonomyId}/version/${versionId}/user-changes`,
		);
		return data;
	};
};

//Not used
export const useGetApprovedChangesRequest = () => {
	const orgId = useRecoilValue(orgIdState);
	const token = useRecoilValue(userRoleTokenState);
	const { taxonomyId, versionId } = useRecoilValue(selectedTaxonomyState);

	return async () => {
		const { data } = await createRequest(
			'get',
			token,
			`${mainUrl}/${orgId}/taxonomy/${taxonomyId}/version/${versionId}/changes/approved`,
		);
		return data;
	};
};

//Not used
export const useGetTaxonomyAuditRequest = () => {
	const { taxonomyId } = useRecoilValue(selectedTaxonomyState);
	const token = useRecoilValue(userRoleTokenState);

	return async () => {
		const { data } = await createRequest('get', token, `${mainUrl}/audit-actions/${taxonomyId}`);
		return data;
	};
};

//Not used
export const useGetUserChangesRequest = () => {
	const orgId = useRecoilValue(orgIdState);
	const userId = useRecoilValue(userIdState);
	const token = useRecoilValue(userRoleTokenState);
	const { taxonomyId, versionId } = useRecoilValue(selectedTaxonomyState);

	return async () => {
		const { data } = await createRequest(
			'get',
			token,
			`${mainUrl}/${orgId}/taxonomy/${taxonomyId}/version/${versionId}/user-changes`,
			{ userId, userRole: 'member' },
		);
		return data;
	};
};
