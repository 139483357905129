import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { MODAL_TESTS_IDS } from '../../../../constants/modals.constants';
import { TaxonomySchema } from '../../../../schemas/TaxonomySchema';
import { useRenameTaxonomyRequest } from '../../../../services/api.services';
import { useRenameTaxonomy } from '../../../../services/tree.services';
import { selectedTaxonomyState } from '../../../../state/global.state';
import { BaseForm } from '../../atomsComponents/BaseForm/BaseForm';
import { BaseModal, BaseModalProps } from '../../BaseModal';

type RenameTaxonomyModalProps = BaseModalProps & {
	defaultValue?: string | number;
	onClose: () => void;
};
export const RenameTaxonomyModal = ({ defaultValue, onClose, showModal }: RenameTaxonomyModalProps) => {
	const { isDraft } = useRecoilValue(selectedTaxonomyState);
	const RenameTaxonomyRequest = useRenameTaxonomyRequest();
	const renameTaxonomy = useRenameTaxonomy();

	const { isLoading, mutateAsync } = useMutation(['renameTaxonomy'], RenameTaxonomyRequest);

	const onSubmit = async (taxonomy: Record<string, string>) => {
		if (!isDraft) {
			await mutateAsync({ name: taxonomy.name });
			renameTaxonomy(taxonomy.name);
		}

		onClose();
	};

	return (
		<BaseModal title="Rename taxonomy" showModal={showModal} testid={MODAL_TESTS_IDS.RENAME_TAXONOMY_MODAL}>
			<BaseForm
				description="Type a new name for the taxonomy"
				fields={[{ fieldName: 'name', fieldType: 'input', defaultValue }]}
				isLoading={isLoading}
				onSubmit={onSubmit}
				onDiscard={onClose}
				submitBtnText="UPDATE NAME"
				scheme={TaxonomySchema()}
				validateOnLoad
			/>
		</BaseModal>
	);
};
