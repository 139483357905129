import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { icon } from '../../../../styles/global.styles';

export const EditButton = styled.button``;

export const EditIcon = styled.svg`
	${icon};

	${EditButton}:active & {
		path {
			fill: ${Colors.primary800};
		}
	}

	${EditButton}:disabled & {
		path {
			fill: ${Colors.primary100};
		}
	}
`;
