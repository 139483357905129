import { ReactZoomPanPinchRef } from '@pronestor/react-zoom-pan-pinch';
import { MutableRefObject } from 'react';

import { FitCanvasIcon, ZoomInIcon, ZoomOutIcon } from '../../../../assets/icons/tree';
import { useCollapseAllNodes } from '../../../../services/node.services';
import * as Style from './viewControls.styles';

interface ViewControlsProps {
	transformRef: MutableRefObject<ReactZoomPanPinchRef | null>;
}

export const ViewControls = ({ transformRef }: ViewControlsProps) => {
	const collapseAllNodes = useCollapseAllNodes();

	const centerCanvas = () => transformRef.current?.centerView();
	const zoomIn = () => transformRef.current?.zoomIn?.();
	const zoomOut = () => transformRef.current?.zoomOut?.();

	return (
		<Style.ViewControls>
			<Style.ViewControlsButtons>
				<Style.ViewControl onClick={zoomIn}>
					<ZoomInIcon />
				</Style.ViewControl>
				<Style.ViewControl onClick={centerCanvas}>
					<FitCanvasIcon />
				</Style.ViewControl>
				<Style.ViewControl onClick={zoomOut}>
					<ZoomOutIcon />
				</Style.ViewControl>
			</Style.ViewControlsButtons>
			<Style.ViewControlsButtons>
				<Style.ViewControl onClick={collapseAllNodes}>
					<Style.ButtonText>Collapse</Style.ButtonText>
				</Style.ViewControl>
			</Style.ViewControlsButtons>
		</Style.ViewControls>
	);
};
