import { atom, DefaultValue, selector } from 'recoil';

import { DEFAULT_TAXONOMY } from '../components/tree/tree.constants';
import { myTaxonomies } from '../services/myTaxonomies.services';
import type { IGroupedPendingChanges, IPendingChange, ITaxonomy, ITaxonomyWithChanges } from '../types/taxonomy.types';

export const isAppDisabledState = atom<boolean>({
	key: 'isAppDisabled',
	default: false,
});

const taxonomiesState = atom({
	key: 'taxonomies',
	default: selector({
		key: 'taxonomies/default',
		get: async () => await myTaxonomies.list,
	}),
	effects: [
		({ onSet }) => {
			onSet(async newValue => await myTaxonomies.save(newValue));
		},
	],
});

export const selectedTaxonomyState = atom<ITaxonomy>({
	key: 'selectedTaxonomy',
	default: { taxonomyId: '', taxonomyName: DEFAULT_TAXONOMY, tree: { nodes: [], edges: [], logs: [] } },
});

export const selectedTaxonomyCopyState = atom<ITaxonomy>({
	key: 'selectedTaxonomyCopy',
	default: undefined,
});

export const taxonomiesSelector = selector<ITaxonomy[]>({
	key: 'taxonomiesSelector',
	get: ({ get }) => get(taxonomiesState),
	set: ({ set }, newValue) => {
		if (!(newValue instanceof DefaultValue)) {
			const sortedList = newValue.sort((a, b) => a.taxonomyName.localeCompare(b.taxonomyName));
			set(taxonomiesState, sortedList);
		}
	},
});

export const taxonomiesNamesState = selector({
	key: 'taxonomiesNames',
	get: ({ get }) => get(taxonomiesState).map(taxonomy => taxonomy.taxonomyName),
});

export const isDefaultTaxonomyState = selector({
	key: 'isDefaultTaxonomy',
	get: ({ get }) => get(selectedTaxonomyState).taxonomyName === DEFAULT_TAXONOMY,
});

export const isSelectedDraftState = selector({
	key: 'isSelectedDraft',
	get: ({ get }) => get(selectedTaxonomyState)?.isDraft,
});

export const isSelectedSampleState = selector({
	key: 'isSelectedSample',
	get: ({ get }) => get(selectedTaxonomyState)?.taxonomyId === 'sample-taxonomy',
});

export const taxonomyIdToDeleteState = atom<string>({
	key: 'taxonomyIdToDelete',
	default: undefined,
});

export const taxonomyToFetchState = atom<ITaxonomy>({
	key: 'taxonomyToFetch',
	default: { taxonomyId: '', taxonomyName: DEFAULT_TAXONOMY, tree: { nodes: [], edges: [], logs: [] } },
});

export const taxonomyToImportState = atom<ITaxonomy>({
	key: 'taxonomyToImport',
	default: undefined,
});

export const tempFileNameState = atom({
	key: 'tempFileName',
	default: '',
});

export const taxonomyErrorState = atom<string>({
	key: 'importError',
	default: undefined,
});

export const taxonomiesWithChangesState = atom<ITaxonomyWithChanges[]>({
	key: 'taxonomiesWithChanges',
	default: undefined,
});

export const taxonomiesWithChangesSelector = selector<IGroupedPendingChanges[]>({
	key: 'taxonomiesWithChangesSelector',
	get: ({ get }) => {
		const taxonomies = get(taxonomiesWithChangesState);
		const changesByTaxonomy = taxonomies
			?.filter(taxonomy => !taxonomy.isDraft)
			?.filter(taxonomy => taxonomy.changes.length > 0)
			?.reduce<{ [key: string]: { taxonomyId: string; setsOfChanges: IPendingChange[] } }>((acc, curr) => {
				const { taxonomyName, taxonomyId, changes } = curr;
				acc[taxonomyName] = {
					setsOfChanges: changes,
					taxonomyId,
				};
				return acc;
			}, {});

		return (
			changesByTaxonomy &&
			Object.keys(changesByTaxonomy).map(key => ({
				taxonomyName: key,
				changes: changesByTaxonomy[key].setsOfChanges,
				taxonomyId: changesByTaxonomy[key].taxonomyId,
			}))
		);
	},
});
