import { NodeDragType } from 'reaflow';

export const TREE_TESTS_IDS = {
	TREE: 'TREE',
	SHOW_NO_TREE: 'SHOW_NO_TREE',
	HIDE_NEIGHBOURS: 'HIDE_NEIGHBOURS',
	PATH_TO_ROOT: 'PATH_TO_ROOT',
	ADD_NODE: 'TREE_ADD_NODE',
	MOVE_NODE: 'MOVE_NODE',
	RENAME_NODE: 'RENAME_NODE',
	DELETE_NODE: 'DELETE_NODE',
	CONNECT_NODES: 'CONNECT_NODES',
	CONNECT_MENU: 'CONNECT_MENU',
	CONNECT_BUTTON: 'CONNECT_BUTTON',
	CONNECT_CANCEL_BUTTON: 'CONNECT_CANCEL_BUTTON',
	PRIMARY_CHECKBOX: 'PRIMARY_CHECKBOX',
	EXPANDABLE_ICON: 'EXPANDABLE_ICON',
	MULTIPLE_ICON: 'MULTIPLE_ICON',
	REMOVE_EDGE: 'REMOVE_EDGE',
	PAGINATION_NEXT: 'PAGINATION_NEXT',
	PAGINATION_PREVIOUS: 'PAGINATION_PREVIOUS',
	PAGINATION_TEXT: 'PAGINATION_TEXT',
	PATH_TOGGLE: 'PATH_TOGGLE',
	DELETE_PATH: 'DELETE_PATH',
	NODE_ACTIONS: 'NODE_ACTIONS',
	PATH_ACTIONS: 'PATH_ACTIONS',
};

export const TREE_SETTINGS = {
	DRAG_CURSOR: 'grab',
	DIRECTION: 'RIGHT',
	ROOT_NODE: '100',
	MAX_SCALE: 3,
	MIN_SCALE: 0.5,
	WHEEL_STEP: 0.4,
};

export const wrapperStyle = { width: '100%', height: '100%' };

export const DRAG_TYPE: NodeDragType = 'node';

export const DEFAULT_TAXONOMY = 'Please select taxonomy';
