import { useRecoilValue } from 'recoil';

import { CSV_ERRORS } from '../../../constants/csv.constants';
import { MODAL_TESTS_IDS } from '../../../constants/modals.constants';
import { modalPropsState } from '../../../state/modal.state';
import { Colors } from '../../../styles/colors.styles';
import { BaseModalContent } from '../atomsComponents/BaseForm/BaseModalContent';
import { FormDescription, FormInfo } from '../atomsComponents/modalAtoms';
import { BaseModal, BaseModalProps } from '../BaseModal';

type ErrorModalProps = BaseModalProps & { onClose: () => void };

export const ExportErrorModal = ({ onClose, showModal }: ErrorModalProps) => {
	const { input } = useRecoilValue(modalPropsState);

	return (
		<BaseModal
			title="This is An Error Message "
			showModal={showModal}
			testid={MODAL_TESTS_IDS.ERROR_MESSAGE}
			color={Colors.alert500}
		>
			<BaseModalContent discardBtnText={'OK'} onSubmit={onClose} onDiscard={onClose} isValid>
				<FormDescription>There was an error exporting the file.</FormDescription>
				<FormInfo>
					{input}
					{input === CSV_ERRORS.NO_DATA && `, ${CSV_ERRORS.EDIT_TAXONOMY}`}
				</FormInfo>
				<FormDescription>Please try again.</FormDescription>
			</BaseModalContent>
		</BaseModal>
	);
};
