import styled from 'styled-components';

import { Colors } from '../../../../styles/colors.styles';
import { FONT_SIZES } from '../../../../styles/fonts.styles';

export const MandatoryOptionsContainer = styled.ul`
	color: ${Colors.secondary50};
	font-size: ${FONT_SIZES.XS};
	line-height: 14px;
	width: 100%;
	min-height: 35px;
	margin: 0;
	padding: 0;
	font-weight: 400;
	border: none;
	list-style: none;
`;

export const MandatoryOption = styled.li<{ disabled?: boolean }>`
	background: ${Colors.secondary800};
	display: flex;
	white-space: pre;
	min-height: 20px;
	margin: 0;
	padding: 8px 15px;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
	transition: 0.3s;

	&:last-child {
		color: ${Colors.alert500};
		margin-bottom: 2px;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	&:hover {
		background: ${({ disabled }) => (disabled ? Colors.secondary800 : Colors.secondary900)};
	}
`;

export const ImportOption = styled.li`
	background-color: ${Colors.secondary800};
	min-height: 30px;
	padding-left: 15px;
	cursor: pointer;
	display: flex;
	align-items: center;
	transition: background-color 0.3s;

	&:hover {
		background-color: ${Colors.secondary900};
	}
`;
